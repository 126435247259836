import {getSmartMenuTemplateConfig} from '../../../api/smartMenuService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {SmartMenuTemplateConfig} from '../../../models/smartMenu/smart-menu-config';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {put, select} from 'redux-saga/effects';
import {getMenu} from '../../../api/menuService';
import {
  loadMenuSuccess,
  loadSmartMenuTemplateConfigError,
  loadSmartMenuTemplateConfigSuccess,
} from '../../actions/smartMenuActions';
import {Menu} from '../../../models/menu/menu';
import {State} from '../../reducers';

export function* loadMenuAsync(action: {type: string; payload: {id: string; language: string}}) {
  yield put(enableLoader());
  const language: string = yield select((state: State) => state.global.language);
  const response: CustomResponse<Menu[]> = yield getMenu(action.payload.id, action.payload.language ?? language);
  if (response.status === Status.OK) {
    yield put(loadMenuSuccess({menuList: response.payload}));
  }
  yield put(disableLoader());
}

const OPEN_SPICE_ID = '60811e2fb2d271001782e2ca';
export function* loadTemplateConfigAsync(action: {type: string; payload: {merchantID: string}}) {
  yield put(enableLoader());
  const response: CustomResponse<SmartMenuTemplateConfig> = yield getSmartMenuTemplateConfig(action.payload.merchantID);
  if (response.status === Status.OK) {
    if (action.payload.merchantID === OPEN_SPICE_ID && !response.payload) {
      yield put(
        loadSmartMenuTemplateConfigSuccess({
          templateConfig: {
            color: 'menuGold',
            fontFamily: 'Brygada1918',
            template: {type: 3},
            languages: [],
          },
        }),
      );
    } else {
      yield put(loadSmartMenuTemplateConfigSuccess({templateConfig: response.payload}));
    }
  } else {
    yield put(loadSmartMenuTemplateConfigError());
  }
  yield put(disableLoader());
}
