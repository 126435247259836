import {BASE_URL, http} from './axios';
import {Config} from '../models/globalConfig/config';
import {ActivityType, CreateActivityTypeRequest, UpdateActivityTypeRequest} from '../models/globalConfig/activity-type';
import {Faq, UpsertFaqRequest} from '../models/globalConfig/faq';
import {Food, UpsertFoodRequest} from '../models/globalConfig/food';
import {CustomResponse} from '../models/shared/custom-response';
import {getToken, getUserInfo} from '../services/storageService';
import {Coupon, CouponValidation} from '../models/globalConfig/coupon';

const CONTROLLER_PATH = '/global-config';

// activity type

export const getActivityTypes = (): Promise<CustomResponse<ActivityType[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/activity-type/get-all`);
};

export const addActivityType = async (
  activityType: CreateActivityTypeRequest,
): Promise<CustomResponse<ActivityType>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/activity-type/add`, activityType, {headers});
};

export const updateActivityType = async (
  activityType: UpdateActivityTypeRequest,
): Promise<CustomResponse<ActivityType>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/activity-type/update`, activityType, {headers});
};

export const deleteActivityType = async (id: string): Promise<CustomResponse<ActivityType[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.delete(`${BASE_URL}${CONTROLLER_PATH}/activity-type/${id}`, {headers});
};

// food

export const getFoods = (): Promise<CustomResponse<Food[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/food/get-all`);
};

export const addFood = async (body: UpsertFoodRequest): Promise<CustomResponse<Food>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/food/add`, body, {headers});
};

export const updateFood = async (body: UpsertFoodRequest): Promise<CustomResponse<Food>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/food/update`, body, {headers});
};

export const deleteFood = async (id: string): Promise<CustomResponse<Food[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.delete(`${BASE_URL}${CONTROLLER_PATH}/food/${id}`, {headers});
};

// faq

export const getFaqs = (): Promise<CustomResponse<Faq[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/faq/get-all`);
};

export const addFaq = async (body: UpsertFaqRequest): Promise<CustomResponse<Faq>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/faq/add`, body, {headers});
};

export const updateFaq = async (body: UpsertFaqRequest): Promise<CustomResponse<Faq>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/faq/update`, body, {headers});
};

export const deleteFaq = async (id: string): Promise<CustomResponse<Faq[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.delete(`${BASE_URL}${CONTROLLER_PATH}/faq/${id}`, {headers});
};

// configs

export const getConfigs = (userID = ''): Promise<CustomResponse<Config>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/configs`, {params: {userID}});
};

// coupon

export const isCouponValid = async (
  code: string,
  activityID?: string,
  userID?: string,
): Promise<CustomResponse<CouponValidation>> => {
  const userInfo = await getUserInfo();
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/coupon/is-valid`, {
    params: {code, ...(activityID && {activityID}), userID: userID || userInfo?.id},
  });
};

export const getCoupon = (code: string): Promise<CustomResponse<Coupon>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/coupon/get/${code}`);
};
