import {OrderStatus, OrderType} from '../order';

export interface SearchOrder {
  type: OrderType[];
  status: OrderStatus[];
  period: PeriodValue | string;
  queryOnReservationDate: boolean;
  reservationDay: Date | string;
  reservationHour: string;
  orderBy: OrderBy;
}

export enum PeriodValue {
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last-7-days',
  LAST_1_MONTH = 'last-1-month',
  LAST_6_MONTHS = 'last-6-months',
  LAST_12_MONTHS = 'last-12-months',
}

export enum OrderBy {
  CREATION_DATE = 'creation-date',
  RESERVATION_DATE = 'reservation-date',
}
