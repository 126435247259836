import {BASE_URL, http} from './axios';
import {CustomResponse} from '../models/shared/custom-response';
import {UpsertTableReservationSettingsDto} from '../models/tableReservationSettings/request-objects/upsert-table-reservation-settings.dto';
import {
  TableReservationSettings,
  TableReservationSettingsByDate,
} from '../models/tableReservationSettings/table-reservation-settings.interface';
import {getToken} from '../services/storageService';

const CONTROLLER_PATH = '/table-reservation-settings';

export const getTableReservationSettingsByDate = async (
  userID: string,
  date: Date,
  hour?: string,
): Promise<CustomResponse<TableReservationSettingsByDate>> => {
  let url = `${BASE_URL}${CONTROLLER_PATH}/get-settings-by-date?userID=${userID}&date=${date.toISOString()}`;
  if (hour) {
    url += `&hour=${hour}`;
  }
  return http.get(url);
};

export const getTableReservationSettings = async (
  userID?: string,
): Promise<CustomResponse<TableReservationSettings>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/get-settings`;
  if (userID) {
    url += `?userID=${userID}`;
  }
  return http.get(url, {headers});
};

export const updateTableReservationSettings = async (
  body: UpsertTableReservationSettingsDto,
): Promise<CustomResponse<TableReservationSettings>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}${CONTROLLER_PATH}/update`, body, {headers});
};
