import {Hidden, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useState} from 'react';
import iphone from '../../../../assets/images/smart-menu/desktop/iphone_2.png';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomCarousel from '../../../../components/molecules/CustomCarousel';
import ModalAdvantageDescription from './ModalAdvantageDescription';

const styles = (theme: Theme) => ({
  section: {
    width: '80%',
    margin: 'auto',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  iphone: {
    width: '300px',
  },
  advantage: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      alignItems: 'center',
      padding: '0 60px',
    },
  } as any,
});

const useStyles = makeStyles(styles);

export default function AdvantagesSection({id}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedAdvantage, setSelectedAdvantage] = useState<'no-paper' | 'customer-fidelity' | 'sales' | 'channels'>();
  const [open, setOpen] = useState(false);

  const openAdvantageDialog = (advantage: 'no-paper' | 'customer-fidelity' | 'sales' | 'channels') => {
    setSelectedAdvantage(advantage);
    setOpen(true);
  };

  const Advantage1 = () => (
    <div className={classes.advantage}>
      <CustomText color={'white'} fontFamily={'Goda'} center={isMobile}>
        STAMPI I MENU OGNI VOLTA CHE LI CAMBI?
      </CustomText>
      <CustomText color={'white'} underline center={isMobile} onClick={() => openAdvantageDialog('no-paper')}>
        Ecco perchè è uno spreco
      </CustomText>
    </div>
  );
  const Advantage2 = () => (
    <div className={classes.advantage}>
      <CustomText color={'white'} fontFamily={'Goda'} center={isMobile}>
        CONSOLIDA IL RAPPORTO COI CLIENTI.
      </CustomText>
      <CustomText color={'white'} underline center={isMobile} onClick={() => openAdvantageDialog('customer-fidelity')}>
        È arrivato il momento di diventare amici
      </CustomText>
    </div>
  );
  const Advantage3 = () => (
    <div className={classes.advantage}>
      <CustomText color={'white'} fontFamily={'Goda'} right={!isMobile} center={isMobile}>
        VORRESTI DIREZIONARE LE VENDITE NEL LOCALE?
      </CustomText>
      <CustomText
        color={'white'}
        underline
        right={!isMobile}
        center={isMobile}
        onClick={() => openAdvantageDialog('sales')}
      >
        Farlo è più semplice di quanto credi
      </CustomText>
    </div>
  );
  const Advantage4 = () => (
    <div className={classes.advantage}>
      <CustomText color={'white'} fontFamily={'Goda'} right={!isMobile} center={isMobile}>
        TIENI I CANALI SEMPRE AGGIORNATI TRA LORO.
      </CustomText>
      <CustomText
        color={'white'}
        underline
        right={!isMobile}
        center={isMobile}
        onClick={() => openAdvantageDialog('channels')}
      >
        Investi il tuo tempo solo nel locale
      </CustomText>
    </div>
  );

  const advantagesElements = [Advantage1, Advantage2, Advantage3, Advantage4];

  return (
    <div id={id}>
      <GridContainer
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
        fullWidth={true}
        className={classes.section}
      >
        <GridItem centerHorizontal xs={12} sm={12} md={12} marginTop>
          <CustomText center size={'md'} color={'white'}>
            Qual è il vantaggio di avere un menu digitale?
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} sm={12} md={12} marginTop>
          <CustomText center bold color={'white'} size={'xl'} fontFamily={'Goda'}>
            TRASFORMARE IL MENU NELLO STRUMENTO DIGITALE DI VENDITA PIÙ IMPORTANTE.
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} sm={12} md={12} marginTop>
          <CustomText center size={'md'} color={'white'}>
            Clicca le voci qui sotto per scoprirne alcuni vantaggi.
          </CustomText>
        </GridItem>
      </GridContainer>
      <Hidden xsDown>
        <GridContainer marginTop={100}>
          <GridItem centerHorizontal xs={12} sm={4} md={4}>
            <GridContainer style={{height: '100%'}}>
              <GridItem xs={12} alignItems={'start'} direction={'column'} justifyContent={'center'}>
                <Advantage1 />
              </GridItem>
              <GridItem xs={12} alignItems={'start'} direction={'column'}>
                <Advantage2 />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem justifyContent={'center'} xs={12} sm={4} md={4}>
            <img className={classes.iphone} src={iphone} alt="logo" />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <GridContainer style={{height: '100%'}}>
              <GridItem xs={12} alignItems={'end'} direction={'column'} justifyContent={'center'}>
                <Advantage3 />
              </GridItem>
              <GridItem xs={12} alignItems={'end'} direction={'column'}>
                <Advantage4 />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Hidden>
      <Hidden smUp>
        <GridContainer>
          <GridItem xs={12} style={{padding: '100px 0'}}>
            <CustomCarousel height={'auto'} indicators>
              {advantagesElements.map((Advantage) => (
                <Advantage />
              ))}
            </CustomCarousel>
          </GridItem>
          <GridItem xs={12} centerHorizontal>
            <img className={classes.iphone} src={iphone} alt="logo" />
          </GridItem>
        </GridContainer>
      </Hidden>
      <ModalAdvantageDescription open={open} onClose={() => setOpen(false)} advantage={selectedAdvantage} />
    </div>
  );
}

type Props = {
  id?: string;
};
