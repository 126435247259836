import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';
import {infoColor, WHITE} from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  customIconWrapper: {
    width: 72,
    height: 72,
    marginRight: 4,
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },

  whiteIconElement: {
    fill: WHITE,
  },

  infoIconElement: {
    fill: infoColor,
  },
}));

const CInfoIcon = ({}: Props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Icon className={classes.customIconWrapper}>
        <svg>
          <g id="Info">
            <circle id="InfoCircle" className={classes.infoIconElement} cx="36" cy="33" r="14.5" />
            <path
              id="Border_3_"
              className={classes.whiteIconElement}
              d="M36,50.5c-9.6,0-17.5-7.9-17.5-17.5S26.4,15.5,36,15.5S53.5,23.4,53.5,33
		S45.6,50.5,36,50.5z M36,18.5c-8,0-14.5,6.5-14.5,14.5S28,47.5,36,47.5S50.5,41,50.5,33S44,18.5,36,18.5z"
            />
            <path
              id="InfoCustomIcon"
              className={classes.whiteIconElement}
              d="M34.5,27.8c0-0.3,0.1-0.6,0.4-0.9c0.3-0.2,0.6-0.4,1.1-0.4c0.5,0,0.8,0.1,1.1,0.4
		c0.3,0.2,0.4,0.5,0.4,0.9s-0.1,0.6-0.4,0.9c-0.3,0.2-0.7,0.4-1.1,0.4c-0.5,0-0.8-0.1-1.1-0.4C34.6,28.5,34.5,28.2,34.5,27.8z
		 M34.8,30.4h2.3v9h-2.3V30.4z"
            />
          </g>
        </svg>
      </Icon>
    </React.Fragment>
  );
};

type Props = {};

export default CInfoIcon;
