import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import {Spacing} from '../../../../styles/spacing';
import {Add, Remove} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import CustomRow from '../../../../components/atoms/CustomRow';
import {OrderCartItem} from '../../../../models/orders/order-cart-item';
import {CategoryForMenu} from '../../../../models/menu/category';
import {useEffect, useState} from 'react';

const ModalItemFooter = ({
  itemOrder,
  onRemoveFromOrder,
  hideAddButton,
  upsertOrder,
  category,
  canConfirm,
  totalPrice,
}: Props) => {
  const classes = useStyle();
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (itemOrder) {
      setCount(itemOrder.count);
    }
  }, [itemOrder]);

  const add = () => {
    setCount(count + 1);
  };

  const remove = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  return (
    <>
      <div className={classes.addToOrderStyle}>
        {!hideAddButton && (
          <CustomRow center>
            <CustomButton disabled={count < 2} size={'sm'} icon={Remove} onClick={remove} />
            <CustomText bold size={'lg'}>
              {count}
            </CustomText>
            <CustomButton size={'sm'} icon={Add} onClick={add} />
          </CustomRow>
        )}
        {!hideAddButton && itemOrder && (
          <CustomRow marginTop>
            <CustomButton
              fullWidth
              simple
              color={'primary'}
              onClick={onRemoveFromOrder}
              title={"Rimuovi dall'ordine"}
            />
          </CustomRow>
        )}
        {!hideAddButton ? (
          <CustomRow>
            <CustomButton fullWidth onClick={() => upsertOrder(count)} disabled={!canConfirm}>
              <CustomRow full className={classes.buttonAddToOrderContent}>
                <CustomText size={'sm'} color={'white'} className={classes.textAddToOrder}>
                  {!itemOrder ? "Aggiungi all'ordine" : 'Aggiorna'}
                </CustomText>
                <div className={classes.totalPrice}>
                  <CustomText size={'sm'} color={'white'}>
                    {totalPrice * count} €
                  </CustomText>
                </div>
              </CustomRow>
            </CustomButton>
          </CustomRow>
        ) : (
          <CustomRow center>
            <CustomText center>
              <b>Attualmente</b> l’attività non permette di effettuare ordini.
            </CustomText>
          </CustomRow>
        )}
        {category && category.options && category.options.filter((o) => o.required).length > 0 && (
          <CustomRow marginTop={4} center>
            <CustomText size={'xs'} center>
              Selezionare le opzioni obbligatorie per poter aggiungere l'elemento
            </CustomText>
          </CustomRow>
        )}
      </div>
    </>
  );
};

type Props = {
  itemOrder?: OrderCartItem | null;
  onRemoveFromOrder?: any;
  hideAddButton?: boolean;
  upsertOrder?: any;
  category?: CategoryForMenu | null;
  canConfirm?: boolean;
  totalPrice: number;
};

const useStyle = makeStyles({
  addToOrderStyle: {
    padding: '0 20px 20px 20px',
    width: '100%',
    '@media (max-width: 600px)': {
      padding: '0 30px 20px 30px',
    },
  },
  totalPrice: {
    flex: 1,
    textAlign: 'right',
    marginRight: Spacing.SCALE_8,
    '@media (max-width: 380px)': {
      textAlign: 'center',
      marginRight: 0,
    },
  },
  textAddToOrder: {
    marginLeft: '8px',
    '@media (max-width: 380px)': {
      flex: 1,
      marginLeft: 0,
    },
  },
  buttonAddToOrderContent: {
    '@media (max-width: 380px)': {
      display: 'initial',
    },
  },
});

export default ModalItemFooter;
