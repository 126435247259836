import {makeStyles} from '@material-ui/styles';
import whiteBackground from '../../../../assets/images/smart-menu/desktop/background_carta.jpg';
import CustomDialog from '../../../../components/atoms/CustomDialog';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Spacing} from '../../../../styles/spacing';

const ModalAdvantageDescription = ({style, open, onClose, advantage}: Props) => {
  const classes = useStyle();

  const getTitle = () => {
    switch (advantage) {
      case 'channels':
        return 'Tieni i canali sempre aggiornati tra loro';
      case 'customer-fidelity':
        return 'Consolida il rapporto coi clienti';
      case 'sales':
        return 'Vorresti direzionare le vendite nel locale?';
      case 'no-paper':
        return 'Stampi i menù ogni volta che li cambi?';
    }
  };

  const getDescription = () => {
    switch (advantage) {
      case 'channels':
        return (
          <>
            <CustomRow center>
              <CustomText center color={'primary'} size={'md'} bold>
                (INVESTI IL TUO TEMPO NEL LOCALE, NON ALL’AGGIORNAMENTO DEI CANALI)
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                Collega il menu al tuo sito e ai tuoi social per avere tutti i canali sincronizzati.
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                Tu risparmi tempo aggiornando un solo strumento e i clienti avranno tra le mani il menu{' '}
                <CustomText color={'black'} size={'md'} bold>
                  sempre aggiornato
                </CustomText>{' '}
                in{' '}
                <CustomText color={'black'} size={'md'} bold>
                  tempo reale
                </CustomText>
                .
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'} italic>
                Così siamo contenti tutti, no?
              </CustomText>
            </CustomRow>
          </>
        );
      case 'customer-fidelity':
        return (
          <>
            <CustomRow center>
              <CustomText center color={'primary'} size={'md'} bold>
                (SI, È ARRIVATO IL MOMENTO DI DIVENTARE AMICI)
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                non fermarti al solo inserimento dei piatti: sfruttaci per raccontare ai clienti la tua{' '}
                <CustomText color={'black'} size={'md'} bold underline>
                  filosofia, storia e preparazione
                </CustomText>
                .
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                Durante l’esperienza il cliente è "caldo" ed è il momento giusto per rafforzare il rapporto.
              </CustomText>
            </CustomRow>
          </>
        );
      case 'sales':
        return (
          <>
            <CustomRow center>
              <CustomText center color={'primary'} size={'md'} bold>
                (FARLO È COSÌ SEMPLICE CHE CI SENTIAMO QUASI IN COLPA…)
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                Aggiornare quotidianamente il menu permette di{' '}
                <CustomText color={'black'} size={'md'} bold underline>
                  mettere in evidenza i piatti da spingere
                </CustomText>{' '}
                giorno per giorno.
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                L’utilizzo di{' '}
                <CustomText color={'black'} size={'md'} bold underline>
                  fotografie
                </CustomText>{' '}
                per raccontare un piatto condiziona sensibilmente la scelta del cliente.
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'} italic>
                E falle ste’ foto, non serve essere professionisti, anche quelle del cellulare vanno benissimo.
              </CustomText>
            </CustomRow>
          </>
        );
      case 'no-paper':
        return (
          <>
            <CustomRow center>
              <CustomText center color={'primary'} size={'md'} bold>
                EDDAJE BASTA… PAGHI PER NULLA! E IN PIÙ SALVI L’AMBIENTE EVITANDO DI STAMPARE CARTA INUTILE.
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'}>
                Ti creiamo comodamente noi il listino e potrai modificarlo con un click quante volte vorrai.
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'} bold underline>
                Per sempre.
                <br />
                Per davvero.
              </CustomText>
            </CustomRow>
            <CustomRow center marginTop={16}>
              <CustomText center color={'black'} size={'md'} italic>
                Che vuoi più?
              </CustomText>
            </CustomRow>
          </>
        );
    }
  };

  return (
    <>
      <CustomDialog className={classes.dialogBox} title={getTitle()} open={open} onClose={onClose}>
        <div className={classes.modalContent}>{getDescription()}</div>
      </CustomDialog>
    </>
  );
};

type Props = {
  style?: any;
  open: boolean;
  onClose?: any;
  advantage?: 'no-paper' | 'customer-fidelity' | 'sales' | 'channels';
};

const useStyle = makeStyles({
  dialogBox: {
    backgroundImage: 'url(' + whiteBackground + ')',
    width: '80%',
  },
  modalContent: {
    width: '80%',
    margin: 'auto',
    paddingVertical: Spacing.SCALE_20,
    paddingHorizontal: Spacing.SCALE_20,
    borderRadius: 10,
  },
  iconContainer: {},
  closeContainer: {
    position: 'absolute',
    top: 0,
    right: 4,
  },
});

export default ModalAdvantageDescription;
