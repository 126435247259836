import {useState} from 'react';
import CustomRow from '../atoms/CustomRow';
import CustomInput from '../atoms/CustomInput/CustomInput';
import CustomButton from '../atoms/CustomButtons/CustomButton';
import {makeStyles} from '@material-ui/styles';
import {Spacing} from '../../styles/spacing';
import {WHITE} from '../../styles/colors';
import CustomRadioButton from '../atoms/CustomRadioButton';
import CustomDialog from '../atoms/CustomDialog';

const ModalAddMessage = ({
  style,
  open,
  onClose,
  onConfirm,
  optionsList,
  showMessageOptionValue,
  neutralOption,
}: Props) => {
  const classes = useStyle();
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');

  const _onConfirm = () => {
    const reasonLabel = optionsList?.find((o) => o.value === reason)?.label;
    if (reason && (!showMessageOptionValue?.includes(reason) || !message)) {
      onConfirm(reasonLabel);
      return;
    } else if (message && (showMessageOptionValue?.includes(reason) || !reason)) {
      if (reason && reason !== neutralOption) {
        onConfirm(`${reasonLabel}: ${message}`);
      } else {
        onConfirm(message);
      }
      return;
    }
    onConfirm();
  };

  return (
    <>
      <CustomDialog
        title={'Aggiungi un messaggio'}
        open={open}
        onClose={onClose}
        extraButtons={
          <>
            <CustomButton
              title={'Conferma'}
              onClick={() => {
                _onConfirm();
              }}
              color={'primary'}
            />
          </>
        }
      >
        <div className={classes.modalContent}>
          {optionsList && (
            <div>
              <CustomRadioButton
                textColor={'black'}
                value={reason}
                list={optionsList}
                onValueChange={(v) => setReason(v)}
              />
            </div>
          )}
          {((showMessageOptionValue?.includes(reason) && reason) || !optionsList) && (
            <CustomRow marginTop center>
              <CustomInput placeholder={'Messaggio...'} value={message} onChange={(t) => setMessage(t)} />
            </CustomRow>
          )}
        </div>
      </CustomDialog>
    </>
  );
};

type Props = {
  style?: any;
  open: boolean;
  optionsList?: {value: string; label: string}[];
  showMessageOptionValue?: string[];
  onClose?: any;
  onConfirm: (message?: string) => void;
  neutralOption?: string;
};

const useStyle = makeStyles({
  buttonContainer: {
    flex: 0,
    marginLeft: Spacing.SCALE_8,
  },
  modalContent: {
    backgroundColor: WHITE,
    // justifyContent: 'center',
    // alignItems: 'center',
    width: '100%',
    paddingVertical: Spacing.SCALE_20,
    paddingHorizontal: Spacing.SCALE_20,
    borderRadius: 10,
  },
  iconContainer: {},
  closeContainer: {
    position: 'absolute',
    top: 0,
    right: 4,
  },
});

export default ModalAddMessage;
