import {Theme} from '@material-ui/core';
import {
  BLACK,
  colorMap,
  dangerColor,
  facebookColor,
  grayColor,
  infoColor,
  ORDIFY_BOT_PRIMARY,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
  WHITE,
} from '../../../styles/colors';
import {fontFamilyMap} from '../../../styles/font';
import {Props} from './CustomButton';

const buttonStyle = (theme: Theme) =>
  ({
    button: {
      fontFamily: ({fontFamily}: Props) =>
        fontFamily ? fontFamilyMap[fontFamily] : '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      minHeight: 'auto',
      minWidth: 'auto',
      boxShadow:
        '0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)',
      border: 'none',
      borderRadius: '40px',
      position: 'relative',
      margin: '.3125rem 1px',
      textTransform: 'none',
      willChange: 'box-shadow, transform',
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      '&:hover,&:focus': {
        backgroundColor: grayColor,
        boxShadow:
          '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        position: 'relative',
        display: 'inline-block',
        top: '0',
        fontSize: '1.1rem',
        marginRight: '4px',
        verticalAlign: 'middle',
      },
      '& svg': {
        position: 'relative',
        display: 'inline-block',
        top: '0',
        width: '18px',
        height: '18px',
        marginRight: '4px',
        verticalAlign: 'middle',
      },
    },
    fullWidth: {
      width: '100%',
    },
    primary: {
      color: '#FFFFFF',
      backgroundColor: primaryColor,
      boxShadow:
        '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: primaryColor,
        boxShadow:
          '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
      },
    },
    info: {
      backgroundColor: infoColor,
      boxShadow:
        '0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: infoColor,
        boxShadow:
          '0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)',
      },
    },
    success: {
      backgroundColor: successColor,
      boxShadow:
        '0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: successColor,
        boxShadow:
          '0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)',
      },
    },
    warning: {
      backgroundColor: warningColor,
      boxShadow:
        '0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: warningColor,
        boxShadow:
          '0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)',
      },
    },
    danger: {
      backgroundColor: dangerColor,
      boxShadow:
        '0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: dangerColor,
        boxShadow:
          '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)',
      },
    },
    rose: {
      backgroundColor: roseColor,
      boxShadow:
        '0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: roseColor,
        boxShadow:
          '0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)',
      },
    },
    white: {
      color: primaryColor,
      '&,&:focus,&:hover,&:visited': {
        backgroundColor: WHITE,
        color: primaryColor,
      },
    },
    black: {
      '&,&:focus,&:hover,&:visited': {
        backgroundColor: BLACK,
        color: WHITE,
      },
    },
    twitter: {
      backgroundColor: '#55acee',
      color: '#fff',
      boxShadow:
        '0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12)',
      '&:hover,&:focus,&:visited': {
        backgroundColor: '#55acee',
        color: '#fff',
        boxShadow:
          '0 14px 26px -12px rgba(85, 172, 238, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 172, 238, 0.2)',
      },
    },
    facebook: {
      backgroundColor: facebookColor,
      color: '#fff',
      boxShadow:
        '0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: facebookColor,
        color: '#fff',
        boxShadow:
          '0 14px 26px -12px rgba(59, 89, 152, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(59, 89, 152, 0.2)',
      },
    },
    google: {
      backgroundColor: '#dd4b39',
      color: '#fff',
      boxShadow:
        '0 2px 2px 0 rgba(221, 75, 57, 0.14), 0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: '#dd4b39',
        color: '#fff',
        boxShadow:
          '0 14px 26px -12px rgba(221, 75, 57, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(221, 75, 57, 0.2)',
      },
    },
    github: {
      backgroundColor: '#333333',
      color: '#fff',
      boxShadow:
        '0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: '#333333',
        color: '#fff',
        boxShadow:
          '0 14px 26px -12px rgba(51, 51, 51, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(51, 51, 51, 0.2)',
      },
    },
    ordifyBot: {
      color: '#FFFFFF',
      backgroundColor: ORDIFY_BOT_PRIMARY,
      boxShadow:
        '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
      '&:hover,&:focus': {
        backgroundColor: ORDIFY_BOT_PRIMARY,
        boxShadow:
          '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
      },
    },
    simple: {
      '&,&:focus,&:hover,&:visited': {
        color: '#FFFFFF',
        background: 'white',
        boxShadow: 'none',
      },
      '&$primary': {
        '&,&:focus,&:hover,&:visited': {
          color: primaryColor,
        },
      },
      '&$info': {
        '&,&:focus,&:hover,&:visited': {
          color: infoColor,
        },
      },
      '&$success': {
        '&,&:focus,&:hover,&:visited': {
          color: successColor,
        },
      },
      '&$warning': {
        '&,&:focus,&:hover,&:visited': {
          color: warningColor,
        },
      },
      '&$danger': {
        '&,&:focus,&:hover,&:visited': {
          color: dangerColor,
        },
      },
    },
    transparent: {
      '&,&:focus,&:hover,&:visited': {
        color: 'inherit',
        background: 'transparent',
        boxShadow: 'none',
      },
    },
    transparent_inverted: {
      '&,&:focus,&:hover,&:visited': {
        color: primaryColor,
        background: 'transparent',
        boxShadow: 'none',
      },
    },
    disabled: {
      opacity: '0.65',
      pointerEvents: 'none',
    },
    lg: {
      padding: '1.75rem 2.25rem',
      fontSize: '22px',
      lineHeight: '1.333333',
      '@media (max-width: 480px)': {
        padding: '1.25rem 1.75rem',
        fontSize: '20px',
      },
    },
    md: {
      padding: '1.25rem 1.75rem',
      fontSize: '18px',
      lineHeight: '1.4',
      '@media (max-width: 480px)': {
        padding: '1rem 1.25rem',
        fontSize: '16px',
      },
    },
    sm: {
      padding: '1rem 1.25rem',
      fontSize: '14px',
      lineHeight: '1.5',
      '@media (max-width: 480px)': {
        padding: '0.75rem 1rem',
        fontSize: '14px',
      },
    },
    block: {
      width: '100% !important',
    },
    link: {
      '&,&:hover,&:focus': {
        backgroundColor: 'transparent',
        color: '#999999',
        boxShadow: 'none',
      },
    },
    noShadow: {
      boxShadow: 'none',
    },
    iconButton: {
      backgroundColor: 'transparent',
      minWidth: 0,
      padding: '10px',
      '&:hover': {
        backgroundColor: 'initial',
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: 'initial',
        boxShadow: 'none',
      },
    },
    icon: {
      display: 'flex',
    },
    iconPadding: {
      paddingRight: '10px',
    },
    iconColor: ({iconColor}: Props) => ({color: iconColor ? colorMap(iconColor) : primaryColor}),
    iconBorder: {
      padding: '5px',
      border: '2px solid',
      borderRadius: '50%',
    },
    btnBorder: {
      border: '3px solid',
      borderRadius: '50px',
    },
    iconBorderColor: ({iconColor}: Props) => ({borderColor: iconColor ? colorMap(iconColor) : primaryColor}),
    icon_padding_xs: {padding: '2px'},
    icon_padding_sm: {padding: '4px'},
    icon_padding_md: {padding: '8px'},
    icon_padding_lg: {padding: '12px'},
    imgMargin: {marginRight: '10px'},
  } as any);

export default buttonStyle as any;
