import {isProd} from '../utils/config';
import ReactPixel, {Data} from 'react-facebook-pixel';

export const FB_PIXEL_ID = '1158337131274606';

export class FbPixel {
  isPixelEnabled = () => isProd() && process.env.REACT_APP_FB_PIXEL_ENABLED === 'true';

  init = () => {
    if (this.isPixelEnabled()) {
      ReactPixel.init(FB_PIXEL_ID);
    }
  };

  pageView = () => {
    if (this.isPixelEnabled()) {
      ReactPixel.pageView();
    }
  };

  track = (title: string, data?: Data | any) => {
    if (this.isPixelEnabled()) {
      ReactPixel.track(title, data);
    }
  };

  trackCustom = (title: string, data?: Data | any) => {
    if (this.isPixelEnabled()) {
      ReactPixel.trackCustom(title, data);
    }
  };
}

export const fbPixel = new FbPixel();
