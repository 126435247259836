import {makeStyles} from '@material-ui/styles';
import {GRAY_DARK, WHITE} from '../../../styles/colors';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import {Menu} from '../../../models/menu/menu';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    borderRadius: '24px',
    backgroundColor: WHITE,
  },
  container: {
    backgroundColor: WHITE,
  },
  imageContainer: {
    backgroundColor: GRAY_DARK,
    height: '200px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 480px)': {
      height: '150px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  menuDescription: {
    width: '100%',
    backgroundColor: '#0000007F',
    backdropFilter: 'blur(2.5px)',
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '15px 20px',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
  },
}));

const MenuChoiceElement = ({menu, onClick}: Props) => {
  const classes = useStyle();

  return (
    <>
      <CustomCard className={classes.cardContainer} marginBottom onClick={() => onClick(menu)}>
        <div className={classes.imageContainer}>
          {menu.image && menu.image.url ? <img src={menu.image.url} className={classes.image} /> : <div />}
          <div className={classes.menuDescription}>
            <CustomRow center>
              <CustomText center size={'lg'} color={'white'} fontFamily={'Goda'} bold>
                {menu.name}
              </CustomText>
            </CustomRow>
            <CustomRow center>
              <CustomText center size={'md'} color={'white'} fontFamily={'Open Sans'}>
                {menu.description}
              </CustomText>
            </CustomRow>
          </div>
        </div>
      </CustomCard>
    </>
  );
};

type Props = {
  menu: Menu;
  onClick: (menu: Menu) => void;
};

export default MenuChoiceElement;
