import {createOrderAndPay} from '../../../api/orderService';
import {SearchOrder} from '../../../models/orders/request-objects/search-order';
import {UpsertOrderAndPay} from '../../../models/orders/request-objects/upsert-order-and-pay';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {PaymentInfo} from '../../../models/user/payment-info';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader, loadConfig} from '../../actions/globalActions';
import {createOrder, loadUserOrders} from '../../actions/ordersActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';
import {USER_ORDERS} from '../../../constants';
import stripeJs from '@stripe/stripe-js';

export default function* createOrderAndPayAsync(action: {
  type: string;
  payload: {body: UpsertOrderAndPay; stripeRef: stripeJs.Stripe; cardElement: stripeJs.StripeCardElement};
}) {
  yield put(enableLoader());
  const stripe = action.payload.stripeRef;
  const paymentInfo: PaymentInfo = yield select((state: State) => state.user.userInfo?.paymentInfo);
  if (action.payload.body.paymentMode === 'default') {
    const response: CustomResponse<any> = yield createOrderAndPay({
      upsertOrder: action.payload.body.upsertOrder,
      paymentMode: action.payload.body.paymentMode,
      paymentMethod: paymentInfo?.defaultPaymentMethod?.paymentMethodID,
    });
    if (response.status === Status.OK) {
      if (response.payload.clientSecret && response.payload.intentID) {
        const confirmRes: stripeJs.PaymentIntentResult = yield stripe.confirmCardPayment(response.payload.clientSecret);
        const afterConfirmResult: boolean = yield afterConfirmation(confirmRes, action);
        if (afterConfirmResult) {
          return;
        }
      } else {
        navigate(USER_ORDERS);
        setTimeout(() => {
          showToastMessage(
            AppMessageType.SUCCESS,
            "Ordine effettuato con successo.\nScarica l'app Ordify per ricevere notifiche sullo stato del tuo ordine!",
            10000,
          );
        }, 200);
        yield put(loadConfig());
        yield put(loadUserOrders({searchOrder: {} as SearchOrder}));
      }
    }
  } else if (action.payload.body.paymentMode === 'new-card') {
    const response: CustomResponse<any> = yield createOrderAndPay({
      upsertOrder: action.payload.body.upsertOrder,
      paymentMode: action.payload.body.paymentMode,
    });
    if (response.status === Status.OK) {
      const confirmRes: stripeJs.PaymentIntentResult = yield stripe.confirmCardPayment(response.payload.clientSecret, {
        payment_method: {card: action.payload.cardElement},
        setup_future_usage: 'off_session',
      });
      const afterConfirmResult: boolean = yield afterConfirmation(confirmRes, action);
      if (afterConfirmResult) {
        return;
      }
    }
  } else {
    const response: CustomResponse<any> = yield createOrderAndPay({
      upsertOrder: action.payload.body.upsertOrder,
      paymentMode: action.payload.body.paymentMode,
    } as UpsertOrderAndPay);
    if (response.status === Status.OK) {
      stripe.redirectToCheckout({sessionId: response.payload.sessionID});
      return;
    }
  }
  yield put(disableLoader());
}

function* afterConfirmation(
  confirmRes: stripeJs.PaymentIntentResult,
  action: {type: string; payload: {body: UpsertOrderAndPay; stripeRef: stripeJs.Stripe}},
) {
  if (!confirmRes.error && confirmRes.paymentIntent) {
    yield put(
      createOrder({
        upsertOrder: {
          ...action.payload.body.upsertOrder,
          paymentID: confirmRes.paymentIntent.id,
        },
      }),
    );
    return true;
  } else {
    showToastMessage(
      AppMessageType.ERROR,
      confirmRes.error.message ?? 'Errore generico durante il pagamento. Contatta il supporto.',
    );
    return false;
  }
}
