import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import html2PDF from 'jspdf-html2canvas';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import PageContainer from '../../../components/organisms/PageContainer';
import {HeaderConfigs} from '../../../components/organisms/types/header-configs';
import {SmartMenuTemplateConfig} from '../../../models/smartMenu/smart-menu-config';
import {loadMenu, loadSmartMenuTemplateConfig, setSelectedMenu} from '../../../redux/actions/smartMenuActions';
import {State} from '../../../redux/reducers';
import {fromBase64ToString, isMobileAgent} from '../../../utils/commonFunctions';
import MenuSimple from '../components/MenuSimple';

type PrintConfig = {
  hideImages: boolean;
  margin: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
};

const PRINT_CONFIG: PrintConfig = {
  hideImages: true,
  margin: {
    top: '10',
    right: '150',
    bottom: '100',
    left: '150',
  },
};

const headerConfigs: HeaderConfigs = {
  showLogo: false,
  showBackButton: false,
  showLoginButton: false,
  showHeaderLinksOnWeb: false,
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px 20px 40px 20px',
  },
}));

const PrintPdfMenu = ({}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation('smartmenu');
  const {activityID, menuID} = useParams<{activityID: string; menuID: string}>();
  const [searchParams] = useSearchParams();
  const [currentTemplateConfig, setCurrentTemplateConfig] = useState<SmartMenuTemplateConfig>();
  const [printConfig, setPrintConfig] = useState<PrintConfig>(PRINT_CONFIG);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const htmldoc = document.getElementsByTagName('html')[0];
        if (htmldoc) {
          await html2PDF(htmldoc, {
            jsPDF: {
              format: 'a4',
              unit: 'pt',
            },
            margin: {
              top: parseInt(printConfig.margin.top),
              bottom: parseInt(printConfig.margin.bottom),
              right: isMobileAgent() ? parseInt(printConfig.margin.right) : 10,
              left: isMobileAgent() ? parseInt(printConfig.margin.left) : 10,
            },
            html2canvas: {
              scale: Math.min(window.devicePixelRatio, 2),
            },
            init: async (doc) => {},
            success: async (pdf) => {
              if (isMobileAgent()) {
                await pdf.save('Menu.pdf', {returnPromise: true});
              } else {
                window.open(URL.createObjectURL(pdf.output('blob')), '_blank');
              }
            },
          });
        }
      }
    },
  });

  const menuList = useSelector((s: State) => s.smartMenu.menuList);
  const menu = useSelector((s: State) => s.smartMenu.selectedMenu);
  const templateConfig = useSelector((s: State) => s.smartMenu.templateConfig);
  const templateConfigLoading = useSelector((s: State) => s.smartMenu.templateConfigLoading);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    return () => {
      dispatch(setSelectedMenu({menu: null}));
    };
  }, []);

  useEffect(() => {
    if (activityID) {
      dispatch(loadMenu({id: activityID}));
      dispatch(loadSmartMenuTemplateConfig({merchantID: activityID}));
    }
  }, [activityID]);

  useEffect(() => {
    if (templateConfig) {
      setCurrentTemplateConfig(templateConfig);
    }
  }, [templateConfig]);

  useEffect(() => {
    handleConfigurationsParam();
  }, [searchParams]);

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      if (menuList.length === 1) {
        dispatch(setSelectedMenu({menu: menuList[0]}));
      } else if (menuID) {
        const _menu = menuList.find((m) => m.id === menuID);
        if (_menu) {
          dispatch(setSelectedMenu({menu: _menu}));
          return;
        }
      }
      if (menuList.length > 1 && !menu) {
        dispatch(setSelectedMenu({menu: menuList[0]}));
      }
    }
  }, [menuList, menuID]);

  useEffect(() => {
    if (menu && currentTemplateConfig) {
      handlePrint();
    }
  }, [menu, currentTemplateConfig]);

  const handleConfigurationsParam = () => {
    const configuration = searchParams.get('configuration');
    if (configuration) {
      const payload = fromBase64ToString(configuration);
      const config: PrintConfig = JSON.parse(payload);
      setPrintConfig({...PRINT_CONFIG, ...config});
    }
  };

  if (templateConfigLoading || !menu)
    return (
      <PageContainer
        hideFooter
        pageLoading={templateConfigLoading}
        headerConfigs={{...headerConfigs, showDrawer: false}}
      />
    );

  if (!currentTemplateConfig)
    return (
      <PageContainer
        hideFooter
        pageTitle={t('service_not_available.title')}
        headerConfigs={{...headerConfigs, showDrawer: false}}
      >
        <CustomRow center>
          <CustomText center>{t('service_not_available.description')}</CustomText>
        </CustomRow>
      </PageContainer>
    );

  return (
    <>
      <div ref={componentRef} className={classes.root}>
        <MenuSimple templateConfigs={currentTemplateConfig} hideImages={printConfig.hideImages} />
      </div>
    </>
  );
};

type Props = {};

export default PrintPdfMenu;
