import {verifyEmail} from '../../../api/authService';
import {Role} from '../../../models/auth/role';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {put, select} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';
import {LOGIN, SUBMIT_PHONE_NUMBER} from '../../../constants';
import {State} from '../../reducers';
import {
  AuthPayload,
  isEmailLoginPayload,
  isEmailRegistrationPayload,
  isMerchantRegistrationPayload,
} from '../../../models/auth/redux/auth-payload';
import {login} from '../../actions/authActions';

export default function* verifyEmailAsync(action: {type: string; payload: {token: string}}) {
  yield put(enableLoader());
  const response: CustomResponse<{
    isEmailVerified: boolean;
  }> = yield verifyEmail(action.payload.token);
  const authPayload: AuthPayload = yield select((state: State) => state.auth.authPayload);
  if (response.status === Status.OK && response.payload.isEmailVerified) {
    showToastMessage(AppMessageType.SUCCESS, response.message);
    if (isMerchantRegistrationPayload(authPayload)) {
      navigate(LOGIN);
      showToastMessage(
        AppMessageType.INFO,
        "Registrazione effettuata con successo. Riceverai una email e una notifica quando i nostri sistemi approveranno l'attività.",
      );
    } else if (isEmailLoginPayload(authPayload)) {
      yield put(
        login({
          email: authPayload.email,
          password: authPayload?.password,
        }),
      );
    } else if (isEmailRegistrationPayload(authPayload)) {
      navigate(SUBMIT_PHONE_NUMBER);
    }
  } else {
    showToastMessage(AppMessageType.ERROR, response.message);
  }
  yield put(disableLoader());
}
