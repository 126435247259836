import {registrationUser} from '../../../api/authService';
import {RegistrationUserPayload} from '../../../models/auth/registration';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {LocationInfo} from '../../../models/shared/location-info';
import {RegistrationOrigin, UserInfo} from '../../../models/user/user-info.interface';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';
import {VALIDATE_EMAIL} from '../../../constants';
import {EmailRegistrationPayload} from '../../../models/auth/redux/auth-payload';
import {setAuthData} from '../../actions/authActions';

export default function* registrationUserAsync() {
  yield put(enableLoader());
  const authPayload: EmailRegistrationPayload = yield select((state: State) => state.auth.authPayload);
  const location: LocationInfo = yield select((state: State) => state.user.userInfo?.userInfo?.location);
  const id: string = yield select((state: State) => state.user.userInfo?.id);
  const registrationUserPayload: RegistrationUserPayload = {
    id,
    location,
    email: authPayload.email,
    name: authPayload.name,
    surname: authPayload.surname,
    password: authPayload.password,
    passwordConfirm: authPayload.passwordConfirm,
    registrationOrigin: RegistrationOrigin.WEB,
  };
  const response: CustomResponse<UserInfo> = yield registrationUser(registrationUserPayload);

  if (response.status === Status.OK) {
    yield put(setAuthData({authData: {userInfo: response.payload}}));
    navigate(VALIDATE_EMAIL);
    showToastMessage(AppMessageType.INFO, 'Ti è stata inviata una mail per confermare la tua registrazione');
  }
  yield put(disableLoader());
}
