import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {upsertLoginInfo} from '../api/navigationInfoService';
import {
  ACTIVITY_DETAIL,
  ACTIVITY_DETAIL_MENU,
  ACTIVITY_DETAIL_SELECT_MENU,
  COMPLETE_ORDER,
  COMPLETE_TABLE_RESERVATION,
  DELETE_PROFILE_DATA,
  DOWNLOAD_REDIRECT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SET_NEW,
  HOME,
  LEGAL_TERMS,
  LOGIN,
  MAIL_LOGIN,
  ORDIFY_BOT,
  ORDIFY_BOT_THANK_YOU,
  PRIVACY,
  REGISTRATION,
  RESET_PASSWORD,
  SEARCH,
  SMART_MENU,
  SUBMIT_PHONE_NUMBER,
  USER_ORDERS,
  VALIDATE_EMAIL,
  VALIDATE_PHONE_NUMBER,
} from '../constants';
import ActivityDetail from '../pages/ActivityDetail/ActivityDetail';
import CompleteOrder from '../pages/CompleteOrder/CompleteOrder';
import CompleteTableOrder from '../pages/CompleteTableOrder/CompleteTableOrder';
import DeleteProfileData from '../pages/DeleteProfileData';
import DownloadRedirectPage from '../pages/DownloadRedirect/DownloadRedirect';
import LandingMainPage from '../pages/LandingPage/LandingMainPage';
import ForgotPassword from '../pages/Login/ForgotPassword/ForgotPassword';
import ForgotPasswordSetNew from '../pages/Login/ForgotPasswordSetNew/ForgotPasswordSetNew';
import Login from '../pages/Login/Login/Login';
import MailLogin from '../pages/Login/MailLogin/MailLogin';
import Registration from '../pages/Login/Registration/Registration';
import ResetPassword from '../pages/Login/ResetPassword/ResetPassword';
import SubmitPhoneNumber from '../pages/Login/SubmitPhoneNumber/SubmitPhoneNumber';
import ValidateEmail from '../pages/Login/ValidateEmail/ValidateEmail';
import ValidatePhoneNumber from '../pages/Login/ValidatePhoneNumber/ValidatePhoneNumber';
import OrdifyBotLandingPage from '../pages/OrdifyBotLandingPage';
import OrdifyBotThankYouPage from '../pages/OrdifyBotThankYouPage';
import LegalTermsPage from '../pages/PrivacyAndTerms/LegalTerms';
import PrivacyPage from '../pages/PrivacyAndTerms/Privacy';
import SearchRestaurants from '../pages/Search/SearchRestaurants';
import ChooseMenu from '../pages/SmartMenu/choose';
import SmartMenu from '../pages/SmartMenu/menu';
import PrintPdfMenu from '../pages/SmartMenu/PrintPdfMenu';
import SmartMenuLanding from '../pages/SmartMenuLanding';
import UserOrders from '../pages/UserProfile/UserOrders';
import {loginSuccess} from '../redux/actions/authActions';
import {loadConfig, setCurrentPage} from '../redux/actions/globalActions';
import {loadUserInfo, loadUserInfoSuccess, setUserLocationRadius} from '../redux/actions/userActions';
import store from '../redux/store';
import {logAnalyticsEvent} from '../services/firebaseService';
import {fbPixel} from '../services/fpixel';
import {historyHelp} from '../services/navigationService';
import {getLocationRadius, getToken, getUserInfo, setLocationRadius} from '../services/storageService';

export const RouterConfig = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const token = getToken();
      let radius = await getLocationRadius();
      if (!radius) {
        radius = 20000;
        setLocationRadius(radius);
      }
      dispatch(setUserLocationRadius({radius}));
      const userInfo = await getUserInfo();
      dispatch(loadUserInfoSuccess({userInfo}));
      dispatch(loadUserInfo()); // dopo aver caricato quelle vecchie dietro le quinte le aggiorno
      dispatch(loadConfig());
      if (token) {
        dispatch(loginSuccess());
      }
      if (userInfo && userInfo.id) {
        upsertLoginInfo({userID: userInfo.id});
      }
    };
    init();
    const unlisten = listen();
    onPageChange(historyHelp.location.pathname);
    return () => {
      unlisten();
    };
  }, []);

  const getPageTitle = (pathname: string) => {
    return `${pathname
      .slice(1)
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')} - Ordify`;
  };

  const onPageChange = (pathname: string) => {
    fbPixel.pageView();
    logAnalyticsEvent('page_view', {
      page_title: getPageTitle(pathname),
      page_location: pathname,
    });
    store.dispatch(setCurrentPage(pathname));
  };

  const listen = () => {
    return historyHelp.listen((listener) => {
      onPageChange(listener.location.pathname);
    });
  };

  return (
    <>
      <Routes>
        <Route path={HOME} element={<LandingMainPage />} />

        <Route path={PRIVACY} element={<PrivacyPage />} />
        <Route path={LEGAL_TERMS} element={<LegalTermsPage />} />
        <Route path={DOWNLOAD_REDIRECT} element={<DownloadRedirectPage />} />

        <Route path={SEARCH} element={<SearchRestaurants />} />
        <Route path={`${ACTIVITY_DETAIL}/:activityID`} element={<ActivityDetail />} />
        <Route path={`${ACTIVITY_DETAIL}/:activityID/${ACTIVITY_DETAIL_MENU}`} element={<SmartMenu />} />
        <Route path={`${ACTIVITY_DETAIL}/:activityID/${ACTIVITY_DETAIL_MENU}/:menuID`} element={<SmartMenu />} />
        <Route
          path={`${ACTIVITY_DETAIL}/:activityID/${ACTIVITY_DETAIL_MENU}/:menuID/print`}
          element={<PrintPdfMenu />}
        />
        <Route path={`${ACTIVITY_DETAIL}/:activityID/${ACTIVITY_DETAIL_MENU}/print`} element={<PrintPdfMenu />} />
        <Route path={`${ACTIVITY_DETAIL}/:activityID/${ACTIVITY_DETAIL_SELECT_MENU}`} element={<ChooseMenu />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={MAIL_LOGIN} element={<MailLogin />} />
        <Route path={REGISTRATION} element={<Registration />} />
        <Route path={VALIDATE_EMAIL} element={<ValidateEmail />} />
        <Route path={SUBMIT_PHONE_NUMBER} element={<SubmitPhoneNumber />} />
        <Route path={VALIDATE_PHONE_NUMBER} element={<ValidatePhoneNumber />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword goTo={''} />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={FORGOT_PASSWORD_SET_NEW} element={<ForgotPasswordSetNew />} />
        {/*private routes*/}
        <Route path={COMPLETE_ORDER} element={<CompleteOrder />} />
        <Route path={COMPLETE_TABLE_RESERVATION} element={<CompleteTableOrder />} />
        <Route path={USER_ORDERS} element={<UserOrders />} />

        <Route path={SMART_MENU} element={<SmartMenuLanding />} />
        <Route path={ORDIFY_BOT} element={<OrdifyBotLandingPage />} />
        <Route path={ORDIFY_BOT_THANK_YOU} element={<OrdifyBotThankYouPage />} />

        <Route path={DELETE_PROFILE_DATA} element={<DeleteProfileData />} />

        <Route path="*" element={<Navigate to={HOME} replace />} />
      </Routes>
    </>
  );
};
