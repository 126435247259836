import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {ActivityFilter, FilterType} from '../../../../models/user/activity-filter';
import {searchActivities, setActivitiesFiltersSelected} from '../../../../redux/actions/sectionUserActions';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../../components/atoms/CustomRow';
import ActivityFilterElement from './ActivityFilterElement';
import {State} from '../../../../redux/reducers';
import {makeStyles} from '@material-ui/styles';
import {WHITE} from '../../../../styles/colors';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: '20px 0 0',
    paddingLeft: '15px',
    overflowY: 'hidden',
    '@media (max-width: 767px)': {
      margin: '0 0 44px',
    },
  },
  confirmButtonWrapper: {
    '@media (max-width: 767px)': {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      padding: '10px 10px',
      backgroundColor: WHITE,
    },
  },
}));

const FilterActivities = (props: Props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [filtersActivityTypes, setFiltersActivityTypes] = useState<ActivityFilter[]>([]);
  const [filtersTags, setFiltersTags] = useState<ActivityFilter[]>([]);
  const [filtersGeneral, setFiltersGeneral] = useState<ActivityFilter[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<ActivityFilter[]>([]);

  useEffect(() => {
    setFiltersSelected([...props.filtersSelected]);
  }, []);

  useEffect(() => {
    setFiltersActivityTypes(props.filters.filter((f) => f.type === FilterType.ACTIVITY_TYPE));
    setFiltersTags(props.filters.filter((f) => f.type === FilterType.TAG));
    setFiltersGeneral(props.filters.filter((f) => f.type === FilterType.GENERAL));
    setFiltersSelected(props.filtersSelected);
  }, [props.filters]);

  const onConfirm = () => {
    dispatch(setActivitiesFiltersSelected({activitiesFilters: filtersSelected}));
    dispatch(searchActivities());
    props.onConfirmPressed && props.onConfirmPressed();
  };

  const elementClicked = (filter: ActivityFilter) => {
    let newFilterSelected = [...filtersSelected];
    const index = filtersSelected.findIndex((f) => f.value === filter.value);
    if (index === -1) {
      newFilterSelected.push(filter);
    } else {
      newFilterSelected.splice(index, 1);
    }
    setFiltersSelected(newFilterSelected);
  };

  return (
    <>
      <div className={classes.root}>
        {filtersGeneral.length > 0 && (
          <CustomRow>
            <CustomText fontFamily={'Goda'} size={'lg'} color={'primary'}>
              FILTRI GENERICI
            </CustomText>
          </CustomRow>
        )}
        {filtersGeneral.map((f, i) => (
          <ActivityFilterElement
            key={i}
            activityFilter={f}
            checked={!!filtersSelected.find((fs) => fs.value === f.value)}
            onPress={() => elementClicked(f)}
          />
        ))}
        {filtersActivityTypes.length > 0 && (
          <CustomRow marginTop>
            <CustomText fontFamily={'Goda'} size={'lg'} color={'primary'}>
              TIPI DI ATTIVITA'
            </CustomText>
          </CustomRow>
        )}
        {filtersActivityTypes.map((f, i) => (
          <ActivityFilterElement
            key={i}
            activityFilter={f}
            checked={!!filtersSelected.find((fs) => fs.value === f.value)}
            onPress={() => elementClicked(f)}
          />
        ))}
        {filtersTags.length > 0 && (
          <CustomRow marginTop>
            <CustomText fontFamily={'Goda'} size={'lg'} color={'primary'}>
              TAGS
            </CustomText>
          </CustomRow>
        )}
        {filtersTags.map((f, i) => (
          <ActivityFilterElement
            key={i}
            activityFilter={f}
            checked={!!filtersSelected.find((fs) => fs.value === f.value)}
            onPress={() => elementClicked(f)}
          />
        ))}
        <div className={classes.confirmButtonWrapper}>
          <CustomRow center>
            <CustomButton fullWidth title={'Conferma'} onClick={onConfirm} />
          </CustomRow>
        </div>
      </div>
    </>
  );
};

type Props = {
  filters: ActivityFilter[];
  filtersSelected: ActivityFilter[];
  onConfirmPressed?: () => void;
};

const mapStateToProps = (state: State) => ({
  filters: state.sectionUser.activitiesFiltersAll,
  filtersSelected: state.sectionUser.activitiesFiltersSelected,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FilterActivities);
