/* eslint-disable prettier/prettier */
import {getTableReservationSettingsByDate} from '../../../api/tableReservationSettingsService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {TableReservationSettingsByDate} from '../../../models/tableReservationSettings/table-reservation-settings.interface';
import {ActivityDetail} from '../../../models/user/activity-detail';
import {loadActivityDetailSuccess} from '../../actions/sectionUserActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {TableReservationForm} from '../../../models/tableReservationSettings/request-objects/upsert-table-reservation-settings.dto';

export default function* updateTableReservationInfoAsync(action: {type: string; payload: TableReservationForm}) {
  if (action.payload.day) {
    let userID: string = yield select((state: State) => state.sectionUser.activityDetail?.id);
    let activityDetail: ActivityDetail = yield select((state: State) => state.sectionUser.activityDetail);
    // yield put(enableLoader());
    const response: CustomResponse<TableReservationSettingsByDate> = yield getTableReservationSettingsByDate(
      userID,
      action.payload.day,
      action.payload.hour,
    );
    if (response.status === Status.OK) {
      yield put(
        loadActivityDetailSuccess({
          activityDetail: {
            ...activityDetail,
            tableReservationSettings: response.payload,
          },
        }),
      );
    }
    // yield put(disableLoader());
  }
}
