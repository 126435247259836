import {Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {HeaderLink} from '../../../components/organisms/types/header-link';
import {SmartMenuTemplateConfig} from '../../../models/smartMenu/smart-menu-config';
import {primaryColor} from '../../../styles/colors';

const useStyles = ({templateConfigs}: {templateConfigs: SmartMenuTemplateConfig}) => {
  return makeStyles((theme) => ({
    root: {},
    categoryContainer: {
      padding: '6px 6px',
      marginTop: '10px',
    },
    categoryContainerSelected: {
      padding: '6px 6px',
      marginTop: '10px',
      borderLeft: '1px solid ' + templateConfigs.color ?? primaryColor,
    },
    fixed: {
      position: 'fixed',
      top: '180px',
      overflowX: 'hidden',
      overflowY: 'scroll',
      height: 'calc(100% - 200px)',
      '&::-webkit-scrollbar': {display: 'none'},
      // direction: 'rtl',
      // '& > *': {
      //   direction: 'ltr',
      // },
      // '&::-webkit-scrollbar': {width: '2px'},
      // '&::-webkit-scrollbar-thumb': {backgroundColor: templateConfigs.color ?? primaryColor},
    },
  }))();
};

const HEADER_MARGIN = 250;

const MenuIndex = ({templateConfigs, links}: Props) => {
  const classes = useStyles({templateConfigs});
  const {t} = useTranslation('smartmenu');
  const [indexLinks, setIndexLinks] = useState<HeaderLink[]>([]);
  const [fixed, setFixed] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<any>({top: false});

  const menuIndexClasses = classNames({
    [classes.fixed]: fixed,
  });

  useEffect(() => {
    setIndexLinks(links);
  }, [links]);

  useEffect(() => {
    getAnchorPoints();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', getAnchorPoints);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
      window.removeEventListener('resize', getAnchorPoints, false);
    };
  }, [indexLinks]);

  const handleScroll = () => {
    const curPos = window.scrollY;
    let curSection = 'top';
    indexLinks.forEach((link) => {
      if (curPos + HEADER_MARGIN >= link.pos) {
        curSection = link.id;
      }
    });
    setFixed(curPos > 60);
    setActiveMenuItem({[curSection]: true});
  };

  const getAnchorPoints = () => {
    if (indexLinks.length > 0) {
      const curScroll = window.scrollY;
      const _links = indexLinks;
      _links.forEach((link: any, i: number) => {
        let elementById = document.getElementById(link.id);
        if (elementById) {
          link.pos = elementById.getBoundingClientRect().top + curScroll;
        }
      });

      /* Get the pixel height of the viewport */
      const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

      /* Get the pixel-depth of the bottom of the page */
      const bottom = document.body.offsetHeight;

      /* If max-scroll is less than the pixel-depth of latest section, then adjust the pixel-depth of section 3 to be 50px higher than max scroll depth ... allowing it to be an active destination */
      if (_links.length > 0) {
        if (viewPortHeight > bottom - _links[_links.length - 1].pos) {
          _links[_links.length - 1].pos = bottom - viewPortHeight + HEADER_MARGIN;
        }
      }
      setIndexLinks(_links);
      handleScroll();
    }
  };

  const categoryClicked = (id: string) => {
    const link = indexLinks.find((l) => l.id === id);
    window.scroll({top: link?.pos - 150, behavior: 'smooth'});
  };

  return (
    <>
      <Hidden mdDown>
        {indexLinks && indexLinks.length > 0 && (
          <div className={menuIndexClasses}>
            <CustomRow>
              <CustomText
                bold
                color={templateConfigs.color ?? 'primary'}
                fontFamily={templateConfigs.fontFamily ?? 'Goda'}
                size={'lg'}
              >
                {t('categories').toUpperCase()}
              </CustomText>
            </CustomRow>
            {indexLinks.length > 0 &&
              indexLinks.map((link) => (
                <div
                  className={!activeMenuItem[link.id] ? classes.categoryContainer : classes.categoryContainerSelected}
                  key={link.id}
                >
                  <CustomRow>
                    <CustomText
                      bold={activeMenuItem[link.id]}
                      color={activeMenuItem[link.id] ? templateConfigs.color ?? 'primary' : 'gray'}
                      size={'md'}
                      fontFamily={templateConfigs.fontFamily ?? 'Goda'}
                      onClick={() => categoryClicked(link.id)}
                    >
                      {link.label.toUpperCase()}
                    </CustomText>
                  </CustomRow>
                </div>
              ))}
          </div>
        )}
      </Hidden>
    </>
  );
};

type Props = {
  templateConfigs: SmartMenuTemplateConfig;
  links: HeaderLink[];
};

export default MenuIndex;
