import {makeStyles} from '@material-ui/styles';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Colors} from '../../../../styles/colors';

type Props = {
  icon: string;
  title: string;
  description: string;
  titleColor: Colors;
};

const useStyles = makeStyles({
  icon: {
    width: '65px',
    height: '65px',
    '@media (max-width: 767px)': {
      width: '45px',
      height: '45px',
    },
  },
});

export const Element = ({icon, title, description, titleColor}: Props) => {
  const classes = useStyles();
  return (
    <CustomRow>
      <CustomView center marginRight={30}>
        <img className={classes.icon} src={icon} alt={title} />
      </CustomView>
      <CustomView>
        <CustomRow>
          <CustomText fontFamily={'Montserrat'} color={titleColor} bold>
            {title}
          </CustomText>
        </CustomRow>
        <CustomRow marginTop>
          <CustomText fontFamily={'Montserrat'}>{description}</CustomText>
        </CustomRow>
      </CustomView>
    </CustomRow>
  );
};
