import {forceNewPassword, resetPassword} from '../../../api/authService';
import {ResetPasswordPayload} from '../../../models/auth/reset-password';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {put} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';

export default function* resetPasswordAsync(action: {
  type: string;
  payload: {
    resetPasswordPayload: ResetPasswordPayload;
    goTo: string;
    force: boolean;
  };
}) {
  yield put(enableLoader());
  let response: CustomResponse<any>;
  if (action.payload.force) {
    response = yield forceNewPassword(action.payload.resetPasswordPayload);
  } else {
    response = yield resetPassword(action.payload.resetPasswordPayload);
  }
  if (response.status === Status.OK) {
    navigate(action.payload.goTo);
    showToastMessage(AppMessageType.SUCCESS, response.message);
  }
  yield put(disableLoader());
}
