import React from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import styles from './cardHeaderStyle';

const useStyles = makeStyles(styles);

export default function CustomCardHeader({className, children, color, plain, light, shadow, ...rest}: Props) {
  const classes = useStyles({...rest, light});
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + 'CardHeader']]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderShadow]: shadow,
    ...(className && {[className]: className}),
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}

type Props = {
  className?: any;
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'white' | 'black' | 'gray';
  plain?: boolean;
  light?: boolean;
  shadow?: boolean;
  children: any;
};
