export interface SmartMenuProduct {
  productId: string;
  productType: ProductType;
  productName: string;
  priceId: string;
  priceAmount: number;
  priceRecurringInterval: PriceRecurringInterval;
  enabled: boolean;
}

export interface SubscriptionChoiceByPeriod {
  type: ProductType;
  name: string;
  enabled: boolean;
  products: SmartMenuProduct[];
}

export interface SubscriptionCreated {
  subscription: Subscription;
  clientSecret: string;
}

export interface Subscription {
  id: string;
  merchantID: string;
  stripeCustomerID: string;
  activationDate: Date;
  subscriptionStatus: SubscriptionStatusType;
  subscriptionId: string;
  productId: string;
  productType: ProductType;
  productName: string;
  priceId: string;
  priceAmount: number;
  priceRecurringInterval: PriceRecurringInterval;
  paymentMethod: string;
  trialWillEnd?: string;
}

export type PriceRecurringInterval = typeof PRICE_RECURRING_INTERVALS[keyof typeof PRICE_RECURRING_INTERVALS];
export type ProductType = typeof PRODUCT_TYPES[keyof typeof PRODUCT_TYPES];
export type SubscriptionStatusType = typeof SUBSCRIPTION_STATUS[keyof typeof SUBSCRIPTION_STATUS];

export const PRODUCT_TYPES = {
  STANDARD: 'standard',
  MEDIUM: 'medium',
  PREMIUM: 'premium',
} as const;

export const PRICE_RECURRING_INTERVALS = {
  MONTH: 'month',
  YEAR: 'year',
} as const;

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  PAUSED: 'paused',
} as const;
