import {makeStyles} from '@material-ui/styles';
import React from 'react';
import CustomRow from '../../../components/atoms/CustomRow';
import {allergens} from '../../../constants';
import {AllergenType} from '../../../models/menu/allergens';

const useStyles = makeStyles(() => ({
  allergen: {
    height: '20px',
    width: '20px',
  },
}));

export const AllergenList = ({allergenList}: Props) => {
  const classes = useStyles();
  return (
    <>
      {!!allergenList?.length && (
        <>
          {allergenList.map((a, i) => (
            <CustomRow key={a} marginRight={i !== allergenList.length - 1 ? 4 : 0}>
              <img className={classes.allergen} src={allergens[a].image} alt="allergen" />
            </CustomRow>
          ))}
        </>
      )}
    </>
  );
};

type Props = {
  allergenList?: AllergenType[];
};
