import {Theme} from '@material-ui/core';
import {Styles} from '@material-ui/styles/withStyles';
import {
  BLACK,
  dangerColor,
  GRAY_DARK,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
} from '../../../styles/colors';

const cardHeaderStyle = (theme: Theme) =>
  ({
    cardHeader: {
      marginTop: '0',
      border: '0',
      marginBottom: '0',
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
    cardHeaderPlain: {
      marginLeft: '0px',
      marginRight: '0px',
    },
    warningCardHeader: {
      color: (props: any) => (props.light ? warningColor : '#fff'),
      background: (props: any) => (props.light ? '#fff' : 'linear-gradient(60deg, #ffa726, #fb8c00)'),
    },
    successCardHeader: {
      color: (props: any) => (props.light ? successColor : '#fff'),
      background: (props: any) => (props.light ? '#fff' : 'linear-gradient(60deg, #66bb6a, #43a047)'),
    },
    dangerCardHeader: {
      color: (props: any) => (props.light ? dangerColor : '#fff'),
      background: (props: any) => (props.light ? '#fff' : 'linear-gradient(60deg, #ef5350, #e53935)'),
    },
    infoCardHeader: {
      color: (props: any) => (props.light ? infoColor : '#fff'),
      background: (props: any) => (props.light ? '#fff' : 'linear-gradient(60deg, #26c6da, #00acc1)'),
    },
    primaryCardHeader: {
      color: (props: any) => `${primaryColor} !important`,
      background: (props: any) => (props.light ? '#fff' : primaryColor),
    },
    grayCardHeader: {
      color: (props: any) => (props.light ? GRAY_DARK : '#fff'),
      background: (props: any) => (props.light ? '#fff' : GRAY_DARK),
    },
    whiteCardHeader: {
      color: BLACK,
      background: '#fff',
    },
    blackCardHeader: {
      color: '#fff',
      background: BLACK,
    },
    cardHeaderShadow: {
      boxShadow: '0 0px 10px -12px rgb(0 0 0 / 30%), 0 4px 8px 0px rgb(0 0 0 / 7%), 0 8px 10px -5px rgb(0 0 0 / 10%)',
    },
  } as Styles<Theme, any, any>);

export default cardHeaderStyle as any;
