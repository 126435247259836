import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';

const videoClasses = makeStyles({
  iframe: {
    borderRadius: '30px',
    width: '100%',
    height: '500px',
    '@media (max-width: 379px)': {
      height: '160px',
    },
    '@media (min-width: 380px) and (max-width: 480px)': {
      height: '190px',
    },
    '@media (min-width: 481px) and (max-width: 600px)': {
      height: '250px',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      height: '300px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      height: '350px',
    },
  },
  videoContainer: {
    padding: '50px 10px 50px',
    '@media (max-width: 480px)': {
      padding: '200px 10px 50px',
    },
    '@media (min-width: 481px) and (max-width: 767px)': {
      padding: '200px 10px 50px',
    },
  },
});

export default function VideoPresentationSection({id}: Props) {
  const classes = videoClasses();
  return (
    <div id={id} className={classes.videoContainer}>
      <GridContainer fullWidth={false} marginTop={-300}>
        <GridItem xs={12}>
          <iframe
            className={classes.iframe}
            src="https://www.youtube.com/embed/CWccmwWPG-M"
            title="SmartMenu - Video promo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
