import CustomButton from '../../atoms/CustomButtons/CustomButton';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import headerStyle from './headerStyle';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as Logo} from '../../../assets/images/landing/header/backSVG.svg';
import {colorMap, Colors, primaryColor} from '../../../styles/colors';

const useStyles = makeStyles(headerStyle);

const HeaderBackButton = ({absolute = true, color = 'primary'}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <CustomButton
        onClick={() => navigate(-1)}
        noShadow
        className={absolute ? classes.backContainer : undefined}
        color={'transparent'}
        img={<Logo fill={color ? colorMap(color) : primaryColor} />}
      />
    </>
  );
};

type Props = {
  absolute?: boolean;
  color?: Colors;
};

export default HeaderBackButton;
