import {checkOrder, createOrder} from '../../../api/orderService';
import {Order} from '../../../models/orders/order';
import {SearchOrder} from '../../../models/orders/request-objects/search-order';
import {UpsertOrder} from '../../../models/orders/request-objects/upsert-order';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader, loadConfig} from '../../actions/globalActions';
import {loadUserOrders} from '../../actions/ordersActions';
import {put} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';
import {USER_ORDERS} from '../../../constants';

export default function* createOrderAsync(action: {type: string; payload: {upsertOrder: UpsertOrder}}) {
  const resCheck: CustomResponse<Order> = yield checkOrder(action.payload.upsertOrder);
  if (resCheck.status === Status.OK) {
    yield put(enableLoader());
    const resCreateOrder: CustomResponse<Order> = yield createOrder(action.payload.upsertOrder);
    if (resCreateOrder.status === Status.OK) {
      navigate(USER_ORDERS);
      setTimeout(() => {
        showToastMessage(
          AppMessageType.SUCCESS,
          "Ordine effettuato con successo.\nScarica l'app Ordify per ricevere notifiche sullo stato del tuo ordine!",
          10000,
        );
      }, 200);
      yield put(loadConfig());
      yield put(loadUserOrders({searchOrder: {} as SearchOrder}));
    }
  }
  yield put(disableLoader());
}
