import {Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import redBackground from '../../../../assets/images/smart-menu/desktop/background_rosso.jpg';
import iphoneHeader from '../../../../assets/images/smart-menu/desktop/iphone.png';
import redBackgroundMobile from '../../../../assets/images/smart-menu/mobile/background_rosso_mobile.jpg';
import iphoneHeaderMobile from '../../../../assets/images/smart-menu/mobile/iphone.png';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import ContentResponsive from '../../../../components/molecules/ContentResponsive';
import BackgroundImage from '../../../../components/organisms/BackgroundImage';

const styles = (theme: Theme) => ({
  boxContainer: {
    height: '800px',
    '@media (min-width: 0px) and (max-width: 768px)': {
      height: '800px',
    },
  },
  boxContainerTitle: {
    marginTop: '250px',
    '@media (min-width: 320px) and (max-width: 768px)': {
      marginTop: '200px',
    },
  },
  phoneStyle: {
    position: 'absolute',
    '@media (max-width: 380px)': {
      width: '284px',
      top: '-60px',
      left: '-6px',
    },
    '@media (min-width: 380px) and (max-width: 480px)': {
      width: '330px',
      top: '-80px',
      left: '-6px',
    },
    '@media (min-width: 480px) and (max-width: 768px)': {
      width: '360px',
      top: '-122px',
      left: '-6px',
    },
    '@media (min-width: 768px) and (max-width: 992px)': {
      width: '470px',
      top: '325px',
      right: '65px',
      justifyContent: 'end',
    },
    '@media (min-width: 992px) and (max-width: 1200px)': {
      width: '550px',
      top: '235px',
      right: '100px',
      justifyContent: 'end',
    },
    '@media (min-width: 1201px)': {
      width: '600px',
      top: '180px',
      right: '100px',
      justifyContent: 'end',
    },
  } as any,
});

const useStyles = makeStyles(styles);

export default function TopSection({}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const goToPricing = () => {
    const element = document.getElementById('prezzi');
    const to = (element?.getBoundingClientRect().top ?? 0) - 100;
    window.scrollTo({top: to, behavior: 'smooth'});
  };

  return (
    <BackgroundImage image={redBackground} imageMobile={redBackgroundMobile}>
      <GridContainer style={{height: '800px'}} justifyContent={'space-between'} direction={'row'}>
        <GridItem xs={12} sm={8} md={8} lg={8}>
          <GridContainer className={classes.boxContainerTitle}>
            <GridItem md={12} centerHorizontal={isMobile}>
              <CustomText fontFamily={'Goda'} color={'white'} size={'xxl'} center={isMobile}>
                IL MENU DIGITALE, <br /> È PRONTO PER VOI!
              </CustomText>
            </GridItem>
            <GridItem md={12} marginTop={20} centerHorizontal={isMobile}>
              <CustomText color={'white'} size={'md'} center={isMobile}>
                Creare menù <CustomText bold>personalizzabili, semplici</CustomText> e{' '}
                <CustomText bold>sempre aggiornati</CustomText> per i vostri clienti non è mai stato così facile.
              </CustomText>
            </GridItem>
            <GridItem md={12} marginTop={20} centerHorizontal={isMobile}>
              <CustomText color={'white'} size={'md'} center={isMobile}>
                Ah già e costa poco! Non sottovalutarlo 😉
              </CustomText>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={9} marginTop={40} centerHorizontal={isMobile}>
              <CustomButton size={'lg'} title={"Dai un'occhiata ai prezzi"} color={'white'} onClick={goToPricing} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4}>
          <ContentResponsive
            desktop={<img className={classes.phoneStyle} src={iphoneHeader} alt={'iphoneheader'} />}
            mobile={<img className={classes.phoneStyle} src={iphoneHeaderMobile} alt={'iphoneheader'} />}
          />
        </GridItem>
      </GridContainer>
    </BackgroundImage>
  );
}

type Props = {};
