import {ThemeProvider} from '@material-ui/core';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import './App.scss';
import CustomLoader from './components/atoms/CustomLoader';
import CustomSnackbar from './components/atoms/CustomToast/CustomSnackbar';
import {CookieBanner} from './components/scripts';
import {ProvideAuth} from './navigation/Auth/ProvideAuth';
import {CustomRouter} from './navigation/CustomRouter';
import {RouterConfig} from './navigation/RouterConfig';
import store from './redux/store';
import {fbPixel} from './services/fpixel';
import {hotjar} from './services/hotjar';
import {gtm} from './services/g-tag-manager';
import {historyHelp} from './services/navigationService';
import {light} from './styles/muiTheme';
import {DEFAULT_LANGUAGE, secrets} from './utils/config';
import './utils/dropConsole';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';

const stripePromise = loadStripe(secrets.stripePublishableKey);

i18next.init({
  interpolation: {escapeValue: false},
  fallbackLng: DEFAULT_LANGUAGE,
});

function App() {
  const [darkState, setDarkState] = useState(false);
  const handleThemeChange = () => {
    setDarkState(!darkState);
    console.log('theme=', darkState ? 'dark' : 'light');
  };

  useEffect(() => {
    const url = window.location.origin;
    if (
      !url.includes('https') &&
      !url.includes('localhost') &&
      !url.includes('test.ordify') &&
      !url.match(
        /^http[s]?:\/\/((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])/,
      )
    ) {
      window.location.href = `https:${_.tail(url.split(':')).join(':')}`;
    }
    fbPixel.init();
    hotjar.init();
    gtm.init();
  }, []);

  return (
    <>
      <CookieBanner />
      <div className={'app'}>
        <Provider store={store}>
          <CustomLoader />
          <CustomSnackbar />
          <ThemeProvider theme={light()}>
            <Elements stripe={stripePromise}>
              <I18nextProvider i18n={i18next}>
                {/*<ThemeSwitch darkState={darkState} handleThemeChange={handleThemeChange} />*/}
                <ProvideAuth>
                  <CustomRouter history={historyHelp}>
                    <RouterConfig />
                  </CustomRouter>
                </ProvideAuth>
              </I18nextProvider>
            </Elements>
          </ThemeProvider>
        </Provider>
      </div>
    </>
  );
}

export default App;
