import {Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useState} from 'react';
import whiteBackground from '../../../../assets/images/smart-menu/desktop/background_carta.jpg';
import iphone_demo_pizzeria from '../../../../assets/images/smart-menu/desktop/iphone_pizzeria_demo.png';
import iphone_demo_hamburgeria from '../../../../assets/images/smart-menu/desktop/iphone_hamburgeria_demo.png';
import iphone_demo_ristorante from '../../../../assets/images/smart-menu/desktop/iphone_ristorante_demo.png';
import whiteBackgroundMobile from '../../../../assets/images/smart-menu/mobile/background_carta_mobile.jpg';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomSelect from '../../../../components/atoms/CustomSelect';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import BackgroundImage from '../../../../components/organisms/BackgroundImage';

const styles = (theme: Theme) => ({
  section: {
    width: '80%',
    margin: 'auto',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  iphoneDemoTemplate: {
    width: '300px',
  },
});

const useStyles = makeStyles(styles);

type ActivityExample = {
  type: string;
  name: string;
  description: string;
  demoUrl: string;
  demoImg: any;
};

const activityExamples: ActivityExample[] = [
  {
    type: 'pizzeria',
    name: 'Demo Pizzeria',
    description:
      'Personalizza a tuo piacimento l’estetica del menu digitale della tua pizzeria. <br> Inserisci le foto, scegli il tuo colore preferito e utilizza il font più affine<br> alla tua attività.<br> <br> Ricorda che il menu digitale è aggiornabile in tempo reale, quindi se hai un’idea geniale per la pizza del giorno per inserirla nel menù ci vorrà meno di un minuto.<br> <br> Lo SmartMenu è adattabile per tutte le esigenze del tuo locale, non porti limiti nella creazione.<br> <br> Guarda l’esempio di menu digitale che abbiamo creato per questa pizzeria.',
    demoUrl: 'https://ordify.it/#/activity-detail/625d1810c9b31000181a9a7a/menu',
    demoImg: iphone_demo_pizzeria,
  },
  {
    type: 'ristorante',
    name: 'Demo Ristorante',
    description:
      'Personalizza a tuo piacimento l’estetica del menu digitale del tuo ristorante.<br> Scegli le immagini delle tue specialità, il colore che meglio rappresenta la tua attività e il font più adatto al tuo stile.<br> <br> Ricorda che il menu digitale è facilmente aggiornabile in tempo reale.<br> Se hai un nuovo piatto da aggiungere al menu ti ci vorrà solo un minuto per farlo.<br> <br> Lo SmartMenu è flessibile e adattabile a tutte le esigenze del tuo ristorante.<br> Non porti limiti nella creazione.<br> <br> Guarda l’esempio di menu digitale che abbiamo creato per questo ristorante.',
    demoUrl: 'https://ordify.it/#/activity-detail/601bbf1a3538d400174c5202/menu',
    demoImg: iphone_demo_ristorante,
  },
  {
    type: 'hamburgeria',
    name: 'Demo Hamburgeria',
    description:
      "Personalizza a tuo piacimento l'estetica del menu digitale della tua hamburgeria. Scegli le foto dei tuoi hamburger, il colore che meglio si abbina al tuo brand e il font più adatto al tuo stile.<br> <br> Lo SmartMenu di Ordify è facile da aggiornare, e puoi farlo in tempo reale!<br> Se hai un nuovo hamburger da aggiungere al menu ci impiegherai solo un minuto.<br> <br> Lo SmartMenu è altamente personalizzabile e si adatta alle esigenze del tuo locale. Non porti limiti alla creazione. <br> <br> Guarda l’esempio di menu digitale che abbiamo creato per questa hamburgeria.",
    demoUrl: 'https://ordify.it/#/activity-detail/604908ef6fc52d0017200a57/menu',
    demoImg: iphone_demo_hamburgeria,
  },
];

export default function HowItWorksSection({id}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedActivity, setSelectedActivity] = useState<ActivityExample>(activityExamples[0]);

  const onActivityTypePressed = (type: string) => {
    setSelectedActivity(activityExamples.find((a) => a.type === type) as ActivityExample);
  };

  return (
    <div id={id}>
      <BackgroundImage image={whiteBackground} imageMobile={whiteBackgroundMobile}>
        <GridContainer
          justifyContent={'center'}
          direction={'column'}
          alignItems={'center'}
          className={classes.section}
          style={{padding: '100px 0 16px'}}
        >
          <GridItem centerHorizontal xs={12}>
            <CustomText center bold color={'primary'} size={'xl'} fontFamily={'Goda'}>
              SMARTMENU SI ADATTA
              <br />A SVARIATI TIPI DI ATTIVITÀ:
            </CustomText>
          </GridItem>
          <GridItem centerHorizontal xs={12} marginTop>
            <CustomText center color={'black'}>
              Guarda gli esempi che abbiamo creato apposta per te.
            </CustomText>
          </GridItem>
        </GridContainer>
        <GridContainer
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
          className={classes.section}
          style={{padding: '16px 0 100px'}}
        >
          <GridItem xs={12}>
            <CustomRow style={{width: '100%'}} center>
              {isMobile ? (
                <>
                  <CustomSelect
                    fullWidth={false}
                    items={activityExamples
                      .map((a) => a.type)
                      .map((h) => ({
                        value: h,
                        label: h.toUpperCase(),
                      }))}
                    dataStructure={{id: 'value', name: 'label'}}
                    value={selectedActivity.type}
                    onChange={(t) => onActivityTypePressed(t.value)}
                  />
                </>
              ) : (
                <>
                  {activityExamples
                    .map((a) => a.type)
                    .map((type) => (
                      <CustomText
                        key={type}
                        center
                        bold={selectedActivity.type === type}
                        underline={selectedActivity.type === type}
                        color={selectedActivity.type === type ? 'primary' : 'black'}
                        fontFamily={selectedActivity.type === type ? 'Goda' : 'Open Sans'}
                        onClick={() => onActivityTypePressed(type)}
                        style={{width: '100%', padding: '0 12px'}}
                      >
                        {type.toUpperCase()}
                      </CustomText>
                    ))}
                </>
              )}
            </CustomRow>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer marginTop={48}>
              <GridItem xs={12} sm={12} md={4} centerHorizontal={isMobile}>
                <img className={classes.iphoneDemoTemplate} src={selectedActivity.demoImg} alt="logo" />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} justifyContent={'center'} direction={'column'}>
                <CustomRow center={isMobile}>
                  <CustomText color={'primary'} bold fontFamily={'Goda'} center={isMobile}>
                    {selectedActivity.name.toUpperCase()}
                  </CustomText>
                </CustomRow>
                <CustomRow marginTop={20} center={isMobile}>
                  <CustomText center={isMobile} html>
                    {selectedActivity.description}
                  </CustomText>
                </CustomRow>
                <CustomRow marginTop={20} center={isMobile}>
                  <CustomButton
                    color={'primary'}
                    title={'Versione Demo'}
                    onClick={() => open(selectedActivity.demoUrl)}
                  />
                </CustomRow>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </BackgroundImage>
    </div>
  );
}

type Props = {
  id?: string;
};
