import * as _ from 'lodash';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import ImageViewer from '../../../components/molecules/ImageViewer';
import {ItemForMenu} from '../../../models/menu/category';
import {Colors} from '../../../styles/colors';
import {Fonts} from '../../../styles/font';
import {AllergenList} from './AllergenList';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px 20px 20px',
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  categoryContainer: {},
  titleCategory: {
    paddingLeft: '30px',
  },
  description: {
    paddingLeft: '30px',
  },
  itemContainer: {},
  imageItem: {
    borderRadius: 100,
    height: '120px',
    width: '120px',
    objectFit: 'cover',
  },
}));

const MenuItemTemplate3 = ({menuItem, color, fontFamily, index, hideImages}: Props) => {
  const classes = useStyles();

  return (
    <CustomCard plain padding={'sm'}>
      <GridContainer justifyContent={'center'} fullWidth={true} marginTop={index === 0 ? 0 : 30} marginBottom={20}>
        <GridItem justifyContent={'center'} xs={12} sm={12} md={12} lg={12}>
          {!hideImages && menuItem.image && !!menuItem.image.url && (
            <ImageViewer image={menuItem.image} className={classes.imageItem} />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div>
            <CustomRow marginTop center>
              <CustomText
                style={{lineHeight: 'normal'}}
                bold
                size={'md'}
                center
                color={'black'}
                fontFamily={fontFamily}
              >
                {menuItem.name.toUpperCase()}
              </CustomText>
              <CustomRow marginLeft>
                <AllergenList allergenList={menuItem.allergens} />
              </CustomRow>
            </CustomRow>
            {!!menuItem.ingredients.length && (
              <CustomRow center>
                <CustomText center size={'sm'} fontFamily={fontFamily}>
                  {menuItem.ingredients.map((ingrendient) => _.upperFirst(ingrendient.name))?.join(', ')}
                </CustomText>
              </CustomRow>
            )}
            {!!menuItem.description && (
              <CustomRow marginTop center>
                <CustomText size={'sm'} italic fontFamily={fontFamily} center>
                  {menuItem.description}
                </CustomText>
              </CustomRow>
            )}
            {menuItem.discountPercentage ? (
              <>
                <CustomRow center marginTop={6}>
                  <CustomText size={'xs'} center lineThrough fontFamily={fontFamily}>
                    €{menuItem.price / (1 - menuItem.discountPercentage / 100)}
                  </CustomText>
                  <CustomText size={'xs'}>&nbsp;\&nbsp;</CustomText>
                  <CustomText size={'xs'} color={color} bold fontFamily={fontFamily}>
                    €{menuItem.price}
                  </CustomText>
                </CustomRow>
              </>
            ) : (
              <CustomRow center marginTop={6}>
                <CustomText center size={'xs'} fontFamily={fontFamily}>
                  €{menuItem.price}
                </CustomText>
              </CustomRow>
            )}
          </div>
        </GridItem>
      </GridContainer>
    </CustomCard>
  );
};

type Props = {
  index: number;
  menuItem: ItemForMenu;
  color?: Colors;
  fontFamily?: Fonts;
  hideImages?: boolean;
};

export default MenuItemTemplate3;
