import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {login} from '../../api/authService';
import {deleteUserAccount} from '../../api/userService';
import CustomButton from '../../components/atoms/CustomButtons/CustomButton';
import CustomInput from '../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../components/atoms/CustomRow';
import {AppMessageType} from '../../components/atoms/CustomToast';
import CustomText from '../../components/atoms/Typography/CustomText';
import PageContainer from '../../components/organisms/PageContainer';
import {HOME} from '../../constants';
import {LoginResponse} from '../../models/auth/login';
import {CustomResponse, Status} from '../../models/shared/custom-response';
import {State} from '../../redux/reducers';
import {navigate} from '../../services/navigationService';
import {setToken} from '../../services/storageService';
import {showToastMessage} from '../../services/toastService';

const DeleteProfileData = ({}: Props) => {
  const isLoggedIn = useSelector((s: State) => s.auth.isLoggedIn);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const deleteAccount = async () => {
    if (await checkLogin()) {
      const deleteRes: CustomResponse<{deleted: boolean}> = await deleteUserAccount();
      if (deleteRes.status === Status.OK && deleteRes.payload.deleted) {
        navigate(HOME);
        showToastMessage(AppMessageType.SUCCESS, 'Account eliminato con successo');
      }
    }
  };

  const checkLogin = async () => {
    if (isLoggedIn) {
      return true;
    }
    if (!email || !password) {
      showToastMessage(AppMessageType.ERROR, 'Email o Password non valorizzati');
      return false;
    }
    const loginRes: CustomResponse<LoginResponse> = (await login({username: email, password})) as any;
    if (loginRes.status === Status.OK && loginRes.payload.token) {
      setToken(loginRes.payload.token);
      return true;
    }
    showToastMessage(AppMessageType.ERROR, loginRes.message);
    return false;
  };

  return (
    <>
      <PageContainer
        small
        pageTitle={'Elimina i tuoi dati'}
        headerConfigs={{showLoginButton: false, showDrawer: false, showBackButton: false}}
      >
        <CustomRow center>
          <CustomText bold center>
            Sei sicuro di voler eliminare il tuo account e tutti i suoi dati in modo permanente?
          </CustomText>
        </CustomRow>
        {!isLoggedIn && (
          <>
            <CustomRow marginTop center>
              <CustomText italic size={'sm'} center>
                Per procedere inserisci email e password
              </CustomText>
            </CustomRow>
            <CustomRow marginTop>
              <CustomInput fullWidth placeholder={'Email'} type={'email'} value={email} onChange={(t) => setEmail(t)} />
            </CustomRow>
            <CustomRow marginTop>
              <CustomInput
                fullWidth
                placeholder={'Password'}
                type={'password'}
                value={password}
                onChange={(t) => setPassword(t)}
              />
            </CustomRow>
          </>
        )}
        <CustomRow marginTop center>
          <CustomButton fullWidth title={'Continua'} onClick={deleteAccount} />
        </CustomRow>
      </PageContainer>
    </>
  );
};

type Props = {};

export default DeleteProfileData;
