import React from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import pizzaImage from '../../../../assets/images/landing/discountSection/immagine_sconti.png';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import './DiscountSectionStyle.scss';
import Hidden from '@material-ui/core/Hidden';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import telegramLogo from '../../../../assets/images/footer/logo-telegram.png';

export default function DiscountSection({id}: Props) {
  return (
    <div id={id}>
      <GridContainer marginTop={100} fullWidth={true}>
        <Hidden mdUp>
          <GridItem justifyContent={'end'} noPadding={'right'} xs={12} sm={12} md={12} lg={6}>
            <img alt={'launch-image'} className={'imgOrder'} src={pizzaImage} />
          </GridItem>
        </Hidden>
        <GridItem marginTop={100} direction={'column'} alignItems={'start'} xs={12} sm={12} md={12} lg={6}>
          <GridContainer className={'gridItemTextLeft'}>
            <GridItem xs={12} sm={12} md={12}>
              <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
                I MIGLIORI SCONTI
              </CustomText>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} marginTop>
              <CustomText size={'lg'}>
                Offriamo la possibilità di utilizzare la nostra app usufruendo di <b>sconti</b> che ti faranno venire
                ancora di più <b>l’acquolina in bocca.</b>
              </CustomText>
            </GridItem>
            <GridItem marginTop={30} xs={12} sm={12} md={12}>
              <CustomText italic={true} size={'lg'}>
                E questo tutte le settimane, tutto l’anno. Visita il nostro canale Telegram "Ordify Sconti" !
              </CustomText>
            </GridItem>
            <GridItem marginTop={40} xs={12} sm={12} md={12}>
              <CustomButton
                title={'Ordify Sconti'}
                size={'lg'}
                img={<img alt={'img'} src={telegramLogo} style={{height: '30px', width: '30px'}} />}
                onClick={() => window.open('https://t.me/ordify_sconti')}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <Hidden mdDown>
          <GridItem justifyContent={'end'} noPadding={'right'} xs={12} sm={12} md={12} lg={6}>
            <img alt={'launch-image'} className={'imgOrder'} src={pizzaImage} />
          </GridItem>
        </Hidden>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
