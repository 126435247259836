import React from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';

export default function ChiSiamoSection({id}: Props) {
  return (
    <div id={id}>
      <GridContainer
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
        fullWidth={true}
        style={{width: '80%', margin: 'auto'}}
      >
        <GridItem centerHorizontal xs={12} sm={12} md={12}>
          <CustomText center bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
            COS'È ORDIFY?
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} sm={12} md={12} marginTop>
          <CustomText center={true} size={'lg'}>
            Ordify è un servizio che oltre a permetterti di ordinare <b>take away</b> o attraverso il{' '}
            <b>servizio a domicilio</b> dai ristoranti della tua zona, ti permette anche di <b>riservare un tavolo</b>{' '}
            per le tue occasioni migliori e poter sfruttare l’inedita funzione <b>Pronto in tavola.</b>
          </CustomText>
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
