import {dangerColor, infoColor, primaryColor, roseColor, successColor, warningColor} from '../../../styles/colors';
import {boxShadow, container, drawerWidth, transition} from '../../../styles/material-kit/nextjs-material-kit';

const headerStyle = () =>
  ({
    appBar: {
      display: 'flex',
      border: '0',
      borderRadius: '3px',
      padding: '0.625rem 0',
      marginBottom: '20px',
      color: '#555',
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
      transition: 'all 150ms ease 0s',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      position: 'relative',
      zIndex: 'unset',
    },
    absolute: {
      position: 'absolute',
      zIndex: '1100',
    },
    fixed: {
      position: 'fixed',
      zIndex: '1100',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    container: {
      ...container,
      minHeight: '50px',
      flex: '1',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      '@media (min-width: 320px) and (max-width: 768px)': {
        justifyContent: 'center',
      },
    },
    flex: {
      flex: 1,
    },
    primary: {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)',
    },
    info: {
      backgroundColor: infoColor,
      color: '#FFFFFF',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)',
    },
    success: {
      backgroundColor: successColor,
      color: '#FFFFFF',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)',
    },
    warning: {
      backgroundColor: warningColor,
      color: '#FFFFFF',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)',
    },
    danger: {
      backgroundColor: dangerColor,
      color: '#FFFFFF',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)',
    },
    rose: {
      backgroundColor: roseColor,
      color: '#FFFFFF',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)',
    },
    transparent: {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
      paddingTop: '25px',
      color: '#FFFFFF',
    },
    dark: {
      color: '#FFFFFF',
      backgroundColor: '#212121 !important',
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)',
    },
    white: {
      border: '0',
      padding: '0.625rem 0',
      marginBottom: '20px',
      color: '#555',
      backgroundColor: '#fff !important',
      boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    },
    drawerPaper: {
      border: 'none',
      bottom: '0',
      transitionProperty: 'top, bottom, width',
      transitionDuration: '.2s, .2s, .35s',
      transitionTimingFunction: 'linear, linear, ease',
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      visibility: 'visible',
      overflowY: 'auto',
      overflowX: 'hidden',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      ...transition,
    },
    drawerToggle: {
      position: 'absolute',
      right: '20px',
    },
    logoContainer: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    logo: {
      width: '80px',
      height: '80px',
      '@media (max-width: 480px)': {
        width: '70px',
        height: '70px',
      },
    },
    backContainer: {
      position: 'absolute',
      zIndex: '999',
      left: '20px',
      '@media (max-width: 480px)': {
        left: '5px',
      },
    },
    back: {
      width: '30px',
      height: '30px',
      '@media (max-width: 480px)': {
        width: '20px',
        height: '20px',
      },
    },
    intersectionWhite: {
      position: 'absolute',
      top: '0',
      '@media (min-width: 320px) and (max-width: 480px)': {
        width: '300px',
      },
      '@media (min-width: 481px) and (max-width: 768px)': {
        width: '400px',
      },
      '@media (min-width: 768px) and (max-width: 1024px)': {
        width: '500px',
      },
      '@media (min-width: 1025px) and (max-width: 1200px)': {
        width: '600px',
      },
      '@media (min-width: 1201px)': {
        width: '600px',
      },
    },
  } as any);

export default headerStyle as any;
