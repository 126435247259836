import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import CustomDialog from '../../../components/atoms/CustomDialog';
import {LANGUAGE, LANGUAGES} from '../../../constants/language';
import {setLanguage} from '../../../redux/actions/globalActions';
import {State} from '../../../redux/reducers';
import {Colors} from '../../../styles/colors';

const LanguageSelector = ({languages, onLanguageChange, color = 'primary'}: Props) => {
  const classes = useStyle({color});
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const selectedLanguage = useSelector((s: State) => s.global.language);
  const _languages = [LANGUAGE.IT, ...languages];

  const handleLanguageSelect = (language: string) => {
    setModalVisible(false);
    onLanguageChange(language);
    i18n.changeLanguage(language);
    dispatch(setLanguage({language}));
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <div className={classes.container}>
      <CustomButton
        color={'primary'}
        simple
        className={classes.languageButtonSelected}
        disabled={!languages.length}
        onClick={() => setModalVisible(true)}
      >
        <img src={LANGUAGES[selectedLanguage].image} className={classes.flag} alt={'lang'} />
      </CustomButton>

      <CustomDialog
        className={classes.dialog}
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        showCloseButton={false}
      >
        <div className={classes.modalContainer}>
          <div className={classes.flagContainer}>
            {_languages
              .filter((language) => language !== selectedLanguage)
              .map((language, index) => (
                <CustomButton
                  key={index}
                  color={'transparent'}
                  className={classes.languageButton}
                  title={t(`language.${LANGUAGES[language].code}`)}
                  img={<img src={LANGUAGES[language].image} className={classes.flag} alt={'lang'} />}
                  onClick={() => handleLanguageSelect(language)}
                ></CustomButton>
              ))}
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

type Props = {
  languages: string[];
  onLanguageChange: (language: string) => void;
  color?: Colors;
};

const useStyle = ({color}: {color: Colors}) =>
  makeStyles((theme) => ({
    dialog: {
      borderRadius: 10,
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    flag: {
      width: 22,
      height: 16,
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    flagContainer: {
      backgroundColor: 'white',
      paddingVertical: 10,
      paddingHorizontal: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    languageButtonSelected: {
      marginTop: 6,
      marginBottom: 6,
      borderRadius: 10,
      border: '3px solid',
      borderColor: color,
    },
    languageButton: {
      marginTop: 6,
      marginBottom: 6,
      borderRadius: 10,
    },
  }))();

export default LanguageSelector;
