export class Spacing {
  public static SCALE_80 = '80px';
  public static SCALE_70 = '70px';
  public static SCALE_60 = '60px';
  public static SCALE_50 = '50px';
  public static SCALE_40 = '40px';
  public static SCALE_30 = '30px';
  public static SCALE_20 = '20px';
  public static SCALE_24 = '24px';
  public static SCALE_26 = '26px';
  public static SCALE_28 = '28px';
  public static SCALE_18 = '18px';
  public static SCALE_16 = '16px';
  public static SCALE_14 = '14px';
  public static SCALE_12 = '12px';
  public static SCALE_10 = '10px';
  public static SCALE_8 = '8px';
  public static SCALE_6 = '6px';
  public static SCALE_5 = '5px';
  public static SCALE_4 = '4px';

  public static ICON_SIZE_EXTRA_SMALL = 14;
  public static ICON_SIZE_SMALL = 18;
  public static ICON_SIZE_MEDIUM = 24;
  public static ICON_SIZE_BIG = '40px';

  public static MARGIN_FROM_BOARD = this.SCALE_20;
}
