import {http} from './axios';
import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';

const BASE_URL = process.env.REACT_APP_BASE_URL;

let visitorID: any = null;

export const updateCounter = async (data: any) => {
  return http.post(`${BASE_URL}/statistics/download-redirect-counter/new`, data, {
    // @ts-ignore
    hide: true,
    headers: {'Content-Type': 'application/json'},
  });
};

export const updateFestletStats = async (data: any) => {
  try {
    if (!visitorID) {
      const fingerprint = await getFingerprint();
      visitorID = getHash(fingerprint);
    }
    return http.post(
      `${BASE_URL}/statistics/festlet/new`,
      {
        ...data,
        visitorID,
      },
      {
        // @ts-ignore
        hide: true,
        headers: {'Content-Type': 'application/json'},
      },
    );
  } catch (e) {
    return Promise.resolve();
  }
};

const getFingerprint = () =>
  new Promise((resolve) => {
    Fingerprint2.get({}, (components: any) => {
      const userAgent = getProperty(components, 'userAgent');
      if (userAgent) {
        const parser = new UAParser(userAgent);
        components.push({key: 'os', value: parser.getOS()});
        components.push({key: 'browser', value: parser.getBrowser()});
        components.push({key: 'device', value: parser.getDevice()});
        components.push({key: 'cpu', value: parser.getCPU().architecture});
        components.push({key: 'engine', value: parser.getEngine().name});
      }
      resolve(components);
    });
  });

const getProperty = (array: any[], key: any) => {
  return array.find((obj) => obj.key === key);
};

const getHash = (components: any) => {
  let values = components.map((component: any) => component.value);
  return Fingerprint2.x64hash128(values.join(''), 31);
};
