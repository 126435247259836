import {
  deleteActivityImage,
  deleteProfileImage,
  uploadActivityImage,
  uploadProfileImage,
} from '../../../services/firebaseStorageService';
import {updateUser} from '../../../api/userService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {Image} from '../../../models/shared/image';
import {UpdateUserRequest} from '../../../models/user/request-objects/update-user';
import {UserInfo} from '../../../models/user/user-info.interface';
import {setUserInfo} from '../../../services/storageService';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {loadUserInfoSuccess} from '../../actions/userActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {isValidUrl} from '../../../utils/commonFunctions';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';

export default function* updateUserAsync(action: {
  type: string;
  payload: {
    updateUserRequest: UpdateUserRequest;
    oldImagesID?: string[];
    actions?: any[];
    postUpdateFunc?: () => void;
    goTo?: {path: string; state: string; search: string};
    showMessage?: boolean;
    hideLoader?: boolean;
  };
}) {
  if (!action.payload?.hideLoader) {
    yield put(enableLoader());
  }
  let userInfo: UserInfo = yield select((state: State) => state.user.userInfo);
  const request = {
    ...action.payload.updateUserRequest,
    id: action.payload.updateUserRequest?.id || userInfo.id,
  } as UpdateUserRequest;
  if (action.payload.updateUserRequest.userInfo?.img?.profile) {
    let profileImg = action.payload.updateUserRequest.userInfo.img.profile;
    yield updateImage(profileImg, action.payload.oldImagesID);
  }
  if (action.payload.updateUserRequest.merchantInfo?.images?.profile) {
    let profileImg = action.payload.updateUserRequest.merchantInfo.images.profile;
    yield updateImage(profileImg, action.payload.oldImagesID);
  }
  if (action.payload.updateUserRequest.merchantInfo?.images?.otherImages) {
    const otherImages = action.payload.updateUserRequest.merchantInfo?.images?.otherImages as Image[];
    const oldImagesID = action.payload.oldImagesID;
    if (oldImagesID && oldImagesID.length > 0) {
      for (const img of otherImages) {
        if (img.imageID && oldImagesID.indexOf(img.imageID) < 0) {
          img.url = yield uploadActivityImage(img);
          if (!isValidUrl(img.url)) {
            showToastMessage(AppMessageType.ERROR, "Errore durante il caricamento di un'immagine");
          }
        }
      }
      for (const imgID of oldImagesID) {
        if (imgID && imgID.trim() && otherImages.map((oi) => oi.imageID).indexOf(imgID) < 0) {
          yield deleteActivityImage(imgID);
        }
      }
    } else {
      for (const img of otherImages) {
        img.url = yield uploadActivityImage(img);
        if (!isValidUrl(img.url)) {
          showToastMessage(AppMessageType.ERROR, "Errore durante il caricamento di un'immagine");
        }
      }
    }
  }
  const response: CustomResponse<UserInfo> = yield updateUser(request);
  if (response.status === Status.OK) {
    // if id is not present the api update current user
    if (!action.payload.updateUserRequest?.id) {
      setUserInfo(response.payload);
      yield put(loadUserInfoSuccess({userInfo: response.payload}));
    }
    yield put(disableLoader());

    // dispatch actions
    if (action.payload.actions) {
      for (let a of action.payload.actions) {
        yield put(a());
      }
    }

    // execute postUpdateFunction
    action.payload.postUpdateFunc && action.payload.postUpdateFunc();

    // navigate after user updated
    if (action.payload.goTo) {
      navigate(action.payload.goTo.path, action.payload.goTo.state, action.payload.goTo.search);
    }

    // show update response message
    if (action.payload.showMessage) {
      showToastMessage(AppMessageType.SUCCESS, response.message);
    }
  } else {
    yield put(disableLoader());
  }
}

function* updateImage(image: Image, oldImagesID: string[] | undefined) {
  if (oldImagesID && oldImagesID.length > 0) {
    if (image.imageID && oldImagesID.indexOf(image.imageID) < 0) {
      image.url = yield uploadProfileImage(image);
      if (!isValidUrl(image.url)) {
        showToastMessage(AppMessageType.ERROR, "Errore durante il caricamento dell'immagine");
      }
    }
    if (!image.imageID || oldImagesID.indexOf(image.imageID) < 0) {
      for (const imgID of oldImagesID) {
        if (imgID && imgID.trim()) {
          yield deleteProfileImage(imgID);
        }
      }
    }
  }
}
