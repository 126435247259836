const privacyPageStyle = {
  container: {
    padding: '20px 30px',
  },
  newParagraph: {
    marginTop: '20px',
  },
  alignmentLeft: {
    marginLeft: '20px',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
} as any;

export default privacyPageStyle;
