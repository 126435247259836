import {UserInfo} from '../models/user/user-info.interface';
import {Role} from '../models/auth/role';
import {OrderCartItem} from '../models/orders/order-cart-item';
import {ActivityDetail} from '../models/user/activity-detail';

const TOKEN_KEY = 'AUTH_TOKEN';
const IMPERSONATE_TOKEN_KEY = 'IMPERSONATE_AUTH_TOKEN';
const IS_FIRST_ACCESS_KEY = 'IS_FIRST_ACCESS';
const SHOW_MODAL_REGISTRAZIONE_ATTIVITA = 'SHOW_MODAL_REGISTRAZIONE_ATTIVITA';
const SHOW_MODAL_CREA_MENU = 'SHOW_MODAL_CREA_MENU';
const SHOW_MODAL_PRONTO_IN_TAVOLA = 'SHOW_MODAL_PRONTO_IN_TAVOLA';
const USER_INFO_KEY = 'USER_INFO';
const LOCATION_RADIUS_KEY = 'LOCATION_RADIUS_KEY';
const APP_THEME_KEY = 'APP_THEME';
const DO_RATE_APPEARED_KEY = 'DO_RATE_APPEARED';
const AFTER_LOGIN_PAGE_KEY = 'AFTER_LOGIN_PAGE';
const ORDER_CART_KEY = 'ORDER_CART';
const ACTIVITY_KEY = 'ACTIVITY_DETAIL';

export enum Boolean {
  TRUE = 'true',
  FALSE = 'false',
}

export const getToken = () => {
  const impersonateToken = getImpersonateToken();
  if (impersonateToken) {
    const user = getUserInfo();
    if (user && user.roles.includes(Role.SUPER_ADMIN)) {
      return impersonateToken;
    }
  }
  return localStorage.getItem(TOKEN_KEY);
};
export const setToken = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getImpersonateToken = () => {
  return localStorage.getItem(IMPERSONATE_TOKEN_KEY);
};
export const setImpersonateToken = (token: string): void => {
  localStorage.setItem(IMPERSONATE_TOKEN_KEY, token);
};
export const removeImpersonateToken = (): void => {
  localStorage.removeItem(IMPERSONATE_TOKEN_KEY);
};

export const getIsFirstAccess = (): boolean => {
  const isFirstAccess = localStorage.getItem(IS_FIRST_ACCESS_KEY);
  return isFirstAccess !== Boolean.FALSE;
};
export const setFirstAccess = (isFirstAccess: boolean): void => {
  localStorage.setItem(IS_FIRST_ACCESS_KEY, isFirstAccess ? Boolean.TRUE : Boolean.FALSE);
};

export const getShowModalRegistrazioneAttivita = (): boolean => {
  const show = localStorage.getItem(SHOW_MODAL_REGISTRAZIONE_ATTIVITA);
  return show !== Boolean.FALSE;
};
export const setShowModalRegistrazioneAttivita = (show: boolean): void => {
  localStorage.setItem(SHOW_MODAL_REGISTRAZIONE_ATTIVITA, show ? Boolean.TRUE : Boolean.FALSE);
};

export const getShowModalCreaMenu = (): boolean => {
  const show = localStorage.getItem(SHOW_MODAL_CREA_MENU);
  return show !== Boolean.FALSE;
};
export const setShowModalCreaMenu = (show: boolean): void => {
  localStorage.setItem(SHOW_MODAL_CREA_MENU, show ? Boolean.TRUE : Boolean.FALSE);
};

export const getShowModalProntoInTavola = (): boolean => {
  const show = localStorage.getItem(SHOW_MODAL_PRONTO_IN_TAVOLA);
  return show !== Boolean.FALSE;
};
export const setShowModalProntoInTavola = (show: boolean): void => {
  localStorage.setItem(SHOW_MODAL_PRONTO_IN_TAVOLA, show ? Boolean.TRUE : Boolean.FALSE);
};

export const getUserInfo = (): UserInfo | undefined => {
  const userInfo = localStorage.getItem(USER_INFO_KEY);
  return userInfo ? JSON.parse(userInfo) : undefined;
};
export const setUserInfo = (userInfo?: UserInfo): void => {
  if (userInfo) {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
  }
};
export const removeUserInfo = (): void => {
  localStorage.removeItem(USER_INFO_KEY);
};

export const getLocationRadius = (): number => {
  const radius = localStorage.getItem(LOCATION_RADIUS_KEY);
  return radius ? parseInt(radius, 10) : 0;
};
export const setLocationRadius = (radius: number): void => {
  return localStorage.setItem(LOCATION_RADIUS_KEY, radius + '');
};

export const getAppTheme = (): any => {
  return localStorage.getItem(APP_THEME_KEY);
};
export const setAppTheme = (theme: any): void => {
  return localStorage.setItem(APP_THEME_KEY, theme);
};

export const getDoRateAppeared = (): boolean => {
  const appeared = localStorage.getItem(DO_RATE_APPEARED_KEY);
  return appeared !== Boolean.FALSE;
};
export const setDoRateAppeared = (appeared: boolean): void => {
  return localStorage.setItem(DO_RATE_APPEARED_KEY, appeared ? Boolean.TRUE : Boolean.FALSE);
};

export const getAfterLoginPageFromStorage = () => {
  return localStorage.getItem(AFTER_LOGIN_PAGE_KEY);
};
export const setAfterLoginPageOnStorage = (page: string): void => {
  localStorage.setItem(AFTER_LOGIN_PAGE_KEY, page);
};

export const getOrderCartFromStorage = (): OrderCartItem[] | undefined => {
  const orderCartItems = localStorage.getItem(ORDER_CART_KEY);
  return orderCartItems ? JSON.parse(orderCartItems) : undefined;
};
export const setOrderCartOnStorage = (orderCartItems: OrderCartItem[]): void => {
  localStorage.setItem(ORDER_CART_KEY, JSON.stringify(orderCartItems));
};

export const getActivityFromStorage = (): ActivityDetail | null => {
  const activity = localStorage.getItem(ACTIVITY_KEY);
  return activity ? JSON.parse(activity) : null;
};
export const setActivityOnStorage = (activity: ActivityDetail): void => {
  localStorage.setItem(ACTIVITY_KEY, JSON.stringify(activity));
};
