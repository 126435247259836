import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect, useDispatch} from 'react-redux';
import {Order, OrderStatus, OrderType} from '../../models/orders/order';
import {PeriodValue, SearchOrder} from '../../models/orders/request-objects/search-order';
import {loadUserOrders, updateStatusOrder} from '../../redux/actions/ordersActions';
import {Role} from '../../models/auth/role';
import {UpdateOrderStatus} from '../../models/orders/request-objects/update-order-status';
import GridItem from '../../components/atoms/Grid/GridItem';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import {State} from '../../redux/reducers';
import CustomText from '../../components/atoms/Typography/CustomText';
import CustomCheckbox from '../../components/atoms/CustomCheckbox';
import {PERIOD_LIST_USERS} from '../../utils/static-lists';
import CustomSelect from '../../components/atoms/CustomSelect';
import OrdersView from './components/OrdersView';
import {CheckCircleRounded, RadioButtonUncheckedRounded} from '@material-ui/icons';
import {GRAY_LIGHT, primaryColor} from '../../styles/colors';
import PageContainer from '../../components/organisms/PageContainer';

const useStyles = makeStyles({
  periodList: {
    borderRadius: '40px',
    backgroundColor: GRAY_LIGHT,
    color: primaryColor,
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '10px',
  },
  ordersViewContainer: {
    marginTop: 0,
    '@media (max-width: 768px)': {
      marginTop: '16px',
    },
  },
});

const UserOrders = ({orders}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showTakeAway, setShowTakeAway] = useState(true);
  const [showHomeService, setShowHomeService] = useState(true);
  const [showTableReservation, setShowTableReservation] = useState(true);
  const [period, setPeriod] = useState<string>(PeriodValue.LAST_1_MONTH);

  useEffect(() => {
    loadOrders();
  }, []);

  const onPeriodChange = (p: {label: string; value: string}) => {
    setPeriod(p.value);
    loadOrders(showTakeAway, showHomeService, showTableReservation, p.value, true);
  };

  const onPressShowTakeAway = () => {
    setShowTakeAway(!showTakeAway);
    loadOrders(!showTakeAway, showHomeService, showTableReservation, period, true);
  };

  const onPressShowHomeService = () => {
    setShowHomeService(!showHomeService);
    loadOrders(showTakeAway, !showHomeService, showTableReservation, period, true);
  };

  const onPressShowTableReservation = () => {
    setShowTableReservation(!showTableReservation);
    loadOrders(showTakeAway, showHomeService, !showTableReservation, period, true);
  };

  const loadOrders = (
    takeAway = showTakeAway,
    homeService = showHomeService,
    tableReservation = showTableReservation,
    p: string = period,
    hideLoader = false,
  ) => {
    const searchOrder = getSearchOrder(takeAway, homeService, tableReservation, p);
    dispatch(loadUserOrders({searchOrder, hideLoader}));
  };

  const onCancelPress = (id: string, message?: string) => {
    const searchOrder = getSearchOrder(showTakeAway, showHomeService, showTableReservation, period);
    dispatch(
      updateStatusOrder({
        updateOrderStatus: {
          id,
          status: OrderStatus.CANCELED,
          role: Role.USER,
          searchOrder,
          ...(message && {message}),
        } as UpdateOrderStatus,
        message: 'Ordine annullato con successo!',
      }),
    );
  };

  const getSearchOrder = (
    takeAway = true,
    homeService = true,
    tableReservation = true,
    p: string = PeriodValue.LAST_1_MONTH,
  ) => {
    const type: string[] = [];
    if (takeAway) {
      type.push(OrderType.TAKEAWAY);
    }
    if (homeService) {
      type.push(OrderType.HOME_SERVICE);
    }
    if (tableReservation) {
      type.push(OrderType.TABLE_RESERVATION);
    }
    return {
      status: [
        OrderStatus.PENDING,
        OrderStatus.APPROVED,
        OrderStatus.REFUSED,
        OrderStatus.COMPLETED,
        OrderStatus.REFUNDED,
      ],
      type,
      period: p,
    } as SearchOrder;
  };

  return (
    <>
      <PageContainer pageTitle={'ORDINI EFFETTUATI'}>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <CustomCheckbox
              icon={<RadioButtonUncheckedRounded />}
              checkedIcon={<CheckCircleRounded />}
              checked={showTakeAway}
              onPress={onPressShowTakeAway}
            >
              <CustomText>Servizio d'asporto</CustomText>
            </CustomCheckbox>
            <CustomCheckbox
              icon={<RadioButtonUncheckedRounded />}
              checkedIcon={<CheckCircleRounded />}
              checked={showHomeService}
              onPress={onPressShowHomeService}
            >
              <CustomText>Servizio a domicilio</CustomText>
            </CustomCheckbox>
            <CustomCheckbox
              icon={<RadioButtonUncheckedRounded />}
              checkedIcon={<CheckCircleRounded />}
              checked={showTableReservation}
              onPress={onPressShowTableReservation}
            >
              <CustomText>Prenotazioni tavolo</CustomText>
            </CustomCheckbox>
            <CustomSelect
              fullWidth
              value={period}
              className={classes.periodList}
              items={PERIOD_LIST_USERS}
              onChange={onPeriodChange}
              dataStructure={{id: 'value', name: 'label'}}
            />
          </GridItem>
          <GridItem xs={12} sm={8}>
            <div className={classes.ordersViewContainer}>
              <OrdersView orders={orders} onRefresh={() => loadOrders()} onCancelPress={onCancelPress} />
            </div>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

type Props = {
  orders: Order[] | null;
};

const mapStateToProps = (state: State) => ({
  orders: state.sectionUser.orders,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders);
