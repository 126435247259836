import React from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

const cardBodyStyle = (theme: Theme) => ({
  cardBody: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    flex: '1 1 auto',
    marginBottom: '.600rem',
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const useStyles = makeStyles(cardBodyStyle);

export default function CustomCardBody({className, children, noPadding, ...rest}: Props) {
  const classes = useStyles();
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.noPadding]: noPadding,
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

type Props = {
  className?: any;
  children: any;
  noPadding?: boolean;
};
