import {getActivityDetail} from '../../../api/merchantService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {ActivityDetail} from '../../../models/user/activity-detail';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {loadActivityDetailSuccess} from '../../actions/sectionUserActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';

export default function* loadActivityDetailAsync(action: {type: string; payload: {id: string}}) {
  yield put(enableLoader());
  let userID: string = yield select((state: State) => state.user.userInfo?.id);
  const response: CustomResponse<ActivityDetail> = yield getActivityDetail(action.payload.id, userID);
  if (response.status === Status.OK) {
    yield put(loadActivityDetailSuccess({activityDetail: response.payload}));
  }
  yield put(disableLoader());
}
