import {ContactSupport} from '../../models/auth/contact-support';
import {OAuthUser} from '../../models/auth/oauth-user';
import {Provider} from '../../models/auth/provider';
import {ResetPasswordPayload} from '../../models/auth/reset-password';
import {AuthPayload} from '../../models/auth/redux/auth-payload';
import {AuthData} from '../../models/auth/redux/auth-data';

export enum AuthActionTypes {
  LOGIN = '[AuthState] Login',
  LOGIN_SUCCESS = '[AuthState] LoginSuccess',

  OAUTH_LOGIN = '[AuthState] OAuthLogin',
  OAUTH_LOGIN_SUCCESS = '[AuthState] OAuthLoginSuccess',

  SET_AFTER_LOGIN_PAGE = '[AuthState] SET_AFTER_LOGIN_PAGE',

  LOGOUT = '[AuthState] Logout',
  LOGOUT_SUCCESS = '[AuthState] LogoutSuccess',
  LOGOUT_FAIL = '[AuthState] LogoutFail',

  CONTACT_SUPPORT = '[AuthState] ContactSupport',

  RESEND_EMAIL_TOKEN = '[AuthState] ResendEmailToken',
  VERIFY_EMAIL = '[AuthState] VerifyEmail',
  SUBMIT_PHONE_NUMBER = '[AuthState] SubmitPhoneNumber',
  VERIFY_PHONE_NUMBER = '[AuthState] VerifyPhoneNumber',
  SET_PHONE_REQUEST_ID = '[AuthState] SetPhoneRequestId',
  SET_AUTH_PAYLOAD = '[AuthState] SetAuthPayload',
  SET_AUTH_DATA = '[AuthState] SetAuthData',

  FORGOT_PASSWORD_SEND_TOKEN = '[AuthState] ForgotPasswordSendToken',
  RESET_PASSWORD = '[AuthState] ResetPassword',
}

export const login = (payload: {email: string; password: string}) => {
  return {
    type: AuthActionTypes.LOGIN,
    payload,
  };
};
export const loginSuccess = () => {
  return {
    type: AuthActionTypes.LOGIN_SUCCESS,
  };
};

export const oauthLogin = (payload: {oauthUser: OAuthUser; provider: Provider}) => {
  return {
    type: AuthActionTypes.OAUTH_LOGIN,
    payload,
  };
};
export const oauthLoginSuccess = () => {
  return {
    type: AuthActionTypes.OAUTH_LOGIN_SUCCESS,
  };
};

export const setAfterLoginPage = (page: string) => {
  return {
    type: AuthActionTypes.SET_AFTER_LOGIN_PAGE,
    payload: {page},
  };
};

export const logout = () => {
  return {
    type: AuthActionTypes.LOGOUT,
  };
};
export const logoutSuccess = () => {
  return {
    type: AuthActionTypes.LOGOUT_SUCCESS,
  };
};
export const logoutFail = () => {
  return {
    type: AuthActionTypes.LOGOUT_FAIL,
  };
};

export const contactSupport = (payload: {contactSupport: ContactSupport; goTo: string}) => {
  return {
    type: AuthActionTypes.CONTACT_SUPPORT,
    payload,
  };
};

export const resendEmailToken = (email: string) => {
  return {
    type: AuthActionTypes.RESEND_EMAIL_TOKEN,
    payload: {email},
  };
};
export const validateEmail = (token: string) => {
  return {
    type: AuthActionTypes.VERIFY_EMAIL,
    payload: {token},
  };
};
export const submitPhoneNumber = (phoneNumber: string, update?: boolean) => {
  return {
    type: AuthActionTypes.SUBMIT_PHONE_NUMBER,
    payload: {phoneNumber, update},
  };
};
export const verifyPhoneNumber = (token: string, update?: boolean, phoneNumber?: string) => {
  return {
    type: AuthActionTypes.VERIFY_PHONE_NUMBER,
    payload: {token, phoneNumber, update},
  };
};
export const setPhoneRequestId = (payload: {requestID: string}) => {
  return {
    type: AuthActionTypes.SET_PHONE_REQUEST_ID,
    payload,
  };
};
export const setAuthPayload = (payload: {authPayload: AuthPayload}) => {
  return {
    type: AuthActionTypes.SET_AUTH_PAYLOAD,
    payload,
  };
};
export const setAuthData = (payload: {authData: AuthData}) => {
  return {
    type: AuthActionTypes.SET_AUTH_DATA,
    payload,
  };
};

export const forgotPasswordSendToken = (payload: {email: string}) => {
  return {
    type: AuthActionTypes.FORGOT_PASSWORD_SEND_TOKEN,
    payload,
  };
};
export const resetPassword = (payload: {resetPasswordPayload: ResetPasswordPayload; goTo: string; force: boolean}) => {
  return {
    type: AuthActionTypes.RESET_PASSWORD,
    payload,
  };
};
