import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {verifyPhoneNumber} from '../../../redux/actions/authActions';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {showToastMessage} from '../../../services/toastService';
import {State} from '../../../redux/reducers';
import {navigate} from '../../../services/navigationService';
import {HOME} from '../../../constants';
import PageLoginContainer from '../_Shared/PageLoginContainer';

const ValidatePhoneNumber = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const phoneRequestId = useSelector((s: State) => s.auth.phoneRequestId);
  const authPayload = useSelector((s: State) => s.auth.authPayload);

  useEffect(() => {
    if (!phoneRequestId || !authPayload) {
      navigate(HOME);
    }
  }, [phoneRequestId, authPayload]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        confirm();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [token]);

  const confirm = () => {
    if (!token || !token.trim()) {
      showToastMessage(AppMessageType.ERROR, 'Inserisci il codice che ti abbiamo inviato per SMS');
      return;
    }
    dispatch(verifyPhoneNumber(token, false));
  };

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText center bold size={'xxl'} color={'primary'} fontFamily={'Goda'}>
            VALIDA NUMERO DI TELEFONO
          </CustomText>
        </CustomRow>
        <CustomRow marginTop center>
          <CustomText center>Inserisci il codice che ti abbiamo inviato per SMS</CustomText>
        </CustomRow>
        <GridContainer marginTop>
          <GridItem xs={12}>
            <CustomInput placeholder="Token" value={token} onChange={(text: string) => setToken(text)} />
          </GridItem>
        </GridContainer>
        <CustomRow marginTop>
          <CustomButton fullWidth onClick={confirm} title={'Conferma'} />
        </CustomRow>
      </PageLoginContainer>
    </>
  );
};

export default ValidatePhoneNumber;
