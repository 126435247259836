export interface Step {
  id: string;
  step: OrderTrackingStep;
  date: Date;
  message: string;
}

export enum OrderTrackingStep {
  CREATED = 'created',
  APPROVED = 'approved',
  REFUSED = 'refused',
  SENDED = 'sended',
  READY = 'ready',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  ERROR = 'error',
}
