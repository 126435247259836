import home_service_image from '../../../assets/images/general/home_service.png';
import takeaway_image from '../../../assets/images/general/takeaway.png';
import table_reservation_image from '../../../assets/images/general/table-reservation.png';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {makeStyles} from '@material-ui/styles';
import CustomDialog from '../../../components/atoms/CustomDialog';

const ModalAdviceBeforeOrderContent = ({
  style,
  open,
  onClose,
  onAcceptClick,
  onCloseClick,
  mode,
  homeServiceAvailable,
}: Props) => {
  const classes = useStyle();

  const getImage = () => {
    switch (mode) {
      case 'home-service':
        return home_service_image;
      case 'takeaway':
        return takeaway_image;
      case 'pronto-in-tavola':
      case 'table-reservation':
        return table_reservation_image;
    }
    return takeaway_image;
  };

  return (
    <>
      <CustomDialog onBottom open={open} onClose={onClose}>
        <div className={classes.baseStyle}>
          <CustomRow center>
            <img src={getImage()} className={classes.image} />
          </CustomRow>
          <div className={classes.middle}>
            <CustomRow center marginBottom>
              <CustomText center>Ti avvisiamo che:</CustomText>
            </CustomRow>
            <CustomRow center>
              <CustomText bold center>
                {'\u2022'} l'attività potrebbe impiegare un po' di tempo prima di approvare l'ordine
              </CustomText>
            </CustomRow>
            {mode === 'takeaway' && !homeServiceAvailable && (
              <CustomRow center marginTop>
                <CustomText bold center>
                  {'\u2022'} stai ordinando da asporto (questa attività{' '}
                  <CustomText underline>non offre servizio a domicilio</CustomText>) e dovrai recarti tu presso il
                  locale a ritirare il tuo ordine
                </CustomText>
              </CustomRow>
            )}
            {mode === 'home-service' && (
              <CustomRow center marginTop>
                <CustomText bold center>
                  {'\u2022'} la consegna è gestita dall'attività e potrebbe tardare di qualche minuto
                </CustomText>
              </CustomRow>
            )}
          </div>
          <div className={classes.bottom}>
            <CustomRow center>
              <CustomButton
                fullWidth
                title={'Comprendo e accetto'}
                color={'primary'}
                onClick={() => onAcceptClick && onAcceptClick()}
              />
            </CustomRow>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

type Props = {
  style?: any;
  open?: boolean;
  onClose?: any;
  onAcceptClick: any;
  onCloseClick?: any;
  mode: AdviceModeType;
  homeServiceAvailable?: boolean;
};

export type AdviceModeType = 'home-service' | 'takeaway' | 'table-reservation' | 'pronto-in-tavola';

const useStyle = makeStyles({
  baseStyle: {},
  middle: {
    padding: '12px',
  },
  bottom: {
    padding: '0 12px',
  },
  image: {
    height: 100,
    width: 100,
    objectFit: 'cover',
  },
});

export default ModalAdviceBeforeOrderContent;
