import {UpdateUserRequest} from '../../models/user/request-objects/update-user';
import {UserInfo} from '../../models/user/user-info.interface';
import {Role} from '../../models/auth/role';

export enum UserActionTypes {
  LOAD_USER_INFO = '[UserState] LoadUserInfo',
  LOAD_USER_INFO_SUCCESS = '[UserState] LoadUserInfoSuccess',
  LOAD_USER_INFO_FAIL = '[UserState] LoadUserInfoFail',
  SET_CURRENT_ROLE = '[UserState] SetCurrentRole',
  SET_FIRST_ACCESS = '[UserState] SetFirstAccess',
  SET_LOCATION_RADIUS = '[UserState] SetLocationRadius',
  UPDATE_USER = '[UserState] UpdateUser',
  RESET_USER_INFO = '[UserState] ResetUserInfo',
}

export const loadUserInfo = () => {
  return {
    type: UserActionTypes.LOAD_USER_INFO,
  };
};
export const loadUserInfoSuccess = (payload: {userInfo?: UserInfo}) => {
  return {
    type: UserActionTypes.LOAD_USER_INFO_SUCCESS,
    payload,
  };
};
export const loadUserInfoFail = () => {
  return {
    type: UserActionTypes.LOAD_USER_INFO_FAIL,
  };
};

export const setUserFirstAccess = (payload: {firstAccess: boolean}) => {
  return {
    type: UserActionTypes.SET_FIRST_ACCESS,
    payload,
  };
};

export const setUserLocationRadius = (payload: {radius: number}) => {
  return {
    type: UserActionTypes.SET_LOCATION_RADIUS,
    payload,
  };
};

export const updateUser = (payload: {
  updateUserRequest: UpdateUserRequest;
  oldImagesID?: string[];
  actions?: any[];
  postUpdateFunc?: () => void;
  goTo?: {path: string; state: string; search: string};
  showMessage?: boolean;
  hideLoader?: boolean;
}) => {
  return {
    type: UserActionTypes.UPDATE_USER,
    payload,
  };
};

export const setCurrentRole = (payload: {role: Role}) => {
  return {
    type: UserActionTypes.SET_CURRENT_ROLE,
    payload,
  };
};

export const resetUserInfo = () => {
  return {
    type: UserActionTypes.RESET_USER_INFO,
  };
};
