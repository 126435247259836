import {createTheme} from '@material-ui/core';
import {primaryColor, secondaryColor} from './colors';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const dark = () =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily:
        '"Open Sans", "Goda", "Big Shoulders Text", "Brygada1918", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: {
      type: 'dark',
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: '#757575',
      },
    },
  });

export const light = () =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily:
        '"Open Sans", "Goda", "Big Shoulders Text", "Brygada1918", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: {
      type: 'light',
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });
