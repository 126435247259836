import React from 'react';
import {makeStyles} from '@material-ui/styles';
import CustomCheckbox from '../../../../components/atoms/CustomCheckbox';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Spacing} from '../../../../styles/spacing';
import {ActivityFilter} from '../../../../models/user/activity-filter';
import {CheckCircleOutlineRounded, RadioButtonUncheckedRounded} from '@material-ui/icons';

const useStyle = makeStyles((theme) => ({
  checkBoxContent: {
    marginLeft: Spacing.SCALE_12,
  },
  label: {
    marginLeft: Spacing.SCALE_12,
  },
  occurrences: {
    marginLeft: Spacing.SCALE_12,
  },
}));

const ActivityFilterElement = ({activityFilter, checked, onPress}: Props) => {
  const classes = useStyle();
  return (
    <>
      <CustomCheckbox
        icon={<RadioButtonUncheckedRounded />}
        checkedIcon={<CheckCircleOutlineRounded />}
        itemDivider
        checked={checked}
        onPress={onPress}
      >
        <CustomRow>
          <CustomText size={'sm'}>{activityFilter.label}</CustomText>
          {activityFilter.occurrences > 0 && (
            <CustomText size={'sm'} className={classes.occurrences}>
              ({activityFilter.occurrences})
            </CustomText>
          )}
        </CustomRow>
      </CustomCheckbox>
    </>
  );
};

type Props = {
  activityFilter: ActivityFilter;
  checked: boolean;
  onPress: any;
};

export default ActivityFilterElement;
