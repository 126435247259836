import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid, {GridDirection, GridItemsAlignment, GridSpacing} from '@material-ui/core/Grid';
import {GridJustification} from '@material-ui/core/Grid/Grid';
import classNames from 'classnames';

const styles = {
  grid: {},
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: (props: Props) => (props.marginTop === true ? '8px' : `${props.marginTop}px`),
  },
  marginBottom: {
    marginBottom: (props: Props) => (props.marginBottom === true ? '8px' : `${props.marginBottom}px`),
  },
} as any;

const useStyles = makeStyles(styles);

export default function GridContainer({
  className,
  style,
  children,
  fullWidth,
  marginTop,
  marginBottom,
  direction,
  justifyContent,
  alignItems,
  spacing,
  ...rest
}: Props) {
  const classes = useStyles({...rest, marginTop, marginBottom});
  const gridClasses = classNames({
    [classes.grid]: true,
    [classes.fullWidth]: fullWidth,
    [classes.marginTop]: marginTop,
    [classes.marginBottom]: marginBottom,
    ...(className && {[className]: true}),
  });
  return (
    <Grid
      container
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      spacing={spacing}
      className={gridClasses}
      style={style}
      {...rest}
    >
      {children}
    </Grid>
  );
}

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children: any;
  fullWidth?: boolean;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  direction?: GridDirection;
  justifyContent?: GridJustification;
  alignItems?: GridItemsAlignment;
  spacing?: GridSpacing;
};
