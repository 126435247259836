import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import {makeStyles} from '@material-ui/styles';
import {loadFavoriteActivities, setActiveTab} from '../../../redux/actions/sectionUserActions';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomLabelText from '../../../components/atoms/CustomLabelText';
import {Favorite, FavoriteBorder, Place, Schedule} from '@material-ui/icons';
import {getLocationInfo} from '../../../utils/getLocation';
import CustomTabs, {TabModel} from '../../../components/atoms/CustomTabs/CustomTabs';
import MenuTab from './Tabs/MenuTab';
import InfoTab from './Tabs/InfoTab';
import {updateUser} from '../../../redux/actions/userActions';
import {UpdateUserRequest, UserInfo} from '../../../models/user/request-objects/update-user';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import moment from 'moment';
import {Day} from '../../../models/timetables/timetables.interface';
import TableReservationTab from './Tabs/TableReservationTab';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import {isMobile} from '../../../utils/commonFunctions';

const useStyles = makeStyles((theme) => ({
  favorite: {
    position: 'absolute',
    top: 8,
    right: 10,
  },
  mainBox: {
    borderRadius: '40px',
    '@media (max-width: 480px)': {
      margin: '15px',
    },
  },
  mainBoxCard: {
    borderRadius: '40px',
    '@media (max-width: 480px)': {
      borderRadius: '0px',
    },
  },
}));

export enum ActivityDetailTab {
  MENU = 'menu',
  INFO = 'info',
  TABLE_RESERVATION = 'table-reservation',
}

const MainContent = () => {
  const classes = useStyles();
  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);
  const favoriteActivities = useSelector((s: State) => s.user.userInfo?.userInfo.favoriteActivities);
  const [fav, setFav] = useState(false);
  const [tabs, setTabs] = useState<TabModel[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab(ActivityDetailTab.MENU));
    setTabs(getTabs());
  }, []);

  useEffect(() => {
    if (favoriteActivities && activityDetail) {
      setFav(favoriteActivities.indexOf(activityDetail.id) > -1);
    }
  }, [favoriteActivities, activityDetail]);

  const getDay = () => {
    let dayOfWeek = moment().day();
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    return activityDetail?.info.timetables.days.find((dd) => dd.dayID === dayOfWeek.toString(10)) || ({} as Day);
  };

  const OrariApertura = () => {
    const day = getDay();
    return (
      <>
        {day.closingDay ? (
          <>
            <CustomText>Oggi chiuso</CustomText>
            {!!day.closingReason && (
              <div>
                <CustomText>Motivo: {day.closingReason}</CustomText>
              </div>
            )}
          </>
        ) : (
          <>
            <CustomText>Orari di apertura</CustomText>
            {day.slots.map((s) => (
              <div key={s.id}>
                <CustomText key={s.id}>
                  {s.startHour} - {s.endHour}
                </CustomText>
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  const favoriteClicked = () => {
    setFav(!fav);
    if (activityDetail) {
      let favoriteActivitiesTemp = favoriteActivities || [];
      if (favoriteActivities) {
        const index = favoriteActivities.indexOf(activityDetail.id);
        if (index > -1) {
          favoriteActivitiesTemp.splice(index, 1);
        } else {
          favoriteActivitiesTemp.push(activityDetail.id);
        }
      } else {
        favoriteActivitiesTemp = [activityDetail.id];
      }
      const updateUserRequest = {
        userInfo: {
          favoriteActivities: favoriteActivitiesTemp,
        } as UserInfo,
      } as UpdateUserRequest;
      dispatch(
        updateUser({
          updateUserRequest,
          hideLoader: true,
          actions: [() => loadFavoriteActivities({hideLoader: true})],
        }),
      );
    }
  };

  const getTabs = () => {
    let _tabs = [
      {
        key: ActivityDetailTab.MENU,
        tabName: 'Menù',
        tabContent: <MenuTab />,
      } as TabModel,
      {
        key: ActivityDetailTab.INFO,
        tabName: 'Info',
        tabContent: <InfoTab />,
      } as TabModel,
    ];
    if (activityDetail?.tableReservationSettings?.enabled) {
      _tabs = [
        ..._tabs,
        {
          key: ActivityDetailTab.TABLE_RESERVATION,
          tabName: 'Prenota tavolo',
          tabContent: <TableReservationTab />,
        },
      ];
    }
    return _tabs;
  };

  const onTabSelected = (tab: string) => {
    dispatch(setActiveTab(tab as ActivityDetailTab));
  };

  return (
    <>
      {activityDetail && (
        <div>
          <CustomCard className={classes.mainBox} marginBottom padding={'sm'}>
            <CustomRow center>
              <CustomText fontFamily={'Goda'} color={'primary'} bold size={'lg'} center>
                {activityDetail.info.activityName.toUpperCase()}
              </CustomText>
            </CustomRow>
            <CustomRow center>
              <CustomText>{activityDetail.info.tags.map((t) => t.label).join(', ')}</CustomText>
            </CustomRow>
            <CustomRow center>
              <CustomLabelText
                marginVertical
                noWrap
                icon={Place}
                text={getLocationInfo(activityDetail?.info.location)}
              />
            </CustomRow>
            <CustomRow center>
              <CustomLabelText textCenter marginVertical icon={Schedule} element={<OrariApertura />} />
            </CustomRow>
            {activityDetail.menu.hasDiscountToday && (
              <CustomRow center>
                <CustomText color={'primary'} bold>
                  Sconti attivi!
                </CustomText>
              </CustomRow>
            )}
          </CustomCard>
          <CustomCard plain={isMobile()} className={classes.mainBoxCard}>
            <CustomTabs plainTabs={true} tabs={tabs} onTabSelected={onTabSelected} />
          </CustomCard>
        </div>
      )}
    </>
  );
};

export default MainContent;
