import Drawer from '@material-ui/core/Drawer';
import {Fonts} from '../../../styles/font';
import GridContainer from '../../atoms/Grid/GridContainer';
import GridItem from '../../atoms/Grid/GridItem';
import HeaderLinks from './HeaderLinks';
import CustomButton from '../../atoms/CustomButtons/CustomButton';
import {navigate} from '../../../services/navigationService';
import {LOGIN, USER_ORDERS} from '../../../constants';
import CustomRow from '../../atoms/CustomRow';
import CustomText from '../../atoms/Typography/CustomText';
import CustomListItem from '../../molecules/CustomListItem';
import CustomLabelText from '../../atoms/CustomLabelText';
import {ExitToApp, Receipt} from '@material-ui/icons';
import {UserInfo} from '../../../models/user/user-info.interface';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import headerStyle from './headerStyle';
import HeaderLogo from './HeaderLogo';
import {Colors} from '../../../styles/colors';

const useStyles = makeStyles(headerStyle);

const HeaderDrawer = ({
  mobileOpen,
  isLoggedIn,
  showLoginButton,
  handleDrawerToggle,
  user,
  links,
  logout,
  textColor,
  fontFamily,
}: HeaderDrawerProps) => {
  const classes = useStyles();
  return (
    <>
      <Drawer
        variant="temporary"
        anchor={'right'}
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
      >
        <GridContainer>
          <GridItem xs={12} centerHorizontal={true}>
            <HeaderLogo />
          </GridItem>
        </GridContainer>
        {links && links.length > 0 && <HeaderLinks links={links} textColor={textColor} fontFamily={fontFamily} />}
        {showLoginButton && (
          <>
            {!isLoggedIn ? (
              <CustomRow center style={{padding: '25px'}}>
                <CustomButton
                  noShadow
                  onClick={() => navigate(LOGIN)}
                  title={'Accedi o Registrati'}
                  fontFamily={fontFamily}
                />
              </CustomRow>
            ) : (
              <>
                <CustomRow center style={{padding: '25px'}}>
                  <CustomText color={'primary'} fontFamily={fontFamily}>
                    BenvenutƏ ${user?.userInfo.name ?? ''} !
                  </CustomText>
                </CustomRow>
                <CustomListItem padding={'sm'} onClick={() => navigate(USER_ORDERS)}>
                  <CustomLabelText icon={Receipt} text={'Ordini effettuati'} fontFamily={fontFamily} />
                </CustomListItem>
                <CustomListItem padding={'sm'} onClick={logout}>
                  <CustomLabelText icon={ExitToApp} text={'Logout'} fontFamily={fontFamily} />
                </CustomListItem>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

type HeaderDrawerProps = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  isLoggedIn: boolean;
  showLoginButton: boolean;
  user: UserInfo | null;
  logout: () => void;
  links?: any[];
  textColor?: Colors;
  fontFamily?: Fonts;
};

export default HeaderDrawer;
