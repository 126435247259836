import {contactSupport} from '../../../api/authService';
import {ContactSupport} from '../../../models/auth/contact-support';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {put} from 'redux-saga/effects';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';

export default function* contactSupportAsync(action: {
  type: string;
  payload: {contactSupport: ContactSupport; goTo: string};
}) {
  yield put(enableLoader());
  const response: CustomResponse<{
    emailSended: boolean;
  }> = yield contactSupport(action.payload.contactSupport);
  if (response.status === Status.OK) {
    if (response.payload.emailSended) {
      navigate(action.payload.goTo);
      showToastMessage(
        AppMessageType.INFO,
        'Grazie per aver contattato il nostro supporto! Ti risponderemo via email appena possibile.',
      );
    } else {
      showToastMessage(
        AppMessageType.ERROR,
        'Non è stato possibile contattare il supporto. Ci scusiamo per il disagio, ti preghiamo di scriverci alla mail support@ordify.it',
      );
    }
  }
  yield put(disableLoader());
}
