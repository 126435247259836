import React from 'react';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CustomWarningIcon from '../customIcons/CWarningIcon';
import CustomSuccessIcon from '../customIcons/CSuccessIcon';
import CustomInfoIcon from '../customIcons/CInfoIcon';
import CustomErrorIcon from '../customIcons/CErrorIcon';
import {makeStyles} from '@material-ui/core/styles';
import {BLACK, dangerColor, infoColor, successColor, warningColor, WHITE} from '../../../styles/colors';
import {Snackbar} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import {closeAlert} from '../../../redux/actions/globalActions';
import {AppMessageType} from './AppMessageType';
import {Interweave} from 'interweave';

const useStylesClasses = makeStyles((theme) => ({
  success: {
    backgroundColor: successColor,
    minHeight: 52,
    flexWrap: 'nowrap',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    color: WHITE,
    '@media(max-width: 767px)': {
      paddingLeft: 16,
    },
  },
  error: {
    backgroundColor: dangerColor,
    minHeight: 52,
    flexWrap: 'nowrap',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    color: WHITE,
    '@media(max-width: 767px)': {
      paddingLeft: 16,
    },
  },
  info: {
    backgroundColor: infoColor,
    minHeight: 52,
    flexWrap: 'nowrap',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    color: WHITE,
    '@media(max-width: 767px)': {
      paddingLeft: 16,
    },
  },
  warning: {
    backgroundColor: warningColor,
    minHeight: 52,
    flexWrap: 'nowrap',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    color: BLACK,
    '@media(max-width: 767px)': {
      paddingLeft: 16,
    },
  },

  message: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },

  messageWrapper: {
    paddingTop: 0,
    paddingBottom: 0,

    '@media(max-width: 767px)': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  actionButton: {
    color: 'inherit',
    whiteSpace: 'nowrap',
    fontWeight: 700,
  },
  icon: {},
  iconVariant: {},
}));

const variantIcon = {
  success: CustomSuccessIcon,
  warning: CustomWarningIcon,
  error: CustomErrorIcon,
  info: CustomInfoIcon,
};

function getIcon(type: AppMessageType) {
  switch (type) {
    case AppMessageType.ERROR:
      return <CustomErrorIcon />;
    case AppMessageType.SUCCESS:
      return <CustomSuccessIcon />;
    case AppMessageType.INFO:
      return <CustomInfoIcon />;
    case AppMessageType.WARNING:
      return <CustomWarningIcon />;
  }
}

function CustomSnackbar({id, className, onClose, ...other}: Props) {
  const alertOptions = useSelector((state: State) => state.global.alertOptions);
  const type = alertOptions.type;
  const classes = useStylesClasses();
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeAlert());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      //TransitionComponent={Fade}
      open={alertOptions.open}
      onClose={handleClose}
      autoHideDuration={alertOptions.duration || 5000}
    >
      <SnackbarContent
        id={id || 'snackbar'}
        className={clsx(classes[type], className)}
        classes={{message: classes.messageWrapper}}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {getIcon(type)}
            <Interweave content={alertOptions.message} />
          </span>
        }
        {...other}
      />
    </Snackbar>
  );
}

type Props = {
  id?: string;
  className?: any;
  open?: boolean;
  onClose?: any;
};

export default CustomSnackbar;
