import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

const styles = (theme: Theme) => ({
  baseStyle: {},
});

const useStyles = makeStyles(styles);

const BackgroundImage = ({className, image, imageMobile, style, children}: Props) => {
  const [img, setImg] = React.useState('');
  const classes = useStyles();
  const baseClasses = classNames({
    [classes.baseStyle]: true,
    ...(className && {[className]: true}),
  });

  React.useEffect(() => {
    if (imageMobile) {
      setImg(window.innerWidth <= 480 ? imageMobile : image);
    } else {
      setImg(image);
    }
  }, [image, imageMobile]);

  return (
    <div
      className={baseClasses}
      style={{
        ...style,
        backgroundImage: 'url(' + img + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {children}
    </div>
  );
};

type Props = {
  className?: string;
  children?: any;
  style?: any;
  image: string;
  imageMobile: string;
};

export default BackgroundImage;
