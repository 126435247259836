import {ActivitySearchResult} from '../../../models/user/activity-search-result';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import {GRAY_DARK_TRASPARENT, GRAY_LIGHT, WHITE} from '../../../styles/colors';
import sconto from '../../../assets/images/general/sconto.png';
import {getLocationInfo} from '../../../utils/getLocation';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import {DirectionsBike, LocalOffer, Place, Restaurant, RoomService, Schedule} from '@material-ui/icons';
import CustomRow from '../../../components/atoms/CustomRow';
import {Spacing} from '../../../styles/spacing';
import CustomLabelText from '../../../components/atoms/CustomLabelText';
import Hidden from '@material-ui/core/Hidden';
import CustomIcon from '../../../components/atoms/CustomIcon';

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: WHITE,
  },
  imageContainer: {
    backgroundColor: GRAY_LIGHT,
    height: '200px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 480px)': {
      height: '150px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  distanceFromOrigin: {
    marginLeft: Spacing.SCALE_12,
  },
  locationInfo: {
    marginLeft: Spacing.SCALE_6,
  },
  infoContainer: {
    padding: '25px 20px 25px',
    '@media (max-width: 480px)': {
      padding: '16px 10px 16px',
    },
  },
  icon: {
    margin: `${Spacing.SCALE_4} ${Spacing.SCALE_4}`,
    alignSelf: 'start',
  },
  sconto: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: 1,
    width: '46px',
    height: '46px',
  },
  disabled: {
    borderRadius: '10px',
    position: 'absolute',
    backgroundColor: GRAY_DARK_TRASPARENT,
    top: 0,
    left: 0,
    zIndex: 5,
    width: '100%',
    height: '100%',
    marginLeft: '0',
  },
  notEnabledIcon: {
    color: WHITE,
  },
  notEnabledText: {
    marginLeft: '10px',
  },
  cardContainer: {
    borderRadius: '24px',
  },
  boxIcon: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
    borderRadius: '40px',
    backgroundColor: 'white',
  },
  boxIconPosition: {
    display: 'flex',
  },
  title: {},
  titleDistance: {
    marginLeft: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const ActivitySearchResultElement = ({activitySearchResult, onClick}: Props) => {
  const classes = useStyle();
  const containerClasses = classNames({
    [classes.container]: true,
  });

  const getTags = () => {
    let stringTags = '';
    activitySearchResult.tags.forEach((tag, index) => {
      stringTags += tag.label + (index < activitySearchResult.tags.length - 1 ? ', ' : '');
    });
    return stringTags;
  };

  return (
    <>
      <CustomCard
        className={classes.cardContainer}
        marginBottom
        onClick={activitySearchResult.enabled ? onClick : undefined}
      >
        <GridContainer className={containerClasses} fullWidth>
          <GridItem xs={12} md={4} lg={4} noPadding>
            <div className={classes.imageContainer}>
              {activitySearchResult.image && activitySearchResult.image.url ? (
                <img src={activitySearchResult.image.url} className={classes.image} />
              ) : (
                <CustomText>Immagine non presente</CustomText>
              )}
              {activitySearchResult.hasDiscountToday && <img src={sconto} className={classes.sconto} />}
            </div>
            <Hidden mdUp>
              <div className={classes.boxIcon}>
                <GridItem xs={12} direction={'row'}>
                  <GridContainer direction={'row'}>
                    <GridItem className={classes.boxIconPosition} xs={12} noPadding direction={'row'}>
                      {activitySearchResult.takeaway && <CustomIcon icon={RoomService} className={classes.icon} />}
                      {activitySearchResult.homeService && (
                        <CustomIcon icon={DirectionsBike} className={classes.icon} />
                      )}
                      {activitySearchResult.tableReservation && (
                        <CustomIcon icon={Restaurant} className={classes.icon} />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </div>
            </Hidden>
          </GridItem>
          <GridItem xs={12} md={7} lg={7} className={classes.infoContainer}>
            <CustomRow shrink nowrap>
              <CustomText className={classes.title} size={'lg'} fontFamily={'Goda'} bold>
                {activitySearchResult.activityName.toUpperCase()}
                {activitySearchResult.distanceMeterFromOrigin && (
                  <CustomText className={classes.titleDistance}>({activitySearchResult.distanceFromOrigin})</CustomText>
                )}
              </CustomText>
            </CustomRow>
            <CustomLabelText
              marginTop={6}
              noWrap
              icon={Place}
              sizeText={'sm'}
              text={getLocationInfo(activitySearchResult.location)}
            />
            {activitySearchResult.tags.length > 0 && (
              <CustomLabelText marginTop={6} noWrap icon={LocalOffer} sizeText={'sm'} text={getTags()} />
            )}
          </GridItem>
          {(activitySearchResult.takeaway ||
            activitySearchResult.homeService ||
            activitySearchResult.tableReservation) && (
            <Hidden mdDown>
              <GridItem xs={1} alignItems={'end'}>
                <GridContainer marginBottom={40} direction={'column'} justifyContent={'flex-end'}>
                  {activitySearchResult.takeaway && (
                    <GridItem xs={12}>
                      <CustomIcon icon={RoomService} className={classes.icon} />
                    </GridItem>
                  )}
                  {activitySearchResult.homeService && (
                    <GridItem xs={12}>
                      <CustomIcon icon={DirectionsBike} className={classes.icon} />
                    </GridItem>
                  )}
                  {activitySearchResult.tableReservation && (
                    <GridItem xs={12}>
                      <CustomIcon icon={Restaurant} className={classes.icon} />
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </Hidden>
          )}
        </GridContainer>
        {!activitySearchResult.enabled && (
          <GridContainer className={classes.disabled}>
            <GridItem centerHorizontal centerVertical>
              <Schedule className={classes.notEnabledIcon} fontSize={'large'} />
              <CustomText className={classes.notEnabledText} bold size={'lg'} color={'white'}>
                DISPONIBILE A BREVISSIMO
              </CustomText>
            </GridItem>
          </GridContainer>
        )}
      </CustomCard>
    </>
  );
};

type Props = {
  activitySearchResult: ActivitySearchResult;
  onClick?: any;
};

export default ActivitySearchResultElement;
