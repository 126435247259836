import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import {DEFAULT_LANGUAGE} from './utils/config';

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    ns: ['common', 'smartmenu'],
    defaultNS: 'common',
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
