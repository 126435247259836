import it_flag from '../assets/images/languages/it.png';
import de_flag from '../assets/images/languages/de.png';
import en_flag from '../assets/images/languages/en.png';
import es_flag from '../assets/images/languages/es.png';
import fr_flag from '../assets/images/languages/fr.png';

export const LANGUAGE = {
  IT: 'it',
  EN: 'en',
  DE: 'de',
  ES: 'es',
  FR: 'fr',
} as const;

export const LANGUAGES: Record<string, {code: string; image: any}> = {
  [LANGUAGE.IT]: {
    code: LANGUAGE.IT,
    image: it_flag,
  },
  [LANGUAGE.EN]: {
    code: LANGUAGE.EN,
    image: en_flag,
  },
  [LANGUAGE.FR]: {
    code: LANGUAGE.FR,
    image: fr_flag,
  },
  [LANGUAGE.DE]: {
    code: LANGUAGE.DE,
    image: de_flag,
  },
  [LANGUAGE.ES]: {
    code: LANGUAGE.ES,
    image: es_flag,
  },
} as const;
