import {container, defaultFont} from '../../styles/material-kit/nextjs-material-kit';

const downloadRedirectPageStyle = {
  container: {
    ...container,
    ...defaultFont,
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
    paddingBottom: '70px',
    height: '300px',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  title: {
    textAlign: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
  },
} as any;

export default downloadRedirectPageStyle;
