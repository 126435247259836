import {getUserOrders} from '../../../api/orderService';
import {Order} from '../../../models/orders/order';
import {SearchOrder} from '../../../models/orders/request-objects/search-order';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {loadUserOrdersSuccess} from '../../actions/ordersActions';
import {put} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';

export default function* loadUserOrdersAsync(action: {
  type: string;
  payload?: {
    searchOrder: SearchOrder;
    message?: string;
    hideLoader?: boolean;
  };
}) {
  if (!action.payload?.hideLoader) {
    yield put(enableLoader());
  }
  const searchOrder = action.payload ? action.payload.searchOrder : ({} as SearchOrder);
  const response: CustomResponse<Order[]> = yield getUserOrders(searchOrder);
  if (response.status === Status.OK) {
    yield put(loadUserOrdersSuccess({orders: response.payload}));
    if (action?.payload?.message) {
      showToastMessage(AppMessageType.SUCCESS, action.payload.message);
    }
  }
  yield put(disableLoader());
}
