import {Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {getProductList, getProductServiceList} from '../../../../api/smartMenuService';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Status} from '../../../../models/shared/custom-response';
import {SmartMenuProductServiceAvailability} from '../../../../models/smartMenu/product-service';
import {ProductType, SmartMenuProduct, SubscriptionChoiceByPeriod} from '../../../../models/smartMenu/subscription';
import ProductElement from './ProductElement';
import * as _ from 'lodash';

const styles = (theme: Theme) => ({
  section: {
    width: '80%',
    margin: 'auto',
    padding: '100px 0 20px',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  iphone: {
    width: '850px',
    top: '100px',
    right: '-15px',
    position: 'relative' as any,
  },
  templates: {
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

export default function PricingSection({id}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [productList, setProductList] = useState<SmartMenuProduct[]>();
  const [productServiceList, setProductServiceList] =
    useState<Record<ProductType, SmartMenuProductServiceAvailability[]>>();
  const [subscriptionByPeriod, setSubscriptionByPeriod] = useState<SubscriptionChoiceByPeriod[]>([]);

  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      const resProducts = await getProductList();
      if (resProducts.status === Status.OK) {
        setProductList(resProducts.payload);
      }
      const resServices = await getProductServiceList();
      if (resServices.status === Status.OK) {
        setProductServiceList(resServices.payload);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const byPeriod = _.chain(productList)
      .groupBy('productType')
      .map(
        (value, key) =>
          ({
            type: key,
            name: value[0].productName,
            enabled: value[0].enabled,
            products: value,
          } as SubscriptionChoiceByPeriod),
      )
      .value();
    setSubscriptionByPeriod(byPeriod);
  }, [productList]);

  return (
    <div id={id}>
      <GridContainer
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
        fullWidth
        className={classes.section}
      >
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'primary'} size={'lg'} fontFamily={'Goda'}>
            ATTIVA ORA
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'primary'} size={'xl'} fontFamily={'Goda'}>
            SCEGLI IL TUO PIANO IDEALE
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} marginTop>
          <CustomText center color={'black'}>
            Inizia con un{' '}
            <CustomText color={'black'} bold>
              mese gratuito
            </CustomText>{' '}
            per convincerti che Smartmenu sia lo strumento adatto a te.
            <br />
            Potrai fare l’upgrade o disdire in qualsiasi momento.
          </CustomText>
        </GridItem>
      </GridContainer>
      <GridContainer fullWidth style={{padding: '20px 30px 100px'}}>
        {productList && productList.length ? (
          subscriptionByPeriod.map((p) => (
            <GridItem key={p.name} xs={12} sm={4} md={4} marginTop={20}>
              <ProductElement product={p} productServiceList={productServiceList} />
            </GridItem>
          ))
        ) : (
          <GridItem xs={12} centerHorizontal={isMobile}>
            <CustomText>Errore durante il caricamento dei prodotti...</CustomText>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
