import {forgotPasswordSendToken} from '../../../api/authService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {put} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';
import {FORGOT_PASSWORD_SET_NEW} from '../../../constants';

export default function* forgotPasswordSendTokenAsync(action: {type: string; payload: {email: string}}) {
  yield put(enableLoader());
  const response: CustomResponse<any> = yield forgotPasswordSendToken(action.payload.email);
  if (response.status === Status.OK) {
    yield put(disableLoader());
    navigate(FORGOT_PASSWORD_SET_NEW, {
      email: action.payload.email,
    });
    showToastMessage(AppMessageType.INFO, response.message);
  } else {
    yield put(disableLoader());
  }
}
