import {updateOrderStatus} from '../../../api/orderService';
import {Order} from '../../../models/orders/order';
import {UpdateOrderStatus} from '../../../models/orders/request-objects/update-order-status';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {loadUserOrdersSuccess} from '../../actions/ordersActions';
import {put} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';

export default function* updateOrderStatusAsync(action: {
  type: string;
  payload: {updateOrderStatus: UpdateOrderStatus; message: string};
}) {
  yield put(enableLoader());
  const response: CustomResponse<Order[]> = yield updateOrderStatus(action.payload.updateOrderStatus);
  if (response.status === Status.OK) {
    yield put(loadUserOrdersSuccess({orders: response.payload}));
    if (action?.payload?.message) {
      showToastMessage(AppMessageType.SUCCESS, action.payload.message);
    }
  }
  yield put(disableLoader());
}
