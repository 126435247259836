import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import radial from '../../../../assets/images/ordify-bot/radial-demo.png';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {logAnalyticsEvent} from '../../../../services/firebaseService';
import {ORDIFY_BOT_PRIMARY} from '../../../../styles/colors';
import {Fonts} from '../../../../styles/font';
import {CustomAudioPlayer} from './AudioPlayer';
import {StatsBox} from './StatsBox';

type Props = {};

const fontFamily: Fonts = 'Montserrat';

const useStyles = makeStyles({
  imgRadialContainer: {
    position: 'relative',
    width: '100%',
    height: '1000px',
    '@media (max-width: 992px)': {
      height: '650px',
    },
  },
  imgRadial: {
    position: 'absolute',
    width: '100%',
    height: '1000px',
    top: -120,
    objectFit: 'contain',
    '@media (max-width: 992px)': {
      objectFit: 'cover',
      top: -20,
      height: '650px',
    },
  },
  playerBox: {
    background: `linear-gradient(180deg, #333333, ${ORDIFY_BOT_PRIMARY})`,
    borderRadius: '50px 50px 50px 0',
    padding: '10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 'calc(50% - 120px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    height: '164px',
    '@media (max-width: 992px)': {
      width: '80%',
      top: 'calc(50% - 20px)',
      height: '80px',
      borderRadius: '20px 20px 20px 0',
    },
  },
  statsContainer: {
    background: `linear-gradient(180deg, #333333, ${ORDIFY_BOT_PRIMARY})`,
  },
});

export const TestService = ({}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onPlay = () => {
    logAnalyticsEvent('audio_play', {
      audio_name: 'phone-record',
      date: new Date().toISOString(),
    });
  };

  const onPause = () => {
    logAnalyticsEvent('audio_pause', {
      audio_name: 'phone-record',
      date: new Date().toISOString(),
    });
  };

  return (
    <Box id={'testa-il-servizio'}>
      <CustomView paddingVertical={30} className={classes.imgRadialContainer}>
        <CustomRow center marginBottom={16}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'ordifyBot'} center>
            Senti come suona.
          </CustomText>
        </CustomRow>
        <CustomRow center marginBottom={16}>
          <CustomText fontFamily={fontFamily} center>
            Ascolta un esempio di prenotazione tavolo avvenuta {!isMobile && <br />}
            in uno dei nostri ristoranti affiliati.
          </CustomText>
        </CustomRow>
        <img className={classes.imgRadial} src={radial} alt={'radial'} width={100} height={100} />
        <div className={classes.playerBox}>
          <CustomAudioPlayer onPlay={onPlay} onPause={onPause} />
        </div>
      </CustomView>
      <CustomView paddingVertical={120} paddingHorizontal={isMobile ? 20 : 80} className={classes.statsContainer}>
        <CustomRow center marginBottom={20}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'white'} center>
            Questi sono i numeri mensili
            <br />
            che ci distinguono.
          </CustomText>
        </CustomRow>
        <CustomRow center marginBottom={20}>
          <CustomText fontFamily={fontFamily} color={'white'} center>
            E che hanno permesso a più di 50 ristoranti di scegliere noi.
          </CustomText>
        </CustomRow>
        <GridContainer justifyContent={'center'}>
          <GridItem md={6} marginTop={20} marginBottom={20}>
            <StatsBox title={'200+'} description={'<b>Prenotazioni automatizzate</b> a buon fine <b>ogni mese</b>'} />
          </GridItem>
          <GridItem md={6} marginTop={20} marginBottom={20}>
            <StatsBox
              title={'300+'}
              description={'<b>Euro fatti risparmiare</b> ogni mese ai ristoratori che si sono affidati a noi'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent={'center'}>
          <GridItem md={6} marginTop={20} marginBottom={20}>
            <StatsBox
              title={'30+'}
              description={'<b>Ore fatte risparmiare</b> in chiamate ai ristoratori <b>mensilemente</b>'}
            />
          </GridItem>
        </GridContainer>
      </CustomView>
    </Box>
  );
};
