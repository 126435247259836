import {makeStyles} from '@material-ui/styles';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {PromoBanner as PromoBannerType, Template} from '../../../models/smartMenu/smart-menu-config';
import {GRAY_LIGHT} from '../../../styles/colors';
import {Fonts} from '../../../styles/font';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 10px',
    marginBottom: '20px',
    backgroundColor: GRAY_LIGHT,
    width: 'calc(100% + 92px)',
    marginLeft: '-48px',
    '@media (max-width: 480px)': {
      width: 'calc(100% + 60px)',
      marginLeft: '-30px',
    },
  },
  imageItemRound: {
    borderRadius: 20,
    height: '200px',
    width: '100%',
    objectFit: 'cover',
    '@media (max-width: 768px)': {
      height: '120px',
    },
  },
  imageItem: {
    height: '200px',
    width: '100%',
    objectFit: 'cover',
    '@media (max-width: 768px)': {
      height: '120px',
    },
  },
}));

export const PromoBanner = ({promoBanner, template, fontFamily, color}: Props) => {
  const classes = useStyles();
  const {t} = useTranslation('smartmenu');
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {promoBanner.active && (
        <GridContainer className={classes.root}>
          {promoBanner.image && (
            <GridItem xs={12} sm={6} centerHorizontal centerVertical>
              <img
                src={promoBanner.image.url}
                alt={'banner-image'}
                className={template.type === 1 ? classes.imageItemRound : classes.imageItem}
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={promoBanner.image ? 6 : 12}>
            <GridContainer>
              <GridItem xs={12} centerHorizontal>
                <CustomText color={color} fontFamily={fontFamily} size={'xl'} center>
                  {promoBanner.title}
                </CustomText>
              </GridItem>
              <GridItem xs={12} centerHorizontal marginTop>
                <CustomText center html>
                  {showMore
                    ? promoBanner.description
                    : promoBanner.description.slice(0, 100) + (promoBanner.description.length > 100 ? '...' : '')}
                </CustomText>
              </GridItem>
              {promoBanner.description.length > 100 && (
                <GridItem xs={12} centerHorizontal marginTop>
                  {!showMore ? (
                    <CustomText center color={color} fontFamily={fontFamily} onClick={() => setShowMore(true)}>
                      {t('show_more')}
                    </CustomText>
                  ) : (
                    <CustomText center color={color} fontFamily={fontFamily} onClick={() => setShowMore(false)}>
                      {t('close')}
                    </CustomText>
                  )}
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

type Props = {
  promoBanner: PromoBannerType;
  fontFamily: Fonts;
  color: string;
  template: Template;
};
