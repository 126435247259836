import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';
import {WHITE} from '../../../styles/colors';
import {green} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  customIconWrapper: {
    width: 72,
    height: 72,
    marginRight: 4,
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },

  whiteIconElement: {
    fill: WHITE,
  },

  successIconElement: {
    fill: green[600],
  },
}));

const CSuccessIcon = ({}: Props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Icon className={classes.customIconWrapper}>
        <svg>
          <g id="Success">
            <circle id="SuccessCircle" className={classes.successIconElement} cx="36" cy="33" r="14.5" />
            <path
              id="Border_1_"
              className={classes.whiteIconElement}
              d="M36,50.5c-9.6,0-17.5-7.9-17.5-17.5S26.4,15.5,36,15.5S53.5,23.4,53.5,33
		S45.6,50.5,36,50.5z M36,18.5c-8,0-14.5,6.5-14.5,14.5S28,47.5,36,47.5S50.5,41,50.5,33S44,18.5,36,18.5z"
            />
            <path
              id="SuccessCustomIcon"
              className={classes.whiteIconElement}
              d="M45.6,26.4c-0.6-0.6-1.5-0.6-2.1,0l-9.9,9.9l-3.9-3.9c-0.6-0.6-1.5-0.6-2.1,0
		c-0.6,0.6-0.6,1.5,0,2.1l5,5c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l11-11C46.1,28,46.1,27,45.6,26.4z"
            />
          </g>
        </svg>
      </Icon>
    </React.Fragment>
  );
};

type Props = {};

export default CSuccessIcon;
