import {Status} from '../models/shared/custom-response';
import {isDev} from '../utils/config';
import axios from 'axios';
import {showToastMessage} from '../services/toastService';
import {AppMessageType} from '../components/atoms/CustomToast';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const http = axios.create({
  timeout: 10000,
});

http.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.resolve(error);
  },
);

http.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    let message: string;
    if (error && error.response && error.response.data) {
      if (error.response.data.type === 'NON_GESTITO') {
        message = "Errore generico! Se l'errore persiste contattaci alla mail support@ordify.it";
      } else if (error.response.data.error?.message?.statusCode === 401) {
        message = 'Chiamata al server non autorizzata';
      } else {
        message =
          typeof error.response.data.error?.message === 'string'
            ? error.response.data.error?.message
            : error.response.data.error?.message?.error;
      }
      if (!error.config.isBackground) {
        setTimeout(() => {
          showToastMessage(
            AppMessageType.ERROR,
            message ? message : "Errore generico! Se l'errore persiste contattaci alla mail support@ordify.it",
          );
        }, 0);
      }
      return Promise.resolve(error.response.data);
    }
    message = "Errore generico! Se l'errore persiste contattaci alla mail support@ordify.it";
    const internetKO = error.message === 'Network Error';
    if (internetKO) {
      message = 'Errore di rete. Controlla la connessione.';
    }
    if (!error.config.isBackground) {
      setTimeout(() => {
        showToastMessage(AppMessageType.ERROR, message);
      }, 0);
    }
    return Promise.resolve({status: Status.KO, internetKO});
  },
);

export {http};
