import {registrationMerchant} from '../../../api/authService';
import {RegistrationMerchantPayload} from '../../../models/auth/registration';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {MerchantRegistrationPayload} from '../../../models/auth/redux/auth-payload';
import {RegistrationOrigin, UserInfo} from '../../../models/user/user-info.interface';
import {setAuthData} from '../../actions/authActions';

export default function* registrationMerchantAsync() {
  yield put(enableLoader());

  const authPayload: MerchantRegistrationPayload = yield select((state: State) => state.auth.authPayload);
  const registrationMerchantPayload: RegistrationMerchantPayload = {
    email: authPayload.email,
    activityName: authPayload.activityName,
    activityType: authPayload.activityType,
    phoneNumber: authPayload.phoneNumber,
    password: authPayload.password,
    passwordConfirm: authPayload.passwordConfirm,
    coords: authPayload.coords,
    registrationOrigin: RegistrationOrigin.WEB,
  };
  const response: CustomResponse<UserInfo> = yield registrationMerchant(registrationMerchantPayload);

  if (response.status === Status.OK) {
    yield put(setAuthData({authData: {userInfo: response.payload}}));
    showToastMessage(AppMessageType.INFO, 'Ti è stata inviata una mail per confermare la tua registrazione');
  }
  yield put(disableLoader());
}
