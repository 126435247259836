import {Provider} from '../../models/auth/provider';
import {
  RegistrationAnonymousUserPayload,
  RegistrationMerchantPayload,
  RegistrationOauthPayload,
  RegistrationUserPayload,
} from '../../models/auth/registration';
import {Role} from '../../models/auth/role';

export enum RegistrationActionTypes {
  SET_REGISTRATION_USER_PAYLOAD = '[RegistrationState] SetRegistrationUserPayload',
  SET_REGISTRATION_OAUTH_PAYLOAD = '[RegistrationState] SetRegistrationOauthPayload',

  SET_REGISTRATION_ROLE = '[RegistrationState] SetRegistrationRole',
  SET_REGISTRATION_TYPE = '[RegistrationState] SetRegistrationType',
  SET_PHONE_REQUEST_ID = '[RegistrationState] SetPhoneRequestId',

  REGISTRATION_USER = '[RegistrationState] RegistrationUser',
  REGISTRATION_USER_SUCCESS = '[RegistrationState] RegistrationUserSuccess',
  REGISTRATION_USER_FAIL = '[RegistrationState] RegistrationUserFail',

  SET_REGISTRATION_MERCHANT_PAYLOAD = '[RegistrationState] SetRegistrationMerchantPayload',
  REGISTRATION_MERCHANT = '[RegistrationState] RegistrationMerchant',
  REGISTRATION_MERCHANT_SUCCESS = '[RegistrationState] RegistrationMerchantSuccess',
  REGISTRATION_MARCHANT_FAIL = '[RegistrationState] RegistrationMerchantFail',

  REGISTRATION_ANONYMOUS_USER = '[RegistrationState] RegistrationAnonymousUser',

  RESET_REGISTRATION = '[RegistrationState] ResetRegistration',
}

export const registrationUser = () => {
  return {
    type: RegistrationActionTypes.REGISTRATION_USER,
  };
};
export const registrationUserSuccess = () => {
  return {
    type: RegistrationActionTypes.REGISTRATION_USER_SUCCESS,
  };
};
export const registrationUserFail = () => {
  return {
    type: RegistrationActionTypes.REGISTRATION_USER_FAIL,
  };
};

export const registrationMerchant = () => {
  return {
    type: RegistrationActionTypes.REGISTRATION_MERCHANT,
  };
};
export const registrationMerchantSuccess = () => {
  return {
    type: RegistrationActionTypes.REGISTRATION_MERCHANT_SUCCESS,
  };
};
export const registrationMerchantFail = () => {
  return {
    type: RegistrationActionTypes.REGISTRATION_MARCHANT_FAIL,
  };
};

export const registrationAnonymousUser = (payload: {
  registrationAnonymousUserPayload: RegistrationAnonymousUserPayload;
  actions?: any[];
  postRegistrationFunc?: () => void;
}) => {
  return {
    type: RegistrationActionTypes.REGISTRATION_ANONYMOUS_USER,
    payload,
  };
};

export const resetRegistration = () => {
  return {
    type: RegistrationActionTypes.RESET_REGISTRATION,
  };
};
