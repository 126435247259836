import {getActivitiesFilters} from '../../../api/merchantService';
import {Coords} from '../../../models/shared/coords';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {ActivityFilter} from '../../../models/user/activity-filter';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {getActivitiesFiltersSuccess} from '../../actions/sectionUserActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';

export default function* getFiltersActivitiesSagaAsync() {
  let activitiesFiltersAll: ActivityFilter[] = yield select((state: State) => state.sectionUser.activitiesFiltersAll);
  if (!activitiesFiltersAll || activitiesFiltersAll.length === 0) {
    yield put(enableLoader());
  }
  let coords: Coords = yield select((state: State) => state.user.userInfo?.userInfo?.location?.coords);
  let radius: number = yield select((state: State) => state.user.radius);
  const response: CustomResponse<ActivityFilter[]> = yield getActivitiesFilters(coords, radius);
  if (response.status === Status.OK) {
    yield put(getActivitiesFiltersSuccess({activitiesFilters: response.payload}));
  }
  yield put(disableLoader());
}
