import React, {useEffect} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

import downloadRedirectPageStyle from './style';
import {isAndroid, isIOS} from '../../utils/commonFunctions';
import {updateCounter} from '../../api/landing-page.service';
import Footer from '../../components/organisms/Footer/Footer';
import GridItem from '../../components/atoms/Grid/GridItem';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import Header from '../../components/organisms/Header/Header';

const useStyles = makeStyles(downloadRedirectPageStyle);

export default function DownloadRedirectPage({}: any) {
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      await updateCounter(params);
      if (isAndroid()) {
        // window.location.href = 'https://play.google.com/store/apps/details?id=com.ordify';
        window.open('https://play.google.com/store/apps/details?id=com.ordify', '_self');
      } else if (isIOS()) {
        window.location.href = 'https://apps.apple.com/us/app/ordify/id1544285724';
      } else {
        window.location.href = 'https://ordify.it/home#scarica-app';
      }
    };
    init();
  }, []);

  return (
    <div>
      <Header
        color="transparent"
        title="Download Redirect"
        fixed
        changeColorOnScroll={{
          height: 60,
          color: 'white',
        }}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.title}>
                <h6>Ti stiamo reindirizzando alla pagina di download...</h6>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
