import {resendEmailToken} from '../../../api/authService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {put} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';

export default function* resendEmailTokenAsync(action: {type: string; payload: {email: string}}) {
  yield put(enableLoader());
  const response: CustomResponse<{
    isEmailSent: boolean;
  }> = yield resendEmailToken(action.payload.email);
  if (response.status === Status.OK) {
    showToastMessage(AppMessageType.SUCCESS, response.message);
  }
  yield put(disableLoader());
}
