import React from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import downloadImage from '../../../../assets/images/landing/downloadSection/download.png';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import DownloadButtons from '../../../../components/molecules/DownloadButtons/DownloadButtons';

export default function DownloadSection({id}: Props) {
  return (
    <div id={id}>
      <GridContainer marginTop={150} fullWidth>
        <GridItem noPadding={'left'} xs={12} sm={12} md={12} lg={6}>
          <img width={'100%'} alt={'order-image'} src={downloadImage} />
        </GridItem>
        <GridItem className={'gridItemTextCenter'} direction={'column'} xs={12} sm={12} md={12} lg={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
                SCARICA L'APP.
              </CustomText>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} marginTop>
              <CustomText center={true} size={'lg'}>
                Alla registrazione avrai <b>3€ di sconto</b>
                <br />
                da utilizzare per un ordine.
              </CustomText>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer marginTop={50} direction={'row'}>
                <GridItem xs={12} sm={12} md={12}>
                  <DownloadButtons />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
