import {BLACK, dangerColor, GRAY_DARK, GRAY_LIGHT, primaryColor} from '../../../styles/colors';
import {Theme} from '@material-ui/core';

const customInputStyle = (theme: Theme) =>
  ({
    disabled: {
      '&:before': {
        borderColor: 'transparent !important',
      },
    },
    underline: {
      '&:hover:not($disabled):before,&:before': {
        borderColor: '#D2D2D2 !important',
        borderWidth: '1px !important',
      },
      '&:after': {
        borderColor: primaryColor,
      },
    },
    underlineError: {
      '&:after': {
        borderColor: dangerColor,
      },
    },
    formControl: {
      backgroundColor: GRAY_LIGHT,
      padding: '14px 20px',
      borderRadius: '40px',
    },
    formControlFullWidth: {
      width: '100%',
    },
    input: {
      '&,&::placeholder': {
        color: BLACK,
        fontWeight: '400',
        lineHeight: '1.42857',
        opacity: '1',
      },
    },
    inputWithLabel: {
      padding: '0 8px',
      borderRadius: '40px',
      backgroundColor: GRAY_LIGHT,
    },
    label: {
      padding: '0 10px',
    },
    white: {
      color: BLACK,
      backgroundColor: 'white',
    },
    grey: {
      backgroundColor: GRAY_LIGHT,
    },
  } as any);

export default customInputStyle as any;
