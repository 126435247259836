import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ResetPasswordPayload} from '../../../models/auth/reset-password';
import {resetPassword} from '../../../redux/actions/authActions';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../components/atoms/CustomRow';
import PageLoginContainer from '../_Shared/PageLoginContainer';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {UserInfo} from '../../../models/user/user-info.interface';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';

export default function ResetPassword({userInfo, goTo}: Props) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const dispatch = useDispatch();

  const save = () => {
    dispatch(
      resetPassword({
        resetPasswordPayload: {
          email: userInfo?.email,
          newPassword,
          currentPassword: oldPassword,
        } as ResetPasswordPayload,
        goTo,
        force: false,
      }),
    );
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (oldPassword && newPassword && confirmNewPassword) {
          save();
          event.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [oldPassword, newPassword, confirmNewPassword]);

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText center bold size={'xl'} color={'primary'} fontFamily={'Goda'}>
            RECUPERA LA PASSWORD
          </CustomText>
        </CustomRow>
        <CustomRow marginTop>
          <CustomInput
            placeholder="Email"
            value={oldPassword}
            type={''}
            onChange={(text: string) => setOldPassword(text)}
          />
        </CustomRow>
        <CustomRow marginTop>
          <CustomInput
            placeholder="Nuova Password"
            value={newPassword}
            type={'password'}
            onChange={(text: string) => setNewPassword(text)}
          />
        </CustomRow>
        <CustomRow marginTop>
          <CustomInput
            placeholder="Conferma Nuova Password"
            value={confirmNewPassword}
            type={'password'}
            onChange={(text: string) => setConfirmNewPassword(text)}
          />
        </CustomRow>
        <CustomRow marginTop>
          <CustomButton fullWidth onClick={save} title={'Recupera Password'} />
        </CustomRow>
      </PageLoginContainer>
    </>
  );
}
type Props = {
  userInfo?: UserInfo;
  goTo: string;
};
