import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CustomButton from '../../atoms/CustomButtons/CustomButton';
import btn_android from '../../../assets/images/landing/downloadSection/google.png';
import btn_apple from '../../../assets/images/landing/downloadSection/apple.png';
import classNames from 'classnames';

export default function DownloadButtons({noShadow}: Props) {
  const classes = useStyles();
  const btnDownloadRow = classNames({
    [classes.btnDownloadRow]: true,
  });
  const btnDownloadContainer = classNames({
    [classes.btnDownloadContainer]: true,
    [classes.btnDownloadContainerNoShadow]: noShadow,
  });
  return (
    <div className={btnDownloadRow}>
      <CustomButton
        target={'_blank'}
        href={'https://apps.apple.com/us/app/ordify/id1544285724'}
        className={btnDownloadContainer}
        color={'transparent'}
        img={<img className={classes.btnDownload} src={btn_apple} alt="btn_apple" />}
      />
      <CustomButton
        target={'_blank'}
        href={'https://play.google.com/store/apps/details?id=com.ordify'}
        className={btnDownloadContainer}
        color={'transparent'}
        img={<img className={classes.btnDownload} src={btn_android} alt="btn_android" />}
      />
    </div>
  );
}

type Props = {
  noShadow?: boolean;
};

const styles = {
  btnDownloadRowMargin: {
    marginLeft: '100px',
    marginRight: '100px',
  },
  btnDownloadRow: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  btnDownloadContainer: {
    margin: '4px',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '6px',
    paddingRight: '6px',
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  btnDownloadContainerNoShadow: {
    boxShadow: 'none',
  },
  btnDownload: {
    width: '200px',
    height: 'auto',
  },
} as any;

const useStyles = makeStyles(styles);
