import {getAnonymousUserInfo, getUserInfo} from '../../../api/userService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {UserInfo} from '../../../models/user/user-info.interface';
import {getToken, getUserInfo as getUserInfoFromStorage, setUserInfo} from '../../../services/storageService';
import {loadUserInfoSuccess} from '../../actions/userActions';
import {put} from 'redux-saga/effects';

export default function* loadUserAsync() {
  const token: string = yield getToken();
  const userInfoInStorage: UserInfo = yield getUserInfoFromStorage();
  let response: CustomResponse<UserInfo> | null = null;
  if (token) {
    response = yield getUserInfo();
  } else if (userInfoInStorage) {
    response = yield getAnonymousUserInfo(userInfoInStorage.id);
  }
  if (response && response.status === Status.OK) {
    yield put(loadUserInfoSuccess({userInfo: response.payload}));
    setUserInfo(response.payload);
  }
}
