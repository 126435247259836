import {CustomResponse} from '../models/shared/custom-response';
import {SmartMenuProductServiceAvailability} from '../models/smartMenu/product-service';
import {SmartMenuTemplateConfig} from '../models/smartMenu/smart-menu-config';
import {ProductType, SmartMenuProduct} from '../models/smartMenu/subscription';
import {BASE_URL, http} from './axios';

const CONTROLLER_PATH = '/smart-menu';

export const getSmartMenuTemplateConfig = async (
  merchantID: string,
): Promise<CustomResponse<SmartMenuTemplateConfig>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/v1/config/${merchantID}`);
};

export const getProductList = async (): Promise<CustomResponse<SmartMenuProduct[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/v1/product/list`);
};

export const getProductServiceList = async (): Promise<
  CustomResponse<Record<ProductType, SmartMenuProductServiceAvailability[]>>
> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/v1/product/service/list`);
};
