import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid, {GridDirection, GridSize} from '@material-ui/core/Grid';
import classNames from 'classnames';

const styles = {
  grid: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingRight: (props: Props) => (props.noPadding === true || props.noPadding === 'right' ? 0 : '15px'),
    paddingLeft: (props: Props) => (props.noPadding === true || props.noPadding === 'left' ? 0 : '15px'),
    flexBasis: 'auto',
  },
  alignItemsStart: {
    display: 'flex',
    alignItems: 'start',
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  alignItemsEnd: {
    display: 'flex',
    alignItems: 'end',
  },
  justifyContentStart: {
    display: 'flex',
    justifyContent: 'start',
  },
  justifyContentCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  justifyContentEnd: {
    display: 'flex',
    justifyContent: 'end',
  },
  justifyContent: {
    display: 'flex',
    justifyContent: (props: Props) => props.justifyContentMobile,
    '@media (min-width: 768px)': {
      justifyContent: (props: Props) => props.justifyContentDesktop,
    },
  },
  marginTop: {
    marginTop: (props: Props) => (props.marginTop === true ? '8px' : `${props.marginTop}px`),
  },
  marginBottom: {
    marginBottom: (props: Props) => (props.marginBottom === true ? '8px' : `${props.marginBottom}px`),
  },
  direction: {
    flexDirection: (props: Props) => props.direction,
  },
} as any;

const useStyles = makeStyles(styles);

export default function GridItem({
  style,
  className,
  children,
  xs,
  sm,
  md,
  lg,
  alignItems,
  justifyContent,
  justifyContentDesktop,
  justifyContentMobile,
  centerVertical,
  centerHorizontal,
  marginTop,
  marginBottom,
  noPadding,
  direction,
  ...rest
}: Props) {
  const classes = useStyles({
    ...rest,
    marginTop,
    marginBottom,
    noPadding,
    direction,
    justifyContentDesktop,
    justifyContentMobile,
  });
  const gridClasses = classNames({
    [classes.grid]: true,
    [classes.alignItemsStart]: alignItems === 'start',
    [classes.alignItemsCenter]: alignItems === 'center' || centerVertical,
    [classes.alignItemsEnd]: alignItems === 'end',
    [classes.justifyContentStart]: justifyContent === 'start',
    [classes.justifyContentCenter]: justifyContent === 'center' || centerHorizontal,
    [classes.justifyContentEnd]: justifyContent === 'end',
    [classes.justifyContent]: !!justifyContentDesktop || !!justifyContentMobile,
    [classes.marginTop]: marginTop,
    [classes.marginBottom]: marginBottom,
    [classes.direction]: direction,
    ...(!!className && {[className]: true}),
  });
  return (
    <Grid style={style} item {...rest} xs={xs} sm={sm} md={md} lg={lg} className={gridClasses}>
      {children}
    </Grid>
  );
}

type Props = {
  style?: React.CSSProperties;
  className?: string;
  children?: any;
  alignItems?: 'start' | 'center' | 'end';
  justifyContent?: 'start' | 'center' | 'end';
  justifyContentDesktop?: 'start' | 'center' | 'end';
  justifyContentMobile?: 'start' | 'center' | 'end';
  centerVertical?: boolean;
  centerHorizontal?: boolean;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  noPadding?: boolean | 'right' | 'left';
  direction?: GridDirection;
  xs?: boolean | GridSize;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
};
