import {AuthActionTypes} from '../actions/authActions';
import {AuthData} from '../../models/auth/redux/auth-data';
import {AuthPayload} from '../../models/auth/redux/auth-payload';

export interface AuthState {
  isLoggedIn: boolean;
  afterLoginPage?: string;

  authPayload?: AuthPayload;
  authData?: AuthData;
  phoneRequestId?: string;
}

const initialState: AuthState = {
  isLoggedIn: false,
};

const authReducer = (state: AuthState = initialState, action: {type: string; payload: any}) => {
  switch (action.type) {
    case AuthActionTypes.OAUTH_LOGIN_SUCCESS:
    case AuthActionTypes.LOGIN_SUCCESS:
      return {...state, isLoggedIn: true};
    case AuthActionTypes.LOGOUT_SUCCESS:
      return {...state, isLoggedIn: false};
    case AuthActionTypes.SET_AFTER_LOGIN_PAGE:
      return {...state, afterLoginPage: action.payload.page};
    case AuthActionTypes.SET_PHONE_REQUEST_ID:
      return {...state, phoneRequestId: action.payload.requestID};
    case AuthActionTypes.SET_AUTH_PAYLOAD:
      return {...state, authPayload: action.payload.authPayload};
    case AuthActionTypes.SET_AUTH_DATA:
      return {...state, authData: action.payload.authData};
    default:
      return state;
  }
};

export default authReducer;
