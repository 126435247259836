import {CustomResponse} from '../models/shared/custom-response';
import {UpdateUserRequest} from '../models/user/request-objects/update-user';
import {UserInfo} from '../models/user/user-info.interface';
import {getToken} from '../services/storageService';
import {BASE_URL, http} from './axios';

export const getUserInfo = (): Promise<CustomResponse<UserInfo>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.get(`${BASE_URL}/users/get-user`, {
    headers,
    isBackground: true,
  } as any);
};

export const getAnonymousUserInfo = (userID: string): Promise<CustomResponse<UserInfo>> => {
  return http.get(`${BASE_URL}/users/get-anonymous-user`, {
    params: {id: userID},
    isBackground: true,
  } as any);
};

export const updateUser = (updateUserRequest: UpdateUserRequest): Promise<CustomResponse<UserInfo>> => {
  return http.post(`${BASE_URL}/users/update-user`, updateUserRequest);
};

export const deleteUserAccount = async (): Promise<CustomResponse<{deleted: boolean}>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.delete(`${BASE_URL}/users/delete-account`, {headers});
};
