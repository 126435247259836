import {logout} from '../../../api/authService';
import {Provider} from '../../../models/auth/provider';
import {UserInfo} from '../../../models/user/user-info.interface';
import {removeToken, removeUserInfo, setUserInfo} from '../../../services/storageService';
import {logoutSuccess} from '../../actions/authActions';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {resetRegistration} from '../../actions/registrationActions';
import {resetSectionUser} from '../../actions/sectionUserActions';
import {loadUserInfoSuccess} from '../../actions/userActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {navigate} from '../../../services/navigationService';
import {HOME} from '../../../constants';

export default function* logoutAsync() {
  yield put(enableLoader());

  const userInfo: UserInfo = yield select((state: State) => state.user.userInfo);

  yield logout();
  removeToken();
  removeUserInfo();

  if (userInfo.auth.provider === Provider.GOOGLE || userInfo.auth.provider === Provider.FACEBOOK) {
    // yield signOut(); // da non fare perchè slogga da tutti glie account google
  }
  navigate(HOME);
  yield put(loadUserInfoSuccess({userInfo: undefined}));
  yield put(resetRegistration());
  yield put(resetSectionUser());
  yield put(logoutSuccess());
  yield put(disableLoader());
}
