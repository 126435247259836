import {getConfigs} from '../../../api/globalConfigService';
import {Config} from '../../../models/globalConfig/config';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {loadConfigSuccess} from '../../actions/globalActions';
import {put, select} from 'redux-saga/effects';
import {State} from '../../reducers';

export default function* loadConfigAsync() {
  const userID: string = yield select((state: State) => state?.user?.userInfo?.id);
  if (userID) {
    const response: CustomResponse<Config> = yield getConfigs(userID);
    if (response.status === Status.OK) {
      yield put(loadConfigSuccess(response.payload));
    }
  }
}
