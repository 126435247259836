import {login} from '../../../api/authService';
import {LoginRequest, LoginResponse} from '../../../models/auth/login';
import {Role} from '../../../models/auth/role';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {setToken, setUserInfo} from '../../../services/storageService';
import {loginSuccess, setAuthData} from '../../actions/authActions';
import {disableLoader, enableLoader, loadConfig} from '../../actions/globalActions';
import {loadUserInfoSuccess} from '../../actions/userActions';
import {put, select} from 'redux-saga/effects';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {State} from '../../reducers';
import {navigate} from '../../../services/navigationService';
import {HOME, SUBMIT_PHONE_NUMBER, VALIDATE_EMAIL} from '../../../constants';
import {AuthStatus} from '../../../models/auth/auth-status';
import {EmailLoginPayload} from '../../../models/auth/redux/auth-payload';

export default function* loginAsync(action: {type: string; payload: EmailLoginPayload}) {
  yield put(enableLoader());
  const data: LoginRequest = {
    username: action.payload.email,
    password: action.payload.password,
  };
  const response: CustomResponse<LoginResponse> = yield login(data);
  if (response.status === Status.OK) {
    const authData = {
      jwtToken: response.payload.token,
      userInfo: response.payload.user,
    };
    yield put(setAuthData({authData}));
    switch (response.payload.authStatus) {
      case AuthStatus.EMAIL_NOT_VALID: {
        navigate(VALIDATE_EMAIL);
        if (response.message) {
          showToastMessage(AppMessageType.WARNING, response.message);
        }
        break;
      }
      case AuthStatus.PHONE_NUMBER_MISSING:
      case AuthStatus.PHONE_NUMBER_NOT_VALID: {
        navigate(SUBMIT_PHONE_NUMBER, {comingFromLogin: true});
        if (response.message) {
          showToastMessage(AppMessageType.WARNING, response.message);
        }
        break;
      }
      case AuthStatus.USER_OK: {
        setToken(response.payload.token);
        yield put(loginSuccess());
        const user = response.payload.user;
        setUserInfo(user);
        yield put(loadUserInfoSuccess({userInfo: user}));
        if (user.roles.includes(Role.ADMIN)) {
          showToastMessage(AppMessageType.SUCCESS, `BenvenutƏ ${user.userInfo?.name} !`);
        } else if (user.roles.includes(Role.MERCHANT)) {
          showToastMessage(AppMessageType.SUCCESS, `BenvenutƏ ${user.merchantInfo?.activityName} !`);
        } else {
          yield put(loadConfig());
          const page: string = yield select((state: State) => state.auth.afterLoginPage);
          navigate(page ?? HOME);
          showToastMessage(AppMessageType.SUCCESS, `BenvenutƏ ${user.userInfo?.name} !`);
          break;
        }
      }
    }
  }
  yield put(disableLoader());
}
