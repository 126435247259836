import {Order} from '../../models/orders/order';
import {OrderCartItem} from '../../models/orders/order-cart-item';
import {ActivityDetail} from '../../models/user/activity-detail';
import {ActivityFilter} from '../../models/user/activity-filter';
import {ActivitySearchResult, ActivitySearchResultResponse} from '../../models/user/activity-search-result';
import {OrdersActionTypes} from '../actions/ordersActions';
import {SectionUserActionTypes} from '../actions/sectionUserActions';
import {CountOrders} from '../../models/orders/count-orders';
import {TableReservationForm} from '../../models/tableReservationSettings/request-objects/upsert-table-reservation-settings.dto';
import * as _ from 'lodash';
import {ActivityDetailTab} from '../../pages/ActivityDetail/components/MainContent';

export interface SectionUserState {
  activitySearchResultList: ActivitySearchResultResponse;
  favoriteActivities: ActivitySearchResult[] | null;
  activitiesFiltersAll: ActivityFilter[];
  activitiesFiltersSelected: ActivityFilter[];
  activityDetail: ActivityDetail | null;
  orderCart: OrderCartItem[];
  orders: Order[] | null;
  orderCounters: CountOrders | null;
  tableReservationForm: TableReservationForm | null;
  activeTab?: ActivityDetailTab;
}

const initialState: SectionUserState = {
  activitySearchResultList: {
    favorites: [],
    results: null,
  },
  favoriteActivities: null,
  activitiesFiltersAll: [],
  activitiesFiltersSelected: [],
  activityDetail: null,
  orderCart: [],
  orders: null,
  orderCounters: {
    totalCount: 0,
  } as CountOrders,
  tableReservationForm: {} as TableReservationForm,
};

const sectionUserReducer = (state: SectionUserState = initialState, action: {type: string; payload: any}) => {
  switch (action.type) {
    case SectionUserActionTypes.SEARCH_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activitySearchResultList: action.payload.activitySearchResultList,
      };
    }
    case SectionUserActionTypes.LOAD_FAVORITE_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        favoriteActivities: action.payload.favoriteActivities,
      };
    }
    case SectionUserActionTypes.GET_ACTIVITIES_FILTERS_SUCCESS: {
      return {
        ...state,
        activitiesFiltersAll: action.payload.activitiesFilters,
      };
    }
    case SectionUserActionTypes.SET_ACTIVITIES_FILTERS_SELECTED: {
      return {
        ...state,
        activitiesFiltersSelected: action.payload.activitiesFilters,
      };
    }
    case SectionUserActionTypes.LOAD_ACTIVITY_DETAIL_SUCCESS: {
      return {
        ...state,
        activityDetail: action.payload.activityDetail,
      };
    }
    case SectionUserActionTypes.UPDATE_ORDER_CART: {
      return {
        ...state,
        orderCart: action.payload.orderCart,
      };
    }
    case SectionUserActionTypes.UPDATE_TABLE_RESERVATION_INFO: {
      return {
        ...state,
        tableReservationForm: {
          ...state.tableReservationForm,
          ...(!_.isNil(action.payload.day) && {day: action.payload.day}),
          ...(!_.isNil(action.payload.hour) && {hour: action.payload.hour}),
          ...(!_.isNil(action.payload.numberOfPersons) && {numberOfPersons: action.payload.numberOfPersons}),
          ...(!_.isNil(action.payload.useProntoInTavola) && {useProntoInTavola: action.payload.useProntoInTavola}),
        },
      };
    }
    case OrdersActionTypes.LOAD_USER_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: action.payload.orders,
      };
    }
    case OrdersActionTypes.LOAD_ORDER_COUNTERS_USER_SUCCESS: {
      return {
        ...state,
        orderCounters: action.payload.counters,
      };
    }
    case SectionUserActionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    }
    case SectionUserActionTypes.RESET_SECTION_USER:
      return {...initialState};
    default:
      return state;
  }
};

export default sectionUserReducer;
