import {isProd} from '../utils/config';
import Hotjar from '@hotjar/browser';

const siteId = 5087359;
const hotjarVersion = 6;

export class HotjarService {
  isHotjarEnabled = () => isProd();

  init = () => {
    if (this.isHotjarEnabled()) {
      Hotjar.init(siteId, hotjarVersion);
    }
  };
}

export const hotjar = new HotjarService();
