import React from 'react';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {GRAY_LIGHT} from '../../styles/colors';

const useStyles = makeStyles({
  root: {},
  divider: {
    borderWidth: 0.5,
    borderColor: GRAY_LIGHT,
  },
} as any);

const CustomCheckbox = ({
  containerClassName,
  className,
  disabled,
  checked,
  onPress,
  center,
  icon,
  checkedIcon,
  children,
  itemDivider = false,
  canClickOnLabel = true,
}: Props) => {
  const classes = useStyles();
  const baseClassNames = classNames({
    [classes.root]: true,
    ...(containerClassName && {[containerClassName]: true}),
  });
  return (
    <>
      <div className={baseClassNames}>
        <FormControlLabel
          disabled={disabled}
          color={'primary'}
          control={
            <Checkbox
              color={'primary'}
              icon={icon}
              checkedIcon={checkedIcon}
              disabled={disabled}
              checked={checked}
              onChange={onPress}
            />
          }
          label={children}
        />
        {itemDivider && <div className={classes.divider} />}
      </div>
    </>
  );
};

type Props = {
  containerClassName?: any;
  className?: any;
  disabled?: boolean;
  checked?: boolean;
  onPress?: any;
  children?: any;
  icon?: any;
  checkedIcon?: any;
  center?: boolean;
  itemDivider?: boolean;
  canClickOnLabel?: boolean;
};

export default CustomCheckbox;
