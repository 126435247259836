import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useEffect} from 'react';
import CustomButton from '../../components/atoms/CustomButtons/CustomButton';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import GridItem from '../../components/atoms/Grid/GridItem';
import CustomText from '../../components/atoms/Typography/CustomText';
import Footer from '../../components/organisms/Footer/Footer';
import Header from '../../components/organisms/Header/Header';
import {ORDIFY_BOT} from '../../constants';
import {logAnalyticsEvent} from '../../services/firebaseService';
import {fbPixel} from '../../services/fpixel';
import {navigate} from '../../services/navigationService';
import {Fonts} from '../../styles/font';

const fontFamily: Fonts = 'Montserrat';

const useStyles = makeStyles({
  boxContainerTitle: {
    marginTop: '250px',
    '@media (min-width: 320px) and (max-width: 768px)': {
      marginTop: '150px',
    },
  },
});

const OrdifyBotThankYouPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      fbPixel.track('Contact');
    }, 0);
  }, []);

  const goToHome = () => {
    logAnalyticsEvent('button_click', {
      page_title: 'OrdifyBot Thank You Page',
      page_location: window.location.href,
      page_path: window.location.pathname,
      button_name: 'Return To Home',
    });
    navigate(ORDIFY_BOT);
  };

  return (
    <div style={{overflow: 'hidden'}}>
      <Header
        color="transparent"
        textColor={'black'}
        fontFamily={'Montserrat'}
        fixed
        inverted
        showDrawer={false}
        showLoginButton={false}
        showLogo={true}
        changeColorOnScroll={{
          height: 50,
          color: 'white',
          textColor: 'black',
        }}
      />
      <Box>
        <GridContainer className={classes.boxContainerTitle}>
          <GridItem md={12} centerHorizontal>
            <CustomText fontFamily={fontFamily} color={'ordifyBot'} bold size={'xxl'} center>
              Grazie per aver richiesto la demo
              <br />
              e aver mostrato interesse
              <br />
              nel progetto ❤️
            </CustomText>
          </GridItem>
          <GridItem md={12} marginTop={40} centerHorizontal>
            <CustomText fontFamily={fontFamily} size={'md'} center>
              Ti invieremo un’email con i dettagli il prima possibile.
            </CustomText>
          </GridItem>
          <GridItem xs={12} marginTop={40} marginBottom={40} centerHorizontal>
            <CustomButton
              color={'ordifyBot'}
              size={'lg'}
              title={'Torna alla landing page'}
              fontFamily={fontFamily}
              onClick={goToHome}
            />
          </GridItem>
        </GridContainer>
      </Box>
      <Footer />
    </div>
  );
};

export default OrdifyBotThankYouPage;
