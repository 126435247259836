import {createHashHistory} from 'history';

const historyHelp = createHashHistory();
export {historyHelp};

export const navigate = (pathname: string, state?: any, search?: string) => {
  historyHelp.push({pathname, search}, state);
};

export const replace = (pathname: string, state?: any, search?: string) => {
  historyHelp.replace({pathname, search}, state);
};

export const navigateBack = () => {
  historyHelp.forward();
};
