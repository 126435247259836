import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {
  BLACK,
  Colors,
  dangerColor,
  GRAY_DARK,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  WHITE,
} from '../../styles/colors';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

const CustomRadioButton = ({
  color = 'primary',
  textColor = 'white',
  onValueChange,
  value,
  defaultValue,
  list,
  style,
  className,
}: Props) => {
  const classes = useStyle({color});

  const baseClasses = classNames({
    [classes.baseStyle]: true,
    ...(className && {[className]: true}),
  });

  const radioClasses = classNames({
    ...(color && {[classes[color]]: true}),
  });

  const _onChange = (l: string, v: string) => {
    onValueChange && onValueChange(v, l);
  };

  return (
    <div className={baseClasses} style={style}>
      <RadioGroup defaultValue={defaultValue} value={value}>
        {list.map((l, i) => (
          <FormControlLabel
            onClick={(e) => {
              e.preventDefault();
              _onChange(l.label, l.value);
            }}
            value={l.value}
            control={<Radio className={radioClasses} />}
            label={l.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

const useStyle = makeStyles({
  baseStyle: {},
  primary: {color: `${primaryColor} !important`},
  info: {color: `${infoColor} !important`},
  success: {color: `${successColor} !important`},
  warning: {color: `${warningColor} !important`},
  danger: {color: `${dangerColor} !important`},
  white: {color: `${WHITE} !important`},
  black: {color: `${BLACK} !important`},
  gray: {color: `${GRAY_DARK} !important`},
} as any);

type Props = {
  list: {value: string; label: string}[];
  value?: string;
  defaultValue?: string;
  color?: Colors;
  textColor?: Colors;
  style?: any;
  className?: any;
  onValueChange: (value: string, label: string) => void;
};

export default CustomRadioButton;
