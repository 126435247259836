import Hidden from '@material-ui/core/Hidden';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import logo_white from '../../../assets/images/logo_nome_bianco.png';
import logo_red from '../../../assets/images/logo_nome_rosso.png';
import {HOME} from '../../../constants';
import {navigate} from '../../../services/navigationService';
import CustomButton from '../../atoms/CustomButtons/CustomButton';
import headerStyle from './headerStyle';

const useStyles = makeStyles(headerStyle);

const HeaderLogo = ({isPageOnTop = false, inverted = false, isHome}: HeaderLogoProps) => {
  const classes = useStyles();
  return (
    <>
      <Hidden mdDown>
        <CustomButton
          onClick={() => navigate(HOME)}
          noShadow
          className={classes.logoContainer}
          color={'transparent'}
          img={<img className={classes.logo} src={!isPageOnTop || inverted ? logo_red : logo_white} alt="logo" />}
        />
      </Hidden>
      <Hidden lgUp>
        <CustomButton
          onClick={() => navigate(HOME)}
          noShadow
          color={'transparent'}
          img={<img className={classes.logo} src={!isPageOnTop || !isHome ? logo_red : logo_white} alt="logo" />}
        />
      </Hidden>
    </>
  );
};

type HeaderLogoProps = {
  inverted?: boolean;
  isPageOnTop?: boolean;
  isHome?: boolean;
};

export default HeaderLogo;
