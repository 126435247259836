import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import CustomText from '../../components/atoms/Typography/CustomText';
import CustomRow from '../../components/atoms/CustomRow';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import GridItem from '../../components/atoms/Grid/GridItem';
import CustomButton from '../../components/atoms/CustomButtons/CustomButton';
import {LocationInfo} from '../../models/shared/location-info';
import ModalAdviceBeforeOrderContent, {AdviceModeType} from './components/ModalAdviceBeforeOrderContent';
import {State} from '../../redux/reducers';
import {OrderCartItem} from '../../models/orders/order-cart-item';
import {ActivityDetail} from '../../models/user/activity-detail';
import {CustomResponse, Status} from '../../models/shared/custom-response';
import {checkOrder} from '../../api/orderService';
import {Order, OrderOrigin, OrderType} from '../../models/orders/order';
import {createOrder} from '../../redux/actions/ordersActions';
import {showToastMessage} from '../../services/toastService';
import {AppMessageType} from '../../components/atoms/CustomToast';
import {UpsertOrder} from '../../models/orders/request-objects/upsert-order';
import {HomeServiceInfo, TakeawayInfo} from '../../models/orders/info';
import {getLocationInfoFromDetail} from '../../utils/getLocation';
import {Day, Slot} from '../../models/timetables/timetables.interface';
import moment from 'moment';
import * as _ from 'lodash';
import {Spacing} from '../../styles/spacing';
import CustomInput from '../../components/atoms/CustomInput/CustomInput';
import CustomSelect from '../../components/atoms/CustomSelect';
import CustomCheckbox from '../../components/atoms/CustomCheckbox';
import CustomGooglePlacesAutocomplete from '../../components/atoms/CustomGooglePlacesAutocomplete/CustomGooglePlacesAutocomplete';
import {GooglePlaceSearchResponse} from '../../models/shared/google-api';
import CustomDatePicker from '../../components/atoms/CustomDatePicker';
import PaymentSection from './components/PaymentSection';
import {navigate} from '../../services/navigationService';
import {HOME} from '../../constants';
import TotalCartPrices from './components/TotalCartPrices';
import CustomLabelText from '../../components/atoms/CustomLabelText';
import {isValidDate} from '../../utils/commonFunctions';
import PageContainer from '../../components/organisms/PageContainer';

const useStyles = makeStyles((theme) => ({
  home: {
    width: '70%',
    padding: '12px',
    margin: '150px auto 20px',
    position: 'relative',
    zIndex: 1,
    '@media (max-width: 768px)': {
      margin: '125px auto 0',
      width: 'auto',
    },
    '@media (max-width: 480px)': {
      margin: '85px auto 0',
      width: 'auto',
    },
  },
  streetNumber: {
    marginLeft: Spacing.SCALE_4,
  },
}));

const maximumDate = moment(new Date()).add(2, 'years').toDate();
let showModalAdviceAction: any;

const CompleteOrder = ({activityDetail, orderCart}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [homeService, setHomeService] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [pickupDay, setPickupDay] = useState<Date>();
  const [pickupHour, setPickupHour] = useState<string>();
  const [address, setAddress] = useState<string>('');
  const [streetNumber, setStreetNumber] = useState<string>('');
  const [hours, setHours] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showModalAdvice, setShowModalAdvice] = useState(false);
  const [modalAdviceMode, setModalAdviceMode] = useState<AdviceModeType>('takeaway');
  const [placeDetail, setPlaceDetail] = useState<LocationInfo>();
  const [showPaymentSection, setShowPaymentSection] = useState(false);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    const now = new Date();
    const open = !getDay(now)?.closingDay;
    setIsOpen(open);
    setPickupDay(now);
    if (open) {
      updateHours(false, now);
    }
  }, []);

  useEffect(() => {
    if (!orderCart || orderCart.length === 0) {
      navigate(HOME);
    }
  }, [orderCart]);

  useEffect(() => {
    if (!activityDetail?.takeawaySettings.enabled && activityDetail?.homeServiceSettings.enabled) {
      setHomeService(true);
    }
  }, [activityDetail]);

  const getDay = (d: Date) => {
    try {
      if (!isValidDate(d)) {
        return;
      }
      let dayOfWeek = moment(d.toISOString()).day();
      dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
      return activityDetail?.info.timetables.days.find((dd) => dd.dayID === dayOfWeek.toString(10)) || ({} as Day);
    } catch (e) {}
  };

  const updateHours = (hS: boolean, d?: Date) => {
    const hrs = getHours(hS, d);
    setHours(hrs);
    if (hrs && hrs.length > 0 && (!pickupHour || hrs.indexOf(pickupHour) === -1)) {
      setPickupHour(hrs[0]);
    }
  };

  const getHours = (hS: boolean, d?: Date) => {
    let hoursList: string[] = [];
    const homeS = hS;
    const dateSelected = d;
    if (dateSelected) {
      const timetablesDay = getDay(dateSelected);
      const ds = moment(dateSelected).format('DD/MM/yyyy');
      if (homeS && activityDetail && activityDetail.homeServiceSettings.deliveryTimes.length > 0) {
        hoursList = activityDetail?.homeServiceSettings.deliveryTimes;
      } else {
        hoursList = buildHourListByOrdersInterval(homeS, ds, timetablesDay?.slots || []);
      }
      hoursList = filterByTimeLimit(homeS, ds, hoursList);
    }
    return hoursList;
  };

  const buildHourListByOrdersInterval = (homeS: boolean, ds: string, slots: Slot[]) => {
    const now = moment();
    const hoursList = [];
    const ordersInterval = homeS
      ? activityDetail?.homeServiceSettings.ordersInterval
      : activityDetail?.takeawaySettings.ordersInterval;
    for (let s of slots) {
      let startTime = moment(ds + ' ' + s.startHour, 'DD/MM/yyyy HH:mm');
      let endTime = moment(ds + ' ' + s.endHour, 'DD/MM/yyyy HH:mm');
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }
      if (now.isBefore(startTime) || now.isBetween(startTime, endTime)) {
        while (startTime <= endTime) {
          hoursList.push(moment(startTime).format('HH:mm'));
          startTime.add(ordersInterval, 'minutes');
        }
      }
    }
    return hoursList;
  };

  const filterByTimeLimit = (homeS: boolean, ds: string, hoursList: string[]) => {
    const now = moment();
    const timeLimit = homeS
      ? activityDetail?.homeServiceSettings.timeLimit
      : activityDetail?.takeawaySettings.timeLimit;
    const firstHour = moment(ds + ' ' + hoursList[0], 'DD/MM/yyyy HH:mm');
    return hoursList.filter((h) => {
      const currentHour = moment(ds + ' ' + h, 'DD/MM/yyyy HH:mm');
      if (currentHour.isBefore(firstHour)) {
        currentHour.add(1, 'day');
      }
      return currentHour.subtract(timeLimit, 'minutes').isAfter(now);
    });
  };

  const selectedAddress = (data: GooglePlaceSearchResponse) => {
    const locInfo = getLocationInfoFromDetail(data);
    setPlaceDetail(locInfo);
    setAddress(`${locInfo.address}, ${locInfo.description}`);
    if (locInfo.streetNumber) {
      setStreetNumber(locInfo.streetNumber);
    }
  };

  const getOrderCartTotalPrice = () => {
    return _.sum(orderCart.map((o) => o.totalPrice * o.count));
  };

  const getHomeServiceInfo = () => {
    if (!homeService) {
      return null;
    }
    return {
      hour: pickupHour,
      day: pickupDay,
      locationInfo: {
        ...placeDetail,
        streetNumber,
      },
    } as HomeServiceInfo;
  };

  const getTakeawayInfo = () => {
    if (homeService) {
      return null;
    }
    return {
      hour: pickupHour,
      day: pickupDay,
    } as TakeawayInfo;
  };

  const getUpsertOrder = () => {
    const homeServiceInfo = getHomeServiceInfo();
    const takeawayInfo = getTakeawayInfo();
    return {
      activityID: activityDetail?.id,
      type: homeService ? OrderType.HOME_SERVICE : OrderType.TAKEAWAY,
      orderCartItems: orderCart,
      homeServiceInfo,
      takeawayInfo,
      note,
      origin: OrderOrigin.WEB,
      originUrl: window.origin ?? window.location.origin,
    } as UpsertOrder;
  };

  const canOrder = () => {
    if (homeService) {
      return pickupDay && pickupHour && address && streetNumber;
    }
    return pickupDay && pickupHour;
  };

  const commonDoOrder = (doOrderFunc: () => {}) => {
    if (homeService) {
      const minimumPrice = activityDetail?.homeServiceSettings.minimumPrice;
      if (minimumPrice && getOrderCartTotalPrice() < minimumPrice) {
        showToastMessage(
          AppMessageType.WARNING,
          `Per poter effettuare un ordine con consegna a domicilio aggiungi ${
            minimumPrice - getOrderCartTotalPrice()
          }€ all'ordine`,
        );
        return;
      }
      setModalAdviceMode('home-service');
    } else {
      setModalAdviceMode('takeaway');
    }
    showModalAdviceAction = doOrderFunc;
    setShowModalAdvice(true);
  };

  const doOrder = async () => {
    setShowModalAdvice(false);
    const upsertOrder = getUpsertOrder();
    dispatch(createOrder({upsertOrder}));
  };

  const openPaySection = () => {
    setShowPaymentSection(true);
    setTimeout(() => {
      window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    }, 500);
  };

  const checkAndPay = async (pay: any) => {
    setShowModalAdvice(false);
    const upsertOrder = getUpsertOrder();
    const resCheck: CustomResponse<Order> = await checkOrder(upsertOrder);
    if (resCheck.status === Status.OK) {
      pay();
    }
  };

  const getMissing = () => {
    if (activityDetail) {
      return (activityDetail.homeServiceSettings.minimumPrice - getOrderCartTotalPrice()).toFixed(2);
    }
    return '0';
  };

  return (
    <>
      <PageContainer small pageTitle={"COMPLETA L'ORDINE"}>
        {activityDetail?.homeServiceSettings.enabled && !activityDetail?.takeawaySettings.enabled && (
          <CustomRow center marginTop marginBottom>
            <CustomText center bold>
              Questa attività offre solo servizio a domicilio
            </CustomText>
          </CustomRow>
        )}
        {activityDetail?.homeServiceSettings.enabled && activityDetail?.takeawaySettings.enabled && (
          <>
            <CustomRow center marginTop>
              <CustomCheckbox
                checked={homeService}
                onPress={() => {
                  updateHours(!homeService, pickupDay);
                  setHomeService(!homeService);
                }}
              />
              <CustomText>Usa il servizio a domicilio</CustomText>
            </CustomRow>
            <CustomRow center marginTop>
              <CustomText center>
                {activityDetail?.homeServiceSettings.radiusAreaList &&
                  activityDetail?.homeServiceSettings.radiusAreaList.length > 0 &&
                  activityDetail?.homeServiceSettings.radiusAreaList.map((r, i) => {
                    const radiusInKm = r.radius ? r.radius / 1000 : 0;
                    return (
                      <CustomRow marginBottom={4} center>
                        <CustomText center key={i} size={'xs'}>
                          {'Consegna in un raggio di '}
                          <CustomText center size={'xs'} bold>
                            {radiusInKm} km{' '}
                          </CustomText>
                          {r.centerRadiusDifferent ? `da ${r.centerRadius.cityName}` : 'dalla posizione del ristorante'}
                        </CustomText>
                      </CustomRow>
                    );
                  })}
              </CustomText>
            </CustomRow>
            {homeService && !!activityDetail?.homeServiceSettings.price && (
              <CustomRow center marginBottom={16} marginTop>
                <CustomText center>
                  Verrà applicato un sovrapprezzo di {activityDetail?.homeServiceSettings.price} €
                </CustomText>
              </CustomRow>
            )}
            {homeService &&
              !!activityDetail?.homeServiceSettings.minimumPrice &&
              getOrderCartTotalPrice() < activityDetail?.homeServiceSettings.minimumPrice && (
                <CustomRow marginBottom={16} marginTop>
                  <CustomText color={'primary'} bold size={'sm'}>
                    Per poter effettuare un ordine con consegna a domicilio devi ordinare almeno per un totale di{' '}
                    {activityDetail?.homeServiceSettings.minimumPrice} € (mancano {getMissing()} €)
                  </CustomText>
                </CustomRow>
              )}
          </>
        )}
        <>
          <GridContainer>
            {homeService && (
              <>
                <GridItem xs={9} marginTop>
                  <CustomGooglePlacesAutocomplete
                    types={'address'}
                    value={address}
                    onChange={(s) => setAddress(s)}
                    label={'Indirizzo'}
                    placeholder={'Indirizzo'}
                    onPlaceSelected={selectedAddress}
                  />
                </GridItem>
                <GridItem xs={3} marginTop>
                  <CustomInput
                    value={streetNumber}
                    type={'number-pad'}
                    placeholder={'N°'}
                    onChange={(text: string) => setStreetNumber(text)}
                  />
                </GridItem>
              </>
            )}
            <GridItem xs={12} sm={isOpen && hours.length > 0 ? 6 : 12} marginTop>
              <CustomDatePicker
                label={'Imposta il giorno'}
                placeholder={'Imposta il giorno'}
                value={pickupDay}
                minDate={new Date()}
                maxDate={maximumDate}
                onDateChange={(d) => {
                  if (d) {
                    setPickupDay(d);
                    setIsOpen(!getDay(d)?.closingDay);
                    updateHours(homeService, d);
                  }
                }}
              />
            </GridItem>
            {isOpen && hours.length > 0 ? (
              <>
                <GridItem xs={12} sm={6} marginTop>
                  <CustomSelect
                    label={"Imposta l'orario"}
                    placeholder={"Imposta l'orario"}
                    value={pickupHour}
                    onChange={(d) => setPickupHour(d.value)}
                    items={hours.map((h) => ({
                      value: h,
                      label: h,
                    }))}
                    dataStructure={{id: 'value', name: 'label'}}
                  />
                </GridItem>
                <GridItem xs={12} marginTop>
                  <CustomInput
                    placeholder={"Scrivi qualcosa che vuoi che l'attività prenda in considerazione"}
                    value={note}
                    onChange={(t: string) => setNote(t)}
                    multiline
                  />
                </GridItem>
              </>
            ) : !isOpen ? (
              <GridItem xs={12} sm={12} marginTop justifyContent={'center'}>
                <CustomText bold>L'attività è chiusa nel giorno selezionato</CustomText>
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} marginTop justifyContent={'center'}>
                <CustomText bold>Nessun orario disponibile</CustomText>
              </GridItem>
            )}
          </GridContainer>
        </>
        <GridContainer>
          <GridItem xs={12}>
            <CustomLabelText
              marginTop={20}
              label={'Tipo ordine:'}
              labelColor={'black'}
              textAlignRight
              boldText
              text={homeService ? "Domicilio - ricevi all'indirizzo inserito" : 'Asporto - ritira presso il locale'}
            />
            {!showPaymentSection && (
              <>
                <TotalCartPrices
                  upsertOrder={getUpsertOrder()}
                  activityID={activityDetail?.id}
                  activityServiceAmount={homeService ? activityDetail?.homeServiceSettings.price : 0}
                  canApplyCoupon={false}
                />
              </>
            )}
          </GridItem>
        </GridContainer>
        {!activityDetail?.orderPaymentPreferences?.onlineDisabled && !showPaymentSection && (
          <CustomRow center>
            <CustomButton
              fullWidth
              disabled={!isOpen || hours.length === 0 || !canOrder()}
              color={'primary'}
              title={'Paga subito con carta'}
              onClick={openPaySection}
            />
          </CustomRow>
        )}
        {!activityDetail?.orderPaymentPreferences?.cashOnDeliveryDisabled && (
          <CustomRow
            marginTop={showPaymentSection || !activityDetail?.orderPaymentPreferences?.onlineDisabled ? 20 : 0}
          >
            <CustomButton
              fullWidth
              simple
              bordered
              disabled={!isOpen || hours.length === 0 || !canOrder()}
              color={'primary'}
              title={'Paga alla consegna'}
              onClick={() => commonDoOrder(doOrder)}
            />
          </CustomRow>
        )}
        {showPaymentSection && (
          <PaymentSection
            disabled={!isOpen || hours.length === 0 || !canOrder()}
            onPayAction={(pay) => commonDoOrder(() => checkAndPay(pay))}
            upsertOrder={getUpsertOrder()}
            activityServiceAmount={homeService ? activityDetail?.homeServiceSettings.price : 0}
          />
        )}
        <ModalAdviceBeforeOrderContent
          open={showModalAdvice}
          onClose={() => setShowModalAdvice(false)}
          mode={modalAdviceMode}
          homeServiceAvailable={activityDetail?.homeServiceSettings.enabled}
          onAcceptClick={showModalAdviceAction}
          onCloseClick={() => setShowModalAdvice(false)}
        />
      </PageContainer>
    </>
  );
};

type Props = {
  activityDetail: ActivityDetail | null;
  orderCart: OrderCartItem[];
  isLoggedIn: boolean;
};

const mapStateToProps = (state: State) => ({
  activityDetail: state.sectionUser.activityDetail,
  orderCart: state.sectionUser.orderCart,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteOrder);
