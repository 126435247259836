import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import CustomText from '../../components/atoms/Typography/CustomText';
import CustomRow from '../../components/atoms/CustomRow';
import CustomButton from '../../components/atoms/CustomButtons/CustomButton';
import {State} from '../../redux/reducers';
import {OrderCartItem} from '../../models/orders/order-cart-item';
import {ActivityDetail} from '../../models/user/activity-detail';
import {CustomResponse, Status} from '../../models/shared/custom-response';
import {checkOrder} from '../../api/orderService';
import {Order, OrderOrigin, OrderType} from '../../models/orders/order';
import {createOrder} from '../../redux/actions/ordersActions';
import {showToastMessage} from '../../services/toastService';
import {AppMessageType} from '../../components/atoms/CustomToast';
import {UpsertOrder} from '../../models/orders/request-objects/upsert-order';
import {TableReservationInfo} from '../../models/orders/info';
import moment from 'moment';
import {Spacing} from '../../styles/spacing';
import CustomInput from '../../components/atoms/CustomInput/CustomInput';
import {UserInfo} from '../../models/user/user-info.interface';
import CustomLabelText from '../../components/atoms/CustomLabelText';
import PaymentSection from '../CompleteOrder/components/PaymentSection';
import ModalAdviceBeforeOrderContent, {AdviceModeType} from '../CompleteOrder/components/ModalAdviceBeforeOrderContent';
import {Event, People, Restaurant, Schedule} from '@material-ui/icons';
import {navigate} from '../../services/navigationService';
import {HOME} from '../../constants';
import TotalCartPricesTableOrder from './components/TotalCartPricesTableOrder';
import PageContainer from '../../components/organisms/PageContainer';

const useStyles = makeStyles((theme) => ({
  home: {
    width: '70%',
    padding: '12px',
    margin: '150px auto 20px',
    position: 'relative',
    zIndex: 1,
    '@media (max-width: 768px)': {
      margin: '125px auto 0',
      width: 'auto',
    },
    '@media (max-width: 480px)': {
      margin: '85px auto 0',
      width: 'auto',
    },
  },
}));

let showModalAdviceAction: any;

const CompleteTableOrder = ({userInfo, activityDetail, orderCart}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tableReservationForm = useSelector((s: State) => s.sectionUser.tableReservationForm);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [note, setNote] = useState('');
  const [tableReservationInfo, setTableReservationInfo] = useState<TableReservationInfo>();
  const [showModalAdvice, setShowModalAdvice] = useState(false);
  const [modalAdviceMode, setModalAdviceMode] = useState<AdviceModeType>('table-reservation');
  const [showPaymentSection, setShowPaymentSection] = useState(false);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  useEffect(() => {
    if (!tableReservationForm || !tableReservationForm.day || !tableReservationForm.hour) {
      navigate(HOME);
    } else if (tableReservationForm) {
      const {day, hour, numberOfPersons, useProntoInTavola} = tableReservationForm;
      setTableReservationInfo({
        day,
        hour,
        numberOfPersons,
        useProntoInTavola,
        useDeposit: activityDetail?.tableReservationSettings.config.depositEnabled && !useProntoInTavola,
      } as TableReservationInfo);
    }
  }, [tableReservationForm]);

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.userInfo.name);
      setSurname(userInfo.userInfo.surname);
      setEmail(userInfo.email);
      setPhoneNumber(userInfo.userInfo.phoneNumber);
    }
  }, [userInfo]);

  const canOrder = () => {
    return name && name.trim() && surname && surname.trim();
  };

  const prontoInTavolaUsed = () => {
    return (
      activityDetail?.tableReservationSettings?.config?.prontoInTavolaEnabled &&
      tableReservationInfo?.useProntoInTavola &&
      orderCart &&
      orderCart.length > 0
    );
  };

  const haveToPay = () => {
    return prontoInTavolaUsed() || activityDetail?.tableReservationSettings?.config?.depositEnabled;
  };

  const getTableReservationInfo = (checkError = true) => {
    if (checkError) {
      if (!name) {
        showToastMessage(AppMessageType.ERROR, 'Campo nome vuoto');
        return null;
      }
      if (!surname) {
        showToastMessage(AppMessageType.ERROR, 'Campo nome vuoto');
        return null;
      }
    }
    return {
      ...tableReservationInfo,
      name,
      surname,
    };
  };

  const commonDoOrder = (doOrderFunc: () => {}) => {
    const tableReservationInfoTemp = getTableReservationInfo();
    if (!tableReservationInfoTemp) {
      return;
    }
    setModalAdviceMode(prontoInTavolaUsed() ? 'pronto-in-tavola' : 'table-reservation');
    showModalAdviceAction = () => doOrderFunc();
    setShowModalAdvice(true);
  };

  const doOrder = async () => {
    setShowModalAdvice(false);
    const upsertOrder = getUpsertOrder(false);
    dispatch(createOrder({upsertOrder}));
  };

  const getUpsertOrder = (withOrderCart = true) => {
    const t = getTableReservationInfo(false);
    return {
      activityID: activityDetail?.id,
      type: OrderType.TABLE_RESERVATION,
      tableReservationInfo: t,
      note,
      ...(withOrderCart && {orderCartItems: prontoInTavolaUsed() ? orderCart : []}),
      origin: OrderOrigin.WEB,
      originUrl: window.origin ?? window.location.origin,
    } as UpsertOrder;
  };

  const openPaySection = () => {
    setShowPaymentSection(true);
    setTimeout(() => {
      window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    }, 500);
  };

  const checkAndPay = async (pay: any) => {
    setShowModalAdvice(false);
    const upsertOrder = getUpsertOrder();
    const resCheck: CustomResponse<Order> = await checkOrder(upsertOrder);
    if (resCheck.status === Status.OK) {
      pay();
    }
  };

  return (
    <>
      <PageContainer small pageTitle={'COMPLETA LA PRENOTAZIONE'}>
        <CustomRow marginTop>
          <CustomText color={'primary'}>
            La tua prenotazione tavolo
            {prontoInTavolaUsed() && (
              <CustomText bold color={'primary'}>
                {' '}
                (con Pronto In Tavola)
              </CustomText>
            )}
          </CustomText>
        </CustomRow>
        <CustomRow marginTop>
          <CustomLabelText icon={Restaurant} text={activityDetail?.info.activityName} />
        </CustomRow>
        <CustomRow marginTop>
          <CustomLabelText icon={Event} text={moment(tableReservationInfo?.day).format('ddd DD/MM/YYYY')} />
        </CustomRow>
        <CustomRow marginTop>
          <CustomLabelText icon={Schedule} text={tableReservationInfo?.hour} />
        </CustomRow>
        <CustomRow marginTop>
          <CustomLabelText
            icon={People}
            text={
              tableReservationInfo?.numberOfPersons +
              ` person${tableReservationInfo?.numberOfPersons && tableReservationInfo?.numberOfPersons > 1 ? 'e' : 'a'}`
            }
          />
        </CustomRow>
        <CustomRow marginTop>
          <CustomLabelText
            style={{flex: 1, marginRight: Spacing.SCALE_6}}
            labelOnTop
            label={'Nome'}
            element={<CustomInput value={name} onChange={(t: string) => setName(t)} placeholder={'Nome'} />}
          />
          <CustomLabelText
            style={{flex: 1, marginLeft: Spacing.SCALE_6}}
            labelOnTop
            label={'Cognome'}
            element={<CustomInput value={surname} onChange={(t: string) => setSurname(t)} placeholder={'Cognome'} />}
          />
        </CustomRow>
        <CustomLabelText
          marginTop
          labelOnTop
          label={'Email*'}
          element={<CustomInput disabled value={email} placeholder={'Email'} />}
        />
        <CustomLabelText
          marginTop
          labelOnTop
          label={'Numero di telefono*'}
          element={<CustomInput disabled value={phoneNumber} placeholder={'Numero di telefono'} />}
        />
        <CustomLabelText
          marginTop
          labelOnTop
          element={
            <CustomInput
              value={note}
              onChange={(t: string) => setNote(t)}
              placeholder={"Scrivi qualcosa che vuoi che l'attività prenda in considerazione"}
            />
          }
        />
        {haveToPay() ? (
          <>
            {!showPaymentSection && (
              <>
                <TotalCartPricesTableOrder
                  upsertOrder={getUpsertOrder()}
                  activityID={activityDetail?.id}
                  prontoInTavolaUsed={!!prontoInTavolaUsed()}
                  tableReservationConfig={activityDetail?.tableReservationSettings?.config}
                  canApplyCoupon={false}
                />
                <CustomRow marginTop>
                  <CustomButton
                    fullWidth
                    disabled={!canOrder()}
                    color={'primary'}
                    title={'Conferma e vai al pagamento'}
                    onClick={openPaySection}
                  />
                </CustomRow>
              </>
            )}
          </>
        ) : (
          <CustomRow marginTop>
            <CustomButton
              fullWidth
              simple
              bordered
              disabled={!canOrder()}
              color={'primary'}
              title={'Conferma prenotazione'}
              onClick={() => commonDoOrder(doOrder)}
            />
          </CustomRow>
        )}
        <CustomRow marginTop center>
          <CustomText size={'xs'} center>
            I campi di inserimento che presentano l'asterisco (*) sono quelli inseriti in fase di registrazione e non
            sono modificabili da questa pagina
          </CustomText>
        </CustomRow>
        {showPaymentSection && (
          <PaymentSection
            disabled={!canOrder()}
            onPayAction={(pay: any) => commonDoOrder(() => checkAndPay(pay))}
            upsertOrder={getUpsertOrder()}
            prontoInTavolaUsed={!!prontoInTavolaUsed()}
            tableReservationConfig={activityDetail?.tableReservationSettings?.config}
            canApplyCoupon={!!prontoInTavolaUsed()}
          />
        )}
      </PageContainer>
      <ModalAdviceBeforeOrderContent
        open={showModalAdvice}
        onClose={() => setShowModalAdvice(false)}
        mode={modalAdviceMode}
        homeServiceAvailable={activityDetail?.homeServiceSettings.enabled}
        onAcceptClick={showModalAdviceAction}
        onCloseClick={() => setShowModalAdvice(false)}
      />
    </>
  );
};

type Props = {
  userInfo: UserInfo | null;
  activityDetail: ActivityDetail | null;
  orderCart: OrderCartItem[];
};

const mapStateToProps = (state: State) => ({
  userInfo: state.user.userInfo,
  activityDetail: state.sectionUser.activityDetail,
  orderCart: state.sectionUser.orderCart,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteTableOrder);
