import {Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import * as _ from 'lodash';
import React from 'react';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import ImageViewer from '../../../components/molecules/ImageViewer';
import {ItemForMenu} from '../../../models/menu/category';
import {Colors} from '../../../styles/colors';
import {Fonts} from '../../../styles/font';
import {AllergenList} from './AllergenList';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px 20px 20px',
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  categoryContainer: {},
  titleCategory: {
    paddingLeft: '30px',
  },
  description: {
    paddingLeft: '30px',
  },
  itemContainer: {},
  imageItem: {
    borderRadius: 10,
    objectFit: 'cover',
    width: 'inherit',
    height: '130px',
    '@media (min-width: 768px)': {
      height: '70px',
      width: '70px',
    },
  },
}));

const MenuItemTemplate1 = ({menuItem, color, fontFamily, index, hideImages}: Props) => {
  const classes = useStyles();

  return (
    <CustomCard plain padding={'sm'}>
      <GridContainer justifyContent={'center'} fullWidth marginTop={index === 0 ? 0 : 30} marginBottom={20}>
        <Hidden smUp>
          <GridItem justifyContent={'center'} xs={12} sm={4} md={4} lg={4}>
            {!hideImages && menuItem.image && !!menuItem.image.url && (
              <ImageViewer image={menuItem.image} className={classes.imageItem} />
            )}
          </GridItem>
        </Hidden>
        <GridItem xs={12} sm={8} md={8} lg={8}>
          <div>
            <CustomRow marginTop={!!menuItem.image}>
              <CustomText style={{lineHeight: 'normal'}} bold size={'md'} fontFamily={fontFamily}>
                {menuItem.name.toUpperCase()}
              </CustomText>
              <CustomRow marginLeft>
                <AllergenList allergenList={menuItem.allergens} />
              </CustomRow>
            </CustomRow>
            {!!menuItem.ingredients.length && (
              <CustomText size={'sm'} fontFamily={fontFamily}>
                {menuItem.ingredients.map((ingrendient) => _.upperFirst(ingrendient.name))?.join(', ')}
              </CustomText>
            )}
            {!!menuItem.description && (
              <CustomRow marginTop>
                <CustomText size={'sm'} italic fontFamily={fontFamily}>
                  {menuItem.description}
                </CustomText>
              </CustomRow>
            )}
            {menuItem.discountPercentage ? (
              <>
                <CustomRow marginTop={6}>
                  <CustomText size={'sm'} lineThrough fontFamily={fontFamily}>
                    €{menuItem.price / (1 - menuItem.discountPercentage / 100)}
                  </CustomText>
                  <CustomText size={'sm'}>&nbsp;\&nbsp;</CustomText>
                  <CustomText size={'sm'} color={color} bold fontFamily={fontFamily}>
                    €{menuItem.price}
                  </CustomText>
                </CustomRow>
              </>
            ) : (
              <CustomRow marginTop={6}>
                <CustomText bold size={'sm'} fontFamily={fontFamily}>
                  €{menuItem.price}
                </CustomText>
              </CustomRow>
            )}
          </div>
        </GridItem>
        <Hidden xsDown>
          <GridItem justifyContent={'center'} xs={12} sm={4} md={4} lg={4}>
            {menuItem.image && !!menuItem.image.url && (
              <ImageViewer image={menuItem.image} className={classes.imageItem} />
            )}
          </GridItem>
        </Hidden>
      </GridContainer>
    </CustomCard>
  );
};

type Props = {
  menuItem: ItemForMenu;
  index: number;
  color?: Colors;
  fontFamily?: Fonts;
  hideImages?: boolean;
};

export default MenuItemTemplate1;
