import {makeStyles} from '@material-ui/styles';
import CustomCard from '../../../../components/atoms/Card/CustomCard';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomText from '../../../../components/atoms/Typography/CustomText';

type Props = {
  title: string;
  description: string;
};

const useStyles = makeStyles({
  container: {
    background: 'linear-gradient(90deg, #FFFFFF, #CCCCCC)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '200px',
    padding: '10px 20px',
  },
});

export const StatsBox = ({title, description}: Props) => {
  const classes = useStyles();
  return (
    <CustomCard className={classes.container}>
      <CustomRow center>
        <CustomText fontFamily={'Montserrat'} size={'xl'} bold center>
          {title}
        </CustomText>
      </CustomRow>
      <CustomRow center>
        <CustomText fontFamily={'Montserrat'} center html>
          {description}
        </CustomText>
      </CustomRow>
    </CustomCard>
  );
};
