import {isProd} from '../utils/config';
import TagManager from 'react-gtm-module';

const gtmId = 'GTM-KLRJT9X5';

export class GtmService {
  isGtmEnabled = () => isProd();

  init = () => {
    if (this.isGtmEnabled()) {
      TagManager.initialize({gtmId});
    }
  };
}

export const gtm = new GtmService();
