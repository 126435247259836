import React from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import orderImage from '../../../../assets/images/landing/orderSection/orderImage.png';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import './orderSectionStyle.scss';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';

export default function OrderSection(props: Props) {
  return (
    <GridContainer marginTop={100} fullWidth={true}>
      <GridItem noPadding={'left'} alignItems={'center'} xs={12} sm={12} md={12} lg={6}>
        <img alt={'order-image'} className={'imgOrder'} src={orderImage} />
      </GridItem>
      <GridItem marginTop={100} className={'gridItemTextRight'} direction={'column'} xs={12} sm={12} md={12} lg={6}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
              ORDINA DA NOI.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomText size={'lg'}>
              <b>Takeaway</b> o a <b>domicilio*</b>, con Ordify puoi ordinare i piatti che ami dai migliori ristoranti,
              col servizio che preferisci.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomText size={'md'}>
              * è il locale a occuparsi del delivery. Nell’app è indicato se offre il servizio, o se devi essere tu a
              ritirare l’ordine.
            </CustomText>
          </GridItem>
        </GridContainer>
        <GridContainer marginTop={40}>
          <GridItem xs={12} sm={12} md={12}>
            <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
              PRENOTA TAVOLO.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomText size={'lg'}>
              Verifica la disponibilità e <b>prenota comodamente un tavolo</b> direttamente dall’applicazione, in
              qualsiasi luogo e momento.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomText size={'md'} italic={true}>
              Ancora perdi tempo a chiamare il ristorante?
            </CustomText>
          </GridItem>
          <GridItem className={'hidden'} marginTop={50} xs={12} sm={12} md={12}>
            <CustomButton size={'lg'}>Fammi vedere</CustomButton>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}

type Props = {
  id?: string;
};
