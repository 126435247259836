import Header from '../../../components/organisms/Header/Header';
import React from 'react';
import HamburgerImage from './HamburgerImage';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../../components/organisms/Footer/Footer';
import {makeStyles} from '@material-ui/styles';
import {WHITE} from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  home: {
    width: '50%',
    padding: '12px',
    margin: '160px auto',
    position: 'relative',
    zIndex: 1,
    '@media (max-width: 768px)': {
      margin: '160px auto 100px',
      width: '90%',
    },
  },
  background: {
    margin: '0 !important',
    padding: '0 !important',
    background: WHITE,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  backButton: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
}));

export default function PageLoginContainer({children, hideLogo, showHamburger}: Props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background} />
      <Header
        color="transparent"
        fixed
        inverted
        showBackButton
        showDrawer={false}
        showLoginButton={false}
        showLogo={!hideLogo}
        changeColorOnScroll={{
          height: 60,
          color: 'white',
        }}
      />
      <div className={classes.home}>{children}</div>
      {showHamburger && <HamburgerImage />}
      <Hidden smDown>
        <Footer />
      </Hidden>
    </>
  );
}

type Props = {
  children: any;
  hideLogo?: boolean;
  showHamburger?: boolean;
};
