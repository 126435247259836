import {colorMap, Colors, primaryColor} from '../../styles/colors';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import CustomText from '../atoms/Typography/CustomText';
import {Fonts} from '../../styles/font';

const TitleWithHorizontalLine = ({
  children,
  className,
  color = 'primary',
  fontFamily = 'Goda',
  center,
  right = false,
  bold = true,
}: Props) => {
  const classes = useStyle({color} as Props);
  const containerClasses = classNames({
    [classes.root]: true,
    ...(className && {[className]: true}),
  });
  const horizontalLineClasses = classNames({
    [classes.horizontalLine]: !right,
    [classes.horizontalLineRight]: right,
  });

  return (
    <>
      <div className={containerClasses}>
        <CustomText
          size={'xl'}
          fontFamily={fontFamily}
          center={center}
          bold={bold}
          className={horizontalLineClasses}
          color={color}
        >
          {children}
        </CustomText>
      </div>
    </>
  );
};

type Props = {
  children: any;
  color?: Colors;
  className?: string;
  fontFamily?: Fonts;
  center?: boolean;
  left?: boolean;
  right?: boolean;
  bold?: boolean;
};

const useStyle = makeStyles({
  root: {},
  horizontalLine: {
    display: 'block',
    overflow: 'hidden',
    textAlign: 'center',
    '&:before,&:after': {
      backgroundColor: (props: Props) => (props.color ? colorMap(props.color) : primaryColor),
      content: '""',
      display: 'inline-block',
      height: '1px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '50%',
    },
    '&:before': {
      right: '0.5em',
      marginLeft: '-50%',
    },
    '&:after': {
      left: '0.5em',
      marginRight: '-50%',
    },
  },
  horizontalLineRight: {
    display: 'flex',
    alignItems: 'center',
    '&:after': {
      backgroundColor: (props: Props) => (props.color ? colorMap(props.color) : primaryColor),
      content: '""',
      flex: 1,
      marginLeft: '0.5em',
      height: '1px',
    },
  },
});

export default TitleWithHorizontalLine;
