export const primaryColor = '#9C1F1E';
export const primaryTrasparentColor = '#9C1F1E30';
export const secondaryColor = '#101084';
export const warningColor = '#ffae00';
export const dangerColor = '#cc4b37';
export const successColor = '#69C779';
export const infoColor = '#87CEFA';
export const roseColor = '#e91e63';
export const grayColor = '#999999';

export const facebookColor = '#3b5998';
export const instagramColor = '#DD2A7B';
export const whatsappColor = '#25D366';

export const menuBlueColor = '#135EB6';
export const menuGoldColor = '#B69213';

export const ORDIFY_BOT_PRIMARY = '#C90202';

// GRAYSCALE
export const TRASPARENT = '#00000000';
export const WHITE = '#FFFFFF';
export const WHITE_SMOKED = '#f8f8f8';
export const GRAY_LIGHT = '#e9e9e9';
export const GRAY_MEDIUM = '#d7d7d7';
export const GRAY_DARK = '#929292';
export const BLACK_SMOKED_LIGHT = '#2f2f2f';
export const BLACK_SMOKED = '#2B2422';
export const BLACK = '#000000';
export const GRAY_MEDIUM_TRASPARENT = '#cacaca80';
export const GRAY_DARK_TRASPARENT = '#00000080';

export const BACKGROUND_PAGE_COLOR = WHITE_SMOKED;

export const colorMap = (color: Colors | string) => colorMapInternal[color] ?? color;

const colorMapInternal: Record<Colors | string, string> = {
  ['primary']: primaryColor,
  ['info']: infoColor,
  ['success']: successColor,
  ['warning']: warningColor,
  ['danger']: dangerColor,
  ['white']: WHITE,
  ['black']: BLACK,
  ['gray']: grayColor,
  ['facebook']: facebookColor,
  ['instagram']: instagramColor,
  ['whatsapp']: whatsappColor,
  ['transparent']: GRAY_MEDIUM_TRASPARENT,
  ['menuBlue']: menuBlueColor,
  ['menuGold']: menuGoldColor,
  ['ordifyBot']: ORDIFY_BOT_PRIMARY,
};

export const colorTextForBackgroundMap: Record<Colors, string> = {
  ['primary']: WHITE,
  ['secondary']: WHITE,
  ['info']: WHITE,
  ['success']: WHITE,
  ['warning']: BLACK,
  ['danger']: WHITE,
  ['white']: BLACK,
  ['black']: WHITE,
  ['gray']: WHITE,
  ['facebook']: WHITE,
  ['instagram']: WHITE,
  ['whatsapp']: WHITE,
  ['transparent']: BLACK,
  ['menuBlue']: WHITE,
  ['menuGold']: WHITE,
  ['ordifyBot']: WHITE,
};

export type Colors =
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'white'
  | 'black'
  | 'gray'
  | 'facebook'
  | 'instagram'
  | 'whatsapp'
  | 'transparent'
  | 'menuBlue'
  | 'menuGold'
  | 'ordifyBot'
  | string;
