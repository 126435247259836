import {registrationAnonymousUser} from '../../../api/authService';
import {RegistrationAnonymousUserPayload} from '../../../models/auth/registration';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {RegistrationOrigin, UserInfo} from '../../../models/user/user-info.interface';
import {setUserInfo} from '../../../services/storageService';
import {disableLoader, enableLoader, loadConfig} from '../../actions/globalActions';
import {loadUserInfoSuccess} from '../../actions/userActions';
import {put} from 'redux-saga/effects';

export default function* registrationAnonymousUserAsync(action: {
  type: string;
  payload: {
    registrationAnonymousUserPayload: RegistrationAnonymousUserPayload;
    actions?: any[];
    postRegistrationFunc?: () => void;
  };
}) {
  yield put(enableLoader());
  const response: CustomResponse<UserInfo> = yield registrationAnonymousUser({
    ...action.payload.registrationAnonymousUserPayload,
    registrationOrigin: RegistrationOrigin.WEB,
  });
  if (response.status === Status.OK) {
    yield put(loadUserInfoSuccess({userInfo: response.payload}));
    setUserInfo(response.payload);
    yield put(loadConfig());

    if (action.payload.actions) {
      for (let a of action.payload.actions) {
        yield put(a());
      }
    }

    action.payload.postRegistrationFunc && action.payload.postRegistrationFunc();
  }
  yield put(disableLoader());
}
