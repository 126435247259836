import React from 'react';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {useDispatch} from 'react-redux';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import RegistrationForm from './components/RegistrationForm';
import {RegistrationUserPayload} from '../../../models/auth/registration';
import {isEmailValid} from '../../../utils/commonFunctions';
import {registrationUser} from '../../../redux/actions/registrationActions';
import PageLoginContainer from '../_Shared/PageLoginContainer';
import {EmailRegistrationPayload} from '../../../models/auth/redux/auth-payload';
import {setAuthPayload} from '../../../redux/actions/authActions';
import {Provider} from '../../../models/auth/provider';

export default function Registration() {
  const dispatch = useDispatch();
  const campiCompilati = (registrationPayload: EmailRegistrationPayload) => {
    return (
      registrationPayload.name &&
      registrationPayload.surname &&
      registrationPayload.email &&
      registrationPayload.password &&
      registrationPayload.passwordConfirm
    );
  };

  const passwordCoincidenti = (registrationPayload: RegistrationUserPayload) => {
    return registrationPayload.password === registrationPayload.passwordConfirm;
  };

  const doRegistration = (registrationPayload: EmailRegistrationPayload) => {
    if (!campiCompilati(registrationPayload)) {
      showToastMessage(AppMessageType.ERROR, 'Compila tutti i campi');
      return;
    }
    if (!isEmailValid(registrationPayload.email)) {
      showToastMessage(AppMessageType.ERROR, 'Email non valida');
      return;
    }
    if (!passwordCoincidenti(registrationPayload)) {
      showToastMessage(AppMessageType.ERROR, 'Password non coincidenti');
      return;
    }
    const authPayload: EmailRegistrationPayload = {
      ...registrationPayload,
      type: 'email-registration',
      provider: Provider.LOCAL,
    };
    dispatch(setAuthPayload({authPayload}));
    dispatch(registrationUser());
  };

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText bold size={'xxl'} color={'primary'} fontFamily={'Goda'}>
            REGISTRATI
          </CustomText>
        </CustomRow>
        <>
          <GridContainer marginTop={12}>
            <GridItem xs={12}>
              <RegistrationForm register={doRegistration} />
            </GridItem>
          </GridContainer>
        </>
      </PageLoginContainer>
    </>
  );
}
