import {makeStyles} from '@material-ui/styles';
import {UserCredential} from 'firebase/auth';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import facebookLogo from '../../../assets/images/general/facebook_logo.png';
import googleLogo from '../../../assets/images/general/google_logo.png';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../components/atoms/CustomRow';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {COMPLETE_ORDER, LOGIN, MAIL_LOGIN, REGISTRATION} from '../../../constants';
import {OAuthUser} from '../../../models/auth/oauth-user';
import {Provider} from '../../../models/auth/provider';
import {OAuthPayload} from '../../../models/auth/redux/auth-payload';
import {oauthLogin, setAfterLoginPage, setAuthPayload} from '../../../redux/actions/authActions';
import {loadActivityDetailSuccess, updateOrderCart} from '../../../redux/actions/sectionUserActions';
import {State} from '../../../redux/reducers';
import {
  getSignInRedirectResults,
  signInFacebookWithPopup,
  signInGoogleWithPopup,
} from '../../../services/firebaseService';
import {navigate} from '../../../services/navigationService';
import {
  getActivityFromStorage,
  getAfterLoginPageFromStorage,
  getOrderCartFromStorage,
  setAfterLoginPageOnStorage,
} from '../../../services/storageService';
import {showToastMessage} from '../../../services/toastService';
import {isFacebookInstagramInAppBrowser, isMobileAgent} from '../../../utils/commonFunctions';
import PageLoginContainer from '../_Shared/PageLoginContainer';

const useStyles = makeStyles(() => ({
  logo: {
    height: '16px',
    objectFit: 'contain',
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const previousPage = useSelector((state: State) => state.global.previousPage);
  const afterLoginPage = useSelector((state: State) => state.auth.afterLoginPage);

  useEffect(() => {
    if (previousPage !== LOGIN && afterLoginPage !== COMPLETE_ORDER) {
      dispatch(setAfterLoginPage(previousPage));
      setAfterLoginPageOnStorage(previousPage);
    }
  }, [previousPage, afterLoginPage]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    const init = async () => {
      try {
        const oauthResult = await getSignInRedirectResults();
        if (oauthResult) {
          const page = getAfterLoginPageFromStorage() || '';
          dispatch(setAfterLoginPage(page));
          const orderCart = getOrderCartFromStorage() || [];
          dispatch(updateOrderCart({orderCart}));
          const activity = getActivityFromStorage();
          dispatch(loadActivityDetailSuccess({activityDetail: activity}));
          if (oauthResult.providerId === 'google.com') {
            await completeOauthLogin(oauthResult, Provider.GOOGLE);
          } else if (oauthResult.providerId === 'facebook.com') {
            await completeOauthLogin(oauthResult, Provider.FACEBOOK);
          }
        }
      } catch (e) {
        console.log('Signin With Redirect Error', JSON.stringify(e));
      }
    };
    init();
  }, []);

  async function getOauthUser(oauthResult: UserCredential): Promise<OAuthUser> {
    const nameSplitted = oauthResult.user.displayName?.split(' ');
    return {
      email: oauthResult.user.email,
      name: oauthResult.user.displayName,
      givenName: nameSplitted && nameSplitted.length > 0 ? nameSplitted[0] : oauthResult.user.displayName,
      familyName: nameSplitted && nameSplitted.length > 1 ? nameSplitted[1] : oauthResult.user.displayName,
      accessToken: await oauthResult.user.getIdToken(),
      id: oauthResult.user.uid,
    } as OAuthUser;
  }

  const completeOauthLogin = async (oauthResult: UserCredential, provider: Provider) => {
    const oauthUser = await getOauthUser(oauthResult);
    const authPayload: OAuthPayload = {
      oauthUser,
      provider,
      type: 'oauth',
      email: oauthUser.email,
    };
    dispatch(setAuthPayload({authPayload}));
    dispatch(oauthLogin({oauthUser, provider}));
  };

  const signInWithPopup = async (
    signInFn: () => Promise<UserCredential>,
    completeFn: (oauthResult: UserCredential) => void,
  ) => {
    try {
      const oauthResult = await signInFn();
      completeFn(oauthResult);
    } catch (e) {
      console.log('Signin With Popup Error', JSON.stringify(e));
    }
  };

  const facebookSignin = async () => {
    if (isMobileAgent() && isFacebookInstagramInAppBrowser()) {
      showToastMessage(
        AppMessageType.WARNING,
        'Per effettuare il login con Facebook apri ordify.it su un browser esterno!',
      );
      return;
    } else {
      signInWithPopup(signInFacebookWithPopup, (oauthResult) => completeOauthLogin(oauthResult, Provider.FACEBOOK));
    }
  };

  const googleSignin = async () => {
    if (isMobileAgent() && isFacebookInstagramInAppBrowser()) {
      showToastMessage(
        AppMessageType.WARNING,
        'Per effettuare il login con Google apri ordify.it su un browser esterno!',
      );
      return;
    } else {
      signInWithPopup(signInGoogleWithPopup, (oauthResult) => completeOauthLogin(oauthResult, Provider.GOOGLE));
    }
  };

  const goToRegistrazioneUser = () => {
    navigate(REGISTRATION);
  };
  const goToLoginWithMail = () => {
    navigate(MAIL_LOGIN);
  };

  return (
    <>
      <PageLoginContainer showHamburger>
        <CustomRow center>
          <CustomText bold size={'xxl'} color={'primary'} fontFamily={'Goda'}>
            ACCEDI
          </CustomText>
        </CustomRow>
        <CustomRow center marginTop>
          <CustomText center>
            Accedi per iniziare a ordinare i tuoi piatti preferiti e godere delle promozioni a te riservate.
          </CustomText>
        </CustomRow>
        <GridContainer marginTop={12}>
          <GridItem xs={12}>
            <CustomButton fullWidth title={'Accedi con email'} onClick={goToLoginWithMail} />
          </GridItem>
          <GridItem xs={6}>
            <CustomButton
              fullWidth
              img={<img className={classes.logo} src={googleLogo} alt="google" />}
              onClick={googleSignin}
              color={'white'}
            />
          </GridItem>
          <GridItem xs={6}>
            <CustomButton
              fullWidth
              img={<img className={classes.logo} src={facebookLogo} alt="fb" />}
              onClick={facebookSignin}
              color={'facebook'}
            />
          </GridItem>
          <GridItem xs={12} justifyContent={'center'} marginTop marginBottom>
            <CustomText size={'xs'}>Oppure</CustomText>
          </GridItem>
          <GridItem xs={12}>
            <CustomButton fullWidth color={'black'} title={'Registrati'} onClick={goToRegistrazioneUser} />
          </GridItem>
        </GridContainer>
      </PageLoginContainer>
    </>
  );
}
