import {getFavoriteActivities} from '../../../api/merchantService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {ActivitySearchResult} from '../../../models/user/activity-search-result';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {loadFavoriteActivitiesSuccess} from '../../actions/sectionUserActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {ActivityFilter} from '../../../models/user/activity-filter';

export default function* loadFavoriteActivitiesAsync(action: {type: string; payload: {hideLoader: boolean}}) {
  const favoriteActivities: ActivityFilter[] | null = yield select(
    (state: State) => state.sectionUser.favoriteActivities,
  );
  if (!favoriteActivities || favoriteActivities.length === 0 || action?.payload?.hideLoader) {
    const userID: string = yield select((state: State) => state.user.userInfo?.id);
    if (!action?.payload?.hideLoader) {
      yield put(enableLoader());
    }
    const response: CustomResponse<ActivitySearchResult[]> = yield getFavoriteActivities(userID);
    if (response.status === Status.OK) {
      yield put(loadFavoriteActivitiesSuccess({favoriteActivities: response.payload}));
    }
    yield put(disableLoader());
  }
}
