import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import HeaderRelative from '../../components/organisms/HeaderRelative/HeaderRelative';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../redux/reducers';
import {makeStyles} from '@material-ui/styles';
import {loadActivityDetail} from '../../redux/actions/sectionUserActions';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import GridItem from '../../components/atoms/Grid/GridItem';
import Footer from '../../components/organisms/Footer/Footer';
import MainContent from './components/MainContent';
import MenuIndex from './components/MenuIndex';
import OrderCart from './components/OrderCart';
import {setAfterLoginPage} from '../../redux/actions/authActions';
import ModalDownloadMobileApp from '../../components/molecules/ModalDownloadMobileApp';
import {setAfterLoginPageOnStorage} from '../../services/storageService';

const useStyles = makeStyles((theme) => ({
  root: {},
  imgBackground: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'bottom center',
    height: 'auto',
    maxHeight: '400px',
    '@media (max-width: 767px)': {
      height: '250px',
    },
  },
  contentContainer: {
    padding: '20px',
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  main: {
    margin: '-90px 0 0',
    padding: '0 8px',
    zIndex: 10,
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  orderCart: {
    margin: '-90px 0 0',
    padding: '0 8px',
  },
}));

const ActivityDetail = () => {
  const classes = useStyles();
  const {activityID} = useParams<{activityID: string}>();
  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAfterLoginPage(''));
    setAfterLoginPageOnStorage('');
    if (activityID) {
      dispatch(loadActivityDetail({id: activityID}));
    }
  }, []);

  return (
    <div>
      <ModalDownloadMobileApp />
      <HeaderRelative color="transparent" showBackButton absolute />
      {activityDetail && (
        <>
          <img className={classes.imgBackground} src={activityDetail?.image.url} />
          <div className={classes.contentContainer}>
            <GridContainer>
              <GridItem md={3}>
                <MenuIndex />
              </GridItem>
              <GridItem xs={12} sm={8} md={6} className={classes.main}>
                <MainContent />
              </GridItem>
              <GridItem sm={4} md={3} className={classes.orderCart}>
                <OrderCart />
              </GridItem>
            </GridContainer>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default ActivityDetail;
