import React from 'react';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';

const useStyle = makeStyles((theme) => ({
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  full: {
    width: '100%',
  },
  center: {
    justifyContent: 'center',
  },
  right: {
    justifyContent: 'end',
  },
  marginLeft: {
    marginLeft: (props: Props) => (props.marginLeft === true ? '12px' : `${props.marginLeft}px`),
  },
  marginRight: {
    marginRight: (props: Props) => (props.marginRight === true ? '12px' : `${props.marginRight}px`),
  },
  marginTop: {
    marginTop: (props: Props) => (props.marginTop === true ? '12px' : `${props.marginTop}px`),
  },
  marginBottom: {
    marginBottom: (props: Props) => (props.marginBottom === true ? '12px' : `${props.marginBottom}px`),
  },
  wrap: {
    flexWrap: 'wrap',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  flexShrink: {
    flexShrink: 1,
  },
})) as any;

const CustomRow = ({
  style,
  className,
  children,
  center,
  right,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  wrap,
  nowrap,
  shrink,
  full,
  ...rest
}: Props) => {
  const classes = useStyle({...rest, marginLeft, marginRight, marginTop, marginBottom});
  const rowStyle = classNames({
    [classes.baseStyle]: true,
    [classes.center]: center,
    [classes.right]: right,
    [classes.full]: full,
    [classes.marginLeft]: marginLeft,
    [classes.marginRight]: marginRight,
    [classes.marginTop]: marginTop,
    [classes.marginBottom]: marginBottom,
    [classes.wrap]: wrap,
    [classes.noWrap]: nowrap,
    [classes.flexShrink]: shrink,
    ...(className && {[className]: true}),
  });
  return (
    <div style={style} className={rowStyle}>
      {children}
    </div>
  );
};

type Props = {
  style?: React.CSSProperties;
  className?: any;
  full?: boolean;
  center?: boolean;
  right?: boolean;
  marginLeft?: boolean | number;
  marginRight?: boolean | number;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  wrap?: boolean;
  nowrap?: boolean;
  shrink?: boolean;
  children: any;
};

export default CustomRow;
