import {Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import iphone from '../../../../assets/images/smart-menu/desktop/iphone_3.png';
import iphoneMobile from '../../../../assets/images/smart-menu/mobile/iphone_3.png';
import templates from '../../../../assets/images/smart-menu/desktop/iphone_4.png';
import template_1 from '../../../../assets/images/smart-menu/mobile/iphone_demo.png';
import template_2 from '../../../../assets/images/smart-menu/mobile/iphone_demo_2.png';
import template_3 from '../../../../assets/images/smart-menu/mobile/iphone_demo_3.png';
import redBackground from '../../../../assets/images/smart-menu/desktop/background_rosso.jpg';
import redBackgroundMobile from '../../../../assets/images/smart-menu/mobile/background_rosso_mobile.jpg';
import CustomRow from '../../../../components/atoms/CustomRow';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import ContentResponsive from '../../../../components/molecules/ContentResponsive';
import CustomCarousel from '../../../../components/molecules/CustomCarousel';
import BackgroundImage from '../../../../components/organisms/BackgroundImage';

const styles = (theme: Theme) => ({
  section: {
    width: '80%',
    margin: 'auto',
    padding: '100px 0',
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '100px 0 60px',
    },
  },
  iphone: {
    width: '850px',
    top: '100px',
    right: '-15px',
    position: 'relative' as any,
    '@media (min-width: 768px) and (max-width: 992px)': {
      width: '750px',
    },
  },
  iphoneMobile: {
    width: '350px',
    top: '50px',
    right: '-15px',
    position: 'relative' as any,
  },
  templates: {
    width: '100%',
    '@media (max-width: 480px)': {
      maxWidth: '240px',
    },
    '@media (min-width: 480px) and (max-width: 768px)': {
      maxWidth: '300px',
    },
  },
});

const useStyles = makeStyles(styles);

export default function InterfaceSection({id}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div id={id}>
      <BackgroundImage image={redBackground} imageMobile={redBackgroundMobile}>
        <GridContainer
          justifyContent={'center'}
          direction={'column'}
          alignItems={'center'}
          fullWidth={true}
          className={classes.section}
        >
          <GridItem centerHorizontal xs={12}>
            <CustomText center bold color={'white'} size={'xl'} fontFamily={'Goda'}>
              MA{' '}
              <CustomText center bold color={'white'} size={'xl'} fontFamily={'Goda'} underline>
                L'ESPERIENZA UTENTE
              </CustomText>
              <br />
              CON SMARTMENU COM'È?
              <br /> SI LEGGE BENE?
            </CustomText>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6} direction={'column'} alignItems={'center'} justifyContent={'center'}>
            <CustomText color={'white'} fontFamily={'Goda'} center>
              SÌ, SI LEGGE BENE.
              <br />
              MOOOOOLTO BENE.
            </CustomText>
            <CustomRow marginTop={12}>
              <CustomText color={'white'} center>
                Lo SmartMenu di Ordify è studiato per una{' '}
                <CustomText color={'white'} bold center>
                  visione ottimizzata da mobile
                </CustomText>
                . Tranquilli, i vostri clienti non perderanno la vista!
              </CustomText>
            </CustomRow>
          </GridItem>
          <GridItem
            marginTop={isMobile ? 70 : 0}
            xs={12}
            sm={6}
            md={6}
            lg={6}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CustomText color={'white'} fontFamily={'Goda'} center>
              L’ORIENTAMENTO È FACILITATO.
              <br />È IMPOSSIBILE PERDERSI.
            </CustomText>
            <CustomRow marginTop={12}>
              <CustomText color={'white'} center>
                Dividere il menu in{' '}
                <CustomText color={'white'} bold>
                  categorie
                </CustomText>{' '}
                e{' '}
                <CustomText color={'white'} bold>
                  varianti
                </CustomText>{' '}
                è come avere una bussola per il cliente (e per te) nei menu più intricati! Non c'è più bisogno di
                perdersi in mezzo a tante opzioni, ora la scelta diventa un gioco da ragazzi!
              </CustomText>
            </CustomRow>
          </GridItem>
        </GridContainer>
        <GridContainer marginTop>
          <GridItem xs={12} justifyContent={'end'}>
            <ContentResponsive
              mobile={<img className={classes.iphoneMobile} src={iphoneMobile} alt="iphone" />}
              desktop={<img className={classes.iphone} src={iphone} alt="iphone" />}
            />
          </GridItem>
        </GridContainer>
      </BackgroundImage>
      <GridContainer
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
        fullWidth={true}
        className={classes.section}
      >
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'primary'} size={'xl'} fontFamily={'Goda'}>
            SCEGLI TRA
            <br />3 DIVERSI TEMPLATE
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} marginTop={30}>
          <CustomText center color={'primary'}>
            Hai un locale{' '}
            <CustomText bold color={'primary'}>
              MODERNO
            </CustomText>
            ,{' '}
            <CustomText bold color={'primary'}>
              ELEGANTE
            </CustomText>{' '}
            o{' '}
            <CustomText bold color={'primary'}>
              RETRÒ
            </CustomText>
            ? <br />
            <CustomText bold color={'primary'}>
              No Problem
            </CustomText>
            . Scegli lo stile, i colori e il font del menù che fanno più al caso tuo.
          </CustomText>
        </GridItem>
        <GridItem xs={12} marginTop={60}>
          <ContentResponsive
            mobile={
              <CustomCarousel height={'auto'} center indicators indicatorsIconsColor={'primary'}>
                <img className={classes.templates} src={template_1} alt="template1" />
                <img className={classes.templates} src={template_2} alt="template2" />
                <img className={classes.templates} src={template_3} alt="template3" />
              </CustomCarousel>
            }
            desktop={<img className={classes.templates} src={templates} alt="templates" />}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
