import React, {useEffect, useState} from 'react';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomInput from '../../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../../components/atoms/CustomRow';
import {EmailLoginPayload} from '../../../../models/auth/redux/auth-payload';

const LoginForm = ({login}: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const _login = () => {
    login({email: email.trim(), password});
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (email && password) {
          _login();
          event.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [email, password]);

  return (
    <>
      <CustomRow>
        <CustomInput placeholder="Email" value={email} type={'email'} onChange={(text: string) => setEmail(text)} />
      </CustomRow>
      <CustomRow marginTop>
        <CustomInput
          placeholder="Password"
          value={password}
          type={'password'}
          onChange={(text: string) => setPassword(text)}
        />
      </CustomRow>
      <CustomRow marginTop>
        <CustomButton fullWidth onClick={_login} title={'Accedi'} />
      </CustomRow>
    </>
  );
};

type Props = {
  login: (loginPayload: {email: string; password: string}) => void;
};

export default LoginForm;
