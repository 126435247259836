import CryptoJS from 'crypto-js';

const SECRET_KEY_1 = 'ssdkFOrDiFyHUy2A';
const SECRET_KEY_2 = 'weJiSOrDiFyvR5yAweJiSOrDiFyvR5yA';

// const toHex = RNSimpleCrypto.utils.convertArrayBufferToHex;
// const toUtf8 = RNSimpleCrypto.utils.convertArrayBufferToUtf8;

// const fromHexToArrayBuffer = RNSimpleCrypto.utils.convertHexToArrayBuffer;
// const fromUtf8toArrayBuffer = RNSimpleCrypto.utils.convertUtf8ToArrayBuffer;

export const _encrypt = async (data: any) => {
  // const rawData = JSON.stringify(data);
  // const iv = fromUtf8toArrayBuffer(SECRET_KEY_1);
  // const key = fromHexToArrayBuffer(SECRET_KEY_2);
  // const encryptedData = await RNSimpleCrypto.AES.encrypt(fromUtf8toArrayBuffer(rawData), key, iv);
  // return toHex(encryptedData);
};

export const _decrypt = async (data: any) => {
  // const iv = fromUtf8toArrayBuffer(SECRET_KEY_1);
  // const key = fromHexToArrayBuffer(SECRET_KEY_2);
  // const decryptedData = await RNSimpleCrypto.AES.decrypt(fromHexToArrayBuffer(data), key, iv);
  // return JSON.parse(toUtf8(decryptedData));
};

export const decrypt = (data: string): any => {
  const iv = CryptoJS.enc.Utf8.parse(SECRET_KEY_1);
  const key = CryptoJS.enc.Hex.parse(SECRET_KEY_2);
  const plaintextData = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    format: CryptoJS.format.Hex,
  });
  return JSON.parse(plaintextData.toString(CryptoJS.enc.Utf8));
};

export const encrypt = (data: any): string => {
  const rawData = JSON.stringify(data);
  const iv = CryptoJS.enc.Utf8.parse(SECRET_KEY_1);
  const key = CryptoJS.enc.Hex.parse(SECRET_KEY_2);
  const plaintextData = CryptoJS.AES.encrypt(rawData, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    format: CryptoJS.format.Hex,
  });
  return plaintextData.ciphertext.toString();
};
