import React from 'react';
import CustomAccordion from '../../../../components/atoms/CustomAccordion';
import {makeStyles} from '@material-ui/styles';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../../components/atoms/CustomRow';

const ProntoInTavolaCardElement = ({activityName, onHeaderPress}: Props) => {
  const classes = useStyles();

  const Header = () => (
    <div>
      <CustomRow>
        <CustomText bold color={'primary'}>
          Servizio Pronto In Tavola
        </CustomText>
      </CustomRow>
    </div>
  );

  const CollapsedContent = () => (
    <div>
      <CustomRow>
        <CustomText>
          {activityName} offre il servizio{' '}
          <CustomText bold color={'primary'}>
            Pronto In Tavola{' '}
          </CustomText>
          attraverso cui è possibile contemporaneamente prenotare questo tavolo e ordinare il cibo che si vorrà
          mangiare, trovandolo{' '}
          <CustomText bold color={'primary'}>
            già pronto all'arrivo nel locale
          </CustomText>
          .
        </CustomText>
      </CustomRow>
      <CustomRow marginTop>
        <CustomText bold>
          {'\n'}Vuoi usufruire di questo servizio? Scegli cosa mangiare selezionando gli elementi dalla sezione menù e
          poi torna qui per proseguire con la prenotazione del tavolo, a cui verrà automaticamente agganciato il tuo
          ordine!
        </CustomText>
      </CustomRow>
    </div>
  );

  return (
    <>
      <CustomAccordion className={classes.container} header={<Header />} onHeaderPress={onHeaderPress}>
        <CollapsedContent />
      </CustomAccordion>
    </>
  );
};

type Props = {
  activityName?: string;
  onHeaderPress?: any;
};

const useStyles = makeStyles({
  container: {
    borderRadius: '40px !important',
  },
});

export default ProntoInTavolaCardElement;
