import React, {useEffect} from 'react';
import Footer from '../../components/organisms/Footer/Footer';
import Header from '../../components/organisms/Header/Header';
import {ORDIFY_BOT_HEADER_LINKS} from '../../constants';

import {Advantages, HowItWorks, RequestTrial, TestService, TopSection, WhyUs} from './components';

const OrdifyBotLandingPage = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div style={{overflow: 'hidden'}}>
      <Header
        color="transparent"
        textColor={'black'}
        fontFamily={'Montserrat'}
        links={ORDIFY_BOT_HEADER_LINKS}
        fixed
        showLoginButton={false}
        showLogo={false}
        changeColorOnScroll={{
          height: 100,
          color: 'white',
          textColor: 'black',
        }}
      />
      <TopSection />
      <HowItWorks />
      <TestService />
      <Advantages />
      <WhyUs />
      <RequestTrial />
      <Footer />
    </div>
  );
};

export default OrdifyBotLandingPage;
