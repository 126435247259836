import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {usePlacesWidget} from 'react-google-autocomplete';
import {config, secrets} from '../../../utils/config';
import CustomInput from '../CustomInput/CustomInput';
import {GooglePlaceSearchResponse} from '../../../models/shared/google-api';
import {InputAdornment} from '@material-ui/core';
import {SearchOutlined} from '@material-ui/icons';
import classNames from 'classnames';
import {GRAY_DARK, primaryColor} from '../../../styles/colors';

const useStyles = makeStyles({
  searchInput: {},
});

export default function CustomGooglePlacesAutocomplete({
  id,
  className,
  value,
  onChange,
  onPlaceSelected,
  placeholder,
  label,
  disabled,
  error,
  fullWidth = true,
  types,
  searchFunction,
  defaultValue,
  backgroundInputColor = 'grey',
}: Props) {
  const classes = useStyles();

  const searchInputClasses = classNames({
    [classes.searchInput]: true,
    ...(className && {[className]: true}),
  });

  const {ref: materialRef} = usePlacesWidget({
    apiKey: secrets.googleApiKey,
    language: 'it',
    options: {
      types: types ? [types] : [],
      componentRestrictions: {country: 'it'},
    },
    onPlaceSelected: (place: GooglePlaceSearchResponse) => {
      onPlaceSelected && onPlaceSelected(place);
    },
  });

  return (
    <>
      <CustomInput
        inputRef={materialRef}
        placeholder={placeholder}
        disabled={disabled}
        label={label}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        className={searchInputClasses}
        backgroundInputColor={backgroundInputColor}
        inputProps={{
          endAdornment: (
            <InputAdornment
              onClick={searchFunction ? searchFunction : null}
              style={{marginRight: '20px', color: (value || defaultValue) && !disabled ? primaryColor : GRAY_DARK}}
              position="end"
            >
              <SearchOutlined style={{cursor: 'pointer'}} />
            </InputAdornment>
          ),
        }}
        // value={value}
        onChange={onChange}
      />
    </>
  );
}

type Props = {
  id?: string;
  value?: string;
  className?: string;
  onChange?: (data: string) => void;
  onPlaceSelected?: (data: GooglePlaceSearchResponse) => void;
  placeholder?: string;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  types?: 'address' | '(cities)' | '(regions)' | 'geocode' | 'establishment';
  searchFunction?: any;
  defaultValue?: string;
  backgroundInputColor?: 'white' | 'grey';
};
