import {PeriodValue} from '../models/orders/request-objects/search-order';

export const RADIUS_LIST = [
  {label: '5 km', value: 5000, key: 5000},
  {label: '10 km', value: 10000, key: 10000},
  {label: '15 km', value: 15000, key: 15000},
  {label: '20 km', value: 20000, key: 20000},
  {label: '30 km', value: 30000, key: 30000},
];

export const PERIOD_LIST_ACTIVITIES = [
  {label: 'Ultimo giorno', value: PeriodValue.YESTERDAY, key: 1},
  {label: 'Ultimi 7 giorni', value: PeriodValue.LAST_7_DAYS, key: 2},
  {label: 'Ultimo mese', value: PeriodValue.LAST_1_MONTH, key: 3},
  {label: 'Ultimi 6 mesi', value: PeriodValue.LAST_6_MONTHS, key: 4},
  {label: 'Ultimi 12 mesi', value: PeriodValue.LAST_12_MONTHS, key: 5},
];

export const PERIOD_LIST_USERS = [
  {label: 'Ultimi 30 giorni', value: PeriodValue.LAST_1_MONTH, key: 1},
  {label: 'Ultimi 6 mesi', value: PeriodValue.LAST_6_MONTHS, key: 2},
  {label: 'Ultimi 12 mesi', value: PeriodValue.LAST_12_MONTHS, key: 3},
];
