import {Theme} from '@material-ui/core';

const headerSearchStyle = (theme: Theme) =>
  ({
    appBar: {
      display: 'flex',
      border: '0',
      borderRadius: '3px',
      padding: '0.625rem 0',
      marginBottom: '20px',
      color: '#555',
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
      transition: 'all 150ms ease 0s',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      position: 'relative',
      zIndex: 'unset',
    },
    absolute: {
      position: 'absolute',
      zIndex: '1100',
    },
    fixed: {
      position: 'fixed',
      zIndex: '1100',
    },
    container: {
      width: '100%',
      justifyContent: 'center',
    },
    transparent: {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
      paddingTop: '25px',
      color: '#FFFFFF',
    },
    drawerToggle: {
      position: 'absolute',
      right: '20px',
      top: '40px',
      '@media (max-width: 480px)': {
        top: '28px',
      },
    },
    logoContainer: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    logo: {
      width: '80px',
      height: '80px',
      '@media (max-width: 480px)': {
        width: '70px',
        height: '70px',
      },
    },
  } as any);

export default headerSearchStyle as any;
