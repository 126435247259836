import {defaultFont} from '../../../styles/material-kit/nextjs-material-kit';
import {GRAY_MEDIUM, primaryColor} from '../../../styles/colors';
import {Theme} from '@material-ui/core';
import {Styles} from '@material-ui/styles/withStyles';

const cardStyle = (theme: Theme) =>
  ({
    card: {
      ...defaultFont,
      border: '0',
      borderRadius: '10px',
      marginBottom: (props: any) => (props.marginBottom === true ? '30px' : `${props.marginBottom}px`),
      marginTop: (props: any) => (props.marginTop === true ? '30px' : `${props.marginTop}px`),
      color: 'rgba(0, 0, 0, 0.87)',
      background: '#fff',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '0',
      wordWrap: 'break-word',
      transition: 'all 300ms linear',
    },
    cardPlain: {
      background: 'transparent',
      boxShadow: 'none',
    },
    divider: {
      borderBottom: `1px solid ${GRAY_MEDIUM}`,
      borderRadius: 0,
    },
    cardCarousel: {
      overflow: 'hidden',
    },
    cardAlignementLeft: {
      borderLeft: '20px solid ' + primaryColor,
      textAlign: 'left',
      borderRadius: '0',
    },
    cardAlignementRight: {
      borderRight: '20px solid ' + primaryColor,
      textAlign: 'right',
      borderRadius: '0',
    },
    cardAction: {},
    noPaddingHorizontal: {paddingLeft: '0 !important', paddingRight: '0 !important'},
    noPaddingVertical: {paddingTop: '0 !important', paddingBottom: '0 !important'},
    padding_sm: {padding: '14px'},
    padding_md: {padding: '26px'},
    padding_lg: {padding: '38px'},
    font_sm: {fontSize: '16px'},
    font_md: {fontSize: '20px'},
    font_lg: {fontSize: '24px'},
  } as Styles<Theme, any, any>);

export default cardStyle as any;
