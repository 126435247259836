import moment from 'moment';
import CryptoJS from 'crypto-js';
import {Order, OrderStatus, OrderType} from '../models/orders/order';
import {OrderTrackingStep} from '../models/orders/step';
import {Coords} from '../models/shared/coords';

const phoneNumberMatcher = /^(([+]|00)39)?((3[1-9][0-9]))(\d{6,7})$/;
const telephoneNumberMatcher = /^(([+]|00)39)?((([0-9]{2}|0{1}((x|[0-9]){2}[0-9]{2})))s*[0-9]{3,4}[-]*[0-9]{4}$)/;

const emailValidMatcher =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  if (phoneNumber.match(phoneNumberMatcher) || phoneNumber.match(telephoneNumberMatcher)) {
    return true;
  }
  return false;
};

export const patchPhoneNumber = (phoneNumber: string, acceptTelephone = false) => {
  return phoneNumber.startsWith('+39') ? phoneNumber.trim() : '+39' + phoneNumber.trim();
};

export const isEmailValid = (email: string): boolean => {
  return !!email.match(emailValidMatcher);
};

export const isValidUrl = (s: string) => {
  return s && s.trim() && (s.trim().startsWith('http') || s.trim().startsWith('https'));
};

export const fixUrl = (s: string) => {
  return isValidUrl(s) ? s : `http://${s}`;
};

export function checkValue(value: string) {
  return !!(value && value.trim());
}

export function dateToString(date: Date) {
  return moment(date).format('DD/MM/YYYY, HH:mm');
}

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isAndroid = () => {
  return navigator.userAgent.toLowerCase().includes('android');
};

export const isMobileAgent = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isMobile = () => {
  return window.innerWidth <= 480;
};

export const isFacebookInstagramInAppBrowser = () =>
  /FB_IAB/.test(navigator.userAgent) ||
  /FBAN/.test(navigator.userAgent) ||
  /FBAV/.test(navigator.userAgent) ||
  /Instagram/i.test(navigator.userAgent);

export const calculateFee = (amount: number, fixedFee: number, percentageFee: number) => {
  if (amount) {
    return parseFloat(parseFloat((fixedFee + (amount * percentageFee) / 100).toFixed(1)).toFixed(2));
  }
  return 0;
};

export const getOrderTotalPrice = (o: Order) => {
  if (o) {
    return o.paymentID
      ? o.totalAmount + (o.fee ? o.fee : 0) - (o.couponDiscountAmount ? o.couponDiscountAmount : 0)
      : o.totalAmount;
  }
  return 0;
};

export const getOrderStatus = (status: OrderStatus): string | null => {
  switch (status) {
    case OrderStatus.APPROVED:
      return 'Approvato';
    case OrderStatus.PENDING:
      return 'In attesa';
    case OrderStatus.CANCELED:
      return 'Annullato';
    case OrderStatus.REFUSED:
      return 'Rifiutato';
    case OrderStatus.COMPLETED:
      return 'Completato';
    default:
      return null;
  }
};

export const getOrderTrackingStep = (step: OrderTrackingStep): string | null => {
  switch (step) {
    case OrderTrackingStep.APPROVED:
      return 'Approvato';
    case OrderTrackingStep.CANCELED:
      return 'Annullato';
    case OrderTrackingStep.REFUSED:
      return 'Rifiutato';
    case OrderTrackingStep.COMPLETED:
      return 'Completato';
    case OrderTrackingStep.CREATED:
      return 'Creato';
    case OrderTrackingStep.READY:
      return 'Pronto';
    case OrderTrackingStep.SENDED:
      return 'Spedito';
    case OrderTrackingStep.ERROR:
      return 'Errore';
    default:
      return null;
  }
};

export const getOrderType = (status: OrderType, prontoInTavola = false): string | null => {
  switch (status) {
    case OrderType.HOME_SERVICE:
      return 'Domicilio';
    case OrderType.TAKEAWAY:
      return 'Asporto';
    case OrderType.TABLE_RESERVATION:
      return prontoInTavola ? 'Pronto In Tavola' : 'Prenotazione Tavolo';
    default:
      return null;
  }
};

export const wait = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const getCoordsFromStringFormat = (origin: string | null) => {
  if (!origin) {
    return;
  }
  const originCoordsSplit = origin.split(',');
  if (originCoordsSplit.length !== 2) {
    return;
  }
  return {
    latitude: parseFloat(originCoordsSplit[0]),
    longitude: parseFloat(originCoordsSplit[1]),
  } as Coords;
};

export const isValidDate = (d: any) => {
  return !isNaN(d) && d instanceof Date;
};

export const fromBase64ToString = (base64: string) => {
  const words = CryptoJS.enc.Base64.parse(base64);
  return CryptoJS.enc.Utf8.stringify(words);
};

export const buildSearch = (params: URLSearchParams) => {
  let search = '';
  params.forEach((value, key) => {
    search += `${key}=${value}`;
  });
  return search ? `?${search}` : '';
};

export const isFunction = (fnToCheck: any): fnToCheck is Function => {
  return (
    fnToCheck &&
    (Object.prototype.toString.call(fnToCheck) === '[object Function]' ||
      'function' === typeof fnToCheck ||
      fnToCheck instanceof Function)
  );
};
