import {useEffect} from 'react';

export const CookieBanner = () => {
  useEffect(() => {
    // Create script element for iubenda configuration
    const scriptConfig = document.createElement('script');
    scriptConfig.type = 'text/javascript';
    scriptConfig.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {"siteId": 3724370, "cookiePolicyId": 54622609, "lang": "it"};
    `;

    // Create script element to load iubenda script
    const scriptIubenda = document.createElement('script');
    scriptIubenda.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js';
    scriptIubenda.async = true;

    // Append the scripts to the document head
    document.head.appendChild(scriptConfig);
    document.head.appendChild(scriptIubenda);

    // Cleanup script elements on component unmount
    return () => {
      document.head.removeChild(scriptConfig);
      document.head.removeChild(scriptIubenda);
    };
  }, []);

  return null;
};
