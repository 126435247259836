import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomView from '../../../components/atoms/CustomView';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import TitleWithHorizontalLine from '../../../components/molecules/TitleWithHorizontalLine';
import {allergens} from '../../../constants';
import {SmartMenuTemplateConfig} from '../../../models/smartMenu/smart-menu-config';
import {State} from '../../../redux/reducers';
import MenuItem from './MenuItem';
import {PromoBanner} from './PromoBanner';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px 20px 20px',
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  categoryContainer: {},
  titleCategory: {
    paddingLeft: '30px',
  },
  itemContainer: {},
  imageItem: {
    borderRadius: 10,
    height: '70px',
    width: '70px',
    objectFit: 'cover',
  },
  allergenImg: {
    width: '40px',
    height: '40px',
  },
}));

const MenuSimple = React.forwardRef<HTMLDivElement, Props>(({templateConfigs, hideImages}: Props, ref) => {
  const classes = useStyles();
  const {t} = useTranslation('smartmenu');
  const menu = useSelector((s: State) => s.smartMenu.selectedMenu);

  return (
    <div className={classes.root} ref={ref}>
      {!!templateConfigs?.promoBanners?.length && (
        <PromoBanner promoBanner={templateConfigs.promoBanners[0]} {...templateConfigs} />
      )}
      {templateConfigs && menu?.categories && menu?.categories.length > 0 ? (
        <>
          {menu.categories.map((c, i) => (
            <div className={classes.categoryContainer} id={c.id} key={c.id}>
              <CustomView
                style={{padding: 0}}
                center={templateConfigs.template.type === 1}
                marginTop={i > 0}
                className={classes.titleCategory}
              >
                <TitleWithHorizontalLine
                  right={templateConfigs.template.type === 2}
                  color={templateConfigs.color}
                  fontFamily={templateConfigs.fontFamily}
                  bold={templateConfigs.template.type !== 3}
                >
                  {c.name.toUpperCase()}
                </TitleWithHorizontalLine>
                {c.discountPercentage && (
                  <CustomRow center>
                    <CustomText
                      center
                      size={'sm'}
                      color={templateConfigs.color}
                      fontFamily={templateConfigs.fontFamily}
                    >
                      ({t('discount', {amount: c.discountPercentage}).toUpperCase()})
                    </CustomText>
                  </CustomRow>
                )}
              </CustomView>
              {c?.description && (
                <CustomRow center={templateConfigs.template.type !== 2}>
                  <CustomText center={templateConfigs.template.type !== 2} fontFamily={templateConfigs.fontFamily}>
                    {c?.description}
                  </CustomText>
                </CustomRow>
              )}
              <div className={classes.itemContainer}>
                {c.items && c.items.length > 0
                  ? c.items.map((menuItem, index) => (
                      <MenuItem
                        menuStyleNumber={templateConfigs.template.type}
                        key={menuItem.id}
                        menuItem={menuItem}
                        color={templateConfigs.color}
                        fontFamily={templateConfigs.fontFamily}
                        index={index}
                        hideImages={hideImages}
                      />
                    ))
                  : !!c && (
                      <CustomCard plain padding={'sm'}>
                        <GridContainer>
                          <GridItem xs={12} justifyContent={'center'}>
                            <CustomText bold center fontFamily={templateConfigs.fontFamily}>
                              {t('no_items')}
                            </CustomText>
                          </GridItem>
                        </GridContainer>
                      </CustomCard>
                    )}
              </div>
            </div>
          ))}
          <div className={classes.categoryContainer} id={'general'}>
            <CustomView
              style={{padding: 0}}
              center={templateConfigs.template.type === 1}
              marginTop
              className={classes.titleCategory}
            >
              <TitleWithHorizontalLine
                right={templateConfigs.template.type === 2}
                color={templateConfigs.color}
                fontFamily={templateConfigs.fontFamily}
                bold={templateConfigs.template.type !== 3}
              >
                {t('general_info').toUpperCase()}
              </TitleWithHorizontalLine>
              <CustomView marginTop>
                <CustomRow center>
                  <CustomText bold size={'lg'} fontFamily={templateConfigs.fontFamily}>
                    {t('allergen_list').toUpperCase()}
                  </CustomText>
                </CustomRow>
                <GridContainer marginTop={16}>
                  {Object.values(allergens).map((a, i) => (
                    <GridItem key={a.id} marginBottom xs={2}>
                      <CustomRow center>
                        <img src={a.image} className={classes.allergenImg} />
                      </CustomRow>
                      <CustomRow center>
                        <CustomText center size={'xxs'}>
                          {t(`allergens.${a.id}.name`)}
                        </CustomText>
                      </CustomRow>
                    </GridItem>
                  ))}
                </GridContainer>
              </CustomView>
            </CustomView>
          </div>
        </>
      ) : (
        <CustomRow center marginTop>
          <CustomText bold fontFamily={templateConfigs.fontFamily}>
            {t('no_menu')}
          </CustomText>
        </CustomRow>
      )}
    </div>
  );
});

type Props = {
  templateConfigs: SmartMenuTemplateConfig;
  hideImages?: boolean;
};

export default MenuSimple;
