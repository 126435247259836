import * as _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Coupon} from '../../../models/globalConfig/coupon';
import {calculateFee} from '../../../utils/commonFunctions';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {isCouponValid} from '../../../api/globalConfigService';
import {Status} from '../../../models/shared/custom-response';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {Spacing} from '../../../styles/spacing';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {UpsertOrder} from '../../../models/orders/request-objects/upsert-order';
import {makeStyles} from '@material-ui/styles';
import CustomLabelText from '../../../components/atoms/CustomLabelText';

const TotalCartPrices = ({
  promoFirstOrderNoTax,
  activityID,
  upsertOrder,
  fixedFee,
  percentageFee,
  activityServiceAmount,
  onCouponApplied,
  canApplyCoupon = true,
}: Props) => {
  const classes = useStyle();
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [coupon, setCoupon] = useState<Coupon>();
  const [calculatedDiscount, setCalculatedDiscount] = useState<number>(0);
  const [discountError, setDiscountError] = useState('');
  const [orderCartAmount, setOrderCartAmount] = useState<number>(0);
  const [orderTotalPrice, setOrderTotalPrice] = useState<number>(0);

  useEffect(() => {
    setOrderCartAmount(getOrderCartAmount());
    setOrderTotalPrice(getOrderTotalPrice());
  }, [upsertOrder]);

  const feeEnable = () => {
    return !upsertOrder?.tableReservationInfo?.useDeposit;
  };

  const getOrderCartAmount = () => {
    if (upsertOrder && upsertOrder.orderCartItems) {
      return _.sum(upsertOrder.orderCartItems.map((o) => o.totalPrice * o.count));
    }
    return 0;
  };

  const getOrderTotalPrice = () => {
    if (upsertOrder && upsertOrder.orderCartItems) {
      let cartTotalPrice = getOrderCartAmount();
      if (activityServiceAmount) {
        cartTotalPrice += activityServiceAmount;
      }
      return cartTotalPrice;
    }
    return 0;
  };

  const _calculateFee = (amount: number): number => {
    if (!_.isNil(fixedFee) && !_.isNil(percentageFee) && feeEnable() && !promoFirstOrderNoTax) {
      return calculateFee(amount, fixedFee, percentageFee);
    }
    return 0;
  };

  const _calculateCouponDiscount = (amount: number, _coupon: Coupon): number => {
    if (amount && _coupon) {
      if (_coupon.percentOff) {
        const discountAmount = parseFloat(parseFloat(((amount * _coupon.percentOff) / 100).toFixed(2)).toFixed(2));
        checkDiscountAmount(amount, discountAmount);
        return discountAmount;
      }
      if (_coupon.amountOff) {
        checkDiscountAmount(amount, _coupon.amountOff);
        return _coupon.amountOff;
      }
    }
    return 0;
  };

  const checkDiscountAmount = (amount: number, amountOff: number) => {
    if (amount - amountOff < 0.5) {
      setDiscountError(
        `L'importo totale di ${amount.toFixed(2)} € è troppo basso: devi acquistare per un totale di almeno ${(
          amountOff + 0.5
        ).toFixed(2)} €. Torna al carrello e poi reinserisci il coupon.`,
      );
    } else {
      setDiscountError('');
    }
  };

  const validateCoupon = async () => {
    setDiscountError('');
    if (!couponCode || !couponCode.trim()) {
      showToastMessage(AppMessageType.ERROR, 'Codice coupon non valorizzato');
      return;
    }
    const res = await isCouponValid(couponCode, activityID);
    if (res.status === Status.OK && res.payload.valid) {
      setCoupon(res.payload.coupon);
      onCouponApplied(res.payload.coupon);
      const discountCalcTemp = _calculateCouponDiscount(
        orderCartAmount + _calculateFee(orderTotalPrice),
        res.payload.coupon,
      );
      setCalculatedDiscount(discountCalcTemp);
      showToastMessage(AppMessageType.SUCCESS, 'Coupon valido');
    } else {
      showToastMessage(AppMessageType.ERROR, res.payload.error || 'Coupon non valido');
    }
  };

  return (
    <>
      <div>
        {upsertOrder && upsertOrder.orderCartItems && upsertOrder.orderCartItems.length > 0 && (
          <CustomLabelText
            marginTop
            label={'Totale carrello:'}
            labelColor={'black'}
            textAlignRight
            boldText
            text={[orderCartAmount, ' ', '€']}
          />
        )}
        {!!activityServiceAmount && (
          <CustomLabelText
            marginTop
            label={"Costo di servizio richiesto dall'attività:"}
            labelColor={'black'}
            textAlignRight
            boldText
            text={[activityServiceAmount, ' ', '€']}
          />
        )}
        {!_.isNil(fixedFee) && !_.isNil(percentageFee) && feeEnable() && (
          <CustomLabelText
            marginTop
            label={`Commissioni${promoFirstOrderNoTax ? ' (PROMO):' : ':'}`}
            labelColor={'black'}
            textAlignRight
            boldText
            text={[_calculateFee(orderTotalPrice), ' ', '€']}
          />
        )}
        {canApplyCoupon && !showCouponInput && (
          <CustomRow marginTop>
            <CustomText color={'primary'} underline bold onClick={() => setShowCouponInput(true)}>
              Hai un codice sconto?
            </CustomText>
          </CustomRow>
        )}
        {showCouponInput && (
          <>
            <CustomRow marginTop>
              <CustomInput
                placeholder={'Codice coupon'}
                value={couponCode}
                onChange={(t: string) => setCouponCode(t)}
              />
              <div style={{alignItems: 'flex-end', marginLeft: Spacing.SCALE_12}}>
                <CustomButton simple bordered color={'primary'} onClick={validateCoupon} title={'Applica'} />
              </div>
            </CustomRow>
            <CustomRow marginTop>
              <CustomText>
                Sconto coupon:{' '}
                <CustomText bold>
                  {coupon ? (coupon.percentOff ? `(-${coupon.percentOff} %)` : `(-${coupon.amountOff} €)`) : ''}
                </CustomText>
              </CustomText>
              <div style={{flex: 1, alignItems: 'flex-end'}}>
                <CustomText bold style={{marginLeft: Spacing.SCALE_12}}>
                  - {calculatedDiscount} €
                </CustomText>
              </div>
            </CustomRow>
            {!!discountError && (
              <CustomRow marginTop>
                <CustomText color={'primary'}>
                  <CustomText color={'primary'} bold>
                    Attenzione:{' '}
                  </CustomText>
                  {discountError}
                </CustomText>
              </CustomRow>
            )}
          </>
        )}
        <CustomLabelText
          marginTop
          marginBottom
          label={'Totale da pagare:'}
          labelColor={'black'}
          textAlignRight
          boldText
          textColor={'primary'}
          text={[_calculateFee(orderTotalPrice) + orderTotalPrice - calculatedDiscount, ' ', '€']}
        />
      </div>
    </>
  );
};

const useStyle = makeStyles({
  baseStyle: {},
});

type Props = {
  upsertOrder: UpsertOrder;
  activityID?: string;
  fixedFee?: number;
  percentageFee?: number;
  promoFirstOrderNoTax?: boolean;
  activityServiceAmount?: number;
  onCouponApplied?: any;
  canApplyCoupon?: boolean;
};

export default TotalCartPrices;
