import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState} from 'react';
import logo from '../../assets/images/logo.png';
import {GRAY_DARK, WHITE} from '../../styles/colors';
import {Spacing} from '../../styles/spacing';
import {isMobileAgent} from '../../utils/commonFunctions';
import CustomButton from '../atoms/CustomButtons/CustomButton';
import CustomRow from '../atoms/CustomRow';
import CustomText from '../atoms/Typography/CustomText';
import {setShowModalDownloadApp} from '../../redux/actions/globalActions';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../redux/reducers';

const ModalDownloadMobileApp = ({}: Props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const showModalDownloadApp = useSelector((state: State) => state.global.showModalDownloadApp);

  useEffect(() => {
    if (isMobileAgent() && showModalDownloadApp) {
      setOpen(true);
      dispatch(setShowModalDownloadApp(false));
    }
  }, []);

  const download = () => {
    window.open('https://ordify.page.link/P6tj', '_self');
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div className={classes.modal}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => close()}>
            <CloseIcon />
          </IconButton>
          <div className={classes.modalContent}>
            <CustomRow>
              <div>
                <img className={classes.logo} src={logo} alt="logo" />
              </div>
              <div className={classes.textContent}>
                <div>
                  <CustomText size={'md'} bold>
                    Ordina dall'app
                  </CustomText>
                </div>
                <div>
                  <CustomText size={'md'}>
                    Approfitta di offerte esclusive dai tuoi locali e ristoranti preferiti.
                  </CustomText>
                </div>
              </div>
            </CustomRow>
            <CustomRow marginTop>
              <CustomButton fullWidth title={"Scarica l'app"} onClick={download} />
            </CustomRow>
          </div>
        </div>
      )}
    </>
  );
};

type Props = {};

const useStyle = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    backgroundColor: WHITE,
    zIndex: 9999,
    padding: '20px 46px',
    boxShadow: `0px 0px 10px 0 ${GRAY_DARK}`,
  },
  logo: {
    width: '50px',
    height: '50px',
    padding: '10px',
    boxShadow: `0px 0px 10px 0 ${GRAY_DARK}`,
    borderRadius: '10px',
  },
  textContent: {
    paddingLeft: Spacing.SCALE_20,
  },
  closeButton: {
    position: 'absolute',
    right: 2,
    top: 2,
    color: GRAY_DARK,
  },
  modalContent: {
    // backgroundColor: WHITE,
    // justifyContent: 'center',
    // alignItems: 'center',
    // width: '100%',
    // paddingVertical: Spacing.SCALE_20,
    // paddingHorizontal: Spacing.SCALE_20,
    // borderRadius: 10,
  },
}));

export default ModalDownloadMobileApp;
