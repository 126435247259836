import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import {makeStyles} from '@material-ui/styles';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';
import {primaryColor} from '../../../styles/colors';
import classNames from 'classnames';
import {Hidden} from '@material-ui/core';
import {ActivityDetailTab} from './MainContent';
import {HeaderLink} from '../../../components/organisms/types/header-link';

const useStyles = makeStyles((theme) => ({
  root: {},
  categoryContainer: {
    padding: '6px 6px',
    marginTop: '10px',
  },
  categoryContainerSelected: {
    padding: '6px 6px',
    marginTop: '10px',
    borderLeft: '1px solid ' + primaryColor,
  },
  fixed: {
    position: 'fixed',
    top: '20px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '90%',
    '&::-webkit-scrollbar': {display: 'none'},
  },
}));

const HEADER_MARGIN = 20;

const MenuIndex = () => {
  const classes = useStyles();
  const categories = useSelector((s: State) => s.sectionUser.activityDetail?.menu.categories);
  const activeTab = useSelector((s: State) => s.sectionUser.activeTab);
  const [links, setLinks] = useState<HeaderLink[]>([]);
  const [fixed, setFixed] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<any>({top: false});

  const menuIndexClasses = classNames({
    [classes.fixed]: fixed,
  });

  useEffect(() => {
    if (categories) {
      setLinks(categories.map((c) => ({id: c.id, label: c.name} as HeaderLink)));
    }
  }, [categories]);

  useEffect(() => {
    getAnchorPoints();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', getAnchorPoints);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
      window.removeEventListener('resize', getAnchorPoints, false);
    };
  }, [links]);

  const handleScroll = () => {
    const curPos = window.scrollY;
    let curSection = 'top';
    links.forEach((link) => {
      if (curPos + HEADER_MARGIN >= link.pos) {
        curSection = link.id;
      }
    });
    setFixed(curPos > 400);
    setActiveMenuItem({[curSection]: true});
  };

  const getAnchorPoints = () => {
    const curScroll = window.scrollY;
    const _links = links;
    _links.forEach((link: any, i: number) => {
      let elementById = document.getElementById(link.id);
      if (elementById) {
        link.pos = elementById.getBoundingClientRect().top + curScroll;
      }
    });

    /* Get the pixel height of the viewport */
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    /* Get the pixel-depth of the bottom of the page */
    const bottom = document.body.offsetHeight;

    /* If max-scroll is less than the pixel-depth of latest section, then adjust the pixel-depth of section 3 to be 50px higher than max scroll depth ... allowing it to be an active destination */
    if (_links.length > 0) {
      if (viewPortHeight > bottom - _links[_links.length - 1].pos) {
        _links[_links.length - 1].pos = bottom - viewPortHeight + HEADER_MARGIN;
      }
    }
    setLinks(_links);
    handleScroll();
  };

  const categoryClicked = (id: string) => {
    const link = links.find((l) => l.id === id);
    window.scroll({top: link?.pos, behavior: 'smooth'});
  };

  return (
    <>
      <Hidden smDown>
        {links && links.length > 0 && activeTab === ActivityDetailTab.MENU && (
          <div className={menuIndexClasses}>
            <CustomRow>
              <CustomText bold color={'primary'} fontFamily={'Goda'} size={'lg'}>
                CATEGORIE
              </CustomText>
            </CustomRow>
            {links.length > 0 &&
              links.map((c) => (
                <div
                  className={!activeMenuItem[c.id] ? classes.categoryContainer : classes.categoryContainerSelected}
                  key={c.id}
                >
                  <CustomRow>
                    <CustomText
                      bold={activeMenuItem[c.id]}
                      color={activeMenuItem[c.id] ? 'primary' : 'gray'}
                      size={'md'}
                      fontFamily={'Goda'}
                      onClick={() => categoryClicked(c.id)}
                    >
                      {c.label.toUpperCase()}
                    </CustomText>
                  </CustomRow>
                </div>
              ))}
          </div>
        )}
      </Hidden>
    </>
  );
};

export default MenuIndex;
