import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
);

reportWebVitals((metrics: any) => {});
