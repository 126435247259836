import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../components/atoms/CustomRow';
import PageLoginContainer from '../_Shared/PageLoginContainer';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {resetPassword} from '../../../redux/actions/authActions';
import {ResetPasswordPayload} from '../../../models/auth/reset-password';
import {HOME} from '../../../constants';
import {useLocation} from 'react-router-dom';

export default function ForgotPasswordSetNew() {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const location = useLocation();

  const callChangePassword = (newPasswordToken: string, newPassword: string, email: string) => {
    if (!newPassword || !newPasswordToken) {
      showToastMessage(AppMessageType.ERROR, 'Compila tutti i campi');
    } else {
      dispatch(
        resetPassword({
          resetPasswordPayload: {
            email,
            newPassword,
            newPasswordToken,
          } as ResetPasswordPayload,
          goTo: HOME,
          force: false,
        }),
      );
    }
  };
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (token && password) {
          callChangePassword(token, password, (location?.state as any)?.email);
          event.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [token, password, location]);

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText center bold size={'xl'} color={'primary'} fontFamily={'Goda'}>
            RESETTA LA PASSWORD
          </CustomText>
        </CustomRow>
        <CustomRow marginTop>
          <CustomInput placeholder="Token" value={token} type={'text'} onChange={(text: string) => setToken(text)} />
        </CustomRow>
        <CustomRow marginTop>
          <CustomInput
            placeholder="Nuova Password"
            value={password}
            type={'password'}
            onChange={(text: string) => setPassword(text)}
          />
        </CustomRow>
        <CustomRow marginTop>
          <CustomButton
            fullWidth
            onClick={() => callChangePassword(token, password, (location.state as any)?.email)}
            title={'Resetta Password'}
          />
        </CustomRow>
      </PageLoginContainer>
    </>
  );
}
