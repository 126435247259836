import React from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import fidelityImage from '../../../../assets/images/landing/fidelitySection/fidelity.png';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import './fidelitySectionStyle.scss';

export default function FidelitySection({id}: Props) {
  return (
    <GridContainer marginTop={100} fullWidth={true}>
      <GridItem noPadding={'left'} xs={12} sm={12} md={12} lg={6}>
        <img alt={'order-image'} className={'imgOrder'} src={fidelityImage} />
      </GridItem>
      <GridItem marginTop={100} className={'gridItemTextRight'} direction={'column'} xs={12} sm={12} md={12} lg={6}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
              FIDELITY PROGRAM.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomText size={'lg'}>
              Il programma fedeltà pensato per <b>premiarti sempre</b>, a ogni tuo ordine su Ordify. Accumula punti,
              convertili in buoni sconto e ordina pagando meno.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomText size={'lg'}>Nulla da perdere, tutto da guadagnare. Inizia ad abbuffarti di sconti!</CustomText>
          </GridItem>
          <GridItem className={'hidden'} marginTop={50} xs={12} sm={12} md={12}>
            <CustomButton size={'lg'}>Come giocare</CustomButton>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}

type Props = {
  id?: string;
};
