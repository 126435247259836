import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React, {useState} from 'react';
import {contactForInfo} from '../../../../api/authService';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomInput from '../../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../../components/atoms/CustomRow';
import {AppMessageType} from '../../../../components/atoms/CustomToast';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Status} from '../../../../models/shared/custom-response';
import {showToastMessage} from '../../../../services/toastService';
import {isEmailValid} from '../../../../utils/commonFunctions';
import {config} from '../../../../utils/config';

const styles = (theme: Theme) => ({
  section: {
    width: '80%',
    margin: 'auto',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  iphone: {
    width: '850px',
    top: '100px',
    right: '-15px',
    position: 'relative' as any,
  },
  templates: {
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

export default function ContactSection({id}: Props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [activityName, setActivityName] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  const classes = useStyles();

  const canSend = () => {
    return name && email && activityName && city && message && !sent;
  };

  const send = async () => {
    if (!isEmailValid(email)) {
      showToastMessage(AppMessageType.ERROR, 'Email non valida');
      return;
    }
    const res = await contactForInfo({
      name,
      email,
      activityName,
      city,
      message,
      object: 'SmartMenu Info',
    });
    if (res.status === Status.OK) {
      showToastMessage(AppMessageType.SUCCESS, 'Email inviata con successo');
      setName('');
      setEmail('');
      setActivityName('');
      setCity('');
      setMessage('');
      setSent(true);
    } else {
      showToastMessage(AppMessageType.ERROR, "Errore durante l'invio della mail. Prova più tardi.");
    }
  };

  const talkWithUs = () => {
    open(config.calendlySmartMenuInfo);
  };

  return (
    <div id={id}>
      <GridContainer
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
        className={classes.section}
        style={{padding: '30px 0 16px'}}
      >
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'black'} size={'lg'} fontFamily={'Goda'}>
            CONTATTACI
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'black'} size={'xl'} fontFamily={'Goda'}>
            HAI ANCORA DUBBI?
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} marginTop>
          <CustomText center color={'black'}>
            Compila il form e sarai ricontattato entro un giorno con le risposte alle tue domande.
          </CustomText>
        </GridItem>
      </GridContainer>
      <GridContainer
        justifyContent={'center'}
        alignItems={'center'}
        fullWidth
        className={classes.section}
        style={{padding: '16px 0 16px'}}
      >
        <GridItem xs={12} sm={6} md={6} marginTop>
          <CustomInput placeholder={'Nome'} value={name} onChange={(v) => setName(v)} />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} marginTop>
          <CustomInput placeholder={'Email'} type={'email'} value={email} onChange={(v) => setEmail(v)} />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} marginTop>
          <CustomInput placeholder={'Nome attività'} value={activityName} onChange={(v) => setActivityName(v)} />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} marginTop>
          <CustomInput placeholder={'Provincia'} value={city} onChange={(v) => setCity(v)} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} marginTop>
          <CustomInput placeholder={'Il tuo messaggio'} multiline value={message} onChange={(v) => setMessage(v)} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} marginTop>
          <CustomButton fullWidth color={'primary'} onClick={send} disabled={!canSend()}>
            <div>
              <CustomRow full center>
                <CustomText color={'white'} center>
                  Contattaci
                </CustomText>
              </CustomRow>
              <CustomRow full center>
                <CustomText size={'sm'} color={'white'} center>
                  Rispondiamo entro un giorno
                </CustomText>
              </CustomRow>
            </div>
          </CustomButton>
        </GridItem>
      </GridContainer>
      <GridContainer
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        fullWidth
        className={classes.section}
        style={{padding: '32px 0 100px'}}
      >
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'black'} size={'lg'} fontFamily={'Goda'}>
            OPPURE
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12}>
          <CustomText center bold color={'black'} size={'xl'} fontFamily={'Goda'}>
            PARLA CON NOI
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} marginTop>
          <CustomText center color={'black'}>
            Clicca il bottone qui sotto per prenotare una chiamata con noi.
          </CustomText>
        </GridItem>
        <GridItem centerHorizontal xs={12} marginTop>
          <CustomButton fullWidth color={'black'} onClick={talkWithUs}>
            <div>
              <CustomRow full center>
                <CustomText color={'white'} center>
                  Parla con noi
                </CustomText>
              </CustomRow>
              <CustomRow full center>
                <CustomText size={'sm'} color={'white'} center>
                  Qaundo vuoi e senza impegno
                </CustomText>
              </CustomRow>
            </div>
          </CustomButton>
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
