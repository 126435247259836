import {CircularProgress, Fade} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {State} from '../../redux/reducers';
import {makeStyles} from '@material-ui/core/styles';
import {primaryColor} from '../../styles/colors';

const useStyles = makeStyles({
  fullScreen: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 99999,
  },
  spinnerBackground: {
    backgroundColor: 'rgba(157, 157, 157, 0.75)',
  },
  centerItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    color: primaryColor,
    width: '6rem !important',
    height: '6rem !important',
  },
});

const CustomLoader = ({show = false}: Props) => {
  const classes = useStyles();
  const showProgressBar = useSelector((state: State) => state.global.isLoading);
  return (
    <Fade in={showProgressBar || show} timeout={{enter: 1500, exit: 1000}}>
      <div className={classes.fullScreen + ' ' + classes.spinnerBackground + ' ' + classes.centerItem}>
        <CircularProgress className={classes.progress} />
      </div>
    </Fade>
  );
};

type Props = {
  show?: boolean;
};

export default CustomLoader;
