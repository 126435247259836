import React, {useMemo} from 'react';
import {ItemForMenu} from '../../../models/menu/category';
import {Colors} from '../../../styles/colors';
import MenuItemTemplate1 from './MenuItemTemplate1';
import MenuItemTemplate2 from './MenuItemTemplate2';
import MenuItemTemplate3 from './MenuItemTemplate3';
import {Fonts} from '../../../styles/font';

const MenuItem = ({menuItem, color, fontFamily, menuStyleNumber = 1, index, hideImages}: Props) => {
  const _menuStyleNumber = useMemo(() => menuStyleNumber, []);

  if (_menuStyleNumber === 1) {
    return (
      <MenuItemTemplate1
        menuItem={menuItem}
        color={color}
        fontFamily={fontFamily}
        index={index}
        hideImages={hideImages}
      />
    );
  } else if (_menuStyleNumber === 2) {
    return (
      <MenuItemTemplate2
        menuItem={menuItem}
        color={color}
        fontFamily={fontFamily}
        index={index}
        hideImages={hideImages}
      />
    );
  } else if (_menuStyleNumber === 3) {
    return (
      <MenuItemTemplate3
        menuItem={menuItem}
        color={color}
        fontFamily={fontFamily}
        index={index}
        hideImages={hideImages}
      />
    );
  } else {
    return null;
  }
};

type Props = {
  index: number;
  menuItem: ItemForMenu;
  color?: Colors;
  fontFamily?: Fonts;
  menuStyleNumber?: number;
  hideImages?: boolean;
};

export default MenuItem;
