import {Order} from '../../models/orders/order';
import {OrdersByStatusActivity} from '../../models/orders/order-by-status';
import {SearchOrder} from '../../models/orders/request-objects/search-order';
import {UpdateOrderStatus} from '../../models/orders/request-objects/update-order-status';
import {UpdateOrderTrackingStep} from '../../models/orders/request-objects/update-order-tracking';
import {UpsertOrder} from '../../models/orders/request-objects/upsert-order';
import {UpsertOrderAndPay} from '../../models/orders/request-objects/upsert-order-and-pay';
import {CountOrders} from '../../models/orders/count-orders';
import * as stripeJs from '@stripe/stripe-js';

export enum OrdersActionTypes {
  CREATE_ORDER = '[Orders] CreateOrder',
  CREATE_ORDER_AND_PAY = '[Orders] CreateOrderAndPay',
  LOAD_ACTIVITY_TABLE_RESERVATIONS_SUCCESS = '[Orders] LoadActivityTableReservationsSuccess',
  LOAD_USER_ORDERS = '[Orders] LoadUserOrders',
  LOAD_USER_ORDERS_SUCCESS = '[Orders] LoadUserOrdersSuccess',
  LOAD_ORDER_COUNTERS_USER_SUCCESS = '[Orders] LoadOrderCountersUserSuccess',
  UPDATE_STATUS_ORDER = '[Orders] UpdateStatusOrder',
  UPDATE_TRACKING_STEP = '[Orders] UpdateTrackingStep',
}

export const createOrder = (payload: {upsertOrder: UpsertOrder}) => {
  return {
    type: OrdersActionTypes.CREATE_ORDER,
    payload,
  };
};

export const createOrderAndPay = (payload: {
  body: UpsertOrderAndPay;
  stripeRef: stripeJs.Stripe | null;
  cardElement?: stripeJs.StripeCardElement | null;
}) => {
  return {
    type: OrdersActionTypes.CREATE_ORDER_AND_PAY,
    payload,
  };
};
export const loadActivityTableReservationsSuccess = (payload: OrdersByStatusActivity) => {
  return {
    type: OrdersActionTypes.LOAD_ACTIVITY_TABLE_RESERVATIONS_SUCCESS,
    payload,
  };
};
export const loadUserOrders = (payload?: {searchOrder: SearchOrder; message?: string; hideLoader?: boolean}) => {
  return {
    type: OrdersActionTypes.LOAD_USER_ORDERS,
    payload,
  };
};
export const loadUserOrdersSuccess = (payload: {orders: Order[]}) => {
  return {
    type: OrdersActionTypes.LOAD_USER_ORDERS_SUCCESS,
    payload,
  };
};
export const loadOrderCounterUserSuccess = (payload: {counters: CountOrders | null}) => {
  return {
    type: OrdersActionTypes.LOAD_ORDER_COUNTERS_USER_SUCCESS,
    payload,
  };
};
export const updateStatusOrder = (payload: {updateOrderStatus: UpdateOrderStatus; message?: string}) => {
  return {
    type: OrdersActionTypes.UPDATE_STATUS_ORDER,
    payload,
  };
};
export const updateTrackingStep = (payload: {updateOrderTrackingStep: UpdateOrderTrackingStep; message?: string}) => {
  return {
    type: OrdersActionTypes.UPDATE_TRACKING_STEP,
    payload,
  };
};
