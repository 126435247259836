import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import CustomDialog from '../../../components/atoms/CustomDialog';
import CustomGooglePlacesAutocomplete from '../../../components/atoms/CustomGooglePlacesAutocomplete/CustomGooglePlacesAutocomplete';
import {GooglePlaceSearchResponse} from '../../../models/shared/google-api';
import {setUserLocationRadius, updateUser} from '../../../redux/actions/userActions';
import {UpdateUserRequest} from '../../../models/user/request-objects/update-user';
import {getLocationRadius, setLocationRadius} from '../../../services/storageService';
import {RADIUS_LIST} from '../../../utils/static-lists';
import {KeyboardArrowDown, Place} from '@material-ui/icons';
import {getActivitiesFilters, searchActivities} from '../../../redux/actions/sectionUserActions';
import {replace} from '../../../services/navigationService';
import {SEARCH} from '../../../constants';
import CustomIcon from '../../../components/atoms/CustomIcon';
import Hidden from '@material-ui/core/Hidden';
import CustomSelect from '../../../components/atoms/CustomSelect';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContainer: {
    minWidth: '420px',
    '@media (max-width: 480px)': {
      minWidth: 'auto',
    },
  },
}));

const SearchLocation = ({}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useSelector((s: State) => s.user.userInfo?.userInfo.location);
  const [radius, setRadius] = useState<number>(0);
  const [radiusList, setRadiusList] = useState<any[]>([]);
  const [modalChangeLocation, setModalChangeLocation] = useState(false);

  const rootClasses = classNames({
    [classes.root]: true,
  });

  useEffect(() => {
    const init = async () => {
      setRadius(await getLocationRadius());
    };
    setRadiusList(RADIUS_LIST);
    init();
  }, []);

  const changeLocation = () => {
    setModalChangeLocation(true);
  };

  const placeSelected = async (data: GooglePlaceSearchResponse) => {
    const {lat, lng} = data.geometry.location;
    const coords = {latitude: lat(), longitude: lng()};
    dispatch(
      updateUser({
        updateUserRequest: {
          userInfo: {coords},
        } as UpdateUserRequest,
        showMessage: true,
        actions: [searchActivities, getActivitiesFilters],
      }),
    );
    const search = `?coords=${coords?.latitude},${coords?.longitude}`;
    replace(SEARCH, {}, search);
    setModalChangeLocation(false);
  };

  const changeRadius = (r: {value: number; label: string}) => {
    const _radius = r.value;
    setRadius(_radius);
    dispatch(setUserLocationRadius({radius: _radius}));
    setLocationRadius(_radius);
    dispatch(searchActivities());
  };

  return (
    <div>
      {location && (
        <GridContainer fullWidth={true}>
          <Hidden xsDown>
            <GridItem alignItems={'center'} xs={12}>
              <CustomIcon size={'md'} color={'primary'} icon={Place} />
              <CustomText fontFamily={'Goda'} color={'primary'} size={'lg'}>
                POSIZIONE
              </CustomText>
            </GridItem>
          </Hidden>
          <GridItem justifyContentMobile={'center'} justifyContentDesktop={'start'} alignItems={'center'} xs={12}>
            <Hidden smUp>
              <CustomIcon size={'md'} color={'primary'} icon={Place} />
            </Hidden>
            <CustomText onClick={changeLocation}>{`${location?.cityName}, ${location?.townCode}`}</CustomText>
            <CustomIcon onClick={changeLocation} size={'md'} color={'black'} icon={KeyboardArrowDown} />
          </GridItem>
          <GridItem justifyContentMobile={'center'} justifyContentDesktop={'start'} alignItems={'center'} xs={12}>
            <CustomText size={'sm'} onClick={changeLocation}>
              In un raggio di <b>{radius / 1000}km</b>{' '}
            </CustomText>
          </GridItem>
        </GridContainer>
      )}
      <CustomDialog
        title={'Modifica posizione'}
        fullWidthOnMobile
        onBottom
        open={modalChangeLocation}
        onClose={() => setModalChangeLocation(false)}
        extraButtons={
          <GridContainer style={{width: '100%', margin: '0 8px'}}>
            <GridItem xs={12} marginTop>
              <CustomButton fullWidth title={'Conferma'} onClick={() => setModalChangeLocation(false)} />
            </GridItem>
          </GridContainer>
        }
      >
        <GridContainer
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          className={classes.dialogContainer}
        >
          <GridItem xs={12}>
            <CustomGooglePlacesAutocomplete
              value={location?.description}
              defaultValue={location?.description}
              placeholder={'Inserisci la tua città'}
              onPlaceSelected={placeSelected}
            />
          </GridItem>
        </GridContainer>
        <GridItem direction={'row'} justifyContent={'center'} alignItems={'center'} xs={12}>
          <CustomRow marginTop>
            <CustomText style={{marginRight: '10px'}}>Modifica il raggio</CustomText>
            <CustomSelect
              fullWidth={false}
              value={radius}
              onChange={changeRadius}
              items={radiusList}
              dataStructure={{id: 'value', name: 'label'}}
            />
          </CustomRow>
        </GridItem>
      </CustomDialog>
    </div>
  );
};

type Props = {};

export default SearchLocation;
