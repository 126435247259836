import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {Fonts} from '../../../styles/font';

import buttonStyle from './buttonStyle';
import CustomIcon from '../CustomIcon';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import {IconButton, SvgIconTypeMap} from '@material-ui/core';
import {Colors} from '../../../styles/colors';
import CustomRow from '../CustomRow';
import * as _ from 'lodash';

const useStyle = makeStyles(buttonStyle);

const CustomButton = React.forwardRef(
  (
    {
      id,
      children,
      onClick,
      color = 'primary',
      className,
      ariaControls,
      disabled,
      simple,
      size = 'sm',
      block,
      fullWidth,
      noShadow,
      link,
      target = '_self',
      href,
      title,
      img,
      icon,
      iconColor = 'black',
      iconSize = 'sm',
      iconBordered,
      bordered,
      inverted,
      fontFamily,
      ...rest
    }: Props,
    ref,
  ) => {
    const classes = useStyle({color, iconColor, fontFamily});

    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.noShadow]: noShadow,
      [classes.btnBorder]: bordered,
      ...(className && {[className]: className}),
    });

    const iconClassName = classNames({
      [classes.icon]: true,
      [classes.iconPadding]: icon && title,
    });

    const imgClasses = classNames({
      [classes.imgMargin]: !!img && !!title,
    });

    const iconButtonClassName = classNames({
      [classes.iconColor]: true,
      [classes[`font_${iconSize}`]]: true,
      [classes[`icon_padding_${iconSize}`]]: iconSize,
      [classes.iconBorderColor]: iconBordered,
      [classes.iconBorder]: iconBordered,
      ...(className && {[className]: className}),
    });

    const _onClick = (e: any) => {
      e.stopPropagation();
      onClick && onClick(e);
    };

    const renderTextOrPrice = (t: string | (string | number)[]) => {
      if (t instanceof Array && t.length > 0 && t.findIndex((c) => typeof c === 'string' && c.trim() === '€') > -1) {
        const i = t.findIndex((c) => _.isNumber(c));
        let newtitle = [...t];
        if (i > -1 && typeof newtitle[i] === 'number') {
          newtitle[i] = (newtitle[i] as number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return newtitle;
      }
      return t;
    };

    const Icon = icon as OverridableComponent<SvgIconTypeMap>;
    return (
      <>
        {!!icon && !title ? (
          <IconButton aria-controls={ariaControls} className={iconButtonClassName} onClick={_onClick}>
            <Icon />
          </IconButton>
        ) : (
          <Button
            {...rest}
            aria-controls={ariaControls}
            target={target}
            // @ts-ignore
            ref={ref}
            classes={{root: btnClasses}}
            href={href}
            onClick={_onClick}
          >
            <span className={iconClassName}>
              {!!icon && !!title && (
                <CustomIcon icon={icon} color={iconColor} size={iconSize} bordered={iconBordered} />
              )}
            </span>
            <CustomRow>
              <div className={imgClasses}>{!!img && img}</div>
              {!!title && renderTextOrPrice(title)}
            </CustomRow>
            {!title && !img && !icon && children}
          </Button>
        )}
      </>
    );
  },
);

export type Props = {
  id?: string;
  onClick?: any;
  color?: Colors;
  target?: '_blank' | '_parent' | '_self' | '_top';
  size?: 'sm' | 'md' | 'lg';
  simple?: boolean;
  fullWidth?: boolean;
  noShadow?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  children?: any;
  className?: string;
  ariaControls?: string;
  href?: string;
  title?: string | (string | number)[];
  img?: any;
  icon?: OverridableComponent<SvgIconTypeMap>;
  iconSize?: 'xs' | 'sm' | 'md' | 'lg';
  iconColor?: Colors;
  iconBordered?: boolean;
  bordered?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  inverted?: boolean;
  fontFamily?: Fonts;
};

export default CustomButton;
