import React from 'react';
import smartMenuImageDesktop from '../../../../assets/images/smart-menu/desktop/iphone_4.png';
import smartMenuImageMobile from '../../../../assets/images/smart-menu/mobile/iphone_demo.png';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import ContentResponsive from '../../../../components/molecules/ContentResponsive';
import {SMART_MENU} from '../../../../constants';
import {navigate} from '../../../../services/navigationService';

export default function SmartMenuSection({id}: Props) {
  return (
    <div id={id}>
      <GridContainer style={{width: '80%', margin: '100px auto 100px'}}>
        <GridItem xs={12} centerHorizontal>
          <CustomText center bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
            ORDIFY SMARTMENU
          </CustomText>
        </GridItem>
        <GridItem xs={12} centerHorizontal marginTop>
          <CustomText center size={'lg'}>
            Sei un ristoratore e vorresti creare menu{' '}
            <CustomText bold size={'lg'}>
              personalizzabili
            </CustomText>
            ,{' '}
            <CustomText bold size={'lg'}>
              semplici
            </CustomText>{' '}
            e{' '}
            <CustomText bold size={'lg'}>
              sempre aggiornati
            </CustomText>{' '}
            per i tuoi clienti? Con lo Smartmenu di Ordify non è mai stato così facile.
          </CustomText>
        </GridItem>
        <GridItem xs={12} centerHorizontal marginTop>
          <CustomText center size={'lg'} italic>
            Ah già, e costa poco! Non sottovalutarlo 😉
          </CustomText>
        </GridItem>
        <GridItem xs={12} centerHorizontal marginTop={40}>
          <CustomButton
            title={'Voglio saperne di più'}
            color={'primary'}
            size={'lg'}
            onClick={() => navigate(SMART_MENU)}
          />
        </GridItem>
        <GridItem xs={12} centerHorizontal marginTop={40}>
          <ContentResponsive
            desktop={<img style={{width: '800px'}} src={smartMenuImageDesktop} alt="smartmenulogo" />}
            mobile={<img style={{width: '300px'}} src={smartMenuImageMobile} alt="smartmenulogo" />}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
