import * as _ from 'lodash';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import ImageViewer from '../../../components/molecules/ImageViewer';
import {ItemForMenu} from '../../../models/menu/category';
import {Colors} from '../../../styles/colors';
import {Fonts} from '../../../styles/font';
import CustomView from '../../../components/atoms/CustomView';
import {AllergenList} from './AllergenList';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px 20px 20px',
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  categoryContainer: {},
  titleCategory: {
    paddingLeft: '30px',
  },
  description: {
    paddingLeft: '30px',
  },
  itemContainer: {},
  imageItem: {
    borderRadius: 0,
    height: '110px',
    width: '80px',
    objectFit: 'cover',
  },
}));

const MenuItemTemplate2 = ({menuItem, color, fontFamily, index, hideImages}: Props) => {
  const classes = useStyles();

  function renderEvenItem() {
    return (
      <>
        <GridContainer fullWidth marginTop={index === 0 ? 0 : 50}>
          <CustomRow full>
            {!hideImages && menuItem.image && !!menuItem.image.url && (
              <CustomView marginRight={20}>
                <ImageViewer image={menuItem.image} className={classes.imageItem} />
              </CustomView>
            )}
            <CustomView>
              <CustomRow>
                <CustomText style={{lineHeight: 'normal'}} bold size={'md'} fontFamily={fontFamily}>
                  {menuItem.name.toUpperCase()}
                </CustomText>
                <CustomRow marginLeft>
                  <AllergenList allergenList={menuItem.allergens} />
                </CustomRow>
              </CustomRow>
              {!!menuItem.ingredients.length && (
                <CustomRow>
                  <CustomText size={'sm'} fontFamily={fontFamily}>
                    {menuItem.ingredients.map((ingrendient) => _.upperFirst(ingrendient.name))?.join(', ')}
                  </CustomText>
                </CustomRow>
              )}
              {!!menuItem.description && (
                <CustomRow marginTop>
                  <CustomText size={'sm'} italic fontFamily={fontFamily}>
                    {menuItem.description}
                  </CustomText>
                </CustomRow>
              )}
              {menuItem.discountPercentage ? (
                <>
                  <CustomRow marginTop={6}>
                    <CustomText size={'sm'} lineThrough fontFamily={fontFamily}>
                      €{menuItem.price / (1 - menuItem.discountPercentage / 100)}
                    </CustomText>
                    <CustomText size={'sm'}>&nbsp;\&nbsp;</CustomText>
                    <CustomText size={'sm'} color={color} bold fontFamily={fontFamily}>
                      €{menuItem.price}
                    </CustomText>
                  </CustomRow>
                </>
              ) : (
                <CustomRow marginTop={6}>
                  <CustomText size={'sm'} fontFamily={fontFamily}>
                    €{menuItem.price}
                  </CustomText>
                </CustomRow>
              )}
            </CustomView>
          </CustomRow>
        </GridContainer>
      </>
    );
  }

  function renderOddItem() {
    return (
      <>
        <GridContainer fullWidth marginTop={index === 0 ? 0 : 30} marginBottom={20}>
          <CustomRow right full>
            <CustomView>
              <CustomRow right>
                <CustomRow marginRight>
                  <AllergenList allergenList={menuItem.allergens} />
                </CustomRow>
                <CustomText right style={{lineHeight: 'normal'}} bold size={'md'} fontFamily={fontFamily}>
                  {menuItem.name.toUpperCase()}
                </CustomText>
              </CustomRow>
              {menuItem.ingredients && menuItem.ingredients.length > 0 && (
                <CustomRow right>
                  <CustomText right size={'sm'} fontFamily={fontFamily}>
                    {menuItem.ingredients.map((ingrendient) => _.upperFirst(ingrendient.name))?.join(', ')}
                  </CustomText>
                </CustomRow>
              )}
              {!!menuItem.description && (
                <CustomRow marginTop>
                  <CustomText size={'sm'} italic fontFamily={fontFamily}>
                    {menuItem.description}
                  </CustomText>
                </CustomRow>
              )}
              {menuItem.discountPercentage ? (
                <CustomRow right marginTop={6}>
                  <CustomText size={'sm'} right lineThrough fontFamily={fontFamily}>
                    €{menuItem.price / (1 - menuItem.discountPercentage / 100)}
                  </CustomText>
                  <CustomText size={'sm'}>&nbsp;\&nbsp;</CustomText>
                  <CustomText size={'sm'} color={color} bold fontFamily={fontFamily}>
                    €{menuItem.price}
                  </CustomText>
                </CustomRow>
              ) : (
                <CustomRow right marginTop={6}>
                  <CustomText right size={'sm'} fontFamily={fontFamily}>
                    €{menuItem.price}
                  </CustomText>
                </CustomRow>
              )}
            </CustomView>
            {!hideImages && menuItem.image && !!menuItem.image.url && (
              <CustomView marginLeft={20}>
                <ImageViewer image={menuItem.image} className={classes.imageItem} />
              </CustomView>
            )}
          </CustomRow>
        </GridContainer>
      </>
    );
  }

  return (
    <CustomCard plain padding={'sm'} noPaddingHorizontal>
      {index % 2 === 0 ? renderEvenItem() : renderOddItem()}
    </CustomCard>
  );
};

type Props = {
  index: number;
  menuItem: ItemForMenu;
  color?: Colors;
  fontFamily?: Fonts;
  hideImages?: boolean;
};

export default MenuItemTemplate2;
