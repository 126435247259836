export interface ActivityFilter {
  label: string;
  value: string;
  type: FilterType;
  occurrences: number;
}

export enum FilterType {
  ACTIVITY_TYPE = 'ACTIVITY_TYPE',
  TAG = 'TAG',
  GENERAL = 'GENERAL',
}
