import {DialogContent, Fade} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import React from 'react';
import CustomButton from './CustomButtons/CustomButton';
import CustomText from './Typography/CustomText';
import {BLACK, WHITE} from '../../styles/colors';

const dialogTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    marginTop: '25px',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '10px',
    color: BLACK,
  },
  modalTitle: {
    marginLeft: '15px',
    fontWeight: 700,
  },
}));

const DialogTitle = ({children, onClose, showCloseButton = true, ...other}: DialogTitleProps) => {
  const classes = dialogTitleStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children && (
        <CustomText color={'primary'} fontFamily={'Goda'} size={'lg'} className={classes.modalTitle}>
          {children}
        </CustomText>
      )}
      {onClose && showCloseButton && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

type DialogTitleProps = {
  children?: any;
  classes?: any;
  onClose?: any;
  showCloseButton?: boolean;
  id?: string;
};

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// ========================================== Dialog Wrapper ==========================================

const styleDialogContent = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '40px',
  },
  dialogHeader: {
    padding: 40,
  },
  root: {
    padding: theme.spacing(2),
  },
  noPadding: {
    padding: 0,
  },
  onBottom: {
    borderRadius: '40px 40px 0 0',
    bottom: '0',
    margin: '0',
    position: 'absolute',
  },
  fullWidthNoMargin: {
    '@media (max-width: 767px)': {
      width: '100%',
      margin: '0',
      maxWidth: '100%',
    },
  },
  fullScreenOnMobile: {
    '@media (max-width: 767px)': {
      height: '93%',
      margin: '0',
      maxHeight: 'none',
    },
    '@media (max-width: 480px)': {
      width: '100%',
      height: '93%',
      margin: '0',
      maxWidth: '100%',
      maxHeight: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '10px',
    color: BLACK,
    padding: 4,
    backgroundColor: WHITE,
  },
}));

const CustomDialog = ({
  className,
  dialogContentClassName,
  cancelButtonLabel,
  showCancelButton,
  showCloseButton = true,
  onClose,
  extraButtons,
  children,
  open,
  title,
  maxWidth,
  noPadding,
  fullWidth,
  onBottom,
  fullWidthOnMobile,
  fullScreenOnMobile,
}: DialogWrapperProps) => {
  const classes = styleDialogContent();

  const dialogClasses = classNames({
    [classes.dialogPaper]: true,
    [classes.onBottom]: onBottom,
    [classes.fullWidthNoMargin]: fullWidthOnMobile,
    [classes.fullScreenOnMobile]: fullScreenOnMobile,
    ...(className && {[className]: true}),
  });

  const dialogContentClasses = classNames({
    [classes.root]: true,
    [classes.noPadding]: noPadding,
    ...(dialogContentClassName && {[dialogContentClassName]: true}),
  });

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div>
      <Dialog
        transitionDuration={{enter: 550, exit: 400}}
        classes={{paper: dialogClasses}}
        TransitionComponent={Fade}
        open={open ? open : false}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={maxWidth ?? false}
        fullWidth={fullWidth ?? false}
      >
        {!!title && (
          <DialogTitle id="customized-dialog-title" onClose={handleClose} showCloseButton={showCloseButton}>
            {title}
          </DialogTitle>
        )}
        {!title && onClose && showCloseButton && (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent className={dialogContentClasses}>{children}</DialogContent>
        {(showCancelButton || extraButtons) && (
          <DialogActions>
            {showCancelButton && (
              <CustomButton size={'md'} simple onClick={handleClose} title={cancelButtonLabel || 'Annulla'} />
            )}
            {extraButtons}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

type DialogWrapperProps = {
  className?: any;
  dialogContentClassName?: any;
  height?: number;
  open?: boolean;
  noPadding?: boolean;
  onBottom?: boolean;
  fullWidth?: boolean;
  fullWidthOnMobile?: boolean;
  fullScreenOnMobile?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  title?: string;
  children?: any;
  cancelButtonLabel?: string;
  extraButtons?: any;
};

export default CustomDialog;
