import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React, {useState} from 'react';
import {Image} from '../../models/shared/image';
import CustomDialog from '../atoms/CustomDialog';
import CustomView from '../atoms/CustomView';

const useStyle = makeStyles({
  img: {},
  imgFull: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
  },
  dialog: {
    borderRadius: 0,
  },
});

const ImageViewer = ({image, className}: Props) => {
  const classes = useStyle({});
  const imgStyle = classNames({
    [classes.img]: true,
    ...(className && {[className]: true}),
  });
  const [openModal, setOpenModal] = useState(false);

  const onPressImage = () => {
    setOpenModal(true);
  };

  return (
    <>
      <img className={imgStyle} src={image.url} alt={''} onClick={onPressImage} />
      <CustomDialog
        className={classes.dialog}
        fullWidthOnMobile
        noPadding
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <CustomView key={image.imageID}>
          <img className={classes.imgFull} src={image.url} alt={image.filename || ''} width={100} height={100} />
        </CustomView>
      </CustomDialog>
    </>
  );
};

type Props = {
  image: Image;
  className?: any;
};

export default ImageViewer;
