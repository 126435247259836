import {SvgIconTypeMap} from '@material-ui/core';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import {BLACK, colorMap, Colors} from '../../styles/colors';

const CustomIcon = (props: Props) => {
  const {icon, size = 'sm', bordered, onClick, className} = props;
  const classes = useStyles(props);
  let baseClassNames = classNames({
    [classes.root]: true,
    [classes.iconColor]: true,
    [classes[`font_${size}`]]: true,
    [classes.borderColor]: bordered,
    [classes.border]: bordered,
    ...(className && {[className]: true}),
  });

  const Icon = icon;
  return (
    <>
      <Icon className={baseClassNames} onClick={onClick} />
    </>
  );
};

type Props = {
  color?: Colors;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  icon: OverridableComponent<SvgIconTypeMap>;
  bordered?: boolean;
  className?: any;
  onClick?: any;
};

const useStyles = makeStyles({
  root: {},
  font_xs: {fontSize: '16px'},
  font_sm: {fontSize: '24px'},
  font_md: {fontSize: '30px'},
  font_lg: {fontSize: '36px'},
  iconColor: ({color}: Props) => ({color: color ? colorMap(color) : BLACK}),
  border: {
    padding: '5px',
    border: '2px solid',
    borderRadius: '50%',
  },
  borderColor: ({color}: Props) => ({borderColor: color ? colorMap(color) : BLACK}),
});

export default CustomIcon;
