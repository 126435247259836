import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {resendEmailToken, validateEmail} from '../../../redux/actions/authActions';
import {State} from '../../../redux/reducers';
import {navigate} from '../../../services/navigationService';
import {HOME} from '../../../constants';
import PageLoginContainer from '../_Shared/PageLoginContainer';
import HamburgerImage from '../_Shared/HamburgerImage';

const ValidateEmail = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const authPayload = useSelector((s: State) => s.auth.authPayload);

  useEffect(() => {
    if (!authPayload) {
      navigate(HOME);
    }
  }, [authPayload]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        confirm();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [token]);

  const confirm = () => {
    if (!token || !token.trim()) {
      showToastMessage(AppMessageType.ERROR, 'Inserisci il token che ti abbiamo inviato per email');
      return;
    }
    dispatch(validateEmail(token));
  };

  const doResendEmailToken = () => {
    if (!authPayload?.email) {
      return;
    }
    dispatch(resendEmailToken(authPayload.email));
  };

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText center bold size={'xxl'} color={'primary'} fontFamily={'Goda'}>
            VALIDA EMAIL
          </CustomText>
        </CustomRow>
        <CustomRow marginTop center>
          <CustomText center>
            Ti abbiamo inviato una mail con il <b>codice da inserire</b>.
          </CustomText>
        </CustomRow>
        <GridContainer marginTop>
          <GridItem xs={12}>
            <CustomInput placeholder="Token" value={token} onChange={(text: string) => setToken(text)} />
          </GridItem>
          <GridItem xs={12} marginTop>
            <CustomButton fullWidth onClick={confirm} title={'Conferma'} />
          </GridItem>
          <GridItem xs={12}>
            <CustomButton
              fullWidth
              bordered
              simple
              color={'primary'}
              onClick={doResendEmailToken}
              title={'Invia di nuovo'}
            />
          </GridItem>
        </GridContainer>
        <CustomRow center marginTop>
          <CustomText center italic size={'sm'}>
            <b>Non vedi nessuna mail?</b> Controlla la casella di spam o inviala nuovamente.
          </CustomText>
        </CustomRow>
      </PageLoginContainer>
    </>
  );
};

export default ValidateEmail;
