import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import PageContainer from '../../../components/organisms/PageContainer';
import {HeaderConfigs} from '../../../components/organisms/types/header-configs';
import {HeaderLink} from '../../../components/organisms/types/header-link';
import {ACTIVITY_DETAIL, ACTIVITY_DETAIL_SELECT_MENU} from '../../../constants';
import {SmartMenuTemplateConfig} from '../../../models/smartMenu/smart-menu-config';
import {loadActivityDetail} from '../../../redux/actions/sectionUserActions';
import {loadMenu, loadSmartMenuTemplateConfig, setSelectedMenu} from '../../../redux/actions/smartMenuActions';
import {State} from '../../../redux/reducers';
import {navigate} from '../../../services/navigationService';
import {buildSearch, fromBase64ToString} from '../../../utils/commonFunctions';
import LanguageSelector from '../components/LanguageSelector';
import MenuIndex from '../components/MenuIndex';
import MenuSimple from '../components/MenuSimple';

const headerConfigs: HeaderConfigs = {
  showLogo: false,
  showBackButton: false,
  showLoginButton: false,
  showHeaderLinksOnWeb: false,
};

const SmartMenu = ({}: Props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation('smartmenu');
  const {activityID, menuID} = useParams<{activityID: string; menuID: string}>();
  const [searchParams] = useSearchParams();
  const [currentTemplateConfig, setCurrentTemplateConfig] = useState<SmartMenuTemplateConfig>();

  const menuList = useSelector((s: State) => s.smartMenu.menuList);
  const menu = useSelector((s: State) => s.smartMenu.selectedMenu);
  const templateConfig = useSelector((s: State) => s.smartMenu.templateConfig);
  const templateConfigLoading = useSelector((s: State) => s.smartMenu.templateConfigLoading);
  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    return () => {
      dispatch(setSelectedMenu({menu: null}));
    };
  }, []);

  useEffect(() => {
    if (activityID) {
      dispatch(loadMenu({id: activityID}));
      dispatch(loadSmartMenuTemplateConfig({merchantID: activityID}));
      dispatch(loadActivityDetail({id: activityID}));
    }
  }, [activityID]);

  useEffect(() => {
    if (templateConfig) {
      setCurrentTemplateConfig(templateConfig);
    }
    handleTemplateParam();
    handleConfigurationsParam();
  }, [searchParams, templateConfig]);

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      if (menuList.length === 1) {
        dispatch(setSelectedMenu({menu: menuList[0]}));
      } else if (menuID) {
        const _menu = menuList.find((m) => m.id === menuID);
        if (_menu) {
          dispatch(setSelectedMenu({menu: _menu}));
          return;
        }
      }
      if (menuList.length > 1 && !menu) {
        navigateToChooseMenuPage();
      }
    }
  }, [menuList, menuID]);

  const links: HeaderLink[] = useMemo(() => {
    if (!menu || !menu.categories) {
      return [];
    }
    const categoryLinks: HeaderLink[] = menu.categories.map((c) => ({
      id: c.id,
      label: c.name,
      linked: true,
    }));
    return [...categoryLinks, {id: 'general', label: t('general_info'), linked: true}];
  }, [menu]);

  const navigateToChooseMenuPage = () => {
    const search = buildSearch(searchParams);
    navigate(`${ACTIVITY_DETAIL}/${activityID}/${ACTIVITY_DETAIL_SELECT_MENU}`, {}, search);
  };

  const handleTemplateParam = () => {
    const template = searchParams.get('template');
    if (template && ['1', '2', '3'].includes(template)) {
      setCurrentTemplateConfig({
        ...currentTemplateConfig,
        template: {type: Number(template)},
      } as SmartMenuTemplateConfig);
    }
  };

  const handleConfigurationsParam = () => {
    const configuration = searchParams.get('configuration');
    if (configuration) {
      const payload = fromBase64ToString(configuration);
      const config: SmartMenuTemplateConfig = JSON.parse(payload);
      setCurrentTemplateConfig(config);
    }
  };

  const menuTitle = menuList && menuList?.length > 1 ? menu?.name?.toUpperCase() : '';

  const onLanguageChange = (_language: string) => {
    dispatch(loadMenu({id: activityID as string, language: _language}));
  };

  if (templateConfigLoading)
    return <PageContainer pageLoading={templateConfigLoading} headerConfigs={headerConfigs} hideFooter />;

  if (!currentTemplateConfig)
    return (
      <PageContainer pageTitle={t('service_not_available.title')} headerConfigs={headerConfigs} hideFooter>
        <CustomRow center>
          <CustomText center>{t('service_not_available.description')}</CustomText>
        </CustomRow>
      </PageContainer>
    );

  return (
    <PageContainer
      pageLoading={templateConfigLoading}
      hideFooter
      headerConfigs={{
        ...headerConfigs,
        links,
        showBackButton: menuList && menuList.length > 1,
        textColor: currentTemplateConfig.color,
        centerElement: !!templateConfig?.languages?.length ? (
          <LanguageSelector
            languages={templateConfig.languages}
            onLanguageChange={onLanguageChange}
            color={templateConfig.color}
          />
        ) : undefined,
      }}
      titleColor={currentTemplateConfig.color}
      titleFontFamily={currentTemplateConfig.fontFamily}
    >
      {menu && (
        <GridContainer>
          <GridItem lg={4}>
            <MenuIndex links={links} templateConfigs={currentTemplateConfig} />
          </GridItem>
          <GridItem xs={12} lg={8} noPadding>
            {/*<ActivityInfo*/}
            {/*  activityDetail={activityDetail}*/}
            {/*  color={currentTemplateConfig.color}*/}
            {/*  fontFamily={currentTemplateConfig.fontFamily}*/}
            {/*/>*/}
            <MenuSimple templateConfigs={currentTemplateConfig} />
          </GridItem>
        </GridContainer>
      )}
    </PageContainer>
  );
};

type Props = {};

export default SmartMenu;
