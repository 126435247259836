import React from 'react';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';

const useStyle = makeStyles((theme) => ({
  baseStyle: {
    alignItems: 'center',
    marginTop: (props: Props) =>
      props.marginTop === true || props.marginVertical === true
        ? '12px'
        : `${props.marginTop ?? props.marginVertical}px`,
    marginBottom: (props: Props) =>
      props.marginBottom === true || props.marginVertical === true
        ? '12px'
        : `${props.marginBottom ?? props.marginVertical}px`,
    marginLeft: (props: Props) =>
      props.marginLeft === true || props.marginHorizontal === true
        ? '12px'
        : `${props.marginLeft ?? props.marginHorizontal}px`,
    marginRight: (props: Props) =>
      props.marginRight === true || props.marginHorizontal === true
        ? '12px'
        : `${props.marginRight ?? props.marginHorizontal}px`,
    paddingTop: ({paddingVertical, paddingTop}: Props) =>
      !paddingVertical && !paddingTop ? 0 : `${paddingVertical ?? paddingTop}px`,
    paddingBottom: ({paddingVertical, paddingBottom}: Props) =>
      !paddingVertical && !paddingBottom ? 0 : `${paddingVertical ?? paddingBottom}px`,
    paddingLeft: ({paddingHorizontal, paddingLeft}: Props) =>
      !paddingHorizontal && !paddingLeft ? 0 : `${paddingHorizontal ?? paddingLeft}px`,
    paddingRight: ({paddingHorizontal, paddingRight}: Props) =>
      !paddingHorizontal && !paddingRight ? 0 : `${paddingHorizontal ?? paddingRight}px`,
  },
  full: {
    width: '100%',
  },
  center: {
    justifyContent: 'center',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  flexShrink: {
    flexShrink: 1,
  },
})) as any;

const CustomView = (props: Props) => {
  const classes = useStyle(props);
  const {
    style,
    className,
    children,
    center,
    marginTop,
    marginBottom,
    marginRight,
    marginLeft,
    wrap,
    nowrap,
    shrink,
    full,
  } = props;
  const rowStyle = classNames({
    [classes.baseStyle]: true,
    [classes.center]: center,
    [classes.full]: full,
    [classes.wrap]: wrap,
    [classes.noWrap]: nowrap,
    [classes.flexShrink]: shrink,
    ...(className && {[className]: true}),
  });
  return (
    <div style={style} className={rowStyle}>
      {children}
    </div>
  );
};

type Props = {
  style?: any;
  className?: any;
  full?: boolean;
  center?: boolean;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  marginLeft?: boolean | number;
  marginRight?: boolean | number;
  marginVertical?: boolean | number;
  marginHorizontal?: boolean | number;
  paddingTop?: boolean | number;
  paddingBottom?: boolean | number;
  paddingLeft?: boolean | number;
  paddingRight?: boolean | number;
  paddingVertical?: boolean | number;
  paddingHorizontal?: boolean | number;
  wrap?: boolean;
  nowrap?: boolean;
  shrink?: boolean;
  children: any;
};

export default CustomView;
