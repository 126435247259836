import {searchActivities} from '../../../api/merchantService';
import {Coords} from '../../../models/shared/coords';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {ActivityFilter} from '../../../models/user/activity-filter';
import {ActivitySearchResultResponse} from '../../../models/user/activity-search-result';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {searchActivitiesSuccess, setActivitiesFiltersSelected} from '../../actions/sectionUserActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';

export default function* searchActivitiesAsync(action: {type: string; payload: {term: string}}) {
  yield put(enableLoader());
  let coords: Coords = yield select((state: State) => state.user.userInfo?.userInfo?.location?.coords);
  let activitiesFilters: ActivityFilter[] = yield select((state: State) => state.sectionUser.activitiesFiltersSelected);
  let filters = '';
  if (action.payload.term) {
    yield put(setActivitiesFiltersSelected({activitiesFilters: []}));
  } else {
    filters = activitiesFilters.map((a) => a.value).join(';');
  }
  let userID: string = yield select((state: State) => state.user.userInfo?.id);
  let radius: number = yield select((state: State) => state.user.radius);
  const response: CustomResponse<ActivitySearchResultResponse> = yield searchActivities(
    coords,
    action.payload.term,
    filters,
    userID,
    radius,
  );
  if (response.status === Status.OK) {
    yield put(searchActivitiesSuccess({activitySearchResultList: response.payload}));
    yield put(disableLoader());
  } else {
    yield put(disableLoader());
  }
}
