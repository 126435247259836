import {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Order} from '../../../models/orders/order';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import OrderCardElement from './OrderCardElement';
import CustomIcon from '../../../components/atoms/CustomIcon';
import {Receipt} from '@material-ui/icons';
import ModalAddMessage from '../../../components/molecules/ModalAddMessage';

const REASONS_LIST = [
  {
    value: '1',
    label: 'Ho cambiato idea',
  },
  {
    value: '2',
    label: "Ho atteso troppo a lungo l'approvazione",
  },
  {
    value: '3',
    label: 'Altro',
  },
];

const OrdersView = ({orders, onRefresh, onCancelPress}: Props) => {
  const classes = useStyle();
  const [showAddMessageModal, setShowAddMessageModal] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const onHeaderPress = (index: number) => {
    // setTimeout(() => {
    //   scrollRef.current?.scrollTo({
    //     y: index * 200,
    //     animated: true,
    //   });
    // }, 300);
  };

  const _onCancelPress = (id: string) => {
    setOrderIdToCancel(id);
    setShowAddMessageModal(true);
  };

  const _onCancelPressConfirm = (message?: string) => {
    setShowAddMessageModal(false);
    onCancelPress && onCancelPress(orderIdToCancel, message);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          {orders && orders.length > 0 ? (
            <>
              {orders.map((o: Order, i: number) => (
                <OrderCardElement
                  key={o.orderID}
                  order={o}
                  onHeaderPress={() => onHeaderPress(i)}
                  onCancelPress={() => _onCancelPress(o.id)}
                />
              ))}
            </>
          ) : (
            orders && (
              <>
                <CustomRow center>
                  <CustomIcon icon={Receipt} color={'primary'} size={'lg'} />
                </CustomRow>
                <CustomRow center>
                  <CustomText bold>Nessun ordine effettuato</CustomText>
                </CustomRow>
              </>
            )
          )}
        </div>
        <ModalAddMessage
          open={showAddMessageModal}
          onClose={() => setShowAddMessageModal(false)}
          onConfirm={_onCancelPressConfirm}
          optionsList={REASONS_LIST}
          showMessageOptionValue={['3']}
          neutralOption={'3'}
        />
      </div>
    </>
  );
};

type Props = {
  orders: Order[] | null;
  onRefresh?: any;
  onCancelPress?: (id: string, message?: string) => void;
};

const useStyle = makeStyles({
  container: {},
  subContainer: {},
});

export default OrdersView;
