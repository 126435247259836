import {Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {BLACK, colorMap, Colors, colorTextForBackgroundMap, WHITE} from '../../styles/colors';

const CustomChip = (props: Props) => {
  const {text, className, icon, onClick, onClose} = props;
  const classes = useStyle(props);

  let chipClasses = classNames({
    [classes.baseStyle]: true,
    [classes.backgroundColor]: true,
    ...(className && {[className]: true}),
  });

  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        icon={icon}
        label={text}
        clickable={!!onClick}
        onClick={onClick}
        onDelete={onClose}
        className={chipClasses}
      />
    </>
  );
};

const useStyle = makeStyles({
  baseStyle: {
    alignSelf: 'flex-start',
    padding: '6px 8px',
    borderRadius: 20,
  },
  backgroundColor: ({color}: Props) => ({
    backgroundColor: color ? colorMap(color) : BLACK,
    color: color ? colorTextForBackgroundMap[color] ?? WHITE : WHITE,
  }),
});

type Props = {
  text: string;
  icon?: any;
  onClick?: any;
  onClose?: any;
  bold?: Boolean;
  className?: any;
  color?: Colors;
};

export default CustomChip;
