import React from 'react';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomText from './Typography/CustomText';

const useStyle = makeStyles((theme) => ({
  baseStyle: {
    width: '100%',
    '&:before': {
      height: '0px !important',
    },
  },
  heading: {},
})) as any;

const CustomAccordion = ({className, title, header, onHeaderPress, children, ...rest}: Props) => {
  const classes = useStyle({...rest});
  const accordionClasses = classNames({
    [classes.baseStyle]: true,
    ...(className && {[className]: true}),
  });
  return (
    <Accordion className={accordionClasses}>
      <AccordionSummary
        onClick={onHeaderPress}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title && <CustomText className={classes.heading}>{title}</CustomText>}
        {!!header && header}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

type Props = {
  className?: any;
  children?: any;
  title?: string;
  header?: any;
  onHeaderPress?: any;
};

export default CustomAccordion;
