import React from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import cardStyle from './cardStyle';
import {Card, CardActionArea} from '@material-ui/core';

const useStyles = makeStyles(cardStyle);

export default function CustomCard({
  style,
  className,
  children,
  plain,
  divider,
  alignmentLeft,
  alignmentRight,
  padding,
  fontSize,
  onClick,
  marginTop,
  marginBottom,
  noPaddingHorizontal,
  noPaddingVertical,
  ...rest
}: Props) {
  const classes = useStyles({...rest, marginTop, marginBottom});
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.divider]: divider,
    [classes.cardAlignementLeft]: alignmentLeft,
    [classes.cardAlignementRight]: alignmentRight,
    ...(padding && {[classes[`padding_${padding}`]]: !onClick}),
    ...(fontSize && {[classes[`font_${fontSize}`]]: true}),
    ...(className && {[className]: className}),
    [classes.noPaddingHorizontal]: noPaddingHorizontal,
    [classes.noPaddingVertical]: noPaddingVertical,
  });

  const cardActionClasses = classNames({
    [classes.cardAction]: true,
    ...(padding && {[classes[`padding_${padding}`]]: true}),
  });

  return (
    <>
      <Card style={style} className={cardClasses} {...rest} onClick={onClick}>
        {onClick ? <CardActionArea className={cardActionClasses}>{children}</CardActionArea> : <>{children}</>}
      </Card>
    </>
  );
}

type Props = {
  style?: React.CSSProperties;
  className?: any;
  children: any;
  plain?: boolean;
  divider?: boolean;
  alignmentLeft?: boolean;
  alignmentRight?: boolean;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  onClick?: any;
  noPaddingHorizontal?: boolean;
  noPaddingVertical?: boolean;
  padding?: 'sm' | 'md' | 'lg';
  fontSize?: 'sm' | 'md' | 'lg';
};
