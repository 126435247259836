import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import {Theme} from '@mui/material/styles';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {Colors} from '../../styles/colors';
import {isFunction} from '../../utils/commonFunctions';
import CustomIcon from '../atoms/CustomIcon';

const carouselStyle =
  ({
    center,
    height,
    absolute,
    style,
  }: {
    center?: boolean;
    height?: string;
    absolute?: boolean;
    style?: ((theme: any) => React.CSSProperties) | React.CSSProperties;
  }) =>
  (theme: Theme) => ({
    position: absolute ? 'absolute' : 'relative',
    zIndex: 5,
    width: '100%',
    height: height ?? '300px',
    textAlign: center ? 'center' : 'initial',
    '.MuiButtonBase-root': {
      opacity: '0.6',
      '&:hover': {
        backgroundColor: 'black',
        filter: 'brightness(120%)',
        opacity: '0.4',
      },
    },
    '[aria-label="Next"]': {
      position: 'absolute',
      zIndex: 15,
      backgroundColor: 'transparent',
      top: 'calc(50% - 70px)',
      right: 10,
      marginRight: 0,
      [theme.breakpoints.down('xs')]: {
        right: 0,
      },
    },
    '[aria-label="Previous"]': {
      position: 'absolute',
      zIndex: 15,
      backgroundColor: 'transparent',
      top: 'calc(50% - 70px)',
      left: 10,
      marginLeft: 0,
      [theme.breakpoints.down('xs')]: {
        left: 0,
      },
    },
    ...(style && (isFunction(style) ? style(theme) : style)),
  });

const CustomCarousel = ({
  children,
  indicators = false,
  autoPlay = true,
  index = 0,
  indicatorsIconsColor = 'white',
  indicatorContainerStyle,
  indicatorIconButtonStyle,
  activeIndicatorIconButtonStyle,
  ...rest
}: Props) => {
  return (
    <>
      <Carousel
        sx={carouselStyle({...rest})}
        index={index}
        interval={8000}
        indicators={indicators}
        changeOnFirstRender={false}
        navButtonsAlwaysVisible
        autoPlay={autoPlay}
        indicatorContainerProps={{style: {marginTop: 20, ...indicatorContainerStyle}}}
        indicatorIconButtonProps={{style: {...indicatorIconButtonStyle}}}
        activeIndicatorIconButtonProps={{style: {...activeIndicatorIconButtonStyle}}}
        NextIcon={<CustomIcon size={'lg'} color={indicatorsIconsColor} icon={ChevronRight} />}
        PrevIcon={<CustomIcon size={'lg'} color={indicatorsIconsColor} icon={ChevronLeft} />}
      >
        {children}
      </Carousel>
    </>
  );
};

type Props = {
  children: React.ReactNode;
  indicatorsIconsColor?: Colors;
  height?: string;
  absolute?: boolean;
  indicators?: boolean;
  autoPlay?: boolean;
  center?: boolean;
  index?: number;
  style?: ((theme: any) => React.CSSProperties) | React.CSSProperties;
  indicatorContainerStyle?: React.CSSProperties;
  indicatorIconButtonStyle?: React.CSSProperties;
  activeIndicatorIconButtonStyle?: React.CSSProperties;
};

export default CustomCarousel;
