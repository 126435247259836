import './AudioPlayer.css';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import phoneRecord from '../../../../assets/audio/phone-record.mp3';

type Props = {
  onPlay?: () => void;
  onPause?: () => void;
};

export const CustomAudioPlayer = ({onPlay, onPause}: Props) => {
  return (
    <AudioPlayer
      autoPlay={false}
      src={phoneRecord}
      onPlay={onPlay}
      onPause={onPause}
      style={{backgroundColor: 'transparent', boxShadow: 'none', paddingTop: '18px'}}
      showDownloadProgress={false}
      showFilledProgress={false}
      showFilledVolume={false}
      showJumpControls={false}
      showSkipControls={false}
      customVolumeControls={[]}
      customAdditionalControls={[]}
      customProgressBarSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.PROGRESS_BAR]}
      customControlsSection={[]}
      layout={'stacked'}
    />
  );
};
