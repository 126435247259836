import {BASE_URL, http} from './axios';
import {Coords} from '../models/shared/coords';
import {CustomResponse} from '../models/shared/custom-response';
import {ActivityDetail} from '../models/user/activity-detail';
import {ActivityFilter} from '../models/user/activity-filter';
import {ActivitySearchResult, ActivitySearchResultResponse} from '../models/user/activity-search-result';
import {Merchants, SortBy} from '../models/user/user-info.interface';
import {getToken} from '../services/storageService';

const CONTROLLER_PATH = '/users';

export const getActivitiesFilters = (
  originCoords: Coords,
  radius?: number,
): Promise<CustomResponse<ActivityFilter[]>> => {
  const coords = originCoords.latitude + ',' + originCoords.longitude;
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/get-activities-filters`, {
    params: {coords, radius},
  });
};

export const getAllActivities = async (): Promise<CustomResponse<ActivitySearchResult[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/get-all-activities`);
};

export const searchActivities = (
  originCoords: Coords,
  term: string = '',
  filter: string = '',
  userID: string = '',
  radius?: number,
): Promise<CustomResponse<ActivitySearchResultResponse>> => {
  const coords = originCoords.latitude + ',' + originCoords.longitude;
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/search-activities`, {
    params: {coords, term, filter, radius, userID},
  });
};

export const getFavoriteActivities = (userID: string = ''): Promise<CustomResponse<ActivitySearchResult[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/favorite-activities`, {
    params: {userID},
  });
};

export const getActivityDetail = (id: string, userID: string = ''): Promise<CustomResponse<ActivityDetail>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/activity-detail`, {
    params: {id, userID},
  });
};

export const getMerchants = (sortBy?: SortBy): Promise<CustomResponse<Merchants>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  const params = {sortBy};
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/get-merchants`, {
    headers,
    params,
  });
};
