import {ActivityType} from '../../models/globalConfig/activity-type';
import {Config} from '../../models/globalConfig/config';
import {Faq} from '../../models/globalConfig/faq';
import {Food} from '../../models/globalConfig/food';
import {AppMessageType} from '../../components/atoms/CustomToast';

export enum GlobalActionTypes {
  ENABLE_LOADER = '[GlobalState] ENABLE_LOGIN_LOADER',
  DISABLE_LOADER = '[GlobalState] DISABLE_LOGIN_LOADER',

  SET_PREVIOUS_PAGE = '[GlobalState] SET_PREVIOUS_PAGE',
  SET_CURRENT_PAGE = '[GlobalState] SET_CURRENT_PAGE',

  LOAD_ACTIVITY_TYPES = '[GlobalState] LoadActivityTypes',
  LOAD_ACTIVITY_TYPES_SUCCESS = '[GlobalState] LoadActivityTypesSuccess',

  LOAD_FOODS = '[GlobalState] LoadFoods',
  LOAD_FOODS_SUCCESS = '[GlobalState] LoadFoodsSuccess',

  LOAD_FAQS = '[GlobalState] LoadFaqs',
  LOAD_FAQS_SUCCESS = '[GlobalState] LoadFaqSuccess',

  LOAD_CONFIG = '[GlobalState] LoadConfig',
  LOAD_CONFIG_SUCCESS = '[GlobalState] LoadConfigSuccess',

  SHOW_ALERT = '[GlobalState] SHOW_ALERT',
  CLOSE_ALERT = '[GlobalState] CLOSE_ALERT',

  SET_LANGUAGE = '[GlobalState] SET_LANGUAGE',

  SET_SHOW_MODAL_DOWNLOAD_APP = '[GlobalState] SET_SHOW_MODAL_DOWNLOAD_APP',
}

export const enableLoader = () => {
  return {
    type: GlobalActionTypes.ENABLE_LOADER,
  };
};
export const disableLoader = () => {
  return {
    type: GlobalActionTypes.DISABLE_LOADER,
  };
};

export const setCurrentPage = (page: string) => {
  return {
    type: GlobalActionTypes.SET_CURRENT_PAGE,
    payload: {page},
  };
};
export const setPreviousPage = (page: string) => {
  return {
    type: GlobalActionTypes.SET_PREVIOUS_PAGE,
    payload: {page},
  };
};

export const loadFaqs = () => {
  return {
    type: GlobalActionTypes.LOAD_FAQS,
  };
};
export const loadFaqsSuccess = (faqs: Faq[]) => {
  return {
    type: GlobalActionTypes.LOAD_FAQS_SUCCESS,
    payload: faqs,
  };
};

export const loadFoods = () => {
  return {
    type: GlobalActionTypes.LOAD_FOODS,
  };
};
export const loadFoodsSuccess = (foods: Food[]) => {
  return {
    type: GlobalActionTypes.LOAD_FOODS_SUCCESS,
    payload: foods,
  };
};

export const loadActivityTypes = () => {
  return {
    type: GlobalActionTypes.LOAD_ACTIVITY_TYPES,
  };
};
export const loadActivityTypesSuccess = (activityTypes: ActivityType[]) => {
  return {
    type: GlobalActionTypes.LOAD_ACTIVITY_TYPES_SUCCESS,
    payload: activityTypes,
  };
};

export const loadConfig = () => {
  return {
    type: GlobalActionTypes.LOAD_CONFIG,
  };
};
export const loadConfigSuccess = (config: Config) => {
  return {
    type: GlobalActionTypes.LOAD_CONFIG_SUCCESS,
    payload: config,
  };
};

export const showAlert = (payload: {message: string; type?: AppMessageType; duration?: number}) => {
  return {
    type: GlobalActionTypes.SHOW_ALERT,
    payload,
  };
};
export const closeAlert = () => {
  return {
    type: GlobalActionTypes.CLOSE_ALERT,
  };
};

export const setLanguage = (payload: {language: string}) => {
  return {
    type: GlobalActionTypes.SET_LANGUAGE,
    payload,
  };
};

export const setShowModalDownloadApp = (show: boolean) => {
  return {
    type: GlobalActionTypes.SET_SHOW_MODAL_DOWNLOAD_APP,
    payload: {show},
  };
};
