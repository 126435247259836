import globalReducer, {GlobalState} from './globalReducer';
import authReducer, {AuthState} from './authReducer';
import sectionUserReducer, {SectionUserState} from './sectionUserReducer';
import userReducer, {UserState} from './userReducer';
import {combineReducers} from 'redux';
import smartMenuReducer, {SmartMenuState} from './smartMenuReducer';

export interface State {
  global: GlobalState;
  auth: AuthState;
  user: UserState;
  sectionUser: SectionUserState;
  smartMenu: SmartMenuState;
}

const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  user: userReducer,
  sectionUser: sectionUserReducer,
  smartMenu: smartMenuReducer,
});

export default rootReducer;
