import {takeEvery} from 'redux-saga/effects';
import {AuthActionTypes} from '../actions/authActions';
import {RegistrationActionTypes} from '../actions/registrationActions';
import {GlobalActionTypes} from '../actions/globalActions';
import loadFaqsAsync from './global/loadFaqsSaga';
import {UserActionTypes} from '../actions/userActions';
import {SectionUserActionTypes} from '../actions/sectionUserActions';
import updateTableReservationInfoAsync from './sectionUser/updateTableReservationInfoSaga';
import {OrdersActionTypes} from '../actions/ordersActions';
import loginSaga from './auth/loginSaga';
import oauthLoginSaga from './auth/oauthLoginSaga';
import forgotPasswordSendTokenSaga from './auth/forgotPasswordSendTokenSaga';
import resetPasswordSaga from './auth/resetPasswordSaga';
import logoutSaga from './auth/logoutSaga';
import contactSupportSaga from './auth/contactSupportSaga';
import registrationUserSaga from './auth/registrationUserSaga';
import registrationAnonymousUserSaga from './auth/registrationAnonymousUserSaga';
import registrationMerchantSaga from './auth/registrationMerchantSaga';
import resendEmailTokenSaga from './auth/resendEmailTokenSaga';
import verifyEmailSaga from './auth/verifyEmailSaga';
import submitPhoneNumberUserSaga from './auth/submitPhoneNumberUserSaga';
import verifyPhoneNumberUserSaga from './auth/verifyPhoneNumberUserSaga';
import loadFoodsSaga from './global/loadFoodsSaga';
import loadActivityTypesSaga from './global/loadActivityTypesSaga';
import loadConfigSaga from './global/loadConfigSaga';
import loadUserSaga from './user/loadUserSaga';
import updateUserSaga from './user/updateUserSaga';
import searchActivitiesSaga from './sectionUser/searchActivitiesSaga';
import loadFavoriteActivitiesSaga from './sectionUser/loadFavoriteActivitiesSaga';
import loadActivityDetailSaga from './sectionUser/loadActivityDetailSaga';
import getFiltersActivitiesSaga from './sectionUser/getFiltersActivitiesSaga';
import createOrderSaga from './orders/createOrderSaga';
import createOrderAndPaySaga from './orders/createOrderAndPaySaga';
import loadUserOrdersSaga from './orders/loadUserOrdersSaga';
import updateOrderStatusSaga from './orders/updateOrderStatusSaga';
import {SmartMenuActionTypes} from '../actions/smartMenuActions';
import {loadMenuAsync, loadTemplateConfigAsync} from './smartMenu/loadSmartMenuSaga';

export default function* rootSaga() {
  // auth
  yield takeEvery(AuthActionTypes.LOGIN, loginSaga);
  yield takeEvery(AuthActionTypes.OAUTH_LOGIN, oauthLoginSaga);
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_SEND_TOKEN, forgotPasswordSendTokenSaga);
  yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(AuthActionTypes.LOGOUT, logoutSaga);
  yield takeEvery(AuthActionTypes.CONTACT_SUPPORT, contactSupportSaga);
  yield takeEvery(RegistrationActionTypes.REGISTRATION_USER, registrationUserSaga);
  yield takeEvery(RegistrationActionTypes.REGISTRATION_ANONYMOUS_USER, registrationAnonymousUserSaga);
  yield takeEvery(RegistrationActionTypes.REGISTRATION_MERCHANT, registrationMerchantSaga);
  yield takeEvery(AuthActionTypes.RESEND_EMAIL_TOKEN, resendEmailTokenSaga);
  yield takeEvery(AuthActionTypes.VERIFY_EMAIL, verifyEmailSaga);
  yield takeEvery(AuthActionTypes.SUBMIT_PHONE_NUMBER, submitPhoneNumberUserSaga);
  yield takeEvery(AuthActionTypes.VERIFY_PHONE_NUMBER, verifyPhoneNumberUserSaga);
  // global
  yield takeEvery(GlobalActionTypes.LOAD_FAQS, loadFaqsAsync);
  yield takeEvery(GlobalActionTypes.LOAD_FOODS, loadFoodsSaga);
  yield takeEvery(GlobalActionTypes.LOAD_ACTIVITY_TYPES, loadActivityTypesSaga);
  yield takeEvery(GlobalActionTypes.LOAD_CONFIG, loadConfigSaga);
  // user
  yield takeEvery(UserActionTypes.LOAD_USER_INFO, loadUserSaga);
  yield takeEvery(UserActionTypes.UPDATE_USER, updateUserSaga);
  // section user
  yield takeEvery(SectionUserActionTypes.SEARCH_ACTIVITIES, searchActivitiesSaga);
  yield takeEvery(SectionUserActionTypes.LOAD_FAVORITE_ACTIVITIES, loadFavoriteActivitiesSaga);
  yield takeEvery(SectionUserActionTypes.LOAD_ACTIVITY_DETAIL, loadActivityDetailSaga);
  yield takeEvery(SectionUserActionTypes.GET_ACTIVITIES_FILTERS, getFiltersActivitiesSaga);
  yield takeEvery(SectionUserActionTypes.UPDATE_TABLE_RESERVATION_INFO, updateTableReservationInfoAsync);
  // orders
  yield takeEvery(OrdersActionTypes.CREATE_ORDER, createOrderSaga);
  yield takeEvery(OrdersActionTypes.CREATE_ORDER_AND_PAY, createOrderAndPaySaga);
  yield takeEvery(OrdersActionTypes.LOAD_USER_ORDERS, loadUserOrdersSaga);
  yield takeEvery(OrdersActionTypes.UPDATE_STATUS_ORDER, updateOrderStatusSaga);
  // smartMenu
  yield takeEvery(SmartMenuActionTypes.LOAD_MENU, loadMenuAsync);
  yield takeEvery(SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG, loadTemplateConfigAsync);
}
