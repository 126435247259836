import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';

const videoClasses = makeStyles({
  iframe: {
    borderRadius: '30px',
    width: '100%',
    height: '500px',
    '@media (max-width: 379px)': {
      height: '160px',
    },
    '@media (min-width: 380px) and (max-width: 480px)': {
      height: '190px',
    },
    '@media (min-width: 481px) and (max-width: 600px)': {
      height: '250px',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      height: '300px',
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      height: '350px',
    },
  },
  videoContainer: {
    padding: '50px 10px 50px',
    '@media (max-width: 480px)': {
      padding: '200px 10px 50px',
    },
    '@media (min-width: 481px) and (max-width: 767px)': {
      padding: '200px 10px 50px',
    },
  },
});

export default function VideoSection({id}: Props) {
  const classes = videoClasses();
  const goToServizi = () => {
    document.getElementById('servizi')?.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  return (
    <div id={id} className={classes.videoContainer}>
      <GridContainer fullWidth={false}>
        <GridItem xs={12} sm={12} md={12}>
          <iframe
            className={classes.iframe}
            src="https://www.youtube.com/embed/2s_4dPb1yxg?autoplay=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
