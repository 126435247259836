import {colorMap, primaryColor} from '../../../styles/colors';
import {Theme} from '@material-ui/core';
import {defaultFont} from '../../../styles/material-kit/nextjs-material-kit';
import tooltipsStyle from '../../../styles/material-kit/tooltipsStyle';
import {Props} from './HeaderLinkItem';

const headerLinksStyle = (theme: Theme) =>
  ({
    list: {
      ...defaultFont,
      fontSize: '14px',
      margin: 0,
      paddingLeft: '0',
      listStyle: 'none',
      paddingTop: '0',
      paddingBottom: '0',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      marginRight: '50px',
      [theme.breakpoints.down('md')]: {
        display: 'initial',
      },
    },
    listMobile: {
      ...defaultFont,
      fontSize: '14px',
      margin: 0,
      paddingLeft: '0',
      listStyle: 'none',
      paddingTop: '0',
      paddingBottom: '0',
      color: 'inherit',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginRight: '0px',
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        '&:after': {
          width: 'calc(100% - 30px)',
          content: '""',
          display: 'block',
          height: '1px',
          marginLeft: '15px',
          backgroundColor: '#e5e5e5',
        },
      },
    },
    listItemText: {
      padding: '0 !important',
    },
    listContainer: {
      justifyContent: 'space-between',
    },
    navLink: {
      position: 'relative',
      padding: '0.9375rem',
      fontWeight: '400',
      fontSize: '18px',
      borderRadius: '0px',
      lineHeight: '20px',
      textTransform: 'none !important',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
      '&:focus': {
        color: ({textColor}: Props) => (textColor ? colorMap(textColor) : primaryColor),
      },
      '&:hover': {
        color: ({textColor}: Props) => (textColor ? colorMap(textColor) : primaryColor),
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        marginBottom: '8px',
        marginTop: '8px',
        textAlign: 'left',
        '& > span:first-child': {
          justifyContent: 'flex-start',
        },
      },
    },
    notificationNavLink: {
      [theme.breakpoints.down('md')]: {
        top: '0',
        margin: '5px 15px',
      },
      color: '#FFF',
      padding: '0.9375rem',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
      top: '4px',
    },
    registerNavLink: {
      [theme.breakpoints.down('md')]: {
        top: '0',
        margin: '5px 15px',
      },
      top: '3px',
      position: 'relative',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
    },
    navLinkActiveCustom: {
      borderBottom: '2px solid',
      borderColor: ({textColor}: Props) => (textColor ? colorMap(textColor) : primaryColor),
    },
    navLinkActive: {
      color: 'inherit',
    },
    icons: {
      width: '20px',
      height: '20px',
      marginRight: '3px',
    },
    socialIcons: {
      position: 'relative',
      fontSize: '20px !important',
      marginRight: '4px',
    },
    dropdownLink: {
      '&,&:hover,&:focus': {
        color: 'inherit',
        textDecoration: 'none',
        display: 'block',
        padding: '10px 20px',
      },
    },
    ...tooltipsStyle,
    marginRight5: {
      marginRight: '5px',
    },
    extraButtonIsolated: {
      position: 'absolute',
      right: '0',
    },
    textColor: {
      color: (props: Props) => (props.textColor ? colorMap(props.textColor) : primaryColor),
    },
  } as any);

export default headerLinksStyle as any;
