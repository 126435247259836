import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {CustomerPaymentMethod, PaymentInfo} from '../../../models/user/payment-info';
import {updateUser} from '../../../redux/actions/userActions';
import {UpdateUserRequest} from '../../../models/user/request-objects/update-user';
import CustomDialog from '../../../components/atoms/CustomDialog';
import {State} from '../../../redux/reducers';
import {makeStyles} from '@material-ui/styles';
import PaymentMethod from '../../../components/molecules/PaymentMethod';

const ModalChangeCard = ({paymentInfo, open, onClose}: Props) => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const updateMethod = (pm: CustomerPaymentMethod) => {
    dispatch(
      updateUser({
        updateUserRequest: {
          paymentMethod: pm.paymentMethodID,
        } as UpdateUserRequest,
      }),
    );
    onClose();
  };

  return (
    <>
      <CustomDialog noPadding open={open} onClose={onClose} title={'Cambia carta'}>
        <div className={classes.baseStyle}>
          {paymentInfo &&
            paymentInfo.paymentMethods &&
            paymentInfo.paymentMethods
              .filter((x) => x.card.fingerprint !== paymentInfo.defaultPaymentMethod.card.fingerprint)
              .map((pm) => (
                <PaymentMethod key={pm.paymentMethodID} paymentMethod={pm} onClick={() => updateMethod(pm)} />
              ))}
        </div>
      </CustomDialog>
    </>
  );
};

const useStyle = makeStyles({
  baseStyle: {
    minWidth: '480px',
    '@media (max-width: 767px)': {
      minWidth: 'auto',
    },
  },
});

type Props = {
  paymentInfo?: PaymentInfo;
  open?: boolean;
  onClose?: any;
};

const mapStateToProps = (state: State) => ({
  paymentInfo: state.user.userInfo?.paymentInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeCard);
