import {Image} from '../shared/image';
import {LocationInfo} from '../shared/location-info';
import {HomeServiceInfo, TableReservationInfo, TakeawayInfo} from './info';
import {OrderCartItem} from './order-cart-item';
import {Step} from './step';
import {CouponType} from '../globalConfig/coupon';

export interface Order {
  id: string;
  orderID: string;
  activityInfo: OrderActivityInfo;
  userInfo: OrderUserInfo;
  paymentID: string;
  creationDate: Date;
  totalAmount: number;
  orderCartAmount: number;
  activityServiceAmount: number;
  fee: number;
  couponType: CouponType;
  couponDiscountAmount: number;
  couponCode: string;
  type: OrderType;
  status: OrderStatus;
  tracking: Step[];
  orderCartItems: OrderCartItem[];
  takeawayInfo: TakeawayInfo;
  homeServiceInfo: HomeServiceInfo;
  tableReservationInfo: TableReservationInfo;
  note: string;
  origin: OrderOrigin;
}

export interface OrderActivityInfo {
  activityID: string;
  email: string;
  phoneNumber: string;
  activityName: string;
  activityType: string;
  img: Image;
  location: LocationInfo;
}

export interface OrderUserInfo {
  userID: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  img: Image;
}

export enum OrderStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REFUSED = 'refused',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  REFUNDED = 'refunded',
}

export enum OrderType {
  TAKEAWAY = 'takeaway',
  HOME_SERVICE = 'home-service',
  TABLE_RESERVATION = 'table-reservation',
}

export enum OrderOrigin {
  MOBILE = 'mobile',
  WEB = 'web',
}
