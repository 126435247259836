import React, {useEffect} from 'react';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment, {Moment} from 'moment';
import CalendarIcon from './customIcons/CalendarIcon';
import 'moment/locale/it';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {FormControl} from '@material-ui/core';
import {BLACK, GRAY_LIGHT, primaryColor} from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: GRAY_LIGHT,
    borderRadius: '40px',
    padding: '14px 20px',
  },
  datepicker: {
    marginTop: '0',
    marginBottom: '0',
  },
  input: {
    '&,&::placeholder': {
      color: BLACK,
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
  },
  inputWithLabel: {
    padding: '0 8px',
    borderRadius: '40px',
    backgroundColor: GRAY_LIGHT,
  },
  label: {
    padding: '0 10px',
  },
  ellipsis: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  full: {
    width: '100%',
  },
}));

const CustomDatePicker = ({
  id,
  label,
  placeholder,
  value,
  onDateChange,
  style,
  className,
  disabled,
  mandatory,
  minDate,
  maxDate,
  fullWidth = true,
  inputVariant,
}: Props) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date | Moment | null>(null);
  const [canHandleError, setCanHandleError] = React.useState(false);

  const datepickerContainerClasses = classNames({
    [classes.formControl]: !label,
    [classes.full]: fullWidth,
    ...(className && {[className]: true}),
  });

  const datePickerClasses = classNames({
    [classes.datepicker]: true,
    [classes.ellipsis]: true,
  });

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.inputWithLabel]: !!label,
  });

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setCanHandleError(true);
    if (date) {
      setSelectedDate(date);
      if (onDateChange) {
        onDateChange(date.toDate());
      }
    } else {
      setSelectedDate(null);
      onDateChange(null);
    }
  };

  function handleError(err: any) {
    if (canHandleError && err && onDateChange) {
      onDateChange(null);
      setCanHandleError(false);
    }
  }

  return (
    <FormControl required={mandatory} className={datepickerContainerClasses}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
        <KeyboardDatePicker
          className={datePickerClasses}
          inputVariant={label ? 'filled' : 'standard'}
          InputProps={{
            className: inputClasses,
            disableUnderline: true,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
          style={style}
          invalidDateMessage={'Data non valida'}
          minDateMessage={
            'Non è possibile inserire date inferiori ' +
            (minDate ? 'alla data ' + moment(minDate).format('DD/MM/YYYY') : 'al 1900')
          }
          maxDateMessage={
            'Non è possibile inserire date superiori ' +
            (maxDate ? 'alla data ' + moment(maxDate).format('DD/MM/YYYY') : 'al 2100')
          }
          required={mandatory}
          variant="inline"
          format="DD/MM/YYYY"
          disabled={disabled}
          margin="normal"
          autoOk={true}
          id={id}
          label={label}
          placeholder={placeholder}
          value={selectedDate}
          fullWidth={fullWidth}
          onChange={handleDateChange}
          minDate={minDate ? minDate : new Date('1900-01-01')}
          maxDate={maxDate ? maxDate : new Date('2100-01-01')}
          onError={handleError}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

type Props = {
  id?: string;
  label?: string;
  placeholder?: string;
  onDateChange: (date: Date | null) => void;
  value?: Date | null;
  style?: any;
  className?: any;
  disabled?: boolean;
  mandatory?: boolean;
  fullWidth?: boolean;
  minDate?: Date;
  maxDate?: Date;
  inputVariant?: 'outlined' | 'standard' | 'filled';
};

export default CustomDatePicker;
