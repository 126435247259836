import {verifyPhoneNumber} from '../../../api/authService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {showToastMessage} from '../../../services/toastService';
import {login, oauthLogin} from '../../actions/authActions';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {
  AuthPayload,
  isEmailLoginPayload,
  isEmailRegistrationPayload,
  isOAuthPayload,
} from '../../../models/auth/redux/auth-payload';
import {AuthData} from '../../../models/auth/redux/auth-data';

export default function* verifyPhoneNumberUserAsync(action: {
  type: string;
  payload: {
    token: string;
    phoneNumber?: string;
    update?: boolean;
  };
}) {
  yield put(enableLoader());
  let email: string;
  if (action.payload.update) {
    email = yield select((state: State) => state.user.userInfo?.email);
  } else {
    email = yield select((state: State) => state.auth.authPayload?.email);
  }
  const phoneRequestId: string = yield select((state: State) => state.auth.phoneRequestId);
  if (!email || !phoneRequestId) {
    return;
  }
  const response: CustomResponse<{
    isPhoneNumberVerified: boolean;
  }> = yield verifyPhoneNumber(action.payload.token, phoneRequestId, email);
  if (response.status === Status.OK && response.payload.isPhoneNumberVerified) {
    if (action.payload.update) {
      // now we are not managing phone update on web
      // const updateUserRequest = {
      //   userInfo: {
      //     phoneNumber: action.payload.phoneNumber,
      //   } as UpdateUserInfo,
      // } as UpdateUserRequest;
      // yield put(
      //   updateUser({
      //     updateUserRequest,
      //     showMessage: true,
      //     goTo: 'UserProfilePersonalInfoPage',
      //   }),
      // );
    } else {
      const authPayload: AuthPayload = yield select((state: State) => state.auth.authPayload);
      if (isEmailLoginPayload(authPayload) || isEmailRegistrationPayload(authPayload)) {
        yield put(
          login({
            email,
            password: authPayload?.password,
          }),
        );
      } else if (isOAuthPayload(authPayload)) {
        yield put(
          oauthLogin({
            oauthUser: authPayload.oauthUser,
            provider: authPayload.provider,
          }),
        );
      }
      showToastMessage(AppMessageType.SUCCESS, 'Verifica effettuata con successo');
      yield put(disableLoader());
    }
  } else {
    showToastMessage(AppMessageType.ERROR, 'Errore durante la verifica del numero di telefono');
    yield put(disableLoader());
  }
}
