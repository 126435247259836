import PageLoginContainer from '../_Shared/PageLoginContainer';
import CustomRow from '../../../components/atoms/CustomRow';
import CustomText from '../../../components/atoms/Typography/CustomText';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import LoginForm from './components/LoginForm';
import React from 'react';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {login, setAuthPayload} from '../../../redux/actions/authActions';
import {useDispatch} from 'react-redux';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {navigate} from '../../../services/navigationService';
import {FORGOT_PASSWORD} from '../../../constants';
import {EmailLoginPayload} from '../../../models/auth/redux/auth-payload';
import {Provider} from '../../../models/auth/provider';

export default function MailLogin() {
  const dispatch = useDispatch();
  const doLogin = (loginPayload: {email: string; password: string}) => {
    if (!loginPayload.email || !loginPayload.password) {
      showToastMessage(AppMessageType.ERROR, 'Compila tutti i campi');
      return;
    }
    const authPayload: EmailLoginPayload = {
      ...loginPayload,
      type: 'email-login',
      provider: Provider.LOCAL,
    };
    dispatch(setAuthPayload({authPayload}));
    dispatch(login(loginPayload));
  };
  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText bold size={'xxl'} color={'primary'} fontFamily={'Goda'}>
            ACCEDI
          </CustomText>
        </CustomRow>
        <GridContainer marginTop>
          <GridItem xs={12}>
            <LoginForm login={doLogin} />
          </GridItem>
        </GridContainer>
        <CustomRow marginTop center>
          <CustomText underline onClick={() => navigate(FORGOT_PASSWORD)}>
            Hai dimenticato la password?
          </CustomText>
        </CustomRow>
      </PageLoginContainer>
    </>
  );
}
