import {Role} from '../role';
import {OAuthUser} from '../oauth-user';
import {Coords} from '../../shared/coords';
import {Provider} from '../provider';

export const isEmailLoginPayload = (obj: AuthRootPayload): obj is EmailLoginPayload => {
  return obj.type === 'email-login';
};
export const isEmailRegistrationPayload = (obj: AuthRootPayload): obj is EmailRegistrationPayload => {
  return obj.type === 'email-registration';
};
export const isMerchantRegistrationPayload = (obj: AuthRootPayload): obj is MerchantRegistrationPayload => {
  return obj.type === 'merchant-registration';
};
export const isOAuthPayload = (obj: AuthRootPayload): obj is OAuthPayload => {
  return obj.type === 'oauth';
};

export type AuthPayload = EmailLoginPayload | EmailRegistrationPayload | OAuthPayload | MerchantRegistrationPayload;

export interface AuthRootPayload {
  type: 'email-login' | 'email-registration' | 'oauth' | 'merchant-registration';
  provider: Provider;
  fcmToken?: string;
  role?: Role;
}

export interface EmailLoginPayload extends AuthRootPayload {
  email: string;
  password: string;
}

export interface EmailRegistrationPayload extends AuthRootPayload {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface MerchantRegistrationPayload extends AuthRootPayload {
  phoneNumber: string;
  activityName: string;
  activityType: string;
  email: string;
  password: string;
  passwordConfirm: string;
  coords: Coords;
}

export interface OAuthPayload extends AuthRootPayload {
  email: string;
  oauthUser: OAuthUser;
}
