import {oauthLogin} from '../../../api/authService';
import {AuthStatus} from '../../../models/auth/auth-status';
import {OauthLoginResponse} from '../../../models/auth/login';
import {OAuthUser} from '../../../models/auth/oauth-user';
import {Provider} from '../../../models/auth/provider';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {LocationInfo} from '../../../models/shared/location-info';
import {setToken, setUserInfo} from '../../../services/storageService';
import {showToastMessage} from '../../../services/toastService';
import {oauthLoginSuccess, setAuthData} from '../../actions/authActions';
import {disableLoader, enableLoader, loadConfig} from '../../actions/globalActions';
import {loadUserInfoSuccess} from '../../actions/userActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {navigate} from '../../../services/navigationService';
import {HOME, SUBMIT_PHONE_NUMBER} from '../../../constants';
import {RegistrationOrigin} from '../../../models/user/user-info.interface';
import {RegistrationOauthPayload} from '../../../models/auth/registration';

export default function* oauthLoginAsync(action: {type: string; payload: {oauthUser: OAuthUser; provider: Provider}}) {
  yield put(enableLoader());
  const location: LocationInfo = yield select((state: State) => state.user.userInfo?.userInfo?.location);
  const registrationOauthPayload: RegistrationOauthPayload = {
    oauthUser: action.payload.oauthUser,
    location,
    registrationOrigin: RegistrationOrigin.WEB,
  };
  const response: CustomResponse<OauthLoginResponse> = yield oauthLogin(
    registrationOauthPayload,
    action.payload.provider,
  );
  if (response.status === Status.OK) {
    const authData = {
      jwtToken: response.payload.token,
      userInfo: response.payload.user,
    };
    yield put(setAuthData({authData}));
    switch (response.payload.authStatus) {
      case AuthStatus.USER_NOT_REGISTERED: {
        navigate(SUBMIT_PHONE_NUMBER);
        break;
      }
      case AuthStatus.PHONE_NUMBER_MISSING:
      case AuthStatus.PHONE_NUMBER_NOT_VALID: {
        navigate(SUBMIT_PHONE_NUMBER);
        showToastMessage(AppMessageType.WARNING, response.message);
        break;
      }
      case AuthStatus.USER_OK: {
        setToken(response.payload.token);
        yield put(oauthLoginSuccess());
        setUserInfo(response.payload.user);
        yield put(loadUserInfoSuccess({userInfo: response.payload.user}));
        yield put(disableLoader());
        yield put(loadConfig());
        const page: string = yield select((state: State) => state.auth.afterLoginPage);
        navigate(page ?? HOME);
        showToastMessage(AppMessageType.SUCCESS, `BenvenutƏ ${response.payload.user.userInfo?.name} !`);
        break;
      }
    }
  }
  yield put(disableLoader());
}
