import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {TextField} from '@material-ui/core';
import {BLACK, GRAY_LIGHT, primaryColor} from '../../styles/colors';
import {InputProps as StandardInputProps} from '@material-ui/core/Input/Input';

const useStyle = makeStyles((theme) => ({
  formControl: {
    backgroundColor: GRAY_LIGHT,
    borderRadius: '40px',
    padding: '14px 20px',
  },
  full: {
    width: '100%',
  },
  input: {
    '&,&::placeholder': {
      color: BLACK,
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: '1',
    },
  },
  label: {
    padding: '0 10px',
  },
  inputWithLabel: {
    padding: '0 8px',
    borderRadius: '40px',
    backgroundColor: GRAY_LIGHT,
  },
}));

const CustomSelect = ({
  id,
  label,
  placeholder,
  onChange,
  disabled,
  items,
  dataStructure,
  className,
  mandatory,
  value,
  fullWidth = true,
  inputProps,
}: Props) => {
  const classes = useStyle();
  const selectContainerClasses = classNames({
    [classes.formControl]: !label,
    [classes.full]: fullWidth,
    ...(className && {[className]: true}),
  });

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.inputWithLabel]: !!label,
  });

  const onSelectChange = (event: any) => {
    if (onChange && items) {
      onChange(findByKey(items, dataStructure.id, event.target.value));
    }
  };

  const findByKey = (array: any[], key: string, v: string) => {
    if (array && array.length) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][key] === v) {
          return array[i];
        }
      }
    }
    return null;
  };

  return (
    <>
      <FormControl required={mandatory} className={selectContainerClasses}>
        <TextField
          select
          variant={label ? 'filled' : 'standard'}
          InputProps={{
            className: inputClasses,
            disableUnderline: true,
            ...inputProps,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
          id={id}
          value={value}
          label={label}
          placeholder={placeholder}
          style={{textAlign: 'left'}}
          disabled={disabled || items?.length === 0}
          onChange={onSelectChange}
        >
          {items &&
            items.map((item, index) => (
              <MenuItem id={item[dataStructure.id]} key={index} value={item[dataStructure.id]}>
                {item[dataStructure.name]}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    </>
  );
};

type Props = {
  id?: string;
  label?: string;
  placeholder?: string;
  onChange?: (obj: any) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  mandatory?: boolean;
  value?: any;
  items?: any[];
  dataStructure: {id: string; name: string};
  className?: any;
  inputProps?: Partial<StandardInputProps>;
};

export default CustomSelect;
