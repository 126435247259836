import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../../redux/reducers';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../../components/atoms/CustomRow';
import {CustomResponse, Status} from '../../../../models/shared/custom-response';
import {checkOrder} from '../../../../api/orderService';
import {Order, OrderType} from '../../../../models/orders/order';
import {UpsertOrder} from '../../../../models/orders/request-objects/upsert-order';
import {TableReservationInfo} from '../../../../models/orders/info';
import {showToastMessage} from '../../../../services/toastService';
import {AppMessageType} from '../../../../components/atoms/CustomToast';
import {updateTableReservationInfo} from '../../../../redux/actions/sectionUserActions';
import moment from 'moment';
import {Day} from '../../../../models/timetables/timetables.interface';
import {COMPLETE_TABLE_RESERVATION, LOGIN} from '../../../../constants';
import {navigate} from '../../../../services/navigationService';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomCheckbox from '../../../../components/atoms/CustomCheckbox';
import CustomDatePicker from '../../../../components/atoms/CustomDatePicker';
import CustomSelect from '../../../../components/atoms/CustomSelect';
import ProntoInTavolaCardElement from './ProntoInTavolaCardElement';
import {CheckCircleOutlineRounded, RadioButtonUncheckedRounded} from '@material-ui/icons';
import {isValidDate} from '../../../../utils/commonFunctions';
import {GRAY_LIGHT, primaryColor} from '../../../../styles/colors';

const maximumDate = moment(new Date()).add(2, 'years').toDate();

const DepositAdviceText = ({depositAmount}: {depositAmount: number}) => {
  return (
    <CustomRow>
      <CustomText>
        L'attività per questo servizio, in questo giorno, richiede il pagamento di una{' '}
        <CustomText bold color={'primary'}>
          caparra{' '}
        </CustomText>
        di{' '}
        <CustomText bold color={'primary'}>
          {depositAmount ? depositAmount : 0}
          {'€ '}
        </CustomText>
        per{' '}
        <CustomText bold color={'primary'}>
          ogni persona{'. '}
        </CustomText>
        La caparra verrà scalata alla cassa al momento del pagamento del conto totale, ti basterà mostrare l'ordine
        effettuato con Ordify!
      </CustomText>
    </CustomRow>
  );
};

const CoverAmountAdviceText = ({coverAmount}: {coverAmount: number}) => {
  return coverAmount ? (
    <CustomRow>
      <CustomText>
        Costo del coperto:{' '}
        <CustomText bold color={'primary'}>
          {coverAmount} {'€ '}
        </CustomText>
        per{' '}
        <CustomText bold color={'primary'}>
          ogni persona
        </CustomText>
      </CustomText>
    </CustomRow>
  ) : (
    <></>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 24px',
  },
}));

const TableReservationTab = () => {
  const classes = useStyles();

  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);
  const isLoggedIn = useSelector((s: State) => s.auth.isLoggedIn);
  const orderCart = useSelector((s: State) => s.sectionUser.orderCart);
  const tableReservationForm = useSelector((s: State) => s.sectionUser.tableReservationForm);

  const [reservationDay, setReservationDay] = useState<Date>();
  const [reservationHour, setReservationHour] = useState('');
  const [numberOfPersons, setNumberOfPersons] = useState('');
  const [hours, setHours] = useState<string[]>([]);
  const [customersNumberValues, setCustomersNumberValues] = useState<string[]>([]);
  const [useProntoInTavola, setUseProntoInTavola] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const now = new Date();
    const open = !getDay(now)?.closingDay;

    if (tableReservationForm) {
      const {
        day,
        hour,
        numberOfPersons: numberOfPersonsTemp,
        useProntoInTavola: useProntoInTavolaTemp,
      } = tableReservationForm;
      setReservationDay(day || new Date());
      setReservationHour(hour || '');
      setNumberOfPersons(numberOfPersonsTemp ? numberOfPersonsTemp.toString() : '');
      setUseProntoInTavola(useProntoInTavolaTemp || false);
    }

    setIsOpen(open);
    if (open) {
      setHours(getHoursValues());
      setCustomersNumberValues(getCustomersNumberValues());
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setHours(getHoursValues());
      setCustomersNumberValues(getCustomersNumberValues());
    }
  }, [activityDetail, useProntoInTavola]);

  const getCustomersNumberValues = () => {
    let n: string[] = [];
    const maxCustomersByTable = activityDetail?.tableReservationSettings.config?.maxCustomersByTable;
    if (maxCustomersByTable) {
      for (let i = 1; i <= maxCustomersByTable; i++) {
        n.push(i + '');
      }
    }
    return n;
  };

  const getHoursValues = () => {
    const hoursList = activityDetail?.tableReservationSettings?.config?.timeSlots || [];
    const timeLimit = activityDetail?.tableReservationSettings?.config?.timeLimit;
    if (useProntoInTavola && timeLimit) {
      const now = moment();
      const ds = moment(reservationDay).format('DD/MM/yyyy');
      return hoursList.filter((h) =>
        moment(ds + ' ' + h, 'DD/MM/yyyy HH:mm')
          .subtract(timeLimit, 'minutes')
          .isAfter(now),
      );
    }
    return hoursList;
  };

  const getDay = (d: Date) => {
    if (!isValidDate(d)) {
      return;
    }
    let dayOfWeek = moment(d.toISOString()).day();
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    return activityDetail?.info.timetables.days.find((dd) => dd.dayID === dayOfWeek.toString(10)) || ({} as Day);
  };

  const onChangeDate = (d: Date | null) => {
    if (d && isValidDate(d)) {
      setReservationDay(d);
      setUseProntoInTavola(false);
      setIsOpen(!getDay(d)?.closingDay);
      setReservationHour('');
      setNumberOfPersons('');
      dispatch(updateTableReservationInfo({day: d}));
    }
  };

  const onChangeHour = (h: string) => {
    setReservationHour(h);
    setNumberOfPersons('');
    dispatch(updateTableReservationInfo({day: reservationDay, hour: h}));
  };

  const onChangeNumberOfPersons = (n: string) => {
    setNumberOfPersons(n);
    dispatch(updateTableReservationInfo({numberOfPersons: parseInt(n, 10)}));
  };

  const onChangeUseProntoInTavola = () => {
    const use = !useProntoInTavola;
    setUseProntoInTavola(use);
    dispatch(updateTableReservationInfo({useProntoInTavola: use}));
  };

  const canOrder = () => {
    return reservationDay && reservationHour && numberOfPersons;
  };

  const getUpsertOrder = () => {
    const tableReservationInfo = {
      day: reservationDay,
      hour: reservationHour,
      numberOfPersons: parseInt(numberOfPersons, 10),
      useProntoInTavola,
      useDeposit: activityDetail?.tableReservationSettings.config.depositEnabled && !useProntoInTavola,
    } as TableReservationInfo;
    return {
      activityID: activityDetail?.id,
      type: OrderType.TABLE_RESERVATION,
      tableReservationInfo,
    } as UpsertOrder;
  };

  const confirm = async () => {
    if (useProntoInTavola && (!orderCart || orderCart.length === 0)) {
      showToastMessage(AppMessageType.WARNING, 'Per usufruire del Pronto In Tavola metti qualcosa nel carrello');
      return;
    }
    if (!isLoggedIn) {
      // dispatch(setAfterLoginPage(COMPLETE_TABLE_RESERVATION));
      navigate(LOGIN);
    } else {
      const upsertOrder = getUpsertOrder();
      const resCheck: CustomResponse<Order> = await checkOrder(upsertOrder);
      if (resCheck.status === Status.OK) {
        navigate(COMPLETE_TABLE_RESERVATION);
      }
    }
  };

  return (
    <div className={classes.root}>
      {activityDetail?.tableReservationSettings.config?.depositEnabled && (
        <CustomRow marginBottom>
          <DepositAdviceText depositAmount={activityDetail.tableReservationSettings.config?.depositAmount} />
        </CustomRow>
      )}
      {activityDetail?.tableReservationSettings.config?.depositEnabled &&
        activityDetail?.tableReservationSettings.config?.prontoInTavolaEnabled && (
          <CustomRow marginBottom>
            <CustomText>
              Usufruendo del servizio{' '}
              <CustomText bold color={'primary'}>
                Pronto In Tavola non si paga la caparra.
              </CustomText>
            </CustomText>
          </CustomRow>
        )}
      <CustomRow marginTop>
        <CustomDatePicker
          label={'Scegli il giorno di prenotazione'}
          value={reservationDay}
          minDate={new Date()}
          maxDate={maximumDate}
          onDateChange={onChangeDate}
        />
      </CustomRow>
      {!isOpen ? (
        <CustomRow marginTop marginBottom>
          <CustomText bold>L'attività è chiusa nel giorno selezionato</CustomText>
        </CustomRow>
      ) : !activityDetail?.tableReservationSettings?.config ? (
        <CustomRow marginTop marginBottom>
          <CustomText bold>
            L'attività non offre la possibilità di prenotare un tavolo nel giorno selezionato
          </CustomText>
        </CustomRow>
      ) : (
        <>
          <CustomRow marginTop>
            <CustomSelect
              fullWidth
              label={"Scegli l'ora di prenotazione"}
              placeholder={hours.length === 0 ? 'Nessun orario disponibile' : undefined}
              value={reservationHour}
              onChange={(h) => onChangeHour(h.value)}
              items={hours.map((h) => ({
                value: h,
                label: h,
              }))}
              dataStructure={{id: 'value', name: 'label'}}
            />
          </CustomRow>
          <CustomRow marginTop>
            <CustomSelect
              fullWidth
              label={'Imposta il numero di persone'}
              placeholder={hours.length === 0 ? 'Nessun posto disponibile' : undefined}
              disabled={!reservationHour}
              value={numberOfPersons}
              onChange={(n) => onChangeNumberOfPersons(n.value)}
              items={customersNumberValues.map((n) => ({
                value: n,
                label: n,
              }))}
              dataStructure={{id: 'value', name: 'label'}}
            />
          </CustomRow>
        </>
      )}
      {activityDetail?.tableReservationSettings.config?.prontoInTavolaEnabled && isOpen && (
        <>
          <CustomRow marginTop>
            <CustomCheckbox
              icon={<RadioButtonUncheckedRounded />}
              checkedIcon={<CheckCircleOutlineRounded />}
              checked={useProntoInTavola}
              onPress={onChangeUseProntoInTavola}
            >
              <CustomRow>
                <CustomText>
                  Voglio usufruire del servizio{' '}
                  <CustomText bold color={'primary'}>
                    Pronto In Tavola
                  </CustomText>
                </CustomText>
              </CustomRow>
            </CustomCheckbox>
          </CustomRow>
          {useProntoInTavola && (
            <CustomRow marginBottom>
              <CoverAmountAdviceText coverAmount={activityDetail?.tableReservationSettings?.config?.coverAmount} />
            </CustomRow>
          )}
          <CustomRow marginTop marginBottom>
            <ProntoInTavolaCardElement activityName={activityDetail.info.activityName} />
          </CustomRow>
        </>
      )}
      <CustomRow marginTop marginBottom>
        <CustomButton
          fullWidth
          size={'md'}
          disabled={!isOpen || !canOrder()}
          color={'primary'}
          title={'Continua'}
          onClick={confirm}
        />
      </CustomRow>
    </div>
  );
};

export default TableReservationTab;
