import {AppMessageType} from '../../components/atoms/CustomToast';
import {ActivityType} from '../../models/globalConfig/activity-type';
import {Config} from '../../models/globalConfig/config';
import {Faq} from '../../models/globalConfig/faq';
import {Food} from '../../models/globalConfig/food';
import {DEFAULT_LANGUAGE} from '../../utils/config';
import {GlobalActionTypes} from '../actions/globalActions';

export interface GlobalState {
  isLoading: boolean;
  previousPage: string;
  currentPage: string;
  configs: Config;
  showModalDownloadApp: boolean;
  lists: {
    faqs: Faq[];
    foods: Food[];
    activityTypes: ActivityType[];
  };
  alertOptions: {
    message: string;
    open: boolean;
    type: AppMessageType;
    duration: number;
  };
  language: string;
}

const initialState = {
  isLoading: false,
  previousPage: '',
  currentPage: '',
  configs: {} as Config,
  showModalDownloadApp: true,
  lists: {
    faqs: [],
    foods: [],
    activityTypes: [],
  },
  alertOptions: {
    open: false,
  } as any,
  language: DEFAULT_LANGUAGE,
};

const globalReducer = (state: GlobalState = initialState, action: {type: string; payload: any}) => {
  switch (action.type) {
    case GlobalActionTypes.ENABLE_LOADER:
      return {...state, isLoading: true};
    case GlobalActionTypes.DISABLE_LOADER:
      return {...state, isLoading: false};
    case GlobalActionTypes.SET_PREVIOUS_PAGE:
      return {...state, previousPage: action.payload.page};
    case GlobalActionTypes.SET_CURRENT_PAGE:
      return {...state, previousPage: state.currentPage, currentPage: action.payload.page};
    case GlobalActionTypes.LOAD_FAQS_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          faqs: action.payload,
        },
      };
    case GlobalActionTypes.LOAD_FOODS_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          foods: action.payload,
        },
      };
    case GlobalActionTypes.LOAD_ACTIVITY_TYPES_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          activityTypes: action.payload,
        },
      };
    case GlobalActionTypes.LOAD_CONFIG_SUCCESS:
      return {
        ...state,
        configs: action.payload,
      };
    case GlobalActionTypes.SHOW_ALERT:
      return {
        ...state,
        alertOptions: {
          open: true,
          message: action.payload.message,
          type: action.payload.type || AppMessageType.ERROR,
          duration: action.payload.duration || 5000,
        },
      };
    case GlobalActionTypes.CLOSE_ALERT:
      return {
        ...state,
        alertOptions: {
          ...state.alertOptions,
          open: false,
        },
      };
    case GlobalActionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      };
    case GlobalActionTypes.SET_SHOW_MODAL_DOWNLOAD_APP:
      return {
        ...state,
        showModalDownloadApp: action.payload.show,
      };
    default:
      return state;
  }
};

export default globalReducer;
