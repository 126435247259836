import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import CustomText from '../../../components/atoms/Typography/CustomText';
import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState} from 'react';
import CustomRow from '../../../components/atoms/CustomRow';
import {PaymentInfo} from '../../../models/user/payment-info';
import {State} from '../../../redux/reducers';
import {connect, useDispatch} from 'react-redux';
import PaymentMethod from '../../../components/molecules/PaymentMethod';
import {createOrderAndPay} from '../../../redux/actions/ordersActions';
import {PaymentMode, UpsertOrderAndPay} from '../../../models/orders/request-objects/upsert-order-and-pay';
import {Coupon} from '../../../models/globalConfig/coupon';
import {UpsertOrder} from '../../../models/orders/request-objects/upsert-order';
import TotalCartPrices from './TotalCartPrices';
import ModalChangeCard from './ModalChangeCard';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {primaryColor} from '../../../styles/colors';
import {Config} from '../../../models/tableReservationSettings/table-reservation-settings.interface';
import {OrderType} from '../../../models/orders/order';
import TotalCartPricesTableOrder from '../../CompleteTableOrder/components/TotalCartPricesTableOrder';

const PaymentSection = ({
  style,
  onPayAction,
  upsertOrder,
  activityServiceAmount,
  canApplyCoupon,
  disabled,
  paymentInfo,
  activityID,
  promoFirstOrderNoTax,
  percentageFee,
  fixedFee,
  prontoInTavolaUsed,
  tableReservationConfig,
}: Props) => {
  const classes = useStyle();
  const stripe = useStripe();
  const elements = useElements();
  const [coupon, setCoupon] = useState<Coupon>();
  const [redraw, setRedraw] = useState(false);
  const [showModalChangePaymentMethod, setShowModalChangePaymentMethod] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stripe && elements) {
      console.log('redraw');
      setRedraw(!redraw);
    }
  }, [stripe, elements]);

  const upsertDefaultPaymentMethod = () => {
    setShowModalChangePaymentMethod(true);
  };

  const onPaymentConfirm = (paymentMode: PaymentMode) => {
    const pay = () => {
      if (coupon) {
        upsertOrder.couponCode = coupon.code;
      }
      dispatch(
        createOrderAndPay({
          body: {upsertOrder, paymentMode} as UpsertOrderAndPay,
          stripeRef: stripe,
          cardElement: elements?.getElement(CardElement),
        }),
      );
    };
    onPayAction(pay);
  };

  return (
    <>
      <CustomRow marginTop>
        <CustomText size={'lg'} color={'primary'}>
          Metodo di pagamento
        </CustomText>
      </CustomRow>
      {paymentInfo?.defaultPaymentMethod ? (
        <div className={classes.containerWithDefaultCard}>
          <CustomRow>
            <CustomText bold color={'primary'} style={{flex: 1}}>
              Predefinito
            </CustomText>
            {paymentInfo?.paymentMethods && paymentInfo.paymentMethods.length > 1 && (
              <div style={{alignItems: 'flex-end'}}>
                <CustomButton size={'sm'} color={'primary'} title="Cambia carta" onClick={upsertDefaultPaymentMethod} />
              </div>
            )}
          </CustomRow>
          <PaymentMethod paymentMethod={paymentInfo.defaultPaymentMethod} />
        </div>
      ) : (
        <div className={classes.defaultPayment}>
          <CustomRow marginTop={4}>
            <CustomText bold>Nessun metodo di pagamento impostato</CustomText>
          </CustomRow>
        </div>
      )}
      {upsertOrder.type === OrderType.TABLE_RESERVATION ? (
        <TotalCartPricesTableOrder
          upsertOrder={upsertOrder}
          activityID={activityID}
          fixedFee={fixedFee}
          percentageFee={percentageFee}
          promoFirstOrderNoTax={promoFirstOrderNoTax}
          onCouponApplied={(c: Coupon) => setCoupon(c)}
          canApplyCoupon={canApplyCoupon}
          tableReservationConfig={tableReservationConfig}
          prontoInTavolaUsed={prontoInTavolaUsed}
        />
      ) : (
        <TotalCartPrices
          upsertOrder={upsertOrder}
          activityID={activityID}
          fixedFee={fixedFee}
          percentageFee={percentageFee}
          promoFirstOrderNoTax={promoFirstOrderNoTax}
          onCouponApplied={(c: Coupon) => setCoupon(c)}
          canApplyCoupon={canApplyCoupon}
          activityServiceAmount={activityServiceAmount}
        />
      )}
      {paymentInfo?.defaultPaymentMethod && (
        <CustomRow marginTop>
          <CustomButton
            fullWidth
            disabled={disabled}
            color={'primary'}
            title="Paga con il metodo predefinito"
            onClick={() => onPaymentConfirm('default')}
          />
        </CustomRow>
      )}
      <div className={classes.newCardBox}>
        <CustomRow>
          <CustomText bold size={'md'}>
            Inserisci i dati della carta
          </CustomText>
        </CustomRow>
        <div className={classes.cardElementContainer}>
          <CardElement id={'card-element'} />
        </div>
      </div>
      <CustomRow>
        <CustomButton
          fullWidth
          simple={!!paymentInfo?.defaultPaymentMethod}
          bordered
          disabled={disabled}
          color={'primary'}
          title={paymentInfo?.defaultPaymentMethod ? 'Paga con una nuova carta' : 'Paga con carta'}
          // onClick={() => onPaymentConfirm('checkout-session')}
          onClick={() => onPaymentConfirm('new-card')}
        />
      </CustomRow>
      <ModalChangeCard open={showModalChangePaymentMethod} onClose={() => setShowModalChangePaymentMethod(false)} />
    </>
  );
};

const useStyle = makeStyles({
  containerInit: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerWithDefaultCard: {
    flex: 1,
  },
  defaultPayment: {
    flex: 1,
    flexDirection: 'column',
  },
  newCardBox: {
    margin: '16px 0',
    padding: '10px 10px',
    border: '1px solid ' + primaryColor,
  },
  cardElementContainer: {
    padding: '20px 0',
    transform: 'translateZ(0)',
  },
});

type Props = {
  style?: any;
  onPayAction: (pay: () => void) => void;
  disabled?: boolean;
  upsertOrder: UpsertOrder;
  activityServiceAmount?: number;
  canApplyCoupon?: boolean;
  prontoInTavolaUsed?: boolean;
  tableReservationConfig?: Config;

  activityID?: string;
  paymentInfo?: PaymentInfo;
  fixedFee: number;
  percentageFee: number;
  promoFirstOrderNoTax: boolean;
};

const mapStateToProps = (state: State) => ({
  activityID: state.sectionUser.activityDetail?.id,
  paymentInfo: state.user.userInfo?.paymentInfo,
  fixedFee: state.global.configs.fixedFee,
  percentageFee: state.global.configs.percentageFee,
  promoFirstOrderNoTax: state.global.configs.promoFirstOrderNoTax,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSection);
