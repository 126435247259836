import React, {useEffect} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

import privacyPageStyle from './style';
import {navigate} from '../../services/navigationService';
import {PRIVACY} from '../../constants';
import CustomRow from '../../components/atoms/CustomRow';
import CustomText from '../../components/atoms/Typography/CustomText';
import PageContainer from '../../components/organisms/PageContainer';

const useStyles = makeStyles(privacyPageStyle);

export default function LegalTermsPage({}: any) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <PageContainer pageTitle={'Termini Legali'} showModalDownloadApp>
      <div className={classes.container}>
        <CustomRow center>
          <CustomText bold>TERMINI E CONDIZIONI D’USO</CustomText>
        </CustomRow>

        <div className={classes.newParagraph}>
          <span>
            Benvenuti in Ordify. I presenti Termini e Condizioni individuano le regole sulla base delle quali si
            fornisce accesso all’applicazione mobile Ordify, la quale consente agli utenti di ordinare prodotti da
            attività del settore agroalimentare, riservarsi un tavolo all’interno dei locali delle attività e prenotare
            le pietanze che s’intendono consumare al tavolo preventivamente, tramite l’innovativo servizio “Pronto in
            Tavola”. Si prega di leggere attentamente le presenti Condizioni prima di ordinare eventuali prodotti o
            effettuare prenotazioni dall’applicazione. L’inoltro di un ordine o di una prenotazione tramite
            l’applicazione comporta l’accettazione da parte degli utenti delle presenti Condizioni che disciplinano
            l’utilizzo dell’applicazione stessa.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>1. INFORMAZIONI GENERALI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify è gestito da un team con sede legale in Roverbella (MN), via Gorizia, n.21. È possibile contattarci
            all’indirizzo di posta elettronica support@ordify.it. Ordify fornisce agli utenti un sistema per la
            comunicazione dei propri ordini alle attività del settore agroalimentare che compaiono sull’applicazione. Il
            contratto di compravendita di tali prodotti è concluso tra l’utente e l’attività alla quale l’ordine, per
            tramite di Ordify, viene inoltrato.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>2. MODALITA' DI ACCESSO ALL’APPLICAZIONE</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            È possibile avere accesso a molteplici aree dell’applicazione anche senza effettuare un ordine o senza
            procedere alla registrazione dei propri dati. Gli utenti che non intendono accettare le presenti Condizioni
            sono tenuti a non utilizzare i servizi offerti dall’applicazione e a non effettuare ordini per tramite la
            stessa. L’utente è altresì tenuto a controllare periodicamente i Termini e Condizioni d’Uso in vigore al
            momento dell’effettuazione dell’ordine dei prodotti poiché Ordify ha facoltà di modificare le presenti
            Condizioni in qualsiasi momento. Gli utenti sono tenuti al rispetto delle regole in vigore al momento in cui
            effettuano un ordine tramite Ordify.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>3. ACCOUNT E STATUS DELL’UTENTE</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Prima di poter effettuare un ordine utilizzando l’applicazione, è necessario registrarsi tramite l’apertura
            di un account in Ordify. Nell’aprire un account, l’utente deve creare una password. Egli s’impegna a
            mantenere tale password segreta nonché ad impedire ad altri di accedere al proprio account, e-mail o
            telefono cellulare. Effettuando un ordine tramite l’applicazione l’utente garantisce:
          </span>
          <ul>
            <li>di aver compiuto gli anni 18;</li>
            <li>di avere la capacità di agire e di poter concludere contratti giuridicamente vincolanti.</li>
          </ul>
        </div>
        <div className={classes.newParagraph}>
          <span>
            L’utente si impegna, nel qual caso abbia una specifica allergia o intolleranza alimentare, a contattare
            direttamente l’attività. Questo al fine di verificare la conformità del prodotto alle proprie esigenze,
            prima di effettuare l’ordine direttamente all’attività stessa.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Bevande alcoliche: l’utente accetta e dichiara che costituisce reato acquistare, o tentare di acquistare,
            per i soggetti di età minore degli anni 18, bevande alcoliche, ovvero, per qualsiasi soggetto, anche di età
            superiore agli anni 18, acquistare, o tentare di acquistare, bevande alcoliche per conto di persona
            minorenne. Qualora l’ordine includa bevande alcoliche, all’utente potrà essere richiesto di fornire prova
            della propria età al ricevimento, alla consegna o al momento di somministrazione del suo ordine. Se l’utente
            non fosse in grado di fornire la prova della propria maggiore età, ovvero, laddove l’attività ritenesse
            ragionevolmente che le bevande alcoliche siano state acquistate dall’utente per conto di una persona minore
            degli anni 18, la stessa si può riservare il diritto di non completare la somministrazione, la consegna o il
            ritiro dell’ordine.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>4. MODALITA’ DI INVIO ED ELABORAZIONE DELL’ORDINE</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Dopo aver selezionato i prodotti che si intendono ordinare dal Menù messo a disposizione dell’attività
            sull’applicazione, l’utente dovrà effettuare l’accesso selezionando una tra le due opzioni
            {'<<'}ordina e paga subito{'>>'} e {'<<'}ordina e paga alla consegna{'>>'}. Si avrà quindi la possibilità di
            scegliere tra la modalità di pagamento istantanea tramite carta ovvero il pagamento alla consegna dei
            prodotti.{'\n'}
            L’utente si impegna a controllare tutte le informazioni relative ai prodotti immessi prima di inoltrare
            definitivamente l’ordine. {'\n'}
            Una volta effettuato l’ordine, questo dev’essere accettato dal gestore dell’attività. Il contratto di
            compravendita si intende perfezionato solo al ricevimento della comunicazione di conferma (tramite notifica
            da parte di Ordify).{'\n'} Se l’attività rifiuta l’ordine dei prodotti nessun contratto può dirsi concluso e
            l’utente riceverà istantaneamente il rimborso di quanto versato a titolo di pagamento del prezzo (nel caso
            in cui si fosse scelta la modalità di pagamento tramite carta) nonché la comunicazione di rigetto
            dell’ordine (tramite notifica da parte di Ordify). Fintanto che l’attività non ha prestato la propria
            conferma, ovvero il proprio diniego, l’utente può annullare l’ordine effettuato cliccando il pulsante {'<<'}
            annulla{'>>'}.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ove sia previsto, il servizio Prenota Tavolo consente all’utente di riservarsi un tavolo all’interno dei
            locali dell’attività che svolge somministrazione di cibo e bevande.{'\n'}
            Per usufruire del servizio l’utente deve selezionare, nell’apposito spazio {'<<'}Prenota Tavolo{'>>'}
            messo a disposizione dall’attività sull’applicazione, il giorno, l’ora e il numero di persone per le quali
            s’intende effettuare la prenotazione. Quest’ultima potrebbe essere subordinata al pagamento di una caparra
            da effettuarsi immediatamente tramite carta di debito/credito.{'\n'}
            Cliccando il pulsante {'<<'}continua{'>>'} viene presentato all’utente un riepilogo della prenotazione
            tavolo. L’utente si impegna a controllare la correttezza delle informazioni ivi presenti e, nel qual caso
            abbia una specifica allergia o intolleranza alimentare, a contattare direttamente l’attività prima di
            effettuare la prenotazione, non utilizzando lo spazio {'<<'}note all’attenzione dell’attività{'>>'}.{'\n'}
            Una volta completata la prenotazione tramite il pulsante {'<<'}conferma la prenotazione{'>>'}, questa
            dev’essere accettata dal gestore dell’attività. Il tavolo si considera riservato solo al ricevimento della
            comunicazione di conferma (tramite notifica da parte di Ordify).{'\n'}
            Fintanto che l’attività non ha prestato la propria conferma, ovvero il proprio diniego, l’utente può
            annullare la prenotazione effettuata cliccando il pulsante {'<<'}annulla{'>>'}.{'\n'}
            L’utente può usufruire del servizio Pronto in Tavola, ove questo sia messo a disposizione dall’attività.
            Selezionando l’opzione {'<<'}voglio usufruire del servizio pronto in tavola{'>>'} l’utente richiede la
            possibilità di ordinare tramite l’applicazione il cibo e le bevande che intende consumare nei locali
            dell’attività.{'\n'}
            Tale prenotazione richiede la compilazione, da parte dell’utente, dei campi obbligatori per il riserbo del
            tavolo nonché la selezione dei prodotti che intende consumare dal Menù messo a disposizione dell’attività
            sull’applicazione.{'\n'}
            Tornato alla sezione Prenota Tavolo, l’utente clicca il pulsante {'<<'}continua{'>>'} e il pulsante {'<<'}
            conferma e vai al pagamento{'>>'}. Si avrà quindi la possibilità di pagare con il metodo predefinito (nel
            caso si sia già effettuato un ordine tramite l’applicazione) ovvero di pagare con una nuova carta.{'\n'}
            Una volta effettuato l’ordine, questo dev’essere accettato dal gestore dell’attività. Il contratto si
            intende concluso solo al ricevimento della comunicazione di conferma (tramite notifica da parte di Ordify).
            Se l’attività rifiuta la prenotazione nessun contratto può dirsi concluso e l’utente riceverà
            istantaneamente il rimborso di quanto versato a titolo di pagamento del prezzo (nel caso in cui si fosse
            scelta la modalità di pagamento tramite carta) nonché la comunicazione di rigetto dell’ordine (tramite
            notifica da parte di Ordify).{'\n'}
            Fintanto che l’attività non ha prestato la propria conferma, ovvero il proprio diniego, l’utente può
            annullare la prenotazione effettuata cliccando il pulsante {'<<'}annulla{'>>'}.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify esorta le attività presenti sull’applicazione ad eseguire tutti gli ordini nonché a comunicare
            tempestivamente eventuali rifiuti di esecuzione degli stessi. In ogni caso, salvo quanto precedentemente
            previsto, Ordify non può escludere che le attività rifiutino di eseguire gli ordini in qualsiasi momento
            successivo all’avvenuta conferma per cause sopravvenute ed impreviste. In tali casi, qualsiasi pagamento già
            effettuato in relazione all’ordine sarà restituito all’utente.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Nel caso in cui vi sia la possibilità di scegliere l’opzione {'<<'}servizio a domicilio{'>>'}
            si precisa che quest’ultima è esclusivamente a carico dell’attività fornitrice dei prodotti.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>5. PREZZO E PAGAMENTO</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            I prezzi dei prodotti sono quelli indicati sull’applicazione, comprensivi di IVA.{'\n'}I prezzi non
            comprendono eventuali costi di consegna, che vengono esplicitati qualora si scelga la consegna a domicilio
            in luogo del ritiro e sono aggiunti all’importo totale dovuto.{'\n'}
            Ordify controlla tutti i prodotti, e relativi prezzi, immessi nel menù dalle attività che richiedono la
            registrazione presso l’applicazione. Tuttavia, è sempre possibile che alcune voci di menu possano recare una
            indicazione inesatta del prezzo. In tal caso, né Ordify né l’attività interessata hanno alcun obbligo di
            assicurare che l’ordine venga concluso con l’utente all’eventuale prezzo inferiore erroneamente indicato né
            di rimborsare all’utente la differenza rispetto al prezzo errato. Nel caso di eccessivo squilibrio tra il
            prezzo originariamente previsto e quello effettivo del prodotto, ove l’utente sia un consumatore, ai sensi
            del Codice del Consumo avrà facoltà di recedere dall’ordine.{'\n'}
            Il pagamento degli ordini può essere effettuato al momento della conclusione dell’ordine tramite l’utilizzo
            di carta di debito/credito oppure direttamente all’attività, in contanti o tramite altro mezzo di pagamento,
            al momento della consegna o del ritiro.{'\n'}
            Se l’utente sceglie la modalità immediata con carta di debito/credito, dopo aver concluso la scelta
            dell’ordine, egli deve cliccare sul pulsante {'<<'}ordina e paga{'>>'}. Se non si è mai effettuato un
            pagamento l’utente deve inserire un metodo di pagamento tramite il pulsante {'<<'}paga con carta{'>>'}. Si
            apre, quindi, la webview della pagina di inserimento dei dati della carta del gateway di pagamento
            stripe.com.{'\n'}
            Se hai già effettuato un pagamento online si ha la possibilità di scegliere tra le opzioni
            {'<<'}paga con il metodo di pagamento predefinito{'>>'} (scegliendo nel caso di più opzioni predefinite)
            ovvero {'<<'}paga con una nuova carta{'>>'}.{'\n'}
            Il totale della somma versata è comprensivo delle Commissioni di Servizio applicate da Ordify in proporzione
            al prezzo dei prodotti ordinati.{'\n'}
            Il pagamento degli ordini effettuati tramite il servizio Pronto in Tavola deve essere effettuato al momento
            della conclusione dell’ordine tramite l’utilizzo di carta di debito/credito.{'\n'}
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            In caso di mancata esecuzione, annullamento degli ordini da parte dell’utente o rifiuto di prendere in
            carica l’ordine da parte dell’attività, l’utente ha diritto ad ottenere il rimborso di quanto versato a
            titolo di pagamento del prezzo. Tuttavia, allorché si tratti di mancata esecuzione successiva alla conferma
            dell’attività, l’operazione di rimborso potrebbe richiedere anche qualche giorno lavorativo.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>6. SERVIZIO CLIENTI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify mette a disposizione dell’utente una linea diretta per comunicare col nostro team. L’utente può
            entrare in contatto con i gestori dell’applicazione selezionando il pulsante {'<<'}contatta il supporto
            {'>>'}, inviandoci direttamente un messaggio o scrivendo una e-mail all’indirizzo indicato.{'\n'}
            Ordify considera il servizio clienti estremamente importante ed invita l’utente, qualora non sia soddisfatto
            della qualità di un prodotto o del servizio di un’attività ovvero dell’applicazione, a rivolgersi al team di
            Ordify con le modalità sopra descritte.{'\n'}
            Si fa, in ogni caso, presente, che il contratto di fornitura e acquisto di prodotti intercorre tra l’utente
            e l’attività alla quale Ordify inoltra l’ordine. Ordify non ha alcun controllo sulle attività o sulla
            qualità dei prodotti ovvero, ancora, sul servizio fornito dalle attività stesse e non è in grado di assumere
            alcuna responsabilità, o impegno, di fornire alcun indennizzo o risarcimento in favore dell’utente per conto
            di qualsivoglia attività.{'\n'}
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>7. LICENZA D’USO</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            È consentito all’utente di utilizzare l’applicazione e scaricare contenuti dalla stessa per uso personale
            non avente finalità commerciali.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>8. ACCESSO ALL’APPLICAZIONE</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify si adopera affinchè l’accesso all’applicazione possa avvenire, normalmente, ventiquattro ore su
            ventiquattro. Ordify non assume, tuttavia, alcun obbligo a riguardo e non è responsabile nei confronti
            dell’utente nel caso in cui l’applicazione, in qualsiasi momento o per periodi di qualsiasi durata, non sia
            disponibile.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            L’accesso all’applicazione può essere temporaneamente sospeso in qualsiasi momento, anche senza preavviso,
            in caso di manutenzione e/o per motivi che sono fuori dal controllo di Ordify. Ordify e l’utente possono
            recedere dalle presenti condizioni generali di contratto con un preavviso di due settimane da comunicarsi
            via e-mail. In caso di recesso, Ordify provvederà a cancellare l’account dell’utente, fatti salvi gli
            obblighi di legge.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>9. LINK VERSO E DA ALTRI SITI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Eventuali link verso siti di soggetti terzi presenti sull’applicazione vengono forniti esclusivamente per
            comodità dei visitatori e degli utenti. L’utilizzo di tali link comporta l’abbandono dell’applicazione.
            Ordify non ha esaminato i suddetti siti di soggetti terzi e non ha alcun controllo su di essi o sul relativo
            contenuto o accessibilità . Ordify non rilascia garanzia alcuna in relazione ai suddetti siti, al materiale
            in essi presente o ai risultati derivanti dall’utilizzo dei medesimi.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>10. ESONERO DA RESPONSABILITA’</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify si impegna a verificare che l’informazione fornita sull’applicazione sia corretta, tuttavia, non può
            garantire che essa sia sempre accurata o completa, essendo la maggior parte dei dati inseriti dalle attività
            stesse. Ordify può apportare qualsivoglia modifica al materiale presente sull’applicazione o al
            funzionamento della stessa. L’informazione presente sull’applicazione (voci di menu, contatti delle
            attività, fornitura di prodotti ecc) potrebbe, tuttavia, non essere aggiornata e Ordify non assume alcun
            impegno di aggiornarla in luogo dell’attività a cui il materiale si riferisce. Sull’applicazione vengono
            fornite informazioni aggiornate sul menù dell’attività, ivi comprese quelle relative alle allergie e quelle
            di carattere dietetico. Si esorta l’utente, nel caso di dubbi relativi ad allergie alimentari, intolleranze
            o altre preferenze alimentari, a contattare sempre e direttamente l’attività prescelta prima di effettuare
            l’ordine. L’utente non può utilizzare il campo {'<<'}note all’attenzione dell’attività{'>>'} per comunicare
            allergie o intolleranza alimentari.{'\n'} Il contratto di compravendita di prodotti intercorre tra l’utente
            e l’attività. Ordify non ha alcun controllo sulle azioni o omissioni di tali attività. Fatto salvo il dolo e
            colpa grave di Ordify, con l’utilizzo dell’applicazione l’utente accetta quanto segue:
          </span>
        </div>
        <div className={classes.alignmentLeft}>
          <ol>
            <li>
              Ordify non assume alcun impegno di garantire che la qualità dei prodotti ordinati risulti soddisfacente o
              che i prodotti siano conformi ai fini dell’utente ed espressamente esclude ogni garanzia di questo tipo.
            </li>
            <li>
              I tempi stimati di ritiro presso il punto vendita o di consegna presso il domicilio dell’utente sono
              esclusivamente indicativi. Non sussiste alcuna garanzia da parte di Ordify riguardo al fatto che gli
              ordini vengano consegnati o messi a disposizione per essere ritirati entro i tempi indicati.
            </li>
            <li>
              Ordify non garantisce che le attività eseguano tutti gli ordini e non può escludere che le attività stesse
              rifiutino un ordine, anche dopo l’avvenuta conferma dello stesso, per cause sopravvenute ed impreviste
              ovvero se l’utente non fornisce prova della maggiore età per l’acquisto di bevande alcoliche.
            </li>
            <li>
              {' '}
              La consegna a domicilio, ove prevista, è esclusivamente a carico dell’attività e Ordify non assume alcun
              impegno circa la prestazione della stessa; rimane, pertanto, esclusa qualsivoglia responsabilità per la
              perdita, la sottrazione o il danneggiamento dei prodotti.
            </li>
            <li>
              {' '}
              Le precedenti clausole di esonero da responsabilità lasciano impregiudicati i diritti inderogabili degli
              utenti previsti dalla legge e/o dal contratto di vendita con le attività nonché dalla normativa del
              D.lgs.206/2005 a tutela dei consumatori.
            </li>
          </ol>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify fornisce all’utente accesso all’applicazione e ne consente l’utilizzo sul presupposto che, nella
            misura massima consentita della legge, resti esclusa ogni e qualsiasi garanzia, dichiarazione, condizione,
            impegno ed ogni altra clausola in relazione all’applicazione e all’utilizzo della stessa da parte
            dell’utente.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Fatta salva la normativa applicabile a tutela dei consumatori, Ordify non risponde nei confronti degli
            utenti in nessun caso, né a titolo contrattuale né extracontrattuale, anche se si tratta di circostanza
            prevedibile, derivante da, o in connessione all’utilizzo dell’applicazione, in relazione ad eventuali
            perdite o danni subiti dagli utenti stessi (perdita di profitto, perdita di opportunità commerciali, perdita
            o distruzione di dati, informazio ni o software, perdita di risparmi previsti, perdita indiretta o
            conseguente ecc).
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>11. RISOLUZIONE</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify ha facoltà di risolvere il rapporto contrattuale con l’utente, ex art.1456 cod. civ., e sospendere il
            diritto dello stesso di avvalersi dell’applicazione, immediatamente, mediate comunicazione scritta, nel caso
            in cui ritenga, a suo esclusivo giudizio:
          </span>
        </div>
        <div className={classes.alignmentLeft}>
          <ol>
            <li> che l’utente abbia utilizzato l’applicazione in violazione dell’articolo 7 (Licenza d’uso);</li>
            <li>
              {' '}
              che l’utente abbia omesso di effettuare il pagamento di un ordine i cui prodotti siano stati consegnati
              dall’attività o abbia violato qualsiasi altra previsione essenziale del presente documento di condizioni
              generali di contratto.
            </li>
            <li> qualcosa sul comportamento scorretto</li>
          </ol>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>12. INFORMATIVA SUI DATI PERSONALI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify si obbliga a proteggere la riservatezza e la sicurezza degli utenti. Tutti i dati degli utenti
            raccolti da Ordify vengono elaborati in conformità alla informativa di Ordify in materia di Privacy.
            L’utente è tenuto ad esaminare l’informativa in materia di Privacy, la quale costituisce parte integrante
            delle condizioni generali di contratto ed è disponibile al seguente link.{' '}
            <a onClick={() => navigate(PRIVACY)}>http://ordify.it/privacy</a>
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>13. LEGGE APPLICABILE E FORO COMPETENTE</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Tutti i reclami e le controversie che dovessero sorgere in relazione o ai sensi de i presenti Termini e
            Condizioni d’uso saranno regolati e interpretati ai sensi della legge italiana. Per i consumatori residenti
            in Italia troverà applicazione la normativa a tutela del consumatore prevista dal D.lgs. 6 settembre 2005,
            n.206 (Codice del Consumo). Qualsiasi controversia derivante dai presenti Termini e Condizioni d’uso,
            riguardante utenti non consumatori, sarà devoluta alla competenza esclusiva del foro di Mantova. In caso di
            controversia derivante dai presenti Termini e Condizioni d’uso riguardante utenti consumatori, quest’ultimi
            potranno adire i tribunali del foro di Mantova ovvero del luogo dove hanno la loro residenza o il loro
            domicilio. Ordify e le attività potranno instaurare una causa nei confronti dell’utente consumatore
            residente in Italia solamente presso il foro di residenza o domicilio dello stesso. In caso di reclamo o
            controversia, l’utente è incoraggiato a contattare in primo luogo il servizio clienti al fine di trovare una
            soluzione stragiudiziale.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            L’utente, ai sensi degli artt. 1341 e 1342 cod. civ., con la registrazione del proprio account, dichiara di
            approvare espressamente i seguenti articoli delle presenti condizioni generali di contratto: 4; 5; 6; 8; 10;
            11; 13.
          </span>
        </div>
      </div>
    </PageContainer>
  );
}
