import {
  AttachMoney,
  Call,
  DirectionsBike,
  Event,
  EventSeat,
  Home,
  LocalDining,
  LocationCity,
  MoneyOff,
  People,
  Place,
  Restaurant,
  RoomService,
  Schedule,
} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import CustomAccordion from '../../../components/atoms/CustomAccordion';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import CustomIcon from '../../../components/atoms/CustomIcon';
import CustomLabelText from '../../../components/atoms/CustomLabelText';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomListItem from '../../../components/molecules/CustomListItem';
import {Order, OrderStatus, OrderType} from '../../../models/orders/order';
import {OrderTrackingStep} from '../../../models/orders/step';
import {primaryColor, WHITE} from '../../../styles/colors';
import {Spacing} from '../../../styles/spacing';
import {getOrderTotalPrice} from '../../../utils/commonFunctions';
import {getLocationInfo} from '../../../utils/getLocation';
import OrderCartItemElement from '../../ActivityDetail/components/OrderSummary/OrderCartItemElement';

const OrderCardElement = ({order, onCancelPress}: Props) => {
  const classes = useStyle();

  const getColor = () => {
    if ([OrderStatus.APPROVED, OrderStatus.COMPLETED].includes(order.status)) {
      return 'primary';
    }
    return 'black';
  };

  const getLabelPrice = () => {
    if (order.paymentID) {
      if (
        order.type === OrderType.TABLE_RESERVATION &&
        order.tableReservationInfo.useDeposit &&
        !order.tableReservationInfo.useProntoInTavola
      ) {
        return 'Pagata caparra';
      }
      return 'Pagato online';
    } else {
      if (order.type === OrderType.TABLE_RESERVATION) {
        return 'Pagamento in cassa';
      }
      return 'Pagamento alla consegna';
    }
  };

  const getIcon = () => {
    switch (order.type) {
      case OrderType.TAKEAWAY:
        return RoomService;
      case OrderType.HOME_SERVICE:
        return DirectionsBike;
      case OrderType.TABLE_RESERVATION: {
        if (order.tableReservationInfo.useProntoInTavola) {
          return EventSeat;
        } else {
          return Restaurant;
        }
      }
    }
    return RoomService;
  };

  const getStatusDesc = () => {
    const mapStatusDesc = {
      [OrderStatus.PENDING]: {
        [OrderType.TAKEAWAY]: "Ordine in carico all'attività",
        [OrderType.HOME_SERVICE]: "Ordine in carico all'attività",
        [OrderType.TABLE_RESERVATION]: "Prenotazione in carico all'attività",
      },
      [OrderStatus.APPROVED]: {
        [OrderType.TAKEAWAY]: 'In lavorazione',
        [OrderType.HOME_SERVICE]: 'In lavorazione',
        [OrderType.TABLE_RESERVATION]: 'Prenotazione confermata',
      },
      [OrderStatus.REFUSED]: {
        [OrderType.TAKEAWAY]: 'Ordine rifiutato',
        [OrderType.HOME_SERVICE]: 'Ordine rifiutato',
        [OrderType.TABLE_RESERVATION]: 'Prenotazione rifiutata',
      },
      [OrderStatus.CANCELED]: {
        [OrderType.TAKEAWAY]: 'Ordine annullato',
        [OrderType.HOME_SERVICE]: 'Ordine annullato',
        [OrderType.TABLE_RESERVATION]: 'Prenotazione annullata',
      },
      [OrderStatus.COMPLETED]: {
        [OrderType.TAKEAWAY]: 'Ordine completato',
        [OrderType.HOME_SERVICE]: 'Ordine completato',
        [OrderType.TABLE_RESERVATION]: 'Prenotazione completata',
      },
    } as any;
    return mapStatusDesc[order.status]?.[order.type] || '';
  };

  const Header = () => (
    <GridContainer className={classes.accordion}>
      <GridItem justifyContent={'center'} md={2}>
        <CustomIcon icon={getIcon()} color={getColor()} />
      </GridItem>
      <GridItem justifyContent={'center'} md={8}>
        <CustomText bold color={getColor()}>
          #{order.orderID}
        </CustomText>
      </GridItem>
    </GridContainer>
  );

  const SectionPrimaryInfo = () => {
    switch (order.type) {
      case OrderType.TAKEAWAY:
        return (
          <GridContainer marginTop>
            <GridItem xs={12} sm={12}>
              <CustomLabelText icon={Event} text={moment(order.takeawayInfo.day).format('ddd, DD/MM/YYYY')} />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomLabelText marginTop icon={Schedule} text={order.takeawayInfo.hour} />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomLabelText marginTop icon={LocalDining} noWrap text={order.activityInfo.activityName} />
            </GridItem>
            <GridItem sm={6}>
              <CustomLabelText marginTop icon={LocationCity} noWrap text={order.activityInfo.location.address} />
            </GridItem>
            <GridItem sm={6}>
              <CustomLabelText
                marginTop
                boldText
                noWrap
                icon={order.paymentID ? AttachMoney : MoneyOff}
                element={
                  <>
                    <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                    <CustomText>{getOrderTotalPrice(order)} €</CustomText>
                  </>
                }
              />
            </GridItem>
          </GridContainer>
        );
      case OrderType.HOME_SERVICE:
        return (
          <>
            <GridContainer marginTop>
              <GridItem xs={12} sm={6}>
                <CustomLabelText icon={Event} text={moment(order.homeServiceInfo.day).format('ddd, DD/MM/YYYY')} />
                <CustomLabelText marginTop icon={Schedule} text={order.homeServiceInfo.hour} />
                <CustomLabelText
                  marginTop
                  noWrap
                  icon={Place}
                  text={getLocationInfo(order.homeServiceInfo.locationInfo)}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomLabelText marginTop noWrap icon={Home} text={order.activityInfo.activityName} />
                <CustomLabelText
                  marginTop
                  boldText
                  noWrap
                  icon={order.paymentID ? AttachMoney : MoneyOff}
                  element={
                    <>
                      <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                      <CustomText>{getOrderTotalPrice(order)} €</CustomText>
                    </>
                  }
                />
              </GridItem>
            </GridContainer>
          </>
        );
      case OrderType.TABLE_RESERVATION:
        return (
          <>
            <GridContainer marginTop>
              <GridItem xs={12} sm={6}>
                <CustomLabelText icon={Event} text={moment(order.tableReservationInfo.day).format('ddd, DD/MM/YYYY')} />
                <CustomLabelText marginTop icon={Schedule} text={order.tableReservationInfo.hour} />
                <CustomLabelText marginTop icon={People} text={order.tableReservationInfo.numberOfPersons} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomLabelText marginTop noWrap icon={Home} text={order.activityInfo.activityName} />
                {order.paymentID && (
                  <CustomLabelText
                    marginTop
                    boldText
                    noWrap
                    icon={order.paymentID ? AttachMoney : MoneyOff}
                    element={
                      <>
                        <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                        {order.paymentID && getOrderTotalPrice(order) > 0 && (
                          <CustomText style={{paddingRight: 6}}>
                            {getOrderTotalPrice(order)}€
                            {!order.tableReservationInfo.useProntoInTavola &&
                              ` (${getOrderTotalPrice(order) / order.tableReservationInfo.numberOfPersons}€ a persona)`}
                          </CustomText>
                        )}
                      </>
                    }
                  />
                )}
              </GridItem>
            </GridContainer>
          </>
        );
    }
  };

  const OrderPrimaryInfo = () => {
    switch (order.type) {
      case OrderType.TAKEAWAY:
        return (
          <>
            <GridContainer marginTop>
              <GridItem xs={12} sm={6}>
                <CustomLabelText icon={Event} text={moment(order.takeawayInfo.day).format('ddd, DD/MM/YYYY')} />
                <CustomLabelText marginTop icon={Schedule} text={order.takeawayInfo.hour} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomLabelText marginTop icon={LocalDining} noWrap text={order.activityInfo.activityName} />
                <CustomLabelText
                  marginTop
                  boldText
                  noWrap
                  icon={order.paymentID ? AttachMoney : MoneyOff}
                  element={
                    <>
                      <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                      <CustomText>{getOrderTotalPrice(order)} €</CustomText>
                    </>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <GridItem noPadding sm={6}>
                  <CustomLabelText marginTop icon={LocationCity} noWrap text={order.activityInfo.location.address} />
                </GridItem>
                <GridItem sm={6}>
                  <CustomLabelText
                    marginTop
                    boldText
                    noWrap
                    icon={order.paymentID ? AttachMoney : MoneyOff}
                    element={
                      <>
                        <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                        <CustomText>{getOrderTotalPrice(order)} €</CustomText>
                      </>
                    }
                  />
                </GridItem>
              </GridItem>
            </GridContainer>
          </>
        );
      case OrderType.HOME_SERVICE:
        return (
          <>
            <GridContainer marginTop>
              <GridItem xs={12} sm={6}>
                <CustomLabelText icon={Event} text={moment(order.homeServiceInfo.day).format('ddd, DD/MM/YYYY')} />
                <CustomLabelText marginTop icon={Schedule} text={order.homeServiceInfo.hour} />
                <CustomLabelText
                  marginTop
                  noWrap
                  icon={Place}
                  text={getLocationInfo(order.homeServiceInfo.locationInfo)}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomLabelText marginTop noWrap icon={Home} text={order.activityInfo.activityName} />
                <CustomLabelText
                  marginTop
                  boldText
                  noWrap
                  icon={order.paymentID ? AttachMoney : MoneyOff}
                  element={
                    <>
                      <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                      <CustomText>{getOrderTotalPrice(order)} €</CustomText>
                    </>
                  }
                />
              </GridItem>
            </GridContainer>
          </>
        );
      case OrderType.TABLE_RESERVATION:
        return (
          <>
            <GridContainer marginTop>
              <GridItem xs={12} sm={6}>
                <CustomLabelText icon={Event} text={moment(order.tableReservationInfo.day).format('ddd, DD/MM/YYYY')} />
                <CustomLabelText marginTop icon={Schedule} text={order.tableReservationInfo.hour} />
                <CustomLabelText marginTop icon={People} text={order.tableReservationInfo.numberOfPersons} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomLabelText marginTop noWrap icon={Home} text={order.activityInfo.activityName} />
                {order.paymentID && (
                  <CustomLabelText
                    marginTop
                    boldText
                    noWrap
                    icon={order.paymentID ? AttachMoney : MoneyOff}
                    element={
                      <>
                        <CustomText style={{paddingRight: 6}}>{getLabelPrice()}</CustomText>
                        {order.paymentID && getOrderTotalPrice(order) > 0 && (
                          <CustomText style={{paddingRight: 6}}>
                            {getOrderTotalPrice(order)}€
                            {!order.tableReservationInfo.useProntoInTavola &&
                              ` (${getOrderTotalPrice(order) / order.tableReservationInfo.numberOfPersons}€ a persona)`}
                          </CustomText>
                        )}
                      </>
                    }
                  />
                )}
              </GridItem>
            </GridContainer>
          </>
        );
    }
  };

  const TipoOrdine = () => (
    <>
      {order.type === OrderType.TAKEAWAY && (
        <CustomRow center marginTop>
          <CustomText bold>RITIRO DA ASPORTO</CustomText>
        </CustomRow>
      )}
      {order.type === OrderType.HOME_SERVICE && (
        <CustomRow center marginTop>
          <CustomText bold>CONSEGNA A DOMICILIO</CustomText>
        </CustomRow>
      )}
      {order.type === OrderType.TABLE_RESERVATION && !order.tableReservationInfo.useProntoInTavola && (
        <CustomRow center marginTop>
          <CustomText bold>PRENOTAZIONE TAVOLO</CustomText>
        </CustomRow>
      )}
      {order.type === OrderType.TABLE_RESERVATION && order.tableReservationInfo.useProntoInTavola && (
        <CustomRow center marginTop>
          <CustomText bold>PRONTO IN TAVOLA</CustomText>
        </CustomRow>
      )}
    </>
  );

  const RejectionMessage = () => {
    const message = order.tracking.find((o) => o.step === OrderTrackingStep.REFUSED)?.message;
    return message ? (
      <>
        <CustomRow center marginTop>
          <CustomText center color={'primary'}>
            Motivo:{' '}
          </CustomText>
          <CustomText center>{message}</CustomText>
        </CustomRow>
      </>
    ) : (
      <></>
    );
  };

  const StatoOrdine = () => {
    const statusDesc = getStatusDesc();
    switch (order.status) {
      case OrderStatus.PENDING:
        return (
          <>
            <CustomRow center marginTop>
              <CustomText bold>{statusDesc}</CustomText>
            </CustomRow>
            <CustomRow center>
              <CustomButton
                className={classes.completeBtn}
                title={'Annulla'}
                color={'primary'}
                onClick={onCancelPress}
              />
            </CustomRow>
          </>
        );
      case OrderStatus.APPROVED:
      case OrderStatus.COMPLETED:
      case OrderStatus.CANCELED:
        return (
          <>
            <CustomRow center>
              <CustomText bold color={getColor()}>
                {statusDesc}
              </CustomText>
            </CustomRow>
          </>
        );
      case OrderStatus.REFUSED:
        return (
          <>
            <CustomRow center>
              <CustomText bold color={getColor()}>
                {statusDesc}
              </CustomText>
            </CustomRow>
            <RejectionMessage />
          </>
        );
    }
    return <></>;
  };

  const SubHeader = () => (
    <div className={classes.subHeader}>
      <CustomRow>
        <div style={{flex: 1}}>
          <OrderPrimaryInfo />
        </div>
      </CustomRow>
      <TipoOrdine />
      <StatoOrdine />
    </div>
  );

  const CollapsedContent = () => (
    <div className={classes.collapsedContent}>
      {order.activityInfo.phoneNumber && (
        <CustomListItem divider={false} onClick={() => window.open(`tel:${order.activityInfo.phoneNumber}`)}>
          <CustomLabelText marginVertical icon={Call} boldText underlineText text={order.activityInfo.phoneNumber} />
        </CustomListItem>
      )}
      {order.orderCartItems.map((elem, i) => (
        <CustomListItem key={i} noPaddingVertical>
          <OrderCartItemElement orderCartItem={elem} />
        </CustomListItem>
      ))}
      {!!order.note && order.note.trim() && (
        <CustomLabelText
          marginTop
          labelOnTop
          labelBold
          label={"Richieste all'attenzione dell'attività"}
          element={<CustomText>{order.note}</CustomText>}
        />
      )}
      {!!order.activityServiceAmount && (
        <CustomLabelText
          marginTop
          labelOnTop
          labelBold
          label={"Costo del servizio dell'attività"}
          element={<CustomText>{order.activityServiceAmount} €</CustomText>}
        />
      )}
      {!!order.fee && (
        <CustomLabelText
          marginTop
          labelOnTop
          labelBold
          label={'Commissioni'}
          element={<CustomText>{order.fee} €</CustomText>}
        />
      )}
      {!!order.couponDiscountAmount && (
        <CustomLabelText
          marginTop
          labelOnTop
          labelBold
          label={'Coupon: ' + order.couponCode}
          element={<CustomText>- {order.couponDiscountAmount} €</CustomText>}
        />
      )}
    </div>
  );

  // header={<Header />} subHeader={<SubHeader />

  return (
    <>
      <CustomAccordion className={classes.accordion} header={<Header />}>
        <GridContainer>
          <GridItem md={12}>
            <SectionPrimaryInfo />
          </GridItem>
          <GridItem md={12}>
            <StatoOrdine />
          </GridItem>
        </GridContainer>
      </CustomAccordion>
    </>
  );
};

type Props = {
  order: Order;
  onHeaderPress?: any;
  onCancelPress?: any;
};

const useStyle = makeStyles({
  container: {
    backgroundColor: WHITE,
    borderRadius: '40px !important',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 4,
    padding: 10,
    marginBottom: '16px',
  },
  containerApproved: {
    borderWidth: 2,
    borderColor: primaryColor,
  },
  subHeader: {
    paddingVertical: 10,
  },
  operationBtn: {
    width: 100,
    marginVertical: 5,
  },
  completeBtn: {
    width: 200,
  },
  collapsedContent: {
    paddingVertical: 10,
  },
  orderPrimaryInfoLeft: {
    marginRight: Spacing.SCALE_16,
    flex: 1,
    flexDirection: 'column',
  },
  orderPrimaryInfoRight: {
    marginLeft: Spacing.SCALE_16,
    // marginRight: Spacing.SCALE_26,
    flex: 1,
    flexDirection: 'column',
  },
  accordion: {
    marginBottom: '20px',
    borderRadius: '40px !important',
  },
});

export default OrderCardElement;
