import React from 'react';
import CustomListItem from './CustomListItem';
import CustomText from '../atoms/Typography/CustomText';
import {makeStyles} from '@material-ui/styles';
import {CustomerPaymentMethod} from '../../models/user/payment-info';
import GridContainer from '../atoms/Grid/GridContainer';
import GridItem from '../atoms/Grid/GridItem';

const PaymentMethod = ({onClick, paymentMethod}: Props) => {
  const classes = useStyle();
  return (
    <>
      <CustomListItem padding={'sm'} onClick={onClick}>
        <GridContainer className={classes.defaultPayment}>
          <GridItem xs={4} sm={3}>
            <CustomText bold>{paymentMethod.card.brand}</CustomText>
          </GridItem>
          <GridItem xs={8} sm={3} className={classes.cartNumber}>
            <CustomText bold>•••• {paymentMethod.card.last4}</CustomText>
          </GridItem>
          <GridItem xs={12} sm={6} className={classes.cardExp}>
            <CustomText>
              Scadenza: {paymentMethod.card.expMonth} / {paymentMethod.card.expYear}
            </CustomText>
          </GridItem>
        </GridContainer>
      </CustomListItem>
    </>
  );
};

type Props = {
  paymentMethod: CustomerPaymentMethod;
  onClick?: any;
};

const useStyle = makeStyles({
  defaultPayment: {},
  cartNumber: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      justifyContent: 'end',
    },
  },
  cardExp: {
    display: 'flex',
    justifyContent: 'end',
    '@media (max-width: 767px)': {
      justifyContent: 'start',
    },
  },
});

export default PaymentMethod;
