import {getFoods} from '../../../api/globalConfigService';
import {Food} from '../../../models/globalConfig/food';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {disableLoader, enableLoader, loadFoodsSuccess} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';

export default function* loadFoodsAsync() {
  let foods: Food[] = yield select((state: State) => state.global.lists.foods);
  if (foods && foods.length === 0) {
    yield put(enableLoader());
    const response: CustomResponse<Food[]> = yield getFoods();
    if (response.status === Status.OK) {
      yield put(loadFoodsSuccess(response.payload));
    }
    yield put(disableLoader());
  }
}
