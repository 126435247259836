import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import privacyPageStyle from './style';
import PageContainer from '../../components/organisms/PageContainer';
import CustomText from '../../components/atoms/Typography/CustomText';
import CustomRow from '../../components/atoms/CustomRow';

const useStyles = makeStyles(privacyPageStyle);

export default function PrivacyPage({}: any) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <PageContainer pageTitle={'Privacy Policy'} showModalDownloadApp>
      <div className={classes.container}>
        <CustomRow center>
          <CustomText bold>POLITICA SULLA PRIVACY</CustomText>
        </CustomRow>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>1. QUALI INFORMAZIONI RACCOGLIAMO</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify raccoglie le tue informazioni personali quando utilizzi la nostra applicazione per effettuare ordini
            e quando interagisci con noi tramite i nostri servizi. Vengono raccolte informazioni:
          </span>
        </div>
        <div className={classes.alignmentLeft}>
          <ul>
            <li> quando crei o modifichi le impostazioni del tuo account;</li>
            <li> quando effettui un ordine tramite l’applicazione;</li>
            <li> quando ci contatti direttamente tramite e-mail o supporto;</li>
            <li> quando utilizzi l’applicazione (sia anche senza account).</li>
          </ul>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify ti richiede di fornire informazioni che ti riguardano, incluso il tuo nominativo, i tuoi dettagli di
            contatto, l’indirizzo di consegna (se questa viene messa a disposizione dall’attività), i dettagli
            dell’ordine che intendi effettuare, le informazioni relative al pagamento e relative alla posizione
            geografica in base alle quale calcolare il raggio.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>2. UTILIZZO DELLE INFORMAZIONI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Il trattamento dei tuoi dati personali può sussistere solamente ove vi sia un motivo consentito dalla
            legislazione in materia di protezione dei dati. In particolare, saremo legittimati al trattamento delle
            informazioni raccolte:
          </span>
        </div>
        <div className={classes.alignmentLeft}>
          <ol>
            <li>
              al fine di consentire l’esecuzione del contratto (a tale scopo le informazioni saranno condivise con le
              attività presso le quali hai effettuato un ordine);
            </li>
            <li>se abbiamo il tuo consenso;</li>
            <li>se vi è da parte nostra un legittimo interesse al trattamento dei dati;</li>
            <li>in presenza di un obbligo giuridico.</li>
          </ol>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Al fine di fornirti il servizio richiesto o stipulare un contratto, utilizzeremo le tue informazioni per:
          </span>
        </div>
        <div className={classes.alignmentLeft}>
          <ul>
            <li>consentirti l’accesso a particolari sezioni dell’applicazione;</li>
            <li>eseguire la prestazione oggetto di contratto;</li>
            <li>riscuotere i pagamenti da te effettuati;</li>
            <li>contattarti ove necessario in relazione ai nostri servizi</li>
          </ul>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Trattiamo i tuoi dati anche laddove vi sia una legittima ragione per farlo. Ad esempio, per personalizzare i
            nostri servizi; per migliorare l’efficacia e la qualità del servizio; per analizzare le tue attività
            sull’applicazione così da poter sviluppare il nostro servizio; per scopi statistici e di analisi.
            Analizzeremo anche i dati sull’utilizzo della tua posizione geografica per adattare il contenuto che
            l’applicazione ti sottopone (ad esempio per mostrarti le attività che si trovano nel tuo raggio).
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Potremo, altresì, utilizzare le tue informazioni per adempiere a qualunque obbligo di legge o requisito
            normativo al quale siamo soggetti.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>3. SICUREZZA</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify considera di grande importanza la tenuta in sicurezza delle tue informazioni personali. Adottiamo,
            infatti, misure tecniche e organizzative appropriate per proteggere i dati dall’accesso non autorizzato e da
            trattamenti illeciti, perdita accidentale , distruzione e danni. Nel momento in cui crei un account su
            Ordify ti chiediamo di creare una password, sei responsabile di tenerla riservata. Ti consigliamo di non
            condividere la password con nessuno e di utilizzare una password unica per la nostra applicazione. Non
            saremo responsabili per transazioni non autorizzate effettuate usando il tuo nome e la tua password.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>4. CONSERVAZIONE DELLE INFORMAZIONI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            I dati raccolti saranno conservati per il tempo necessario a soddisfare le finalità descritte nella sezione
            “utilizzo delle informazioni”, in linea con il nostro legittimo interesse al trattamento o per un periodo
            specificamente richiesto dai regolamenti o dalle leggi applicabili, come la conservazione delle informazioni
            a fini di segnalazione regolamentare. Diversamente, cancelleremo in modo sicuro le tue informazioni laddove
            non avessimo più necessità delle stesse per gli scopi esplicitati.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>5. I TUOI DIRITTI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Ordify rispetta i diritti sulla riservatezza e la protezione dei dati. In base alla normativa vigente in
            materia di protezione dei dati, hai una serie di diritti relativi ai tuoi dati in nostro possesso. Ti
            esortiamo, se desideri esercitare uno di questi diritti, a contattarci utilizzando i riferimenti sopra
            indicati. Hai il diritto di ricevere informazioni chiare, trasparenti e facilmente comprensibili circa le
            modalità di utilizzo delle tue informazioni e i tuoi diritti. Hai il diritto di ottenere l’accesso al
            trattamento delle tue informazioni per verificare che le stiamo utilizzando in conformità della legislazione
            in materia di protezione dei dati. Se desideri accedere alle informazioni che deteniamo su di te puoi
            metterti in contatto con noi. Hai il diritto di pretendere la correzione delle tue informazioni qualora
            queste fossero imprecise o incomplete. Puoi chiederci di correggere eventuali errori nelle informazioni che
            conserviamo contattandoci. Hai diritto all’oblio, vale a dire di richiedere la cancellazione o la rimozione
            di alcune informazioni che ti riguardano, contattandoci (tale diritto non è assoluto). Hai il diritto alla
            limitazione del trattamento, “bloccando” un ulteriore utilizzo delle tue informazioni. Quando il trattamento
            è limitato possiamo ancora conservare le tue informazioni, ma non utilizzarle ulteriormente. Hai diritto di
            presentare un reclamo circa le modalità di gestione e trattamento delle tue informazioni dinanzi
            all’autorità competente. Se hai fornito il tuo consenso per determinati trattamenti di informazioni, hai il
            diritto di revocare tale consenso in qualsiasi momento, contattandoci. Rimane comunque legittimo l’utilizzo,
            da parte nostra, delle tue informazioni per il periodo di tempo precedente alla revoca. Hai il diritto di
            opporti a determinati tipi di trattamento.
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>6. MODIFICHE ALLA NOSTRA PRIVACY POLICY</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Qualsiasi modifica a suddetta informativa sarà pubblicata sull’applicazione e, ove si reputi opportuno,
            tramite un avviso mediante notifiche o e-mail. La presente informativa sulla privacy è aggiornata al
            30.11.2020
          </span>
        </div>
        <div className={classes.newParagraph}>
          <span className={classes.sectionTitle}>7. RECLAMI</span>
        </div>
        <div className={classes.newParagraph}>
          <span>
            Se hai domande o preoccupazioni in merito alla presente Privacy Policy, puoi contattarci tramite e-mail
            all’indirizzo <b>support@ordify.it</b>. Se non ti ritieni soddisfatto della nostra risposta ad un tuo
            reclamo ovvero ritieni che il nostro trattamento delle tue informazioni non sia conforme alla legislazione
            in materia di protezione dei dati, puoi presentare un reclamo al Garante per la Protezione dei Dati
            Personali ai seguenti recapiti: Indirizzo: Piazza di Monte Citorio, n.121, 00186, Roma Numero di telefono:
            06 69677 3785 Sito: <a href={'http://www.garanteprivacy.it/'}>http://www.garanteprivacy.it/</a>
          </span>
        </div>
      </div>
    </PageContainer>
  );
}
