import * as _ from 'lodash';
import React from 'react';
import {Ingredient} from '../../../../models/menu/category';
import {Option} from '../../../../models/menu/option';
import CustomRow from '../../../../components/atoms/CustomRow';
import {makeStyles} from '@material-ui/styles';
import {Spacing} from '../../../../styles/spacing';
import {OrderCartItem} from '../../../../models/orders/order-cart-item';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Delete} from '@material-ui/icons';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';

const OrderCartItemElement = ({orderCartItem, removeOptionChoice, className}: Props) => {
  const classes = useStyle();

  const getOption = (options: Option[]) => {
    return _.flatten(options.map((o) => o.choises.map((c) => `${c.label}`))).join(', ');
  };

  const getRemovedIngredient = (removedIngredients: Ingredient[]) => {
    return removedIngredients.map((i) => `NO ${i.name}`).join(', ');
  };

  return (
    <>
      <CustomRow className={className} nowrap>
        <div className={classes.categoryItemDetail}>
          <CustomRow>
            <CustomText bold color="primary" style={{marginRight: Spacing.SCALE_6}}>
              {orderCartItem.count}x
            </CustomText>
            <CustomText bold size={'sm'}>
              {orderCartItem.name}
            </CustomText>
          </CustomRow>
          <div>
            {orderCartItem.selectedOptions && orderCartItem.selectedOptions.length > 0 && (
              <CustomText color={'primary'} size={'sm'}>
                {getOption(orderCartItem.selectedOptions)}
              </CustomText>
            )}
          </div>
          <div>
            {orderCartItem.removedIngredients && orderCartItem.removedIngredients.length > 0 && (
              <CustomText color={'primary'}>{getRemovedIngredient(orderCartItem.removedIngredients)}</CustomText>
            )}
          </div>
          <CustomRow marginTop={6}>
            <CustomText color={'primary'} style={{marginTop: Spacing.SCALE_8}}>
              {orderCartItem.totalPrice * orderCartItem.count} €
            </CustomText>
          </CustomRow>
        </div>
        {removeOptionChoice && (
          <div className={classes.buttonDelete}>
            <CustomButton icon={Delete} iconColor={'primary'} onClick={removeOptionChoice} />
          </div>
        )}
      </CustomRow>
    </>
  );
};

type Props = {
  orderCartItem: OrderCartItem;
  removeOptionChoice?: any;
  className?: any;
};

const useStyle = makeStyles({
  categoryItemDetail: {
    padding: '12px 0',
    marginRight: 20,
  },
  buttonDelete: {
    flex: 1,
    textAlign: 'right',
  },
});

export default OrderCartItemElement;
