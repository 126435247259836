import {container, title} from '../../styles/material-kit/nextjs-material-kit';
import {primaryColor} from '../../styles/colors';

const landingPageStyle = {
  container: {
    ...container,
    zIndex: '12',
    color: '#FFFFFF',
    paddingLeft: '0',
    paddingRight: '0',
    height: '800px',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 20px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  mainTitleContainer: {
    textAlign: 'center',
    marginLeft: '50px',
    marginRight: '50px',
  },
  mainTitle: {
    ...title,
    fontSize: '70px',
    color: 'white',
    textShadow: '0px 6px ' + primaryColor,
    '@media (max-width: 480px)': {
      fontSize: '40px',
    },
    '@media (max-width: 300px)': {
      fontSize: '30px',
    },
    marginLeft: '80px',
  },
  subTitle: {
    marginLeft: '80px',
  },
  startSearchBox: {
    width: '65%',
    margin: 'auto',
    '@media (max-width: 767px)': {
      width: '100%',
      margin: '0 20px 0px',
    },
  },
  boxContainerTitle: {
    marginTop: '250px',
    '@media (min-width: 320px) and (max-width: 480px)': {
      marginTop: '150px',
    },
  },
  pizzaStyle: {
    position: 'absolute',
    '@media (max-width: 480px)': {
      top: '-100px',
      width: '350px',
      justifyContent: 'end',
      right: '-50px',
    },
    '@media (min-width: 481px) and (max-width: 767px)': {
      top: '-100px',
      width: '350px',
      justifyContent: 'end',
      right: '-50px',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      top: '-250px',
      width: '360px',
      justifyContent: 'end',
      right: '-60px',
    },
    '@media (min-width: 1201px)': {
      width: '500px',
      top: '150px',
      right: '-100px',
    },
  },
  googlePlacesInput: {
    '@media (min-width: 320px) and (max-width: 480px)': {
      width: '100%',
    },
    '@media (min-width: 481px) and (max-width: 600px)': {
      width: '400px',
    },
    '@media (min-width: 601px) and (max-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 768px)': {
      width: '500px',
    },
  },
} as any;

export default landingPageStyle;
