import {BASE_URL, http} from './axios';
import {decrypt, encrypt} from './crypto';
import {Order} from '../models/orders/order';
import {Period} from '../models/orders/period';
import {PeriodListRequest} from '../models/orders/request-objects/period-list-request';
import {SearchOrder} from '../models/orders/request-objects/search-order';
import {UpdateOrderStatus} from '../models/orders/request-objects/update-order-status';
import {UpsertOrder} from '../models/orders/request-objects/upsert-order';
import {UpsertOrderAndPay} from '../models/orders/request-objects/upsert-order-and-pay';
import {CustomResponse, Status} from '../models/shared/custom-response';
import {getToken} from '../services/storageService';
import {CountOrders} from '../models/orders/count-orders';

const CONTROLLER_PATH = '/orders';

export const checkOrder = async (body: UpsertOrder): Promise<CustomResponse<any>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/check`;
  return http.post(url, body, {headers});
};

export const createOrder = async (body: UpsertOrder): Promise<CustomResponse<any>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/create`;
  const payload = await encrypt(body);
  const res: CustomResponse<any> = await http.post(url, {payload}, {headers});
  if (res.status === Status.OK) {
    res.payload = await decrypt(res.payload);
  }
  return res;
};

export const createOrderAndPay = async (body: UpsertOrderAndPay): Promise<CustomResponse<any>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/create-and-pay`;
  const payload = await encrypt(body);
  const res: CustomResponse<any> = await http.post(url, {payload}, {headers});
  if (res.status === Status.OK) {
    res.payload = await decrypt(res.payload);
  }
  return res;
};

export const getUserOrders = (body: SearchOrder): Promise<CustomResponse<Order[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/get-user-orders`;
  return http.post(url, body, {headers});
};

export const getUserPeriodList = (body: PeriodListRequest): Promise<CustomResponse<Period[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/get-user-period-list`;
  return http.post(url, body, {headers});
};

export const getTotalPeriodList = (body: PeriodListRequest): Promise<CustomResponse<Period[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  let url = `${BASE_URL}${CONTROLLER_PATH}/get-total-period-list`;
  return http.post(url, body, {headers});
};

export const updateOrderStatus = async (body: UpdateOrderStatus): Promise<CustomResponse<Order[]>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  const url = `${BASE_URL}${CONTROLLER_PATH}/update-status`;
  const payload = await encrypt(body);
  const res: CustomResponse<any> = await http.post(url, {payload}, {headers});
  if (res.status === Status.OK) {
    res.payload = await decrypt(res.payload);
  }
  return res;
};

export const countOrders = async (): Promise<CustomResponse<CountOrders>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  const url = `${BASE_URL}${CONTROLLER_PATH}/count-orders`;
  return http.get(url, {headers, isBackground: true} as any);
};
