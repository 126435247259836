import {ContactForInfo} from '../models/auth/contact-for-info';
import {BASE_URL, http} from './axios';
import {ContactSupport} from '../models/auth/contact-support';
import {LoginRequest, LoginResponse} from '../models/auth/login';
import {Provider} from '../models/auth/provider';
import {
  RegistrationAnonymousUserPayload,
  RegistrationMerchantPayload,
  RegistrationOauthPayload,
  RegistrationUserPayload,
} from '../models/auth/registration';
import {ResetPasswordPayload} from '../models/auth/reset-password';
import {CustomResponse} from '../models/shared/custom-response';
import {getToken} from '../services/storageService';
import {UserInfo} from '../models/user/user-info.interface';

export const login = async (data: LoginRequest) => {
  return http.post(`${BASE_URL}/auth/login`, data);
};

export const logout = async () => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}/auth/logout`, {}, {
    headers,
    isBackground: true,
  } as any);
};

export const forgotPasswordSendToken = (email: string): Promise<CustomResponse<any>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.get(`${BASE_URL}/auth/email/forgot-password/${email}`));
  });
};

export const resetPassword = (resetPasswordPayload: ResetPasswordPayload): Promise<CustomResponse<any>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.post(`${BASE_URL}/auth/email/reset-password`, resetPasswordPayload));
  });
};

export const forceNewPassword = async (resetPasswordPayload: ResetPasswordPayload): Promise<CustomResponse<any>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}/auth/force-new-password`, resetPasswordPayload, {
    headers,
  } as any);
};

export const resendEmailToken = (email: string): Promise<CustomResponse<{isEmailSent: boolean}>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.get(`${BASE_URL}/auth/email/resend-verification/${email}`));
  });
};

export const verifyEmail = (token: string): Promise<CustomResponse<{isEmailVerified: boolean}>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.get(`${BASE_URL}/auth/email/verify/${token}`));
  });
};

export const submitPhoneNumber = (
  phoneNumber: string,
  email: string,
  update = false,
): Promise<CustomResponse<{requestID: string}>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(
      http.post(`${BASE_URL}/auth/phone-number/submit`, {
        phoneNumber,
        email,
        update,
      }),
    );
  });
};

export const verifyPhoneNumber = (
  token: string,
  requestID: string,
  email: string,
): Promise<CustomResponse<{isPhoneNumberVerified: boolean}>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(
      http.post(`${BASE_URL}/auth/phone-number/verify`, {
        token,
        requestID,
        email,
      }),
    );
  });
};

export const registrationUser = (payload: RegistrationUserPayload): Promise<CustomResponse<any>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.post(`${BASE_URL}/auth/register`, payload));
  });
};

export const registrationAnonymousUser = (payload: RegistrationAnonymousUserPayload): Promise<CustomResponse<any>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    // @ts-ignore
    resolve(http.post(`${BASE_URL}/auth/register-anonymous`, payload, {isBackground: true}));
  });
};

export const registrationMerchant = (payload: RegistrationMerchantPayload): Promise<CustomResponse<UserInfo>> => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.post(`${BASE_URL}/auth/register-merchant`, payload));
  });
};

export const oauthLogin = (payload: RegistrationOauthPayload, provider: Provider) => {
  return new Promise<CustomResponse<any>>((resolve) => {
    resolve(http.post(`${BASE_URL}/auth/oauth-user-mobile/${provider}`, payload));
  });
};

export const contactSupport = async (body: ContactSupport): Promise<CustomResponse<{emailSended: boolean}>> => {
  const token = getToken();
  const headers = {Authorization: `Bearer ${token}`};
  return http.post(`${BASE_URL}/auth/contact-support`, body, {
    headers,
  });
};

export const contactForInfo = async (body: ContactForInfo): Promise<CustomResponse<{emailSended: boolean}>> => {
  return http.post(`${BASE_URL}/auth/contact-for-info`, body, {});
};
