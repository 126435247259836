import CustomRow from '../atoms/CustomRow';
import CustomChip from '../atoms/CustomChip';
import {Colors} from '../../styles/colors';
import {makeStyles} from '@material-ui/styles';
import {Spacing} from '../../styles/spacing';
import classNames from 'classnames';

const ChipList = ({data, onClose, className, style, horizontal = true, color}: Props) => {
  const classes = useStyle();
  const chipListClasses = classNames({
    [classes.root]: true,
    ...(className && {[className]: true}),
  });

  return (
    <>
      {data.length > 0 && (
        <div style={style} className={chipListClasses}>
          <CustomRow wrap>
            {data.map((f, i) => (
              <CustomChip
                key={i}
                text={f.label}
                className={classes.chip}
                color={color}
                onClose={onClose ? () => onClose(f) : undefined}
              />
            ))}
          </CustomRow>
        </div>
      )}
    </>
  );
};

type Props = {
  data: any[];
  onClose?: any;
  horizontal?: boolean;
  className?: any;
  style?: any;
  color?: Colors;
};

const useStyle = makeStyles({
  root: {},
  chip: {
    marginRight: Spacing.SCALE_8,
    marginTop: Spacing.SCALE_6,
    marginBottom: Spacing.SCALE_6,
  },
});

export default ChipList;
