import React, {useEffect, useState} from 'react';
import List from '@material-ui/core/List';
import {Fonts} from '../../../styles/font';
import HeaderLinkItem from './HeaderLinkItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import headerLinksStyle from './headerLinksStyle';
import {ListItem} from '@material-ui/core';
import classNames from 'classnames';
import {HeaderLink} from '../types/header-link';
import {Colors} from '../../../styles/colors';

const useStyle = makeStyles(headerLinksStyle);

const HEADER_MARGIN = 250;

const HeaderLinks = ({links = [], extraButton, textColor = 'primary', fontFamily}: Props) => {
  const classes = useStyle();
  const [activeMenuItem, setActiveMenuItem] = useState<any>({top: false});

  const extraButtonClasses = classNames({
    [classes.listItem]: true,
    [classes.extraButtonIsolated]: !links || links.length === 0,
  });

  useEffect(() => {
    if (links) {
      getAnchorPoints();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', getAnchorPoints);
      return () => {
        window.removeEventListener('scroll', handleScroll, false);
        window.removeEventListener('resize', getAnchorPoints, false);
      };
    }
  }, [links]);

  const handleScroll = () => {
    const curPos = window.scrollY;
    let curSection = 'top';
    links.forEach((link) => {
      if (link.linked) {
        if (curPos + HEADER_MARGIN >= link.pos) {
          curSection = link.id;
        }
      }
    });
    setActiveMenuItem({[curSection]: true});
  };

  const getAnchorPoints = () => {
    if (!links) {
      return;
    }
    const curScroll = window.scrollY;
    links.forEach((link: any) => {
      if (link.linked) {
        let elementById = document.getElementById(link.id);
        if (elementById) {
          link.pos = elementById.getBoundingClientRect().top + curScroll;
        }
      }
    });

    /* Get the pixel height of the viewport */
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    /* Get the pixel-depth of the bottom of the page */
    const bottom = document.body.offsetHeight;

    /* If max-scroll is less than the pixel-depth of latest section, then adjust the pixel-depth of section 3 to be 50px higher than max scroll depth ... allowing it to be an active destination */
    if (links.length > 0) {
      if (viewPortHeight > bottom - links[links.length - 1].pos) {
        links[links.length - 1].pos = bottom - viewPortHeight + HEADER_MARGIN;
      }
    }
    handleScroll();
  };

  return (
    <>
      {((links && links.length > 0) || extraButton) && (
        <List className={classes.list}>
          {links &&
            links.map((link) => (
              <HeaderLinkItem
                key={link.id}
                label={link.label}
                itemName={link.id}
                active={activeMenuItem[link.id]}
                pos={link.pos}
                onClick={link.onClick}
                textColor={textColor}
                fontFamily={fontFamily}
              />
            ))}
          {extraButton && <ListItem className={extraButtonClasses}>{extraButton}</ListItem>}
        </List>
      )}
    </>
  );
};

type Props = {
  links?: HeaderLink[];
  extraButton?: any;
  textColor?: Colors;
  fontFamily?: Fonts;
};

export default HeaderLinks;
