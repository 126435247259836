import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import iphone from '../../../../assets/images/smart-menu/desktop/iphone_2.png';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';

const styles = (theme: Theme) => ({
  section: {
    width: '80%',
    margin: 'auto',
    padding: '100px 0',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  iphone: {
    width: '300px',
  },
});

const useStyles = makeStyles(styles);

export default function SentenceSection({id}: Props) {
  const classes = useStyles();

  return (
    <div id={id}>
      <GridContainer
        justifyContent={'center'}
        direction={'column'}
        alignItems={'center'}
        fullWidth={true}
        className={classes.section}
      >
        <GridItem centerHorizontal xs={12} sm={12} md={12}>
          <CustomText center={true} bold size={'xl'} color={'primary'} fontFamily={'Goda'}>
            " PENSATO PER CHI
            <br />
            LA{' '}
            <CustomText center={true} bold size={'xl'} color={'primary'} fontFamily={'Goda'} underline>
              PASSIONE
            </CustomText>
            <br />
            LA METTE NEI{' '}
            <CustomText center={true} bold size={'xl'} color={'primary'} fontFamily={'Goda'} underline>
              PIATTI
            </CustomText>
            ,<br />
            NON SU CARTA. "
          </CustomText>
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
