import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import {LEGAL_TERMS, PRIVACY} from '../../../constants';
import GridContainer from '../../atoms/Grid/GridContainer';
import GridItem from '../../atoms/Grid/GridItem';
import CustomText from '../../atoms/Typography/CustomText';

import blackFooter from '../../../assets/images/footer/footer.png';
import facebookLogo from '../../../assets/images/footer/logo-facebook.svg';
import instagramLogo from '../../../assets/images/footer/logo-instagram.svg';

import styles from './footerStyle';
import {navigate} from '../../../services/navigationService';
import {socialLink} from '../../../utils/config';

const useStyles = makeStyles(styles);

export default function Footer({whiteFont}: Props) {
  const classes = useStyles();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <img className={classes.blackFooter} src={blackFooter} />
      <GridContainer className={classes.footerContainer} justifyContent={'space-around'}>
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer className={classes.marginLeft}>
            <GridItem sm={12}>
              <CustomText fontFamily={'Goda'} color={'white'} size={'xl'}>
                NOTE LEGALI
              </CustomText>
            </GridItem>
            <GridItem alignItems={'start'} direction={'column'} sm={12}>
              <CustomText
                onClick={() => {
                  navigate(LEGAL_TERMS);
                }}
                size={'xs'}
                color={'white'}
              >
                Termini e condizioni
              </CustomText>
              <CustomText
                onClick={() => {
                  navigate(PRIVACY);
                }}
                size={'xs'}
                color={'white'}
              >
                Privacy policy
              </CustomText>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer>
            <GridItem sm={12}>
              <CustomText fontFamily={'Goda'} color={'white'} size={'xl'}>
                CONTATTI
              </CustomText>
            </GridItem>
            <GridItem alignItems={'start'} direction={'column'} sm={12}>
              <CustomText size={'xs'} color={'white'}>
                Contattaci alla mail <b>support@ordify.it</b>
              </CustomText>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer>
            <GridItem sm={12}>
              <CustomText fontFamily={'Goda'} color={'white'} size={'xl'}>
                SOCIAL
              </CustomText>
            </GridItem>
            <GridItem alignItems={'start'} direction={'row'} sm={12}>
              <img onClick={() => window.open(socialLink.instagram)} style={{cursor: 'pointer'}} src={instagramLogo} />
              <img
                onClick={() => window.open(socialLink.facebook)}
                style={{marginLeft: '10px', cursor: 'pointer'}}
                src={facebookLogo}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem className={classes.pivaBoxFooter} direction={'column'} justifyContent={'end'} alignItems={'start'}>
          <CustomText size={'xs'} color={'white'}>
            Version {process.env.REACT_APP_VERSION}
            <br />© {new Date().getFullYear()} NextApp Studio Srls - P.IVA 02636940203
            <br />
            All Rights Reserved.
          </CustomText>
        </GridItem>
      </GridContainer>
    </footer>
  );
}

type Props = {
  whiteFont?: boolean;
};
