import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {Fonts} from '../../styles/font';
import CustomText from './Typography/CustomText';
import CustomRow from './CustomRow';
import CustomIcon from './CustomIcon';
import {Colors} from '../../styles/colors';

const useStyle = makeStyles((theme) => ({
  text: {
    marginLeft: '8px',
  },
  element: {
    marginLeft: '8px',
    flexDirection: 'column',
  },
  textRight: {
    textAlign: 'end',
  },
  textCenter: {
    textAlign: 'center',
  },
}));

const CustomLabelText = ({
  icon,
  iconColor,
  label,
  labelOnTop = false,
  labelColor,
  labelBold = false,
  text,
  textCenter,
  textAlignRight,
  element,
  boldText,
  underlineText,
  sizeText,
  textColor,
  style,
  className,
  noWrap,
  marginTop,
  marginBottom,
  marginVertical,
  fontFamily,
}: Props) => {
  const classes = useStyle();

  const textClasses = classNames({
    [classes.element]: true,
    [classes.textCenter]: textCenter,
    [classes.textRight]: textAlignRight,
  });

  return (
    <>
      {labelOnTop ? (
        <div className={className} style={style}>
          <CustomRow marginTop={marginTop}>
            <CustomText fontFamily={fontFamily} color={labelColor || 'gray'} bold={labelBold}>
              {label}
            </CustomText>
          </CustomRow>
          {!text && <CustomRow marginBottom={!!marginBottom}>{element}</CustomRow>}
          {!!text && !element && (
            <CustomRow marginBottom={!!marginBottom}>
              <CustomText
                fontFamily={fontFamily}
                bold={boldText}
                underline={underlineText}
                color={textColor}
                size={sizeText}
              >
                {text}
              </CustomText>
            </CustomRow>
          )}
        </div>
      ) : (
        <CustomRow
          nowrap={noWrap}
          style={style}
          className={className}
          marginTop={marginTop ?? !!marginVertical}
          marginBottom={marginBottom ?? !!marginVertical}
        >
          {!!icon && <CustomIcon color={iconColor} icon={icon} />}
          {!!label && (
            <CustomText fontFamily={fontFamily} bold={labelBold} color={labelColor || 'gray'}>
              {label}
            </CustomText>
          )}
          {element && <div className={textClasses}>{element}</div>}
          {!!text &&
            (textAlignRight ? (
              <div className={classes.textRight}>
                <CustomText
                  bold={boldText}
                  underline={underlineText}
                  color={textColor}
                  size={sizeText}
                  fontFamily={fontFamily}
                  className={classes.text}
                >
                  {text}
                </CustomText>
              </div>
            ) : (
              <CustomText
                bold={boldText}
                underline={underlineText}
                color={textColor}
                size={sizeText}
                fontFamily={fontFamily}
                className={classes.text}
              >
                {text}
              </CustomText>
            ))}
        </CustomRow>
      )}
    </>
  );
};

type Props = {
  icon?: any;
  iconColor?: Colors;
  label?: string;
  noWrap?: boolean;
  labelOnTop?: boolean;
  labelColor?: Colors;
  labelBold?: boolean;
  text?: string | number | Date | undefined | Array<any>;
  textCenter?: boolean;
  textAlignRight?: boolean;
  textColor?: Colors;
  element?: any;
  boldText?: boolean;
  underlineText?: boolean;
  sizeText?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: any;
  style?: any;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  marginVertical?: boolean;
  fontFamily?: Fonts;
};

export default CustomLabelText;
