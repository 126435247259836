import React from 'react';
import CustomCard from '../atoms/Card/CustomCard';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';

const CustomListItem = ({
  children,
  style,
  className,
  onClick,
  padding,
  divider = true,
  marginTop,
  marginBottom,
  noPaddingHorizontal = false,
  noPaddingVertical = false,
  noBorder = false,
}: Props) => {
  const classes = useStyle({noPaddingHorizontal, noPaddingVertical});
  const listItemClasses = classNames({
    [classes.root]: true,
    ...(className && {[className]: true}),
  });

  return (
    <>
      <CustomCard
        className={listItemClasses}
        plain
        divider={divider}
        onClick={onClick}
        padding={padding}
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        {children}
      </CustomCard>
    </>
  );
};

type Props = {
  children?: any;
  style?: any;
  className?: any;
  onClick?: any;
  divider?: boolean;
  marginTop?: boolean | number;
  marginBottom?: boolean | number;
  noPaddingHorizontal?: boolean;
  noPaddingVertical?: boolean;
  noBorder?: boolean;
  padding?: 'sm' | 'md' | 'lg';
};

const useStyle = makeStyles({
  root: {
    paddingRight: (props: Props) => (props.noPaddingHorizontal ? 0 : undefined),
    paddingLeft: (props: Props) => (props.noPaddingHorizontal ? 0 : undefined),
    paddingTop: (props: Props) => (props.noPaddingVertical ? 0 : undefined),
    paddingBottom: (props: Props) => (props.noPaddingVertical ? 0 : undefined),
  },
});

export default CustomListItem;
