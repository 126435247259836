import {useLayoutEffect, useState} from 'react';
import {Router} from 'react-router-dom';

export const CustomRouter = ({history, ...props}: Props) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

type Props = {
  history: any;
  children: any;
};
