import {makeStyles} from '@material-ui/styles';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import {FilterList} from '@material-ui/icons';
import SearchLocation from './SearchLocation';
import Hidden from '@material-ui/core/Hidden';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import FilterActivities from './FilterActivities/FilterActivities';
import React, {useEffect, useState} from 'react';
import CustomDialog from '../../../components/atoms/CustomDialog';
import classNames from 'classnames';

const useStyle = makeStyles((theme) => ({
  root: {},
  fixed: {
    position: 'fixed',
    width: '30%',
  },
  dialogContainer: {
    minWidth: '450px',
    '@media (max-width: 767px)': {
      minWidth: 'auto',
      '&::-webkit-scrollbar': {display: 'none'},
    },
  },
  searchLocationBox: {},
}));

const SearchOptions = ({modalFilterProps, setModalFilterExt, onSearchInputChange}: Props) => {
  const classes = useStyle();
  const [modalFilter, setModalFilter] = useState(false);
  const [fixed, setFixed] = useState(false);

  const searchOptionClasses = classNames({
    [classes.root]: true,
    [classes.fixed]: fixed,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, []);

  useEffect(() => {
    setModalFilter(modalFilterProps);
  }, [modalFilterProps]);

  const handleScroll = () => {
    // const curPos = window.scrollY;
    // setFixed(curPos > 0 && curPos < window.innerHeight - 100);
  };

  return (
    <>
      <div className={searchOptionClasses}>
        <Hidden xsDown>
          <SearchLocation />
          <FilterActivities />
        </Hidden>
      </div>
      <Hidden smUp>
        <GridContainer>
          <GridItem
            xs={12}
            sm={10}
            md={10}
            lg={10}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.searchLocationBox}
          >
            <SearchLocation />
          </GridItem>
          <Hidden smDown>
            <GridItem xs={2} alignItems={'center'} justifyContent={'end'}>
              <CustomButton iconColor={'primary'} icon={FilterList} onClick={() => setModalFilter(true)} />
            </GridItem>
          </Hidden>
        </GridContainer>
      </Hidden>
      <CustomDialog
        dialogContentClassName={classes.dialogContainer}
        title={'FILTRI'}
        fullWidthOnMobile
        onBottom
        open={modalFilter}
        onClose={() => (setModalFilterExt ? setModalFilterExt(false) : setModalFilter(false))}
      >
        <FilterActivities
          onConfirmPressed={() => (setModalFilterExt ? setModalFilterExt(false) : setModalFilter(false))}
        />
      </CustomDialog>
    </>
  );
};

type Props = {
  modalFilterProps: boolean;
  setModalFilterExt?: any;
  onSearchInputChange: any;
};

export default SearchOptions;
