import {submitPhoneNumber} from '../../../api/authService';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {disableLoader, enableLoader} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';
import {setPhoneRequestId} from '../../actions/authActions';
import {navigate} from '../../../services/navigationService';
import {VALIDATE_PHONE_NUMBER} from '../../../constants';

export default function* submitPhoneNumberUserAsync(action: {
  type: string;
  payload: {phoneNumber: string; update?: boolean};
}) {
  yield put(enableLoader());
  let email: string;
  if (action.payload.update) {
    email = yield select((state: State) => state.user.userInfo?.email);
  } else {
    email = yield select((state: State) => state.auth.authPayload?.email);
  }
  const response: CustomResponse<{
    requestID: string;
  }> = yield submitPhoneNumber(action.payload.phoneNumber, email, action.payload.update);
  if (response.status === Status.OK) {
    yield put(setPhoneRequestId({requestID: response.payload.requestID}));
    if (action.payload.update) {
      // navigate('UserProfileVerifyPhoneNumberPage'); // now we are not managing phone update on web
    } else {
      navigate(VALIDATE_PHONE_NUMBER, {email});
    }
  }
  yield put(disableLoader());
}
