import {LocationInfo} from '../models/shared/location-info';
import {AddressComponent, GooglePlaceSearchResponse} from '../models/shared/google-api';

export const getLocationInfo = (location: LocationInfo | undefined, showPostalCode = false) => {
  if (location) {
    let locationToString = location.address;
    if (location.streetNumber) {
      locationToString += `, ${location.streetNumber},`;
    }
    if (location.postalCode && showPostalCode) {
      locationToString += ` ${location.postalCode}`;
    }
    if (location.localityName) {
      locationToString += ` ${location.localityName}`;
    } else {
      locationToString += `, ${location.cityName}`;
    }
    if (location.townCode) {
      locationToString += ` ${location.townCode}`;
    }
    return locationToString;
  }
  return '-';
};

export const getLocationInfoFromDetail = (detail: GooglePlaceSearchResponse) => {
  if (detail) {
    const addressComponents = detail.address_components;
    const postalCode = getAddressComponentFromType(addressComponents, 'postal_code');
    const streetNumber = getAddressComponentFromType(addressComponents, 'street_number');
    const address = getAddressComponentFromType(addressComponents, 'route');
    const locality = getAddressComponentFromType(addressComponents, 'locality');
    const city = getAddressComponentFromType(addressComponents, 'administrative_area_level_3');
    const town = getAddressComponentFromType(addressComponents, 'administrative_area_level_2');
    const country = getAddressComponentFromType(addressComponents, 'country');
    const {lat, lng} = detail.geometry.location;
    return {
      place_id: detail.place_id,
      description: locality ? `${locality.long_name}, ${town?.short_name}` : `${city?.long_name}, ${town?.short_name}`,
      postalCode: postalCode?.long_name,
      streetNumber: streetNumber?.long_name,
      address: address?.long_name,
      localityName: locality?.long_name,
      cityName: city?.long_name,
      townCode: town?.short_name,
      countryName: country?.long_name,
      coords: {
        latitude: lat(),
        longitude: lng(),
      },
    } as LocationInfo;
  }
  return {} as LocationInfo;
};

const getAddressComponentFromType = (addressComponentList: AddressComponent[], type: any): AddressComponent | null => {
  return addressComponentList.find((ac: AddressComponent) => ac.types.includes(type)) || null;
};
