import React, {useEffect, useState} from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import './recensioniSectionStyle.scss';
import {Carousel} from 'react-bootstrap';

import backgroundImage from '../../../../assets/images/landing/recensioniSection/recensioni.png';
import nextImg from '../../../../assets/images/landing/recensioniSection/bottone.png';
import stellina from '../../../../assets/images/landing/recensioniSection/stella.png';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {isMobile} from '../../../../utils/commonFunctions';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  recensioneBox: {
    maxWidth: '70%',
    '@media (max-width: 480px)': {
      maxWidth: '50%',
    },
  },
});

export default function RecensioniSection({id}: Props) {
  const [recensioni, setRecensioni] = useState([{}]);
  const classes = useStyles();

  useEffect(() => {
    setRecensioni([
      {
        title: 'CIAO',
        content:
          'Ho trovato quest’app molto intuitiva per effettuare ordini sia delivery sia take away. Servizio ottimo da parte dei ristoranti. Molto apprezzata la funzionalità prenota un tavolo.',
        stars: 5,
      },
      {
        title: 'CIAO',
        content:
          'Ha delle funzioni molto interessanti, soprattutto il pronto in tavola (molto utile ed efficace!). I ristoranti presenti offrono un ottimo servizio, sia per quanto riguarda il domicilio sia per l’asporto.',
        stars: 5,
      },
      {
        title: 'CIAO',
        content: 'Ottima app ragazzi continuate cosi Forza Mantova',
        stars: 5,
      },
    ]);
  }, []);

  return (
    <div id={id}>
      <GridContainer style={{height: '600px'}} marginTop={100} fullWidth={true} direction={'row'}>
        <GridItem noPadding={true} md={12} xs={12} sm={12} lg={12}>
          <img alt={''} className={'containerRecensioni'} src={backgroundImage} />
          <Carousel
            indicators={isMobile()}
            variant={'dark'}
            nextIcon={isMobile() ? null : <img style={{width: '50px', height: '50px'}} src={nextImg} />}
            prevIcon={
              isMobile() ? null : (
                <img style={{width: '50px', height: '50px', transform: 'rotate(180deg)'}} src={nextImg} />
              )
            }
            className={'carouselCustom'}
          >
            {recensioni.map((recensione: any, index) => {
              recensione.starsArray = [];
              for (let i = 0; i < recensione.stars; i++) {
                recensione.starsArray.push(<img key={i} style={{width: '25px'}} src={stellina} />);
              }
              return (
                <Carousel.Item key={index}>
                  <Carousel.Caption>
                    <CustomText color={'white'} fontFamily={'Goda'} size={'xl'}>
                      DICONO DI NOI:
                    </CustomText>
                    <CustomText className={classes.recensioneBox} color={'white'} size={'md'}>
                      {recensione.content}
                    </CustomText>
                    <div className={'starsContainer'}>{recensione.starsArray}</div>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </GridItem>
      </GridContainer>
    </div>
  );
}

type Props = {
  id?: string;
};
