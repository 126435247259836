import React, {useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Fonts} from '../../../styles/font';
import headerLinksStyle from './headerLinksStyle';
import CustomButton from '../../atoms/CustomButtons/CustomButton';
import {Colors} from '../../../styles/colors';

const useStyle = makeStyles(headerLinksStyle);

const HeaderLinkItem = ({itemName, label, active, pos, onClick, textColor, fontFamily}: Props) => {
  const classes = useStyle({textColor});
  const [anchorTarget, setAnchorTarget] = useState<HTMLElement | null>();

  // const navLinkActiveCustom = classNames({
  //   [classes.navLinkActiveCustom]: {
  //     color: !inverted ? primaryColor + ' !important' : 'white !important',
  //     borderBottom: '2px solid ' + !inverted ? primaryColor : 'white !important',
  //     backgroundColor: 'rgba(255, 255, 255, 0.1)',
  //   },
  // });

  useEffect(() => {
    setAnchorTarget(document.getElementById(itemName));
  }, []);

  const handleClick = (e: any) => {
    e.preventDefault();
    if (pos) {
      window.scroll({top: pos - 150, behavior: 'smooth'});
    } else if (anchorTarget) {
      anchorTarget.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  return (
    <ListItem className={classes.listItem}>
      <CustomButton
        size={'lg'}
        className={classes.textColor + ' ' + classes.navLink + ' ' + (active ? classes.navLinkActiveCustom : null)}
        aria-label={'Scroll to ' + itemName}
        onClick={onClick ? onClick : (e: any) => handleClick(e)}
        color={'transparent'}
        title={label}
        fontFamily={fontFamily}
      />
    </ListItem>
  );
};

export type Props = {
  itemName: string;
  label: string;
  active: boolean;
  pos?: number;
  onClick?: () => void;
  textColor?: Colors;
  fontFamily?: Fonts;
};

export default HeaderLinkItem;
