import {Provider} from '../auth/provider';
import {MerchantExtraInfo} from './merchant-extra-info';
import {PaymentInfo} from './payment-info';
import {UserExtraInfo} from './user-extra-info';
import {DeviceInfo} from './request-objects/check-device';
import {UserInfoAdvanced} from './user-info-advanced-detail.interface';

export interface UserInfo {
  id: string;
  email: string;
  roles: string[];
  auth: {
    enabled: boolean;
    email: {
      valid: boolean;
    };
    phoneNumber: {
      valid: boolean;
    };
    provider: Provider;
    userId: string;
    accessToken: string;
    registrationOrigin: RegistrationOrigin;
  };
  paymentInfo: PaymentInfo;
  settings: {};
  merchantInfo: MerchantExtraInfo;
  userInfo: UserExtraInfo;
  devices: Device[];
}

export interface Device {
  fcmToken: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  deviceInfo: DeviceInfo;
}

export interface Users {
  anonymousUsers: UserInfoAdvanced[];
  registeredUsers: UserInfoAdvanced[];
}

export interface Merchants {
  merchantsToActivate: UserInfoAdvanced[];
  merchantsActive: UserInfoAdvanced[];
}

export enum RegistrationOrigin {
  MOBILE = 'mobile',
  WEB = 'web',
}

export type SortBy = 'latestLogin' | 'nLogin' | 'createdAt';
