import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../redux/reducers';
import {makeStyles} from '@material-ui/styles';
import CustomText from '../../../components/atoms/Typography/CustomText';
import classNames from 'classnames';
import CustomCard from '../../../components/atoms/Card/CustomCard';
import * as _ from 'lodash';
import {Spacing} from '../../../styles/spacing';
import CustomRow from '../../../components/atoms/CustomRow';
import ModalItem from './ModalItem/ModalItem';
import {CategoryForMenu, ItemForMenu} from '../../../models/menu/category';
import {OrderCartItem} from '../../../models/orders/order-cart-item';
import {updateOrderCart} from '../../../redux/actions/sectionUserActions';
import OrderCartItemElement from './OrderSummary/OrderCartItemElement';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import CustomListItem from '../../../components/molecules/CustomListItem';
import {navigate} from '../../../services/navigationService';
import {COMPLETE_ORDER, LOGIN} from '../../../constants';
import {setAfterLoginPage} from '../../../redux/actions/authActions';
import {Hidden} from '@material-ui/core';
import {ShoppingCart} from '@material-ui/icons';
import {primaryColor, WHITE} from '../../../styles/colors';
import ModalOrderSummary from './OrderSummary/ModalOrderSummary';
import {
  setActivityOnStorage,
  setAfterLoginPageOnStorage,
  setOrderCartOnStorage,
} from '../../../services/storageService';

const useStyles = makeStyles((theme) => ({
  root: {},
  fixed: {
    position: 'fixed',
    top: '10px',
    right: '30px',
    '@media (min-width: 481px) and (max-width: 991px)': {
      width: '29.514%',
    },
    '@media (min-width: 992px) and (max-width: 1024px)': {
      width: '22.38%',
    },
    '@media (min-width: 1025px) and (max-width: 1200px)': {
      width: '22.58%',
    },
    '@media (min-width: 1201px)': {
      right: '29px',
      width: '23.195%',
    },
  },
  orderCartMobile: {
    position: 'fixed',
    zIndex: 10,
    width: '100%',
    bottom: '0',
    left: '0',
    padding: '10px',
    backgroundColor: WHITE,
  },
  numItems: {
    position: 'absolute',
    left: '40px',
    bottom: '-10px',
    zIndex: 9,
    padding: '2px 10px',
    background: primaryColor,
    borderRadius: '50%',
    color: WHITE,
  },
  cardCart: {
    borderRadius: '40px',
  },
}));

const OrderCart = () => {
  const classes = useStyles();
  const orderCart = useSelector((s: State) => s.sectionUser.orderCart);
  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);
  const isLoggedIn = useSelector((s: State) => s.auth.isLoggedIn);
  const [fixed, setFixed] = useState(false);
  const [item, setItem] = useState<ItemForMenu | null>(null);
  const [category, setCategory] = useState<CategoryForMenu | null>(null);
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const [showOrderCartModal, setShowOrderCartModal] = useState<boolean>(false);
  const [indexToUpdate, setIndexToUpdate] = useState<number>(-1);
  const [itemOrderSelected, setItemOrderSelected] = useState<OrderCartItem | null>(null);
  const dispatch = useDispatch();

  const orderCartClasses = classNames({
    [classes.fixed]: fixed,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, []);

  const handleScroll = () => {
    const curPos = window.scrollY;
    setFixed(curPos > 316);
  };

  const getCartTotalItems = () => {
    return _.sum(orderCart.map((o) => o.count));
  };

  const getCartTotalPrice = () => {
    return _.sum(orderCart.map((o) => o.totalPrice * o.count));
  };

  const openModalItem = (itemOrder: OrderCartItem, index: number) => {
    if (activityDetail) {
      const cat = activityDetail.menu.categories.find((c) => c.id === itemOrder.categoryID);
      if (cat) {
        const it = cat.items.find((i) => i.id === itemOrder.itemID);
        if (it) {
          setCategory(cat);
          setItem(it);
          setItemOrderSelected(itemOrder);
          setIndexToUpdate(index);
          setTimeout(() => {
            setShowItemModal(true);
          }, 10);
        }
      }
    }
  };

  const onConfirm = (itemOrder: OrderCartItem) => {
    setShowItemModal(false);
    if (itemOrder) {
      const newOrderCart = [...orderCart];
      if (indexToUpdate > -1) {
        newOrderCart[indexToUpdate] = itemOrder;
        dispatch(updateOrderCart({orderCart: newOrderCart}));
        setIndexToUpdate(-1);
      }
    }
  };

  const onRemoveFromOrder = () => {
    setShowItemModal(false);
    const newOrderCart = [...orderCart];
    if (indexToUpdate > -1) {
      newOrderCart.splice(indexToUpdate, 1);
      dispatch(updateOrderCart({orderCart: newOrderCart}));
      setIndexToUpdate(-1);
    }
  };

  const onDeleteItem = (index: number) => {
    const newOrderCart = [...orderCart];
    // prendo l'indice dalla key perchè se cerco per itemID se ho due item con opzioni diverse elimino il primo che trovo
    if (index > -1) {
      if (newOrderCart[index].count > 1) {
        newOrderCart[index].count--;
      } else {
        newOrderCart.splice(index, 1);
      }
      dispatch(updateOrderCart({orderCart: newOrderCart}));
    }
  };

  const confirmOrder = () => {
    if (!activityDetail?.homeServiceSettings.enabled && !activityDetail?.takeawaySettings.enabled) {
      showToastMessage(
        AppMessageType.WARNING,
        'Questa attività non offre la possibilità di ordinare cibo da asporto o a domicilio',
      );
      return;
    }
    if (!orderCart || orderCart.length === 0) {
      showToastMessage(AppMessageType.WARNING, 'Il carrello è vuoto! Inserisci qualcosa 🚀');
      return;
    }
    if (!isLoggedIn) {
      dispatch(setAfterLoginPage(COMPLETE_ORDER));
      setAfterLoginPageOnStorage(COMPLETE_ORDER);
      setOrderCartOnStorage(orderCart);
      setActivityOnStorage(activityDetail);
      navigate(LOGIN);
      return;
    }
    navigate(COMPLETE_ORDER);
  };

  const ContinueSection = () => {
    return (
      <>
        {!activityDetail?.homeServiceSettings.enabled && !activityDetail?.takeawaySettings.enabled ? (
          <CustomRow marginTop>
            <CustomText color={'primary'} size={'xs'} center>
              Questa attività non offre la possibilità di ordinare cibo da asporto o a domicilio
            </CustomText>
          </CustomRow>
        ) : (
          <>
            <CustomRow marginTop>
              <CustomButton size={'md'} title={'Continua'} fullWidth onClick={confirmOrder} />
            </CustomRow>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Hidden xsDown>
        <div className={orderCartClasses}>
          <CustomCard className={classes.cardCart} padding={'sm'}>
            <CustomRow center>
              <CustomText color={'primary'} size={'lg'} fontFamily={'Goda'} bold>
                CARRELLO
              </CustomText>
            </CustomRow>
            {orderCart.length === 0 && (
              <CustomRow center marginTop>
                <CustomText size={'sm'}>Nessun elemento presente</CustomText>
              </CustomRow>
            )}
            {orderCart && orderCart.length > 0 && (
              <>
                {orderCart.map((o, i) => (
                  <CustomListItem divider={false} key={i} onClick={() => openModalItem(o, i)}>
                    <OrderCartItemElement orderCartItem={o} removeOptionChoice={() => onDeleteItem(i)} />
                  </CustomListItem>
                ))}
                <CustomRow marginTop={20}>
                  <CustomText>Totale:</CustomText>
                  <CustomText bold style={{marginLeft: Spacing.SCALE_12}}>
                    {getCartTotalPrice()} €
                  </CustomText>
                </CustomRow>
                <ContinueSection />
              </>
            )}
          </CustomCard>
        </div>
      </Hidden>
      <Hidden smUp>
        {orderCart && orderCart.length > 0 && (
          <div className={classes.orderCartMobile}>
            <CustomButton
              fullWidth
              icon={ShoppingCart}
              iconColor={'white'}
              iconSize={'md'}
              size={'md'}
              title={['Totale: ', getCartTotalPrice(), '€']}
              onClick={() => setShowOrderCartModal(true)}
            />
          </div>
          // <CustomCard className={classes.orderCartMobile} onClick={() => setShowOrderCartModal(true)} padding={'md'}>
          //   <GridContainer alignItems={'center'}>
          //     <GridItem xs={4}>
          //       <CustomIcon icon={CardTravel} size={'lg'} />
          //       <div className={classes.numItems}>
          //         <CustomText>{getCartTotalItems()}</CustomText>
          //       </div>
          //     </GridItem>
          //     <GridItem xs={8}>
          //       <CustomLabelText label={'Totale'} sizeText={'lg'} boldText text={`${getCartTotalPrice()} €`} />
          //     </GridItem>
          //   </GridContainer>
          // </CustomCard>
        )}
      </Hidden>
      <ModalOrderSummary
        open={showOrderCartModal}
        onClose={() => setShowOrderCartModal(false)}
        orderCart={orderCart}
        totalPrice={getCartTotalPrice()}
        removeOptionChoice={onDeleteItem}
        onItemClick={openModalItem}
        continueSection={<ContinueSection />}
      />
      <ModalItem
        open={showItemModal}
        onClose={() => setShowItemModal(false)}
        onConfirm={onConfirm}
        itemOrder={itemOrderSelected}
        onRemoveFromOrder={onRemoveFromOrder}
        item={item}
        category={category}
        hideAddButton={
          !activityDetail?.homeServiceSettings?.enabled &&
          !activityDetail?.takeawaySettings?.enabled &&
          !activityDetail?.tableReservationSettings?.enabled
        }
      />
    </>
  );
};

export default OrderCart;
