import React, {useEffect, useState} from 'react';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import GridItem from '../../components/atoms/Grid/GridItem';
import imgBox from '../../assets/images/landing/header/texture.svg';
import {makeStyles} from '@material-ui/styles';
import Footer from '../../components/organisms/Footer/Footer';
import {connect, useDispatch} from 'react-redux';
import {
  getActivitiesFilters,
  loadActivityDetailSuccess,
  searchActivities,
  setActivitiesFiltersSelected,
  updateOrderCart,
  updateTableReservationInfo,
} from '../../redux/actions/sectionUserActions';
import {UserInfo} from '../../models/user/user-info.interface';
import {ActivitySearchResultResponse} from '../../models/user/activity-search-result';
import {ActivityFilter} from '../../models/user/activity-filter';
import {CountOrders} from '../../models/orders/count-orders';
import {State} from '../../redux/reducers';
import CustomText from '../../components/atoms/Typography/CustomText';
import ActivitySearchResultElement from './components/ActivitySearchResultElement';
import {navigate} from '../../services/navigationService';
import {ACTIVITY_DETAIL, HOME} from '../../constants';
import * as _ from 'lodash';
import SearchOptions from './components/SearchOptions';
import {getUserInfo} from '../../services/storageService';
import {container} from '../../styles/material-kit/nextjs-material-kit';
import {fbPixel} from '../../services/fpixel';
import {useSearchParams} from 'react-router-dom';
import {updateUser} from '../../redux/actions/userActions';
import {UpdateUserRequest} from '../../models/user/request-objects/update-user';
import {getCoordsFromStringFormat, isMobile} from '../../utils/commonFunctions';
import {registrationAnonymousUser} from '../../redux/actions/registrationActions';
import CustomInput from '../../components/atoms/CustomInput/CustomInput';
import {InputAdornment} from '@material-ui/core';
import {FilterList, SearchOutlined} from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import CustomButton from '../../components/atoms/CustomButtons/CustomButton';
import Header from '../../components/organisms/Header/Header';
import ChipList from '../../components/molecules/ChipList';
import ModalDownloadMobileApp from '../../components/molecules/ModalDownloadMobileApp';

const useStyles = makeStyles({
  main: {
    padding: '20px',
    '@media (max-width: 480px)': {
      padding: '10px 0',
    },
  },
  container: {
    ...container,
    zIndex: 12,
    paddingLeft: '0',
    paddingRight: '0',
  },
  startSearchBox: {
    width: '65%',
    margin: 'auto',
  },
  boxSconto: {
    width: '100%',
    height: '200px',
    backgroundImage: 'url(' + imgBox + ')',
    borderRadius: '40px',
  },
  filterChipList: {
    padding: '12px 0',
  },
  searchInputWrapper: {
    marginTop: '25px',
    '@media (min-width: 768px) and (max-width: 1024px)': {
      marginTop: '0',
    },
  },
  containerSearchOptions: {
    marginTop: '250px',
    '@media (min-width: 481px) and (max-width: 1200px)': {
      marginTop: '140px',
    },
    '@media (max-width: 480px)': {
      marginTop: '130px',
    },
  },
});

const SearchRestaurants = ({
  user,
  activitySearchResultList,
  activitiesFiltersSelected,
  radius,
  orderCounters,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [init, setInit] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalFilter, setModalFilter] = useState(false);

  useEffect(() => {
    const coords = getCoordsFromStringFormat(searchParams.get('coords'));
    const userInfo = getUserInfo();
    if (!init || (!user && userInfo)) {
      return;
    }
    if (coords) {
      if (user) {
        dispatch(
          updateUser({
            updateUserRequest: {
              userInfo: {coords},
            } as UpdateUserRequest,
            actions: [searchActivities, getActivitiesFilters],
          }),
        );
      } else {
        dispatch(
          registrationAnonymousUser({
            registrationAnonymousUserPayload: {coords},
            actions: [searchActivities, getActivitiesFilters],
          }),
        );
      }
    } else if (user?.userInfo?.location?.coords) {
      dispatch(searchActivities());
      dispatch(getActivitiesFilters());
    }
    setInit(false);
  }, [user, searchParams]);

  useEffect(() => {
    const userInfo = getUserInfo();
    if (!userInfo?.userInfo?.location && !searchParams.get('coords')) {
      navigate(HOME);
    }
  }, []);

  const onSearchInputChange = _.debounce((text: string) => {
    if (text.length === 0 || text.length > 2) {
      dispatch(searchActivities({term: text, filter: null}));
      setSearchValue(text);
    }
  }, 400);

  const goToActivity = (id: string, name: string) => {
    dispatch(loadActivityDetailSuccess({activityDetail: null}));
    dispatch(updateOrderCart({orderCart: []}));
    dispatch(updateTableReservationInfo({hour: '', numberOfPersons: 0, useProntoInTavola: false}));
    navigate(`${ACTIVITY_DETAIL}/${id}`);
    fbPixel.track('ViewContent', {content_category: 'Dettaglio attività', content_name: name, content_ids: [id]});
  };

  const removeFilterChip = (filter: ActivityFilter) => {
    let newFilterSelected = [...activitiesFiltersSelected];
    const index = activitiesFiltersSelected.findIndex((f) => f.value === filter.value);
    newFilterSelected.splice(index, 1);
    dispatch(setActivitiesFiltersSelected({activitiesFilters: newFilterSelected}));
    dispatch(searchActivities());
  };

  return (
    <div>
      <ModalDownloadMobileApp />
      <Header
        color="transparent"
        links={[]}
        fixed
        showIntersectionImage
        changeColorOnScroll={{
          height: 60,
          color: 'white',
        }}
      />
      <div className={classes.main}>
        <div className={classes.container}>
          <GridContainer className={classes.containerSearchOptions}>
            <GridItem sm={4}>
              <SearchOptions
                setModalFilterExt={(value: boolean) => setModalFilter(value)}
                modalFilterProps={modalFilter}
                onSearchInputChange={onSearchInputChange}
              />
            </GridItem>
            <GridItem sm={8}>
              <Hidden smDown>
                <GridContainer
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginBottom={25}
                  className={classes.boxSconto}
                  direction={'row'}
                >
                  <GridItem alignItems={'center'} justifyContent={'center'} md={12} lg={12} direction={'column'}>
                    <CustomText color={'white'} size={'lg'} fontFamily={'Goda'}>
                      SCARICA L'APP 😍
                    </CustomText>
                    <CustomText center color={'white'} size={'md'} fontFamily={'Open Sans'}>
                      Avrai <b>3€ di buono sconto</b> da utilizzare per i tuoi ordini.
                    </CustomText>
                  </GridItem>
                </GridContainer>
              </Hidden>
              <GridContainer
                className={classes.searchInputWrapper}
                marginBottom={activitiesFiltersSelected?.length === 0 ? 25 : 0}
              >
                <GridItem xs={10} sm={12} md={12} lg={12} noPadding>
                  <CustomInput
                    placeholder={isMobile() ? 'Nome attività' : 'Nome attività \\ Tipo di attività'}
                    fullWidth
                    onChange={onSearchInputChange}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment style={{marginLeft: '20px', color: '#000000'}} position="start">
                          <SearchOutlined style={{cursor: 'pointer'}} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <Hidden smUp>
                  <GridItem noPadding={'right'} xs={2} alignItems={'center'} justifyContent={'end'}>
                    <CustomButton iconColor={'primary'} icon={FilterList} onClick={() => setModalFilter(true)} />
                  </GridItem>
                </Hidden>
                <GridItem noPadding xs={12} alignItems={'center'} justifyContent={'center'}>
                  <ChipList
                    className={classes.filterChipList}
                    data={activitiesFiltersSelected}
                    onClose={(f: any) => removeFilterChip(f)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {activitySearchResultList?.results && activitySearchResultList.results.length > 0 ? (
                  activitySearchResultList.results.map((a) => (
                    <GridItem key={a.id} noPadding md={12} lg={12}>
                      <ActivitySearchResultElement
                        key={a.id}
                        activitySearchResult={a}
                        onClick={() => goToActivity(a.id, a.activityName)}
                      />
                    </GridItem>
                  ))
                ) : (
                  <CustomText bold>Non ci sono ancora attività{'\n'}corrispondenti alla ricerca</CustomText>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
};

type Props = {
  user: UserInfo | null;
  radius: number;
  activitySearchResultList: ActivitySearchResultResponse;
  activitiesFiltersSelected: ActivityFilter[];
  orderCounters: CountOrders | null;
};

const mapStateToProps = (state: State) => ({
  user: state.user.userInfo,
  radius: state.user.radius,
  activitySearchResultList: state.sectionUser.activitySearchResultList,
  activitiesFiltersSelected: state.sectionUser.activitiesFiltersSelected,
  orderCounters: state.sectionUser.orderCounters,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchRestaurants);
