import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomView from '../../../../components/atoms/CustomView';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {logAnalyticsEvent} from '../../../../services/firebaseService';
import {Fonts} from '../../../../styles/font';
import SettingIcon from '../../../../assets/images/ordify-bot/setting.svg';
import TelephoneIcon from '../../../../assets/images/ordify-bot/telephone.svg';
import BookIcon from '../../../../assets/images/ordify-bot/book.svg';
import MockupImage from '../../../../assets/images/ordify-bot/mockup.png';
import {Element} from './Element';

type Props = {};

const fontFamily: Fonts = 'Montserrat';

const elements = [
  {
    icon: SettingIcon,
    titleColor: 'black',
    title: '1. CONFIGURA I PARAMETRI DEL TUO LOCALE',
    description:
      'Seleziona quanti sono i posti a sedere, gli orari di prenotazione, i doppi turni e altre informazioni di gestione sala.',
  },
  {
    icon: TelephoneIcon,
    titleColor: 'black',
    title: '2. IMPOSTA IL BOT SECONDO LE TUE ESIGENZE',
    description:
      'Configura le risposte ad altre domande secondarie come la possibilità di portare cani, se il locale ha uno spazio per i bambini e molto altro',
  },
  {
    icon: BookIcon,
    titleColor: 'black',
    title: '3. RICEVI LE PRENOTAZIONI IN APP',
    description:
      'Dopo che il cliente avrà concluso la chiamata, riceverai i dettagli della prenotazione sulla nostra app dedicata. In caso di problemi potrai sempre annullare la prenotazione e il cliente verrà informato tramite SMS.',
  },
];

const useStyles = makeStyles({
  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '@media (max-width: 992px)': {
      textAlign: 'center',
    },
  },
  imgMockup: {
    position: 'relative',
    maxWidth: '400px',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    zIndex: 2,
  },
});

export const HowItWorks = ({}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const goToRequestTrial = () => {
    logAnalyticsEvent('cta_button_click', {
      page_title: 'OrdifyBot Landing Page',
      page_location: window.location.href,
      page_path: window.location.pathname,
      button_name: 'Request Trial 2',
    });
    const element = document.getElementById('richiedi-prova');
    if (element) {
      const to = element.offsetTop - 100;
      window.scrollTo({top: to, behavior: 'smooth'});
    }
  };

  return (
    <Box id={'come-funziona'}>
      <CustomView paddingVertical={30} paddingHorizontal={isMobile ? 20 : 80}>
        <CustomRow center marginBottom={16}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'ordifyBot'} center>
            Automatizza le tue prenotazioni tavolo.
          </CustomText>
        </CustomRow>
        <CustomRow center marginBottom={16}>
          <CustomText fontFamily={fontFamily} center>
            Ricevere e gestire le prenotazioni tavolo non è mai stato così comodo.
            <br /> &Egrave; molto più facile e intuitivo di quanto pensi
          </CustomText>
        </CustomRow>
        <GridContainer marginTop={60}>
          <GridItem md={6} centerHorizontal direction={'column'}>
            {elements.map(({icon, titleColor, title, description}, index) => (
              <CustomView key={index} marginBottom={20} marginTop={20}>
                <Element icon={icon} title={title} description={description} titleColor={titleColor} />
              </CustomView>
            ))}
          </GridItem>
          <GridItem md={6} marginTop={20} centerHorizontal>
            <div className={classes.imgContainer}>
              <img src={MockupImage} alt={'mockup'} className={classes.imgMockup} />
            </div>
          </GridItem>
        </GridContainer>
        <CustomRow marginTop={60} center>
          <CustomButton
            color={'ordifyBot'}
            size={isMobile ? 'md' : 'lg'}
            title={'Inizia ad automatizzare ora'}
            fontFamily={fontFamily}
            onClick={goToRequestTrial}
          />
        </CustomRow>
      </CustomView>
    </Box>
  );
};
