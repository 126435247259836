// import storage, {FirebaseStorageTypes} from '@react-native-firebase/storage';
// import ImageResizer from 'react-native-image-resizer';
import {Image} from '../models/shared/image';

export const uploadProfileImage = (img: Image) => {
  // let storageRef = storage().ref(`images/profiles/${img.imageID}`);
  // return uploadImage(storageRef, img.url);
};
export const deleteProfileImage = (imageID: string) => {
  // let storageRef = storage().ref(`images/profiles/${imageID}`);
  // return deleteImage(storageRef);
};

export const uploadActivityImage = (img: Image) => {
  // console.log('upload activity image', img);
  // let storageRef = storage().ref(`images/activities/${img.imageID}`);
  // return uploadImage(storageRef, img.url);
};
export const deleteActivityImage = (imageID: string) => {
  // console.log('delete activity image', imageID);
  // let storageRef = storage().ref(`images/activities/${imageID}`);
  // return deleteImage(storageRef);
};

export const uploadItemImage = (img: Image) => {
  // let storageRef = storage().ref(`images/items/${img.imageID}`);
  // return uploadImage(storageRef, img.url);
};
export const deleteItemImage = (imageID: string) => {
  // let storageRef = storage().ref(`images/items/${imageID}`);
  // return deleteImage(storageRef);
};

export const uploadImage = (storageRef: any, url: string) => {
  // return new Promise((resolve) => {
  //   ImageResizer.createResizedImage(url, 1920, 1080, 'JPEG', 75).then((response: any) => {
  //     storageRef.putFile(response.uri).on(
  //       storage.TaskEvent.STATE_CHANGED,
  //       (snapshot: any) => {
  //         console.log('snapshot: ' + snapshot.state);
  //         console.log('progress: ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //         if (snapshot.state === storage.TaskState.SUCCESS) {
  //           console.log('Success');
  //         }
  //       },
  //       (error: any) => {
  //         resolve(null);
  //         console.log('image upload error: ' + error.toString());
  //       },
  //       () => {
  //         storageRef
  //           .getDownloadURL()
  //           .then((downloadUrl: string) => {
  //             console.log('File available at: ' + downloadUrl);
  //             resolve(downloadUrl);
  //           })
  //           .catch(() => resolve(null));
  //       },
  //     );
  //   });
  // });
};

export const deleteImage = (storageRef: any) => {
  // return new Promise(async (resolve) => {
  //   storageRef
  //     .delete()
  //     .then(() => resolve({}))
  //     .catch(() => resolve({}));
  // });
};
