import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import hamburgerMobile from '../../../assets/images/landing/loginSection/hamburger-mobile.png';
import hamburgerWeb from '../../../assets/images/landing/loginSection/hamburger-web.png';

const useStyles = makeStyles(() => ({
  hamburgerWebStyle: {
    position: 'absolute',
    top: '280px',
    right: 0,
    width: '240px',
    height: '370px',
  },
  hamburgerMobileStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '80%',
  },
}));

export default function HamburgerImage() {
  const classes = useStyles();
  return (
    <>
      <Hidden mdUp>
        <img className={classes.hamburgerMobileStyle} src={hamburgerMobile} alt={'hamburger'} />
      </Hidden>
      <Hidden smDown>
        <img className={classes.hamburgerWebStyle} src={hamburgerWeb} alt={'hamburger'} />
      </Hidden>
    </>
  );
}
