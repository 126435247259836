import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {contactForInfo} from '../../../../api/authService';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomInput from '../../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../../components/atoms/CustomRow';
import {AppMessageType} from '../../../../components/atoms/CustomToast';
import CustomView from '../../../../components/atoms/CustomView';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {ORDIFY_BOT_THANK_YOU} from '../../../../constants';
import {Status} from '../../../../models/shared/custom-response';
import {disableLoader, enableLoader} from '../../../../redux/actions/globalActions';
import {logAnalyticsEvent} from '../../../../services/firebaseService';
import {fbPixel} from '../../../../services/fpixel';
import {navigate} from '../../../../services/navigationService';
import {showToastMessage} from '../../../../services/toastService';
import {Fonts} from '../../../../styles/font';
import {isEmailValid} from '../../../../utils/commonFunctions';

type Props = {};

const fontFamily: Fonts = 'Montserrat';

const useStyles = makeStyles({
  section: {
    width: '80%',
    margin: 'auto',
    paddingTop: 20,
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
});

export const RequestTrial = ({}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [activityName, setActivityName] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  const canSend = () => {
    return name && email && !sent;
  };

  const send = async () => {
    if (!isEmailValid(email)) {
      showToastMessage(AppMessageType.ERROR, 'Email non valida');
      return;
    }
    dispatch(enableLoader());
    const res = await contactForInfo({
      name,
      email,
      activityName,
      city,
      message,
      object: 'Ordify Bot Info',
    });
    dispatch(disableLoader());
    if (res.status === Status.OK) {
      setName('');
      setEmail('');
      setActivityName('');
      setCity('');
      setSent(true);
      logAnalyticsEvent('email_sent', {
        page_title: 'OrdifyBot Landing Page',
        page_location: window.location.href,
        page_path: window.location.pathname,
        email,
      });
      navigate(ORDIFY_BOT_THANK_YOU);
      fbPixel.track('StartTrial');
      showToastMessage(AppMessageType.SUCCESS, 'Email inviata con successo');
    } else {
      logAnalyticsEvent('email_error', {
        page_title: 'OrdifyBot Landing Page',
        page_location: window.location.href,
        page_path: window.location.pathname,
        email,
      });
      showToastMessage(AppMessageType.ERROR, "Errore durante l'invio della mail. Prova più tardi.");
    }
  };

  return (
    <Box id={'richiedi-prova'}>
      <CustomView paddingTop={30} paddingBottom={120} paddingHorizontal={isMobile ? 20 : 80}>
        <CustomRow center marginBottom={16}>
          <CustomText bold fontFamily={fontFamily} size={'xxl'} color={'ordifyBot'} center>
            Non perdere tempo, contattaci ora
          </CustomText>
        </CustomRow>
        <CustomRow center marginBottom={16}>
          <CustomText fontFamily={fontFamily} center>
            Inserisci i dati per richiedere le informazioni di cui hai bisogno.
          </CustomText>
        </CustomRow>
        <GridContainer justifyContent={'center'} alignItems={'center'} fullWidth className={classes.section}>
          <GridItem xs={12} sm={6} md={6} marginTop>
            <CustomInput placeholder={'Nome'} value={name} onChange={(v) => setName(v)} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} marginTop>
            <CustomInput placeholder={'Email'} type={'email'} value={email} onChange={(v) => setEmail(v)} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} marginTop>
            <CustomInput placeholder={'Nome attività'} value={activityName} onChange={(v) => setActivityName(v)} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} marginTop>
            <CustomInput placeholder={'Provincia'} value={city} onChange={(v) => setCity(v)} />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomInput
              placeholder={'Scrivi qui il motivo del contatto'}
              multiline
              value={message}
              onChange={(v) => setMessage(v)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomButton fullWidth color={'primary'} onClick={send} disabled={!canSend()}>
              <div>
                <CustomRow full center>
                  <CustomText color={'white'} center>
                    Contattaci
                  </CustomText>
                </CustomRow>
                <CustomRow full center>
                  <CustomText size={'sm'} color={'white'} center>
                    Rispondiamo entro un'ora
                  </CustomText>
                </CustomRow>
              </div>
            </CustomButton>
          </GridItem>
        </GridContainer>
      </CustomView>
    </Box>
  );
};
