import {Delete} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import CustomCard from '../../../../components/atoms/Card/CustomCard';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../../components/atoms/CustomRow';
import {AppMessageType} from '../../../../components/atoms/CustomToast';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Choice, Option} from '../../../../models/menu/option';
import {showToastMessage} from '../../../../services/toastService';
import {BACKGROUND_PAGE_COLOR, primaryColor} from '../../../../styles/colors';
import {Spacing} from '../../../../styles/spacing';

const ItemOption = ({style, option, selectedChoices, addOptionChoice, removeOptionChoice}: Props) => {
  const classes = useStyle();

  const onPress = (c: Choice) => {
    if (selectedChoices && option) {
      if (
        option?.maxChoisesSelectable === null ||
        option?.maxChoisesSelectable === 1 ||
        selectedChoices?.length < option?.maxChoisesSelectable
      ) {
        addOptionChoice(c, option);
      } else {
        showToastMessage(
          AppMessageType.WARNING,
          'Puoi selezionare al massimo ' +
            option?.maxChoisesSelectable +
            ` scelt${option.maxChoisesSelectable > 1 ? 'e' : 'a'} per questa opzione`,
        );
      }
    }
  };

  const showDelete = (c: Choice) => {
    if (!option) {
      return false;
    }
    const countSelected = selectedChoices?.filter((sc) => sc.label === c.label).length;
    return countSelected && (!option.required || option.maxChoisesSelectable > 1);
  };

  function isSelected(c: Choice) {
    return !!selectedChoices?.find((sc) => sc.label === c.label);
  }

  return (
    <>
      {option && (
        <div className={classes.baseStyle}>
          <div className={classes.titleStyle}>
            <CustomText bold color={'primary'}>
              {option.label}{' '}
              {option.maxChoisesSelectable &&
                option.maxChoisesSelectable > 1 &&
                ` - max. ${option.maxChoisesSelectable} `}
              {option.required && ' - obbligatorio'}
            </CustomText>
          </div>
          <div>
            {option.choises.map(
              (c) =>
                c.visible && (
                  <CustomCard divider plain key={c.label} className={classes.choiseStyle} onClick={() => onPress(c)}>
                    <CustomRow>
                      {isSelected(c) &&
                        (!(option?.maxChoisesSelectable === 1) ? (
                          <div className={classes.selectedWithNumber}>
                            <CustomText color={'white'} size={'xs'}>
                              {selectedChoices?.filter((sc) => sc.label === c.label).length}
                            </CustomText>
                          </div>
                        ) : (
                          <div className={classes.selected} />
                        ))}
                      <CustomText maxWidth={55} bold={isSelected(c)}>
                        {c.label}
                      </CustomText>
                      <CustomRow className={classes.rightSection}>
                        {c.price !== 0 && (
                          <CustomText className={classes.choisePrice} bold={isSelected(c)}>
                            {c.price > 0 ? '+' : ''} {c.price} €
                          </CustomText>
                        )}
                        {!!showDelete(c) && (
                          <CustomButton icon={Delete} iconColor={'primary'} onClick={() => removeOptionChoice(c)} />
                        )}
                      </CustomRow>
                    </CustomRow>
                  </CustomCard>
                ),
            )}
          </div>
        </div>
      )}
    </>
  );
};

type Props = {
  style?: any;
  option?: Option;
  selectedChoices?: Choice[];
  addOptionChoice?: any;
  removeOptionChoice?: any;
};

const useStyle = makeStyles({
  baseStyle: {},
  titleStyle: {
    backgroundColor: BACKGROUND_PAGE_COLOR,
    padding: '12px',
  },
  choiseStyle: {
    padding: '12px',
  },
  choisePrice: {
    paddingRight: Spacing.SCALE_12,
  },
  rightSection: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: Spacing.SCALE_8,
  },
  selected: {
    backgroundColor: primaryColor,
    padding: '8px',
    borderRadius: '40px',
    marginRight: Spacing.SCALE_8,
  },
  selectedWithNumber: {
    backgroundColor: primaryColor,
    padding: '4px 8px',
    borderRadius: '40px',
    marginRight: Spacing.SCALE_8,
  },
});

export default ItemOption;
