import {Theme} from '@material-ui/core';
import {facebookColor, instagramColor, primaryColor} from '../../../styles/colors';

const footerStyle = (theme: Theme) =>
  ({
    footer: {
      fontSize: '32px',
      height: '500px',
    },
    links: {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 767px)': {
        display: 'initial',
        textAlign: 'center',
      },
    },
    contactInfo: {
      textAlign: 'center',
      '@media (max-width: 767px)': {
        textAlign: 'center',
      },
    },
    companyInfo: {
      fontSize: '13px',
      textAlign: 'right',
      padding: '0 26px',
      '@media (max-width: 767px)': {
        textAlign: 'center',
      },
    },
    block: {
      color: 'inherit',
      padding: '0.9375rem',
      fontWeight: '500',
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '3px',
      textDecoration: 'none',
      position: 'relative',
      display: 'block',
    },
    a: {
      color: primaryColor,
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    footerWhiteFont: {
      '&,&:hover,&:focus': {
        color: '#FFFFFF',
      },
    },
    list: {
      marginBottom: '0',
      padding: '0',
      marginTop: '0',
    },
    inlineBlock: {
      display: 'inline-block',
      padding: '0px',
      width: 'auto',
    },
    icon: {
      width: '18px',
      height: '18px',
      position: 'relative',
      top: '3px',
    },
    iconButtonContainer: {
      paddingLeft: '12px',
    },
    iconButton: {
      padding: '6px',
    },
    facebookIcon: {
      width: '26px',
      height: '26px',
      color: facebookColor,
    },
    instagramIcon: {
      width: '26px',
      height: '26px',
      color: instagramColor,
    },
    blackFooter: {
      position: 'absolute',
      height: '500px',
      '@media (min-width: 320px) and (max-width: 1024px)': {
        right: '0px',
      },
      '@media (min-width: 992px)': {
        width: '100%',
      },
    },
    footerContainer: {
      paddingTop: '200px',
      '@media (min-width: 320px) and (max-width: 1024px)': {
        paddingTop: '100px',
      },
    },
    marginLeft: {
      marginLeft: '50px',
      '@media (max-width: 1024px)': {
        marginLeft: '0px',
      },
    },
    pivaBoxFooter: {
      marginLeft: '65px',
      marginTop: '100px',
      '@media (max-width: 1024px)': {
        marginLeft: '15px',
        marginTop: '30px',
      },
    },
  } as any);

export default footerStyle as any;
