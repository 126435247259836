import {Menu} from '../../models/menu/menu';
import {SmartMenuTemplateConfig} from '../../models/smartMenu/smart-menu-config';
import {SmartMenuActionTypes} from '../actions/smartMenuActions';

export interface SmartMenuState {
  menuList?: Menu[];
  selectedMenu?: Menu | null;
  templateConfig?: SmartMenuTemplateConfig;
  templateConfigLoading?: boolean;
}

const initialState: SmartMenuState = {};

const smartMenuReducer = (state: SmartMenuState = initialState, action: {type: string; payload: any}) => {
  switch (action.type) {
    case SmartMenuActionTypes.LOAD_MENU_SUCCESS: {
      return {
        ...state,
        menuList: action.payload.menuList,
      };
    }
    case SmartMenuActionTypes.SET_SELECTED_MENU: {
      return {
        ...state,
        selectedMenu: action.payload.menu,
      };
    }
    case SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG: {
      return {
        ...state,
        templateConfig: action.payload.templateConfig,
        templateConfigLoading: true,
      };
    }
    case SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG_SUCCESS: {
      return {
        ...state,
        templateConfig: action.payload.templateConfig,
        templateConfigLoading: false,
      };
    }
    case SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG_ERROR: {
      return {
        ...state,
        templateConfigLoading: false,
      };
    }
    case SmartMenuActionTypes.RESET_MENU_IN_TAVOLA:
      return {...initialState};
    default:
      return state;
  }
};

export default smartMenuReducer;
