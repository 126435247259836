import React, {useEffect, useState} from 'react';
import {HOME_HEADER_LINKS, SEARCH} from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import {registrationAnonymousUser} from '../../redux/actions/registrationActions';
import {State} from '../../redux/reducers';
import {Coords} from '../../models/shared/coords';
import {makeStyles} from '@material-ui/core';
import SmartMenuSection from './components/smartMenuSection/SmartMenuSection';
import landingPageStyle from './style';
import Header from '../../components/organisms/Header/Header';
import BackgroundImage from '../../components/organisms/BackgroundImage';
import GridContainer from '../../components/atoms/Grid/GridContainer';
import GridItem from '../../components/atoms/Grid/GridItem';
import Footer from '../../components/organisms/Footer/Footer';
import homeBg from '../../assets/images/landing/header/texture.svg';
import pizzaImage from '../../assets/images/landing/header/pizza.png';
import CustomGooglePlacesAutocomplete from '../../components/atoms/CustomGooglePlacesAutocomplete/CustomGooglePlacesAutocomplete';
import {GooglePlaceSearchResponse} from '../../models/shared/google-api';
import {navigate} from '../../services/navigationService';
import {UserInfo} from '../../models/user/user-info.interface';
import store from '../../redux/store';
import {UpdateUserRequest} from '../../models/user/request-objects/update-user';
import {updateUser} from '../../redux/actions/userActions';
import {fbPixel} from '../../services/fpixel';
import CustomText from '../../components/atoms/Typography/CustomText';
import OrderSection from './components/orderSection/OrderSection';
import VideoSection from './components/videoSection/VideoSection';
import LaunchSection from './components/launchSection/LaunchSection';
import FidelitySection from './components/fidelitySection/FidelitySection';
import RecensioniSection from './components/recensioniSection/RecensioniSection';
import DownloadSection from './components/downloadSection/DownloadSection';
import ChiSiamoSection from './components/chiSiamoSection/ChiSiamoSection';
import DiscountSection from './components/discountSection/DiscountSection';
import ModalDownloadMobileApp from '../../components/molecules/ModalDownloadMobileApp';

const useStyles = makeStyles(landingPageStyle);

const LandingMainPage = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((s: State) => s.user.userInfo);
  const [placeValue, setPlaceValue] = useState<string>();
  const [coords, setCoords] = useState<Coords>();

  const classes = useStyles();

  useEffect(() => {
    if (userInfo && userInfo.userInfo?.location?.coords) {
      setPlaceValue(userInfo.userInfo.location.cityName);
      setCoords(userInfo.userInfo?.location?.coords);
    }
  }, [userInfo]);

  const placeSelected = async (data: GooglePlaceSearchResponse) => {
    const {lat, lng} = data.geometry.location;
    const tempCoords = {latitude: lat(), longitude: lng()};
    setCoords(tempCoords);
    if (tempCoords) {
      const user: UserInfo = await store.getState().user.userInfo;
      if (!user) {
        dispatch(
          registrationAnonymousUser({
            registrationAnonymousUserPayload: {coords: tempCoords},
            postRegistrationFunc: () => goToSearch(tempCoords),
          }),
        );
      } else {
        dispatch(
          updateUser({
            updateUserRequest: {
              userInfo: {coords: tempCoords},
            } as UpdateUserRequest,
            postUpdateFunc: () => goToSearch(tempCoords),
          }),
        );
      }
    }
  };

  const goToSearch = (_coords?: Coords) => {
    fbPixel.track('ViewContent', {content_category: 'Vedi ristoranti', content_name: 'Vedi ristoranti'});
    if (!_coords && !coords) {
      return '';
    }
    const search = _coords
      ? `?coords=${_coords?.latitude},${_coords?.longitude}`
      : `?coords=${coords?.latitude},${coords?.longitude}`;
    navigate(SEARCH, {}, search);
  };

  return (
    <div style={{overflow: 'hidden'}}>
      <ModalDownloadMobileApp />
      <Header
        color="transparent"
        textColor={'white'}
        links={HOME_HEADER_LINKS}
        fixed
        inverted={true}
        isHome={true}
        showIntersectionImage
        changeColorOnScroll={{
          height: 100,
          color: 'white',
          textColor: 'primary',
        }}
      />
      <BackgroundImage image={homeBg} imageMobile={homeBg}>
        <GridContainer style={{height: '800px'}} justifyContent={'space-between'} direction={'row'}>
          <GridItem md={12} xs={12} sm={12} lg={8}>
            <GridContainer className={classes.boxContainerTitle}>
              <GridItem md={12}>
                <CustomText fontFamily={'Goda'} color={'white'} size={'xxl'}>
                  I TUOI PIATTI PREFERITI, <br /> A PORTATA DI CLICK.
                </CustomText>
              </GridItem>
              <GridItem md={12}>
                <CustomText color={'white'} size={'md'}>
                  Supportiamo le attività locali e valorizziamo il territorio.
                </CustomText>
              </GridItem>
              <GridItem md={12}>
                <CustomText color={'white'} size={'md'}>
                  Cerca le più vicine a te!
                </CustomText>
              </GridItem>
              <GridItem marginTop={40} xs={12} sm={8} md={9}>
                <CustomGooglePlacesAutocomplete
                  className={classes.googlePlacesInput}
                  value={placeValue}
                  onChange={(s) => setPlaceValue(s)}
                  defaultValue={userInfo?.userInfo?.location?.description}
                  searchFunction={() => goToSearch()}
                  backgroundInputColor={'white'}
                  placeholder={'Qui il mio indirizzo'}
                  onPlaceSelected={placeSelected}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={4}>
            <img className={classes.pizzaStyle} src={pizzaImage} alt={'pizza'} />
          </GridItem>
        </GridContainer>
      </BackgroundImage>
      <div id={'chi-siamo'}>
        <VideoSection />
        <ChiSiamoSection />
      </div>
      <div id={'servizi'}>
        <OrderSection />
        <LaunchSection />
        <FidelitySection />
      </div>
      <RecensioniSection id={'recensioni'} />
      <DiscountSection id={'sconti'} />
      <DownloadSection id={'scarica-app'} />
      <SmartMenuSection id={'smartmenu'} />
      <Footer />
    </div>
  );
};

export default LandingMainPage;
