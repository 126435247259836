import {Menu} from '../../models/menu/menu';
import {SmartMenuTemplateConfig} from '../../models/smartMenu/smart-menu-config';

export enum SmartMenuActionTypes {
  LOAD_MENU = '[SmartMenuState] LoadMenu',
  LOAD_MENU_SUCCESS = '[SmartMenuState] LoadMenuSuccess',
  SET_SELECTED_MENU = '[SmartMenuState] SetSelectedMenu',
  LOAD_TEMPLATE_CONFIG = '[SmartMenuState] LoadTemplateConfig',
  LOAD_TEMPLATE_CONFIG_SUCCESS = '[SmartMenuState] LoadTemplateConfigSuccess',
  LOAD_TEMPLATE_CONFIG_ERROR = '[SmartMenuState] LoadTemplateConfigError',
  // general
  RESET_MENU_IN_TAVOLA = '[SmartMenuState] ResetMenuInTavola',
}

export const loadMenu = (payload: {id: string; language?: string}) => {
  return {
    type: SmartMenuActionTypes.LOAD_MENU,
    payload,
  };
};
export const loadMenuSuccess = (payload: {menuList: Menu[] | null}) => {
  return {
    type: SmartMenuActionTypes.LOAD_MENU_SUCCESS,
    payload,
  };
};

export const setSelectedMenu = (payload: {menu: Menu | null}) => {
  return {
    type: SmartMenuActionTypes.SET_SELECTED_MENU,
    payload,
  };
};

export const loadSmartMenuTemplateConfig = (payload: {merchantID: string}) => {
  return {
    type: SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG,
    payload,
  };
};
export const loadSmartMenuTemplateConfigSuccess = (payload: {templateConfig: SmartMenuTemplateConfig}) => {
  return {
    type: SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG_SUCCESS,
    payload,
  };
};
export const loadSmartMenuTemplateConfigError = () => {
  return {
    type: SmartMenuActionTypes.LOAD_TEMPLATE_CONFIG_ERROR,
  };
};

export const resetMenuInTavola = () => {
  return {
    type: SmartMenuActionTypes.RESET_MENU_IN_TAVOLA,
  };
};
