import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../components/atoms/CustomRow';
import PageLoginContainer from '../_Shared/PageLoginContainer';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {showToastMessage} from '../../../services/toastService';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {forgotPasswordSendToken} from '../../../redux/actions/authActions';

export default function ForgotPassword() {
  const [mail, setMail] = useState('');
  const dispatch = useDispatch();

  const callRecoverPassword = (email: string) => {
    if (!email) {
      showToastMessage(AppMessageType.ERROR, 'Compila tutti i campi');
    } else {
      dispatch(forgotPasswordSendToken({email}));
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (mail) {
          callRecoverPassword(mail);
          event.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [mail]);

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText center bold size={'xl'} color={'primary'} fontFamily={'Goda'}>
            RECUPERA LA PASSWORD
          </CustomText>
        </CustomRow>
        <CustomRow marginTop>
          <CustomInput placeholder="Email" value={mail} type={'text'} onChange={(text: string) => setMail(text)} />
        </CustomRow>
        <CustomRow marginTop>
          <CustomButton fullWidth onClick={() => callRecoverPassword(mail)} title={'Recupera Password'} />
        </CustomRow>
      </PageLoginContainer>
    </>
  );
}
