import {useMediaQuery, useTheme} from '@material-ui/core';
import React from 'react';

const ContentResponsive = ({mobile, desktop}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return <>{isMobile ? mobile : desktop}</>;
};

type Props = {
  mobile: React.ReactNode;
  desktop: React.ReactNode;
};

export default ContentResponsive;
