import React, {useEffect, useState} from 'react';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomInput from '../../../../components/atoms/CustomInput/CustomInput';
import CustomRow from '../../../../components/atoms/CustomRow';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import styles from '../../../../components/atoms/CustomInput/customInputStyle';
import {EmailRegistrationPayload} from '../../../../models/auth/redux/auth-payload';

const RegistrationForm = ({register}: Props) => {
  const useStyles = makeStyles(styles);

  const classes = useStyles();

  const customButtonClasses = classNames({
    [classes.underline]: false,
    [classes.round]: true,
    [classes.centralText]: true,
  });

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const _registerUser = () => {
    register({
      name: name.trim(),
      surname: surname.trim(),
      email: email.trim(),
      password,
      passwordConfirm,
    } as EmailRegistrationPayload);
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (name && surname && email && password && passwordConfirm) {
          _registerUser();
          event.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [name, surname, email, password, passwordConfirm]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} md={6} marginTop>
          <CustomInput
            className={customButtonClasses}
            placeholder="Nome"
            value={name}
            onChange={(text: string) => setName(text)}
          />
        </GridItem>
        <GridItem xs={12} md={6} marginTop>
          <CustomInput placeholder="Cognome" value={surname} onChange={(text: string) => setSurname(text)} />
        </GridItem>
        <GridItem xs={12} marginTop>
          <CustomInput
            placeholder="Email"
            value={email}
            type={'email-address'}
            onChange={(text: string) => setEmail(text)}
          />
        </GridItem>
        <GridItem xs={12} marginTop>
          <CustomInput
            placeholder="Password"
            value={password}
            type={'password'}
            onChange={(text: string) => setPassword(text)}
          />
        </GridItem>
        <GridItem xs={12} marginTop>
          <CustomInput
            placeholder="Conferma password"
            value={passwordConfirm}
            type={'password'}
            onChange={(text: string) => setPasswordConfirm(text)}
          />
        </GridItem>
        <GridItem xs={12} marginTop>
          <CustomButton fullWidth onClick={_registerUser} title={'Registrati'} />
        </GridItem>
      </GridContainer>
    </>
  );
};

type Props = {
  register: (payload: EmailRegistrationPayload) => void;
};

export default RegistrationForm;
