import {OrderCartItem} from '../../models/orders/order-cart-item';
import {ActivityDetail} from '../../models/user/activity-detail';
import {ActivityFilter} from '../../models/user/activity-filter';
import {ActivitySearchResult, ActivitySearchResultResponse} from '../../models/user/activity-search-result';
import {TableReservationForm} from '../../models/tableReservationSettings/request-objects/upsert-table-reservation-settings.dto';
import {ActivityDetailTab} from '../../pages/ActivityDetail/components/MainContent';

export enum SectionUserActionTypes {
  SEARCH_ACTIVITIES = '[SectionUserState] SearchActivities',
  SEARCH_ACTIVITIES_SUCCESS = '[SectionUserState] SearchActivitiesSuccess',
  LOAD_FAVORITE_ACTIVITIES = '[SectionUserState] LoadFavoriteActivities',
  LOAD_FAVORITE_ACTIVITIES_SUCCESS = '[SectionUserState] LoadFavoriteActivitiesSuccess',
  GET_ACTIVITIES_FILTERS = '[SectionUserState] GetActivitiesFilters',
  GET_ACTIVITIES_FILTERS_SUCCESS = '[SectionUserState] GetActivitiesFiltersSuccess',
  SET_ACTIVITIES_FILTERS_SELECTED = '[SectionUserState] SetActivitiesFiltersSelected',
  LOAD_ACTIVITY_DETAIL = '[SectionUserState] LoadActivityDetail',
  LOAD_ACTIVITY_DETAIL_SUCCESS = '[SectionUserState] LoadActivityDetailSuccess',
  UPDATE_ORDER_CART = '[SectionUserState] UpdateOrderCart',
  UPDATE_TABLE_RESERVATION_INFO = '[SectionUserState] UpdateTableReservationInfo',
  SET_ACTIVE_TAB = '[SectionUserState] SetActiveTab',
  // general
  RESET_SECTION_USER = '[SectionUserState] ResetSectionUser',
}

export const searchActivities = (payload: {term: string | null; filter: string | null} = {term: '', filter: ''}) => {
  return {
    type: SectionUserActionTypes.SEARCH_ACTIVITIES,
    payload,
  };
};
export const searchActivitiesSuccess = (payload: {activitySearchResultList: ActivitySearchResultResponse}) => {
  return {
    type: SectionUserActionTypes.SEARCH_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const loadFavoriteActivities = (payload?: {hideLoader?: boolean}) => {
  return {
    type: SectionUserActionTypes.LOAD_FAVORITE_ACTIVITIES,
    payload,
  };
};
export const loadFavoriteActivitiesSuccess = (payload: {favoriteActivities: ActivitySearchResult[]}) => {
  return {
    type: SectionUserActionTypes.LOAD_FAVORITE_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const getActivitiesFilters = () => {
  return {
    type: SectionUserActionTypes.GET_ACTIVITIES_FILTERS,
  };
};
export const getActivitiesFiltersSuccess = (payload: {activitiesFilters: ActivityFilter[]}) => {
  return {
    type: SectionUserActionTypes.GET_ACTIVITIES_FILTERS_SUCCESS,
    payload,
  };
};
export const setActivitiesFiltersSelected = (payload: {activitiesFilters: ActivityFilter[]}) => {
  return {
    type: SectionUserActionTypes.SET_ACTIVITIES_FILTERS_SELECTED,
    payload,
  };
};

export const loadActivityDetail = (payload: {id: string}) => {
  return {
    type: SectionUserActionTypes.LOAD_ACTIVITY_DETAIL,
    payload,
  };
};
export const loadActivityDetailSuccess = (payload: {activityDetail: ActivityDetail | null}) => {
  return {
    type: SectionUserActionTypes.LOAD_ACTIVITY_DETAIL_SUCCESS,
    payload,
  };
};

export const updateOrderCart = (payload: {orderCart: OrderCartItem[]}) => {
  return {
    type: SectionUserActionTypes.UPDATE_ORDER_CART,
    payload,
  };
};

export const updateTableReservationInfo = (payload: TableReservationForm) => {
  return {
    type: SectionUserActionTypes.UPDATE_TABLE_RESERVATION_INFO,
    payload,
  };
};

export const setActiveTab = (activeTab: ActivityDetailTab) => {
  return {
    type: SectionUserActionTypes.SET_ACTIVE_TAB,
    payload: {activeTab},
  };
};

export const resetSectionUser = () => {
  return {
    type: SectionUserActionTypes.RESET_SECTION_USER,
  };
};
