import React, {HTMLInputTypeAttribute} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

import styles from './customInputStyle';
import {FormControl, TextField} from '@material-ui/core';

const useStyles = makeStyles(styles);

export const CustomInput = React.forwardRef(
  (
    {
      id,
      value,
      onChange,
      placeholder,
      label,
      disabled,
      error,
      type,
      fullWidth = true,
      inputRef,
      variant = 'standard',
      className,
      multiline,
      inputProps,
      defaultValue,
      backgroundInputColor = 'grey',
      ...other
    }: Props,
    ref,
  ) => {
    const classes = useStyles();

    const formControlClasses = classNames({
      [classes.formControl]: !label,
      [classes.formControlFullWidth]: fullWidth,
      [classes[backgroundInputColor]]: !label && !!backgroundInputColor,
      ...(className && {[className]: true}),
    });

    const inputClasses = classNames({
      [classes.input]: true,
      [classes.inputWithLabel]: !!label,
      [classes[backgroundInputColor]]: !label && !!backgroundInputColor,
    });

    const _onChange = (event: any) => {
      onChange && onChange(event.target.value);
    };

    return (
      <FormControl className={formControlClasses}>
        <TextField
          inputRef={inputRef}
          id={id}
          value={value}
          defaultValue={defaultValue}
          onChange={_onChange}
          multiline={multiline}
          minRows={multiline ? 4 : undefined}
          placeholder={placeholder}
          label={label}
          type={type}
          fullWidth={fullWidth}
          color={'primary'}
          variant={label ? 'filled' : 'standard'}
          disabled={disabled}
          InputProps={{
            className: inputClasses,
            disableUnderline: true,
            ...inputProps,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
          {...other}
        />
      </FormControl>
    );
  },
);

type Props = {
  id?: string;
  className?: string;
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  multiline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  inputRef?: any;
  inputProps?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  defaultValue?: string;
  backgroundInputColor?: 'white' | 'grey';
};

export default CustomInput;
