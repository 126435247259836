import {getFaqs} from '../../../api/globalConfigService';
import {Faq} from '../../../models/globalConfig/faq';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {disableLoader, enableLoader, loadFaqsSuccess} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';

export default function* loadFaqsAsync() {
  let faqs: Faq[] = yield select((state: State) => state.global.lists.faqs);
  if (faqs && faqs.length === 0) {
    yield put(enableLoader());
    const response: CustomResponse<Faq[]> = yield getFaqs();
    if (response.status === Status.OK) {
      yield put(loadFaqsSuccess(response.payload));
    }
    yield put(disableLoader());
  }
}
