import {Allergen, AllergenType} from '../models/menu/allergens';
import gluten_icon from '../assets/images/allergens/gluten.png';
import gluten_icon_titled from '../assets/images/allergens/gluten_titled.png';
import crustaceans_icon from '../assets/images/allergens/crustaceans.png';
import crustaceans_icon_titled from '../assets/images/allergens/crustaceans_titled.png';
import eggs_icon from '../assets/images/allergens/eggs.png';
import eggs_icon_titled from '../assets/images/allergens/eggs_titled.png';
import fish_icon from '../assets/images/allergens/fish.png';
import fish_icon_titled from '../assets/images/allergens/fish_titled.png';
import peanuts_icon from '../assets/images/allergens/peanuts.png';
import peanuts_icon_titled from '../assets/images/allergens/peanuts_titled.png';
import soybeans_icon from '../assets/images/allergens/soybeans.png';
import soybeans_icon_titled from '../assets/images/allergens/soybeans_titled.png';
import milk_icon from '../assets/images/allergens/milk.png';
import milk_icon_titled from '../assets/images/allergens/milk_titled.png';
import nuts_icon from '../assets/images/allergens/nuts.png';
import nuts_icon_titled from '../assets/images/allergens/nuts_titled.png';
import celery_icon from '../assets/images/allergens/celery.png';
import celery_icon_titled from '../assets/images/allergens/celery_titled.png';
import mustard_icon from '../assets/images/allergens/mustard.png';
import mustard_icon_titled from '../assets/images/allergens/mustard_titled.png';
import sesame_icon from '../assets/images/allergens/sesame.png';
import sesame_icon_titled from '../assets/images/allergens/sesame_titled.png';
import sulphur_dioxide_icon from '../assets/images/allergens/sulphur-dioxide.png';
import sulphur_dioxide_icon_titled from '../assets/images/allergens/sulphur-dioxide_titled.png';
import lupin_icon from '../assets/images/allergens/lupin.png';
import lupin_icon_titled from '../assets/images/allergens/lupin_titled.png';
import molluscs_icon from '../assets/images/allergens/molluscs.png';
import molluscs_icon_titled from '../assets/images/allergens/molluscs_titled.png';

export const ALLERGENS = {
  GLUTEN: 'gluten',
  CRUSTACEANS: 'crustaceans',
  EGGS: 'eggs',
  FISH: 'fish',
  PEANUTS: 'peanuts',
  SOYBEANS: 'soybeans',
  MILK: 'milk',
  NUTS: 'nuts',
  CELERY: 'celery',
  MUSTARD: 'mustard',
  SESAME: 'sesame',
  SULPHUR_DIOXIDE: 'sulphur-dioxide',
  LUPIN: 'lupin',
  MOLLUSCS: 'molluscs',
} as const;

export const allergens: Record<AllergenType, Allergen> = {
  [ALLERGENS.GLUTEN]: {
    id: ALLERGENS.GLUTEN,
    name: 'Glutine',
    description: 'Sostanza proteica contenuta in alcuni cereali',
    image: gluten_icon,
    imageTitled: gluten_icon_titled,
  },
  [ALLERGENS.CRUSTACEANS]: {
    id: ALLERGENS.CRUSTACEANS,
    name: 'Crostacei',
    description: 'Qualsiasi animale marino dotato di un carapace o di un esoscheletro simile',
    image: crustaceans_icon,
    imageTitled: crustaceans_icon_titled,
  },
  [ALLERGENS.EGGS]: {
    id: ALLERGENS.EGGS,
    name: 'Uova',
    description: 'Prodotto alimentare ottenuto dalle ovaie delle galline',
    image: eggs_icon,
    imageTitled: eggs_icon_titled,
  },
  [ALLERGENS.FISH]: {
    id: ALLERGENS.FISH,
    name: 'Pesce',
    description: 'Qualsiasi animale acquatico dotato di branchie',
    image: fish_icon,
    imageTitled: fish_icon_titled,
  },
  [ALLERGENS.PEANUTS]: {
    id: ALLERGENS.PEANUTS,
    name: 'Arachidi',
    description: "Frutto dell'omonima pianta leguminosa",
    image: peanuts_icon,
    imageTitled: peanuts_icon_titled,
  },
  [ALLERGENS.SOYBEANS]: {
    id: ALLERGENS.SOYBEANS,
    name: 'Soia',
    description: "Pianta leguminosa originaria dell'Asia orientale",
    image: soybeans_icon,
    imageTitled: soybeans_icon_titled,
  },
  [ALLERGENS.MILK]: {
    id: ALLERGENS.MILK,
    name: 'Latticini',
    description: 'Prodotti derivati dal latte di animali come le vacche, le pecore o le capre',
    image: milk_icon,
    imageTitled: milk_icon_titled,
  },
  [ALLERGENS.CELERY]: {
    id: ALLERGENS.CELERY,
    name: 'Sedano',
    description: 'Pianta erbacea dalle proprietà aromatiche',
    image: celery_icon,
    imageTitled: celery_icon_titled,
  },
  [ALLERGENS.MUSTARD]: {
    id: ALLERGENS.MUSTARD,
    name: 'Senape',
    description: 'Pianta erbacea coltivata per i suoi semi, utilizzati per produrre la senape',
    image: mustard_icon,
    imageTitled: mustard_icon_titled,
  },
  [ALLERGENS.SESAME]: {
    id: ALLERGENS.SESAME,
    name: 'Sesamo',
    description: 'Pianta erbacea coltivata per i suoi semi, utilizzati come ingrediente in molti piatti',
    image: sesame_icon,
    imageTitled: sesame_icon_titled,
  },
  [ALLERGENS.LUPIN]: {
    id: ALLERGENS.LUPIN,
    name: 'Lupini',
    description: 'Lupini e prodotti a base di lupini',
    image: lupin_icon,
    imageTitled: lupin_icon_titled,
  },
  [ALLERGENS.MOLLUSCS]: {
    id: ALLERGENS.MOLLUSCS,
    name: 'Molluschi',
    description: 'Molluschi e prodotti a base di molluschi',
    image: molluscs_icon,
    imageTitled: molluscs_icon_titled,
  },
  [ALLERGENS.NUTS]: {
    id: ALLERGENS.NUTS,
    name: 'Frutta a guscio',
    description: 'Frutto contenuto in un guscio resistente come noci, mandorle, pistacchi, ecc.',
    image: nuts_icon,
    imageTitled: nuts_icon_titled,
  },
  [ALLERGENS.SULPHUR_DIOXIDE]: {
    id: ALLERGENS.SULPHUR_DIOXIDE,
    name: 'Anidride solforosa e solfiti',
    description: 'Gas incolore e irritante utilizzato come conservante nei cibi',
    image: sulphur_dioxide_icon,
    imageTitled: sulphur_dioxide_icon_titled,
  },
};
