import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../../redux/reducers';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../../components/atoms/CustomRow';
import {Spacing} from '../../../../styles/spacing';
import CustomCard from '../../../../components/atoms/Card/CustomCard';
import {OrderCartItem} from '../../../../models/orders/order-cart-item';
import {CategoryForMenu, ItemForMenu} from '../../../../models/menu/category';
import {updateOrderCart} from '../../../../redux/actions/sectionUserActions';
import * as _ from 'lodash';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import ModalItem from '../ModalItem/ModalItem';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    '@media (max-width: 480px)': {
      padding: '0px',
    },
  },
  categoryContainer: {
    marginTop: '16px',
  },
  titleCategory: {
    paddingLeft: '30px',
  },
  description: {
    paddingLeft: '30px',
  },
  itemOrdered: {
    marginRight: Spacing.SCALE_8,
  },
  categoryItemOrdered: {
    paddingLeft: 0,
  },
  itemContainer: {},
  imageItem: {
    borderRadius: 10,
    height: '70px',
    width: '70px',
    objectFit: 'cover',
  },
  imageItemContainer: {},
  backgroundItemSelected: {
    borderRadius: 0,
    backgroundColor: 'rgba(182, 19, 24, 0.2)',
  },
}));

const MenuTab = () => {
  const classes = useStyles();
  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);
  const menu = useSelector((s: State) => s.sectionUser.activityDetail?.menu);
  const orderCart = useSelector((s: State) => s.sectionUser.orderCart);
  const [item, setItem] = useState<ItemForMenu | null>(null);
  const [category, setCategory] = useState<CategoryForMenu | null>(null);
  const [categoryItems, setCategoryItems] = useState<ItemForMenu[]>([]);
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const itemClicked = (i: ItemForMenu, c: CategoryForMenu) => {
    setItem(i);
    setCategory(c);
    setShowItemModal(true);
  };

  const onConfirm = (itemOrder: OrderCartItem) => {
    setShowItemModal(false);
    if (itemOrder) {
      const index = orderCart.findIndex((o) => _.isEqual(o, itemOrder));
      const newOrderCart = [...orderCart];
      if (index > -1) {
        newOrderCart[index].count++;
      } else {
        newOrderCart.push(itemOrder);
      }
      dispatch(updateOrderCart({orderCart: newOrderCart}));
    }
  };

  const getItemOrdered = (id: string) => {
    return orderCart.find((o) => o.itemID === id);
  };

  const getItemTotalCount = (id: string) => {
    return _.sum(orderCart.filter((o) => o.itemID === id).map((o) => o.count));
  };

  const openImage = (image: any) => {
    // open image
  };

  return (
    <div className={classes.root}>
      {menu?.categories && menu?.categories.length > 0 ? (
        menu.categories.map((c) => (
          <div className={classes.categoryContainer} id={c.id} key={c.id}>
            <CustomRow className={classes.titleCategory}>
              <CustomText size={'lg'} fontFamily={'Goda'} bold color={'primary'}>
                {c.name.toUpperCase()}
              </CustomText>
              {c.discountPercentage && (
                <CustomText size={'sm'} style={{marginLeft: Spacing.SCALE_8}} color={'primary'}>
                  (SCONTO DEL {c.discountPercentage}%)
                </CustomText>
              )}
            </CustomRow>
            {c?.description && (
              <div className={classes.description}>
                <CustomRow>
                  <CustomText color={'primary'}>DESCRIZIONE</CustomText>
                </CustomRow>
                <CustomRow>
                  <CustomText>{c?.description}</CustomText>
                </CustomRow>
              </div>
            )}
            <div className={classes.itemContainer}>
              {c.items && c.items.length > 0
                ? c.items.map((i) => (
                    <CustomCard
                      className={getItemTotalCount(i.id) > 0 ? classes.backgroundItemSelected : ''}
                      key={i.id}
                      plain
                      padding={'sm'}
                      onClick={() => itemClicked(i, c)}
                    >
                      <GridContainer>
                        <GridItem xs={i.image ? 9 : 12}>
                          {getItemOrdered(i.id) && (
                            <div style={{flexDirection: 'column'}}>
                              <div className={classes.itemOrdered} />
                            </div>
                          )}
                          <div>
                            <CustomRow>
                              {getItemOrdered(i.id) && (
                                <CustomText
                                  bold
                                  color="primary"
                                  style={{
                                    marginRight: Spacing.SCALE_6,
                                  }}
                                >
                                  {getItemTotalCount(i.id)}x
                                </CustomText>
                              )}
                              <CustomText bold>{i.name}</CustomText>
                            </CustomRow>
                            {!i.ingredients?.length && !!i.description && (
                              <CustomText size={'sm'}>{i.description}</CustomText>
                            )}
                            {!!i.ingredients?.length && (
                              <CustomText size={'sm'}>
                                {i.ingredients.map((ingrendient) => _.upperFirst(ingrendient.name)).join(', ')}
                              </CustomText>
                            )}
                            {i.discountPercentage ? (
                              <>
                                <CustomRow marginTop>
                                  <CustomText color={'primary'} lineThrough>
                                    {i.price / (1 - i.discountPercentage / 100)}€
                                  </CustomText>
                                  {i.discountPercentage && (
                                    <CustomText color={'primary'} style={{marginLeft: Spacing.SCALE_12}}>
                                      (-{i.discountPercentage}%)
                                    </CustomText>
                                  )}
                                </CustomRow>
                                <CustomRow marginTop>
                                  <CustomText color={'primary'} bold>
                                    {i.price} €
                                  </CustomText>
                                </CustomRow>
                              </>
                            ) : (
                              <CustomRow marginTop>
                                <CustomText color={'primary'}>{i.price} €</CustomText>
                              </CustomRow>
                            )}
                          </div>
                        </GridItem>
                        {i.image && !!i.image.url && (
                          <GridItem xs={3} justifyContent={'end'}>
                            <img className={classes.imageItem} src={i.image.url} />{' '}
                          </GridItem>
                        )}
                      </GridContainer>
                    </CustomCard>
                  ))
                : !!c && (
                    <CustomRow center marginTop>
                      <CustomText bold>Nessun elemento presente nella categoria</CustomText>
                    </CustomRow>
                  )}
            </div>
          </div>
        ))
      ) : (
        <CustomRow center marginTop>
          <CustomText bold>L'attività non ha ancora creato il suo menù</CustomText>
        </CustomRow>
      )}
      <ModalItem
        open={showItemModal}
        onClose={() => setShowItemModal(false)}
        onConfirm={onConfirm}
        item={item}
        category={category}
        hideAddButton={
          !activityDetail?.homeServiceSettings?.enabled &&
          !activityDetail?.takeawaySettings?.enabled &&
          !activityDetail?.tableReservationSettings?.enabled
        }
      />
    </div>
  );
};

export default MenuTab;
