import {LANGUAGE} from '../constants/language';

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const isProd = () => {
  return process.env.NODE_ENV === 'production';
};

export const config = {
  AppleAppID: '1544285724',
  GooglePackageName: 'com.ordify',
  whatsappMessageText: "Salve, ho trovato la sua attività tramite l'app Ordify",
  society: 'NextApp Studio s.r.l.s.',
  pec: 'nextapp@pec.nextappstudio.com',
  calendlySmartMenuInfo: 'https://calendly.com/ordify/30min',
};

export const secrets = {
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
  googleOAuthClientID: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
};

export const socialLink = {
  instagram: 'https://www.instagram.com/ordify_official/',
  facebook: 'https://www.facebook.com/ordifyapp',
  telegram: 'https://t.me/ordify_sconti',
};

export const DEFAULT_LANGUAGE = LANGUAGE.IT;

export const dateConfig = {
  format1: 'dddd, MMM Do', // shows date like Monday, June 1st
  format2: 'DD/MM', // shows date like 09/06
  format3: 'DD/MM/YYYY', // day month year
  format4: 'ddd, MMM DD', // eg. "Mon, Sep 07"
  format5: 'YYYY-MM-DD',
};
