import CustomLoader from '../atoms/CustomLoader';
import Header from './Header/Header';
import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Footer from './Footer/Footer';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import CustomText from '../atoms/Typography/CustomText';
import CustomRow from '../atoms/CustomRow';
import ModalDownloadMobileApp from '../molecules/ModalDownloadMobileApp';
import {HeaderConfigs} from './types/header-configs';
import {Colors} from '../../styles/colors';
import {Fonts} from '../../styles/font';
import {Sizes} from '../../styles/sizes';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12px',
    margin: ({hideHeader}: Props) => (hideHeader ? 'auto' : '140px auto'),
    '@media (max-width: 480px)': {
      margin: ({hideHeader, headerConfigs}: Props) =>
        hideHeader ? 'auto' : `${headerConfigs?.showLogo ? '120' : '80'}px auto 100px`,
    },
  },
  smallContainer: {
    width: '60%',
    padding: '12px',
    margin: '140px auto',
    '@media (max-width: 480px)': {
      margin: '120px auto 100px',
      width: '90%',
    },
    '@media (min-width: 481px) and (max-width: 1024px)': {
      width: '90%',
    },
  },
}));

export default function PageContainer({
  children,
  pageTitle,
  headerConfigs,
  small,
  showModalDownloadApp,
  hideHeader,
  hideFooter,
  titleSize = 'xl',
  titleColor = 'primary',
  titleFontFamily = 'Goda',
  pageLoading = false,
}: Props) {
  const classes = useStyles({hideHeader, headerConfigs});

  const containerClasses = classNames({
    [classes.container]: true,
    [classes.smallContainer]: small,
  });

  return (
    <>
      {showModalDownloadApp && <ModalDownloadMobileApp />}
      {!hideHeader && (
        <Header
          color={headerConfigs?.color ?? 'transparent'}
          textColor={headerConfigs?.textColor}
          fixed={headerConfigs?.fixed ?? true}
          inverted={headerConfigs?.inverted ?? true}
          links={headerConfigs?.links}
          backButton={headerConfigs?.backButton}
          centerElement={headerConfigs?.centerElement}
          showBackButton={headerConfigs?.showBackButton ?? true}
          showDrawer={headerConfigs?.showDrawer ?? true}
          showLogo={headerConfigs?.showLogo ?? true}
          showLoginButton={headerConfigs?.showLoginButton ?? true}
          showHeaderLinksOnWeb={headerConfigs?.showHeaderLinksOnWeb ?? true}
          changeColorOnScroll={{
            height: 60,
            color: 'white',
            textColor: headerConfigs?.textColor,
          }}
        />
      )}
      <div className={containerClasses}>
        {!!pageTitle && (
          <>
            <CustomRow center marginBottom={20}>
              <CustomText center bold size={titleSize} color={titleColor} fontFamily={titleFontFamily}>
                {pageTitle}
              </CustomText>
            </CustomRow>
          </>
        )}
        {children}
      </div>
      {!hideFooter && (
        <Hidden smDown>
          <Footer />
        </Hidden>
      )}
      {pageLoading && <CustomLoader show={pageLoading} />}
    </>
  );
}

type Props = {
  children?: React.ReactNode;
  headerConfigs?: HeaderConfigs;
  pageTitle?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  small?: boolean;
  showModalDownloadApp?: boolean;
  titleSize?: Sizes;
  titleColor?: Colors;
  titleFontFamily?: Fonts;
  pageLoading?: boolean;
};
