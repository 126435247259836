import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomText from '../../../components/atoms/Typography/CustomText';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomInput from '../../../components/atoms/CustomInput/CustomInput';
import CustomButton from '../../../components/atoms/CustomButtons/CustomButton';
import {isPhoneNumberValid, patchPhoneNumber} from '../../../utils/commonFunctions';
import {AppMessageType} from '../../../components/atoms/CustomToast';
import {showToastMessage} from '../../../services/toastService';
import {submitPhoneNumber} from '../../../redux/actions/authActions';
import {State} from '../../../redux/reducers';
import {navigate} from '../../../services/navigationService';
import {HOME} from '../../../constants';
import PageLoginContainer from '../_Shared/PageLoginContainer';

const SubmitPhoneNumber = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const authPayload = useSelector((s: State) => s.auth.authPayload);

  useEffect(() => {
    if (!authPayload) {
      navigate(HOME);
    }
  }, [authPayload]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        confirm();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [phoneNumber]);

  const confirm = () => {
    if (!phoneNumber || !phoneNumber.trim()) {
      showToastMessage(AppMessageType.ERROR, 'Campo numero di telefono vuoto');
      return null;
    }
    if (!isPhoneNumberValid(phoneNumber)) {
      showToastMessage(AppMessageType.ERROR, 'Numero di telefono non valido');
      return null;
    }
    const phoneNumberCorrect = patchPhoneNumber(phoneNumber);
    dispatch(submitPhoneNumber(phoneNumberCorrect, false));
  };

  return (
    <>
      <PageLoginContainer>
        <CustomRow center>
          <CustomText center bold size={'xxl'} color={'primary'} fontFamily={'Goda'}>
            INSERISCI IL NUMERO DI TELEFONO
          </CustomText>
        </CustomRow>
        <CustomRow marginTop center>
          <CustomText center>
            <b>Manca pochissimo!</b> Inserisci il tuo numero di telefono per una maggiore sicurezza nell'effettuare i
            tuoi ordini.
          </CustomText>
        </CustomRow>
        <GridContainer marginTop>
          <GridItem xs={12}>
            <CustomInput
              placeholder="Numero di telefono"
              value={phoneNumber}
              onChange={(text: string) => setPhoneNumber(text)}
            />
          </GridItem>
        </GridContainer>
        <CustomRow marginTop>
          <CustomButton fullWidth onClick={confirm} title={'Conferma'} />
        </CustomRow>
      </PageLoginContainer>
    </>
  );
};
export default SubmitPhoneNumber;
