import React, {useEffect} from 'react';
import blackBackground from '../../assets/images/smart-menu/desktop/background_nero.jpg';
import blackBackgroundMobile from '../../assets/images/smart-menu/mobile/background_nero_mobile.jpg';
import {SMART_MENU_HEADER_LINKS} from '../../constants';
import BackgroundImage from '../../components/organisms/BackgroundImage';
import Footer from '../../components/organisms/Footer/Footer';
import Header from '../../components/organisms/Header/Header';
import AdvantagesSection from './components/advantagesSection';
import ContactSection from './components/contactSection';
import TopSection from './components/topSection';
import HowItWorksSection from './components/howItWorkSection';
import InterfaceSection from './components/interfaceSection';
import PricingSection from './components/pricing';
import SentenceSection from './components/sentenceSection';
import VideoPresentationSection from './components/videoPresentationSection';
import VideoTutorialSection from './components/videoTutorialSection';

const SmartMenuLandingPage = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <div style={{overflow: 'hidden'}}>
      <Header
        color="transparent"
        textColor={'white'}
        links={SMART_MENU_HEADER_LINKS}
        fixed
        isHome
        showLoginButton={false}
        changeColorOnScroll={{
          height: 100,
          color: 'white',
          textColor: 'primary',
        }}
      />
      <TopSection />
      <BackgroundImage image={blackBackground} imageMobile={blackBackgroundMobile} style={{marginTop: '300px'}}>
        <VideoPresentationSection />
        <AdvantagesSection id={'vantaggi'} />
      </BackgroundImage>
      <SentenceSection />
      <InterfaceSection id={'interfaccia'} />
      <HowItWorksSection id={'come-funziona'} />
      {/*<VideoTutorialSection />*/}
      <PricingSection id={'prezzi'} />
      <ContactSection id={'parla-con-noi'} />
      <Footer />
    </div>
  );
};

export default SmartMenuLandingPage;
