import React from 'react';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import hamburgerImage from '../../../../assets/images/landing/launchSection/hamburgerImage.png';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import './launchSectionStyle.scss';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import Hidden from '@material-ui/core/Hidden';

export default function LaunchSection({id}: Props) {
  return (
    <GridContainer marginTop={100} fullWidth={true}>
      <Hidden mdUp>
        <GridItem justifyContent={'end'} noPadding={'right'} xs={12} sm={12} md={12} lg={6}>
          <img alt={'launch-image'} className={'imgOrder'} src={hamburgerImage} />
        </GridItem>
      </Hidden>
      <GridItem marginTop={100} direction={'column'} alignItems={'start'} xs={12} sm={12} md={12} lg={6}>
        <GridContainer className={'gridItemTextLeft'}>
          <GridItem xs={12} sm={12} md={12}>
            <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
              PAUSA PRANZO CORTA?
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomText bold fontFamily={'Goda'} color={'primary'} size={'xxl'}>
              NON È UN PROBLEMA.
            </CustomText>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} marginTop>
            <CustomText size={'lg'}>
              <b>Pronto In Tavola</b> è un servizio inedito che ti permette di trovare già pronto in tavola ciò che hai
              ordinato.
            </CustomText>
          </GridItem>
          <GridItem marginTop={30} xs={12} sm={12} md={12}>
            <CustomText italic={true} size={'lg'}>
              Niente più corse, niente più attese, goditi il tuo piatto senza guardare costantemente le lancette.
            </CustomText>
          </GridItem>
          <GridItem marginTop={30} xs={12} sm={12} md={12}>
            <CustomText size={'lg'}>Il cibo ti aspetta pronto in tavola !</CustomText>
          </GridItem>
          <GridItem className={'hidden'} marginTop={50} xs={12} sm={12} md={12}>
            <CustomButton size={'lg'}>Dimmi di più</CustomButton>
          </GridItem>
        </GridContainer>
      </GridItem>
      <Hidden mdDown>
        <GridItem justifyContent={'end'} noPadding={'right'} xs={12} sm={12} md={12} lg={6}>
          <img alt={'launch-image'} className={'imgOrder'} src={hamburgerImage} />
        </GridItem>
      </Hidden>
    </GridContainer>
  );
}

type Props = {
  id?: string;
};
