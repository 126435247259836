import {applyMiddleware, compose, createStore} from 'redux';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import sagas from './saga';

// devtools for debugging in dev environment.
const devTools =
  // @ts-ignore
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a: any) => a;

const middleware = [];
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);
const enhancers = [applyMiddleware(...middleware)];

const store = createStore(rootReducer, undefined, compose(...enhancers, devTools));

sagaMiddleware.run(sagas);

export default store;
