import {makeStyles} from '@material-ui/styles';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import CustomRow from '../../../components/atoms/CustomRow';
import GridContainer from '../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../components/atoms/Grid/GridItem';
import CustomText from '../../../components/atoms/Typography/CustomText';
import TitleWithHorizontalLine from '../../../components/molecules/TitleWithHorizontalLine';
import PageContainer from '../../../components/organisms/PageContainer';
import {HeaderConfigs} from '../../../components/organisms/types/header-configs';
import {ACTIVITY_DETAIL, ACTIVITY_DETAIL_MENU} from '../../../constants';
import {Menu} from '../../../models/menu/menu';
import {SmartMenuTemplateConfig} from '../../../models/smartMenu/smart-menu-config';
import {loadMenu, loadSmartMenuTemplateConfig, setSelectedMenu} from '../../../redux/actions/smartMenuActions';
import {State} from '../../../redux/reducers';
import {navigate} from '../../../services/navigationService';
import {buildSearch, fromBase64ToString} from '../../../utils/commonFunctions';
import LanguageSelector from '../components/LanguageSelector';
import MenuChoiceElement from '../components/MenuChoiceElement';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '10px',
    padding: '0px 20px',
    '@media (max-width: 480px)': {
      padding: '0',
    },
  },
}));

const headerConfigs: HeaderConfigs = {
  showBackButton: false,
  showLoginButton: false,
  showHeaderLinksOnWeb: false,
  showDrawer: false,
};

const ChooseMenu = ({}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation('smartmenu');
  const [searchParams] = useSearchParams();
  const {activityID} = useParams<{activityID: string}>();
  const [currentTemplateConfig, setCurrentTemplateConfig] = useState<SmartMenuTemplateConfig>();

  const menuList = useSelector((s: State) => s.smartMenu.menuList);
  const templateConfig = useSelector((s: State) => s.smartMenu.templateConfig);
  const templateConfigLoading = useSelector((s: State) => s.smartMenu.templateConfigLoading);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    if (activityID && !menuList) {
      dispatch(loadSmartMenuTemplateConfig({merchantID: activityID}));
    }
  }, []);

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      if (menuList.length < 2) {
        navigate(`${ACTIVITY_DETAIL}/${activityID}/${ACTIVITY_DETAIL_MENU}`);
      }
    }
  }, [menuList]);

  useEffect(() => {
    if (templateConfig) {
      setCurrentTemplateConfig(templateConfig);
      if (activityID) {
        dispatch(loadMenu({id: activityID}));
      }
    }
    handleConfigurationsParam();
  }, [activityID, searchParams, templateConfig]);

  const handleConfigurationsParam = () => {
    const configuration = searchParams.get('configuration');
    if (configuration) {
      const payload = fromBase64ToString(configuration);
      const config: SmartMenuTemplateConfig = JSON.parse(payload);
      setCurrentTemplateConfig(config);
    }
  };

  const onSelectMenu = (m: Menu) => {
    dispatch(setSelectedMenu({menu: m}));
    const search = buildSearch(searchParams);
    navigate(`${ACTIVITY_DETAIL}/${activityID}/${ACTIVITY_DETAIL_MENU}/${m.id}`, {}, search);
  };

  const onLanguageChange = (_language: string) => {
    dispatch(loadMenu({id: activityID as string, language: _language}));
  };

  if (templateConfigLoading)
    return <PageContainer pageLoading={templateConfigLoading} headerConfigs={headerConfigs} hideFooter />;

  if (!currentTemplateConfig)
    return (
      <PageContainer pageTitle={t('service_not_available.title')} headerConfigs={headerConfigs} hideFooter>
        <CustomRow center>
          <CustomText center>{t('service_not_available.description')}</CustomText>
        </CustomRow>
      </PageContainer>
    );

  return (
    <PageContainer
      hideFooter
      headerConfigs={{
        ...headerConfigs,
        showLogo: !currentTemplateConfig?.languages?.length,
        centerElement: !!currentTemplateConfig?.languages?.length ? (
          <LanguageSelector
            languages={currentTemplateConfig.languages}
            onLanguageChange={onLanguageChange}
            color={currentTemplateConfig.color}
          />
        ) : undefined,
      }}
    >
      <TitleWithHorizontalLine color={currentTemplateConfig.color} fontFamily={currentTemplateConfig.fontFamily}>
        {t('choose_menu').toUpperCase()}
      </TitleWithHorizontalLine>
      <div className={classes.main}>
        <GridContainer marginTop={20}>
          {menuList?.map((m) => (
            <GridItem key={m.id} noPadding md={12} lg={12}>
              <MenuChoiceElement menu={m} onClick={onSelectMenu} />
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </PageContainer>
  );
};

type Props = {};

export default ChooseMenu;
