import React from 'react';
import classNames from 'classnames';

import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import CustomCard from '../Card/CustomCard';
import CustomCardBody from '../Card/CustomCardBody';
import CustomCardHeader from '../Card/CustomCardHeader';

import styles from './customTabsStyle';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import {SvgIconTypeMap} from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function CustomTabs({tabs, title, rtlActive, plainTabs, onTabSelected}: Props) {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: any, v: number) => {
    setValue(v);
    onTabSelected && onTabSelected(tabs[v].key);
  };
  const classes = useStyles();
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });

  return (
    <CustomCard plain={plainTabs}>
      <CustomCardHeader>
        {title && <div className={cardTitle}>{title}</div>}
        <Tabs
          variant={'scrollable'}
          color={'primary'}
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          classes={
            {
              // root: classes.tabsRoot,
              // indicator: classes.displayNone,
            }
          }
        >
          {tabs.map((prop, key) => {
            let icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: typeof prop.tabIcon === 'string' ? <Icon>{prop.tabIcon}</Icon> : <prop.tabIcon />,
              };
            }
            return (
              <Tab
                classes={{
                  // root: classes.tabRootButton,
                  labelIcon: classes.tabLabel,
                  // selected: classes.tabSelected,
                  // wrapper: classes.tabWrapper,
                }}
                className={classes.tabLabel}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CustomCardHeader>
      <CustomCardBody noPadding>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CustomCardBody>
    </CustomCard>
  );
}

type Props = {
  title?: string;
  tabs: TabModel[];
  rtlActive?: boolean;
  plainTabs?: boolean;
  onTabSelected?: (key: string) => void;
};

export interface TabModel {
  key: string;
  tabName: string;
  tabIcon?: string | OverridableComponent<SvgIconTypeMap>;
  tabContent: any;
}
