import {UserInfo} from '../../models/user/user-info.interface';
import {UserActionTypes} from '../actions/userActions';
import {Role} from '../../models/auth/role';

export interface UserState {
  userInfo: UserInfo | null;
  currentRole: Role | null;
  isFirstAccess: boolean;
  radius: number;
}

const initialState: UserState = {
  userInfo: null as UserInfo | null,
  currentRole: null,
  isFirstAccess: false,
  radius: 20000,
};

const userReducer = (state: UserState = initialState, action: {type: string; payload: any}) => {
  switch (action.type) {
    case UserActionTypes.LOAD_USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload.userInfo,
        currentRole: action.payload.userInfo?.roles ? action.payload.userInfo.roles[0] : null,
      };
    }
    case UserActionTypes.SET_FIRST_ACCESS:
      return {...state, isFirstAccess: action.payload.firstAccess};
    case UserActionTypes.SET_CURRENT_ROLE:
      return {...state, currentRole: action.payload.role};
    case UserActionTypes.SET_LOCATION_RADIUS:
      return {...state, radius: action.payload.radius};
    case UserActionTypes.RESET_USER_INFO:
      return {...initialState};
    default:
      return state;
  }
};

export default userReducer;
