import {OrderCartItem} from '../../../../models/orders/order-cart-item';
import {makeStyles} from '@material-ui/styles';
import CustomDialog from '../../../../components/atoms/CustomDialog';
import CustomListItem from '../../../../components/molecules/CustomListItem';
import OrderCartItemElement from './OrderCartItemElement';
import React from 'react';
import CustomRow from '../../../../components/atoms/CustomRow';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {Spacing} from '../../../../styles/spacing';

const ModalOrderSummary = ({
  open,
  onClose,
  orderCart,
  onItemClick,
  removeOptionChoice,
  totalPrice,
  continueSection,
}: Props) => {
  const classes = useStyle();

  return (
    <>
      <CustomDialog
        className={classes.dialogBox}
        fullScreenOnMobile
        onBottom
        title={'IL TUO ORDINE'}
        noPadding
        open={open}
        onClose={onClose}
        extraButtons={
          <div style={{width: '100%', padding: '8px 0'}}>
            <CustomRow marginTop center>
              <CustomText>Totale:</CustomText>
              <CustomText bold style={{marginLeft: Spacing.SCALE_12}}>
                {totalPrice} €
              </CustomText>
            </CustomRow>
            {continueSection}
          </div>
        }
      >
        <div className={classes.baseStyle}>
          {orderCart &&
            orderCart.length > 0 &&
            orderCart.map((o, i) => (
              <CustomListItem divider={false} padding={'sm'} key={i} onClick={() => onItemClick(o, i)}>
                <OrderCartItemElement orderCartItem={o} removeOptionChoice={() => removeOptionChoice(i)} />
              </CustomListItem>
            ))}
        </div>
      </CustomDialog>
    </>
  );
};

type Props = {
  open?: boolean;
  onClose?: any;
  orderCart?: OrderCartItem[];
  totalPrice: number;
  removeOptionChoice: (index: number) => void;
  onItemClick: (itemOrder: OrderCartItem, index: number) => void;
  continueSection: any;
};

const useStyle = makeStyles({
  baseStyle: {
    minWidth: '380px',
    '@media (max-width: 480px)': {
      minWidth: 'auto',
    },
  },
  dialogBox: {
    bottom: '0px',
    position: 'absolute',
    borderRadius: '40px 40px 0 0',
    overflowY: 'visible',
  },
});

export default ModalOrderSummary;
