/**
 * Context API used for Auth related information and methods.
 * I am keeping everything related to the authentication and PrivateRoute in this one file.
 */
import React, {createContext, useContext} from 'react';
import {useProvideAuth} from './useProvideAuth';

// Context API used for Auth related information and methods.
const authContext = createContext(null);

// Context Provider to wrap the whole app within and make auth information available.
export function ProvideAuth({children}: any) {
  const auth = useProvideAuth() as any;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useAuth(): any {
  return useContext(authContext);
}
