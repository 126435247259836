import {useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import CustomCard from '../../../../components/atoms/Card/CustomCard';
import CustomAccordion from '../../../../components/atoms/CustomAccordion';
import CustomButton from '../../../../components/atoms/CustomButtons/CustomButton';
import CustomRow from '../../../../components/atoms/CustomRow';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import {SmartMenuProductServiceAvailability} from '../../../../models/smartMenu/product-service';
import {
  PRICE_RECURRING_INTERVALS,
  PriceRecurringInterval,
  ProductType,
  SubscriptionChoiceByPeriod,
} from '../../../../models/smartMenu/subscription';
import {BLACK, primaryColor, TRASPARENT} from '../../../../styles/colors';
import whiteBackground from '../../../../assets/images/smart-menu/desktop/background_carta.jpg';
import {config} from '../../../../utils/config';

const useStyle = makeStyles((theme) => ({
  disabled: {
    borderRadius: '10px',
    position: 'absolute',
    backgroundColor: BLACK,
    top: 0,
    left: 0,
    zIndex: 5,
    width: '100%',
    height: '100%',
    marginLeft: '0',
  },
  cardContainer: {
    borderRadius: '24px',
    padding: '20px 12px',
    border: '1px solid ' + primaryColor,
    backgroundImage: 'url(' + whiteBackground + ')',
  },
  accordion: {
    backgroundColor: TRASPARENT,
    boxShadow: 'none',
  },
}));

const ProductElement = ({productServiceList, product, onClick}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyle();

  const getPeriod = (p?: PriceRecurringInterval) => {
    return p === PRICE_RECURRING_INTERVALS.MONTH ? 'al mese' : "all'anno";
  };

  const calculateDiff = () => {
    const monthly = product.products.find((p) => p.priceRecurringInterval === PRICE_RECURRING_INTERVALS.MONTH);
    const yearly = product.products.find((p) => p.priceRecurringInterval === PRICE_RECURRING_INTERVALS.YEAR);
    if (monthly && yearly) {
      return monthly.priceAmount * 12 - yearly.priceAmount;
    }
    return 0;
  };

  const talkWithUs = () => {
    open(config.calendlySmartMenuInfo);
  };

  const Header = () => (
    <CustomRow center full>
      <CustomText center italic underline>
        Cosa offre l'abbonamento?
      </CustomText>
    </CustomRow>
  );

  const CollapsedContent = () => (
    <div style={{width: '100%'}}>
      {productServiceList ? (
        productServiceList[product.type].map(
          (p) =>
            p.active && (
              <CustomRow key={`${product.name}-${p.service.id}`} center marginTop>
                <CustomText center>
                  {p.service.name}
                  {p.availability && (
                    <>
                      {': '}
                      <CustomText bold>{p.availability === 'nolimit' ? 'illimitate' : p.availability}</CustomText>
                    </>
                  )}
                </CustomText>
              </CustomRow>
            ),
        )
      ) : (
        <CustomRow center>
          <CustomText>Servizi in caricamento...</CustomText>
        </CustomRow>
      )}
    </div>
  );

  return (
    <>
      <CustomCard className={classes.cardContainer} onClick={product.enabled ? onClick : undefined}>
        <GridContainer>
          <GridItem centerHorizontal>
            <CustomText color={'primary'} size={'lg'} fontFamily={'Goda'} bold>
              {product.name.toUpperCase()}
            </CustomText>
          </GridItem>
          <GridItem xs={12} justifyContent={'center'} marginTop={20}>
            <div style={{width: '40px', border: '1px solid black'}}></div>
          </GridItem>
          <GridItem xs={12} justifyContent={'center'} alignItems={'center'} marginTop={20} direction={'column'}>
            {product.products.map((p) => (
              <CustomRow key={p.priceId}>
                <CustomText color={'primary'} bold>
                  €{p.priceAmount}
                </CustomText>
                <CustomText style={{marginLeft: '6px'}}>{getPeriod(p.priceRecurringInterval)}</CustomText>
              </CustomRow>
            ))}
          </GridItem>
          <GridItem xs={12} justifyContent={'center'} marginTop={20}>
            <CustomText center>
              <CustomText bold>Risparmi {calculateDiff()} €</CustomText> facendo l'abbonamento annuale
            </CustomText>
          </GridItem>
          <GridItem xs={12} justifyContent={'center'} marginTop={20}>
            <CustomAccordion className={classes.accordion} header={<Header />}>
              <CollapsedContent />
            </CustomAccordion>
          </GridItem>
          <GridItem xs={12} justifyContent={'center'} marginTop={20}>
            <CustomButton color={'primary'} title={'Parla con noi'} onClick={talkWithUs} />
          </GridItem>
        </GridContainer>
        {!product.enabled && (
          <GridContainer className={classes.disabled}>
            <GridItem centerHorizontal centerVertical>
              <CustomText bold size={'lg'} color={'#E5B73F'} center>
                PIANO {product.name.toUpperCase()} DISPONIBILE A BREVE
              </CustomText>
            </GridItem>
          </GridContainer>
        )}
      </CustomCard>
    </>
  );
};

type Props = {
  productServiceList?: Record<ProductType, SmartMenuProductServiceAvailability[]>;
  product: SubscriptionChoiceByPeriod;
  onClick?: any;
};

export default ProductElement;
