import {LANGUAGE} from '../constants/language';
import {BASE_URL, http} from './axios';
import {Category} from '../models/menu/category';
import {CustomResponse} from '../models/shared/custom-response';
import {getUserInfo} from '../services/storageService';
import {Menu} from '../models/menu/menu';

const CONTROLLER_PATH = '/menu';

export const getMenu = async (merchantID: string, language: string = LANGUAGE.IT): Promise<CustomResponse<Menu[]>> => {
  return http.get(`${BASE_URL}${CONTROLLER_PATH}`, {params: {merchantID, language}});
};

export const getCategories = async (userID?: string): Promise<CustomResponse<Category[]>> => {
  const userInfo = await getUserInfo();
  return http.get(`${BASE_URL}${CONTROLLER_PATH}/categories/get-all?userID=${userID || userInfo?.id}`);
};
