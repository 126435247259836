import {getActivityTypes} from '../../../api/globalConfigService';
import {ActivityType} from '../../../models/globalConfig/activity-type';
import {CustomResponse, Status} from '../../../models/shared/custom-response';
import {disableLoader, enableLoader, loadActivityTypesSuccess} from '../../actions/globalActions';
import {State} from '../../reducers';
import {put, select} from 'redux-saga/effects';

export default function* loadActivityTypesAsync() {
  let activityTypes: ActivityType[] = yield select((state: State) => state.global.lists.activityTypes);
  if (activityTypes && activityTypes.length === 0) {
    yield put(enableLoader());
    const response: CustomResponse<ActivityType[]> = yield getActivityTypes();
    if (response.status === Status.OK) {
      yield put(loadActivityTypesSuccess(response.payload));
    }
    yield put(disableLoader());
  }
}
