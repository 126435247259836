import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useSelector} from 'react-redux';
import {State} from '../../../../redux/reducers';
import moment from 'moment';
import CustomText from '../../../../components/atoms/Typography/CustomText';
import GridContainer from '../../../../components/atoms/Grid/GridContainer';
import GridItem from '../../../../components/atoms/Grid/GridItem';
import {Spacing} from '../../../../styles/spacing';
import CustomLabelText from '../../../../components/atoms/CustomLabelText';
import {
  Call,
  Computer,
  DirectionsBike,
  Facebook,
  Instagram,
  Restaurant,
  RoomService,
  WhatsApp,
} from '@material-ui/icons';
import {config} from '../../../../utils/config';
import {fixUrl} from '../../../../utils/commonFunctions';
import CustomListItem from '../../../../components/molecules/CustomListItem';
import CustomRow from '../../../../components/atoms/CustomRow';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 10px',
  },
  marginLeft: {
    marginLeft: '15px',
  },
}));

const InfoTab = () => {
  const classes = useStyles();
  const activityDetail = useSelector((s: State) => s.sectionUser.activityDetail);
  const timetables = useSelector((s: State) => s.sectionUser.activityDetail?.info.timetables);
  const [modalAllergyVisible, setModalAllergyVisible] = useState(false);

  const CallNumber = (
    <>
      <CustomText>Chiama {activityDetail?.info.activityName}</CustomText>
      <div>
        <CustomText>{activityDetail?.info.phoneNumber || '-'}</CustomText>
      </div>
    </>
  );
  const Takeaway = (
    <>
      <CustomText>Servizio d'asporto</CustomText>
      <div>
        <CustomText size={'sm'}>Ritira il tuo ordine presso il locale</CustomText>
      </div>
    </>
  );
  const HomeService = () => {
    const radiusAreaList = activityDetail?.homeServiceSettings.radiusAreaList;
    return (
      <>
        <CustomText>Servizio a domicilio</CustomText>
        <div>
          <CustomText size={'sm'}>
            Ricevi il tuo ordine direttamente a casa tua
            {radiusAreaList &&
              radiusAreaList.length > 0 &&
              radiusAreaList.map((r, i) => {
                const radiusInKm = r.radius ? r.radius / 1000 : 0;
                return (
                  <CustomText key={i} size={'sm'}>
                    <br />
                    {'Consegna in un raggio di '}
                    <CustomText size={'sm'} bold>
                      {radiusInKm} km{' '}
                    </CustomText>
                    {r.centerRadiusDifferent ? `da ${r.centerRadius.cityName}` : 'dalla posizione del ristorante'}
                  </CustomText>
                );
              })}
          </CustomText>
        </div>
      </>
    );
  };
  const TableReservation = (
    <>
      <CustomText>Prenota un tavolo</CustomText>
      <div>
        <CustomText size={'sm'}>Questa attività accetta prenotazioni tavolo</CustomText>
      </div>
    </>
  );
  const AllergyOrIntolleranceAdvice = (
    <div style={{marginRight: Spacing.SCALE_30}}>
      <CustomText>
        Se hai un'intolleranza alimentare o un'allergia (o se una persona per la quale stai effettuando un'ordine ne
        ha), premi qui.
      </CustomText>
    </div>
  );

  const isToday = (dayID: string) => {
    let dayOfWeek = moment().day();
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    return dayOfWeek.toString(10) === dayID;
  };

  return (
    <div className={classes.root}>
      {activityDetail?.takeawaySettings.enabled && (
        <CustomListItem divider={false}>
          <CustomLabelText className={classes.marginLeft} noWrap marginVertical icon={RoomService} element={Takeaway} />
        </CustomListItem>
      )}
      {activityDetail?.homeServiceSettings.enabled && (
        <CustomListItem divider={false}>
          <CustomLabelText
            className={classes.marginLeft}
            noWrap
            marginVertical
            icon={DirectionsBike}
            element={<HomeService />}
          />
        </CustomListItem>
      )}
      {activityDetail?.tableReservationSettings?.enabled && (
        <CustomListItem divider={false}>
          <CustomLabelText
            className={classes.marginLeft}
            noWrap
            marginVertical
            icon={Restaurant}
            element={TableReservation}
          />
        </CustomListItem>
      )}
      <CustomListItem divider={false} onClick={() => window.open(`tel:${activityDetail?.info.phoneNumber}`)}>
        <CustomLabelText className={classes.marginLeft} marginVertical icon={Call} element={CallNumber} />
      </CustomListItem>
      {activityDetail?.info.whatsapp && (
        <CustomListItem
          divider={false}
          onClick={() =>
            window.open(`https://wa.me/${activityDetail.info.phoneNumber}?text=${config.whatsappMessageText}`)
          }
        >
          <CustomLabelText
            className={classes.marginLeft}
            marginVertical
            iconColor={'whatsapp'}
            icon={WhatsApp}
            text={'Contattaci tramite Whatsapp'}
          />
        </CustomListItem>
      )}
      {activityDetail?.info.instagramURL && (
        <CustomListItem divider={false} onClick={() => window.open(activityDetail.info.instagramURL)}>
          <CustomLabelText
            className={classes.marginLeft}
            marginVertical
            iconColor={'instagram'}
            icon={Instagram}
            text={'Visita la pagina Instagram'}
          />
        </CustomListItem>
      )}
      {activityDetail?.info.facebookURL && (
        <CustomListItem divider={false} onClick={() => window.open(activityDetail.info.facebookURL)}>
          <CustomLabelText
            className={classes.marginLeft}
            marginVertical
            iconColor={'facebook'}
            icon={Facebook}
            text={'Visita la pagina Facebook'}
          />
        </CustomListItem>
      )}
      {activityDetail?.info.websiteURL && (
        <CustomListItem divider={false} onClick={() => window.open(fixUrl(activityDetail.info.websiteURL))}>
          <CustomLabelText className={classes.marginLeft} marginVertical icon={Computer} text={'Visita il sito web'} />
        </CustomListItem>
      )}
      <CustomRow marginTop={40} marginBottom={20}>
        <CustomText style={{marginLeft: '15px'}} bold>
          Orari
        </CustomText>
      </CustomRow>
      {timetables &&
        timetables.days &&
        timetables.days.map((d) => (
          <CustomListItem divider={false} key={d.id} padding={'sm'} noPaddingHorizontal>
            <GridContainer>
              <GridItem xs={6} alignItems={'center'}>
                <CustomText bold={isToday(d.dayID)} color={(isToday(d.dayID) && 'primary') || 'black'}>
                  {d.label}
                </CustomText>
              </GridItem>
              <GridItem xs={6} direction={'column'} alignItems={'end'}>
                {d.closingDay ? (
                  <CustomText right bold color={(isToday(d.dayID) && 'primary') || 'black'}>
                    Giorno di chiusura
                  </CustomText>
                ) : (
                  <>
                    {d.slots &&
                      d.slots.map((s, i) => (
                        <div key={s.id}>
                          {/*<CustomText bold color={(isToday(d.dayID) && 'primary') || 'black'}>*/}
                          {/*  {s.label}*/}
                          {/*</CustomText>*/}
                          <CustomText right bold={isToday(d.dayID)} color={(isToday(d.dayID) && 'primary') || 'black'}>
                            {s.startHour} - {s.endHour}
                          </CustomText>
                        </div>
                      ))}
                  </>
                )}
              </GridItem>
            </GridContainer>
          </CustomListItem>
        ))}
    </div>
  );
};

export default InfoTab;
