import {AnalyticsCallOptions, EventParams, getAnalytics, logEvent, setCurrentScreen} from 'firebase/analytics';
import {initializeApp} from 'firebase/app';
import {
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut as _signOut,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBJ9LQ72wDjZ3Q3lZQoGETNdIePAetP3Xg',
  authDomain: 'ordify-8d2cc.firebaseapp.com',
  databaseURL: 'https://ordify-8d2cc.firebaseio.com',
  projectId: 'ordify-8d2cc',
  storageBucket: 'ordify-8d2cc.appspot.com',
  messagingSenderId: '515061971257',
  appId: '1:515061971257:web:72ab550ba7906ae804d0e8',
  measurementId: 'G-RPWLZSDPBS',
};

const app = initializeApp(firebaseConfig);

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({prompt: 'select_account'});

const facebookAuthProvider = new FacebookAuthProvider();
facebookAuthProvider.setCustomParameters({prompt: 'select_account'});

const auth = getAuth();
auth.languageCode = 'it';

export const signInGoogleWithPopup = () => signInWithPopup(auth, googleAuthProvider);
export const signInGoogleWithRedirect = () => signInWithRedirect(auth, googleAuthProvider);
export const signInFacebookWithPopup = () => signInWithPopup(auth, facebookAuthProvider);
export const signInFacebookWithRedirect = () => signInWithRedirect(auth, facebookAuthProvider);
export const getSignInRedirectResults = () => getRedirectResult(auth);
export const signOut = () => _signOut(auth);

export const analytics = getAnalytics(app);
export const logAnalyticsEvent = (
  eventName: EventName,
  eventParams?: AnalyticsEventParams,
  options?: AnalyticsCallOptions,
) => {
  logEvent(analytics, eventName, eventParams, options);
};
export const logPageChange = () => {
  setCurrentScreen(analytics, `${window.location.hash.replace('#', '')}`);
};

type EventName =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'exception'
  | 'page_change'
  | 'search'
  | 'select_content'
  | 'select_item'
  | 'select_promotion'
  | string;

interface AnalyticsEventParams {
  coupon?: EventParams['coupon'];
  currency?: EventParams['currency'];
  items?: EventParams['items'];
  payment_type?: EventParams['payment_type'];
  checkout_step?: EventParams['checkout_step'];
  checkout_option?: EventParams['checkout_option'];
  description?: EventParams['description'];
  fatal?: EventParams['fatal'];
  search_term?: EventParams['search_term'];
  content_type?: EventParams['content_type'];
  item_id?: EventParams['item_id'];
  item_list_name?: EventParams['item_list_name'];
  item_list_id?: EventParams['item_list_id'];
  promotion_id?: EventParams['promotion_id'];
  promotion_name?: EventParams['promotion_name'];
  page_title?: string;
  page_location?: string;
  page_path?: string;
  [key: string]: any;
}
